import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { YAxisSelector } from './YAxisSelector';
import { Option } from './SelectorDropdown';
import { truncateAndEllipsis } from '../../../../v2/util';
export interface IPlotUnitSelectorProps<T> {
  selectedPlotUnit: Option<T>;
  isPlotUnitSelectorModalOpen?: boolean;
  openPlotUnitSelectorModal?: () => void;
  closePlotUnitSelectorModal?: () => void;
  parentRef?: React.RefObject<HTMLDivElement>;
}

export const PlotUnitSelector = <T,>({
  selectedPlotUnit,
  isPlotUnitSelectorModalOpen,
  openPlotUnitSelectorModal,
  closePlotUnitSelectorModal,
  parentRef,
}: IPlotUnitSelectorProps<T>) => {
  return (
    <YAxisSelector onClick={isPlotUnitSelectorModalOpen ? closePlotUnitSelectorModal : openPlotUnitSelectorModal} parentRef={parentRef}>
      <div className="flex flex-row justify-start items-center gap-x-2 w-fit">
        <div className="text-gray-secondary xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">Plot Unit:</div>
        <div className="text-licorice-noir font-medium xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">{truncateAndEllipsis(selectedPlotUnit.label, 27)}</div>
        <Cog6ToothIcon className={`h-4 w-4 ${isPlotUnitSelectorModalOpen ? 'text-blood-orange-sorbet' : 'text-gray-secondary'}`} />
      </div>
    </YAxisSelector>
  );
};
