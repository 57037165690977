import { useState, useContext, useEffect } from 'react';
import logoUnwrap from '../../../assets/unwrap_full_logo_2_MAIN-bos.svg';
import { useNavigate } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import UserContext from '../../../v2/contexts/UserContext';
import { logError } from '../../../applicationTelemetry';
import { handleUserRedirect } from '../../../v2/util';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { getAuthConfigByEmailDomain } from '../../../AuthConfigs';
import { Password } from '../../baseComponents/Password';
import { VerificationStep } from '../../components/auth/VerificationStep';

const errorMsgs = {
  invalid: 'Invalid email or password.',
  unknown: 'Unknown error.',
};
export const signInIfExistingUser = async (emailInput: string, passwordInput: string) => {
  try {
    await Auth.signIn({ username: emailInput.toLowerCase(), password: passwordInput });
    return true;
  } catch (err: any) {
    if (err.code === 'UserNotFoundException') {
      return false;
    }
    throw err;
  }
};

export default function LoginPage() {
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [loading, setLoading] = useState(false);
  const { setCurrentUser } = useContext(UserContext);

  const [verificationPending, setVerificationPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);

  let navigate = useNavigate();

  const signInWithGoogle = async () => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: window.location.search });
  };

  const loginWithEmail = async () => {
    const externalIDP = getAuthConfigByEmailDomain(emailInput.toLowerCase())?.externalIDP;
    if (externalIDP) {
      setErrorMsg(`Error: Your organization is managed through ${externalIDP}. Please sign in through your ${externalIDP} SSO portal.`);
      return;
    }
    setLoading(true);
    try {
      // you need to handle the case that our user is in our system and not in Cognito.
      await Auth.signIn({ username: emailInput.toLowerCase(), password: passwordInput });
      await setCurrentUser();

      handleUserRedirect(navigate);
    } catch (err: any) {
      logError(err);
      if (err.code === 'UserNotConfirmedException') {
        setVerificationPending(true);
      }
      if (err.response?.status === 401 || err.response?.status === 500) {
        setErrorMsg(errorMsgs.invalid);
      } else {
        setErrorMsg(err.message);
      }
    }
    setLoading(false);
  };
  return (
    <div className="flex min-h-screen bg-white font-circular">
      <div className="flex flex-1 flex-col justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-10 w-auto" src={logoUnwrap} alt="Workflow" />
          </div>

          <div className="mt-12">
            <div className="mt-6">
              {!verificationPending ? (
                <form
                  className="space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    loginWithEmail();
                  }}
                >
                  <div>
                    <label htmlFor="email" className="block text-sm font-light text-gray-secondary">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-buttercream-frosting-100 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-0 sm:text-sm"
                        onChange={(e) => setEmailInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <Password setPasswordInput={(password) => setPasswordInput(password)} />

                  {errorMsg && (
                    <div className="mt-4">
                      <h1 id="error-message" className="text-sm font-medium text-red-600">
                        {errorMsg}
                      </h1>
                    </div>
                  )}

                  <div className="flex items-center justify-between">
                    <div className="flex items-center"></div>

                    <div className="text-sm">
                      <p
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/forgotPassword');
                        }}
                        className="cursor-pointer font-medium text-licorice-noir hover:text-licorice-noir-lighter"
                      >
                        Forgot your password?
                      </p>
                    </div>
                  </div>
                  <div>
                    <Button id="sign-in-button" variant={ButtonVariant.Secondary} text={'Sign in'} submit loadingConfirm={loading} expandWidth />
                    <div className="mt-2">
                      <a
                        id="sign-in-with-google"
                        href="#"
                        className="inline-flex w-full justify-center gap-x-5 rounded-md border border-buttercream-frosting-300 bg-white py-2 px-4 text-md font-medium text-licorice-noir shadow-sm hover:bg-gray-50"
                        onClick={() => signInWithGoogle()}
                      >
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
                          <defs>
                            <path
                              id="a"
                              d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                            />
                          </defs>
                          <clipPath id="b">
                            <use xlinkHref="#a" overflow="visible" />
                          </clipPath>
                          <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
                          <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
                          <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
                          <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
                        </svg>
                        <span className="">Sign in with Google</span>
                      </a>
                    </div>
                    <div className="mt-12">
                      <Button
                        variant={ButtonVariant.Primary}
                        text={'Create a new account'}
                        expandWidth
                        onClick={() => {
                          navigate('/signup' + window.location.search);
                        }}
                        preventDefault
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <VerificationStep email={emailInput} password={passwordInput} fromLogin />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
