import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { BoardAction, BoardActionTypes, BoardReducer, BoardState } from '../reducers/boards/boardReducer';
import { PlotFragment } from '../generated/graphql';
const BoardStateContext = createContext<BoardState | undefined>(undefined);
const BoardDispatchContext = createContext<Dispatch<BoardAction> | undefined>(undefined);

export const BoardProvider = ({ children, initialState }: { children: ReactNode; initialState: BoardState }) => {
  if (!initialState.board) throw new Error('BoardProvider must be initialized with a board');

  const [state, dispatch] = useReducer(BoardReducer, initialState);

  const openChartModal = (selectedChart: PlotFragment) => {
    dispatch({ type: BoardActionTypes.OpenChartModal, payload: { selectedChart } });
  };

  const closeChartModal = () => {
    dispatch({ type: BoardActionTypes.CloseChartModal });
  };

  return (
    <BoardStateContext.Provider value={{ ...state, openChartModal, closeChartModal }}>
      <BoardDispatchContext.Provider value={dispatch}>{children}</BoardDispatchContext.Provider>
    </BoardStateContext.Provider>
  );
};

export const useBoardState = () => {
  const context = useContext(BoardStateContext);
  if (context === undefined) throw new Error('useBoardState must be used within an BoardProvider');

  return context;
};

export const useBoardDispatch = () => {
  const context = useContext(BoardDispatchContext);
  if (context === undefined) throw new Error('useBoardDispatch must be used within an BoardProvider');

  return context;
};
