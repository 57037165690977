import { classNames } from '../v2/util';
import AdjustableLoadingIcon from './AdjustableLoadingIcon';

export enum BadgeSize {
  Small,
  Medium,
  Regular,
  Large,
}

export enum BadgeShape {
  Round,
  Pill,
}

export interface IBadge {
  text: string;
  id: string;
}

interface BadgeProps {
  badge: IBadge;
  id?: string;
  children?: JSX.Element;
  onRemove?: (badge: IBadge) => void;
  onEdit?: (badge: IBadge) => void;
  disabled?: boolean;
  color?: string;
  textColor?: string;
  hoverBg?: string;
  hoverTextColor?: string;
  border?: string;
  borderColor?: string;
  display?: string;
  capitalize?: boolean;
  size?: BadgeSize;
  shape?: BadgeShape;
  loading?: boolean;
  icon?: JSX.Element;
}

const getBadgeSizeClass = (size: BadgeSize) => {
  switch (size) {
    case BadgeSize.Large:
      return 'text-md';
    case BadgeSize.Regular:
      return 'text-xs lg:text-sm';
    case BadgeSize.Medium:
      return 'text-sm';
    case BadgeSize.Small:
      return 'text-xs';
  }
};

const getBadgeShapeClass = (shape: BadgeShape) => {
  switch (shape) {
    case BadgeShape.Round:
      return 'rounded-md py-0.5 px-2';
    case BadgeShape.Pill:
      return 'rounded-full py-1 px-3';
  }
};

const Badge = ({
  badge,
  id,
  disabled,
  onEdit,
  onRemove,
  color = 'bg-licorice-noir',
  textColor = 'text-white',
  hoverBg,
  hoverTextColor,
  border,
  borderColor,
  display,
  capitalize,
  size = BadgeSize.Regular,
  shape = BadgeShape.Round,
  loading,
  icon,
  children,
}: BadgeProps): JSX.Element => {
  if (!id) {
    id = badge.text.replaceAll(' ', '-');
  }
  return (
    <span
      className={classNames(
        `inline-flex items-center border border-buttercream-frosting-100 font-medium ${getBadgeShapeClass(shape)} ${color} ${textColor} duration-300 ${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        }`,
        hoverBg,
        hoverTextColor,
        getBadgeSizeClass(size),
        border,
        borderColor,
        display
      )}
      id={id}
      onClick={() => {
        if (disabled) return;
        if (onEdit) onEdit(badge);
      }}
    >
      {icon && <div className="mr-1">{icon}</div>}
      <div className={`flex flex-row items-center justify-center`}>
        <p className={`${icon ? 'mt-0.5 whitespace-nowrap' : ''}`}>{badge.text !== '' && (capitalize || capitalize == null ? capitalizeFirstLetter(badge.text) : badge.text)}</p>
        {children}
      </div>
      {onRemove && (
        <button
          type="button"
          className="text-white-400 ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full  hover:text-white focus:text-white  focus:outline-none"
          onClick={() => {
            if (disabled) return;
            onRemove(badge);
          }}
        >
          {loading ? (
            <AdjustableLoadingIcon width={3} height={3} color={'text-white'} />
          ) : (
            <svg className="h-2 w-2" id={`badge-remove`} stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          )}
        </button>
      )}
    </span>
  );
};

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export default Badge;
