import { classNames } from '../../v2/util';
import { CampaignModal } from '../components/CampaignModal';
import { CampaignTooltip } from '../components/CampaignTooltip';
import { useCampaignHook } from '../hooks/CampaignHook';

export const PageWrapper = ({
  title,
  styles,
  beta,
  backButton,
  disableMx,
  disableGap,
  children,
}: {
  title: string;
  styles?: string;
  beta?: boolean;
  backButton?: React.ReactNode;
  disableMx?: boolean;
  disableGap?: boolean;
  children?: React.ReactNode;
}) => {
  const { campaign, handleDismissed, handleAcknowledged } = useCampaignHook({ pageName: title });
  //ATTENTION: If you set disableMx to true, you'll need to manage the mx-8 margin inside your entire page.
  return (
    <div id="page-wrapper" className={classNames(`flex flex-col bg-white ${styles}`, disableMx ? '' : ' mx-8', disableGap ? '' : ' gap-y-2')}>
      {backButton}
      <div className={'flex flex-row items-center'}>
        {title ? <h1 className="text-4xl flex flex-col text-licorice-noir mt-4 mb-4 font-recoleta">{title}</h1> : null}
        {beta ? <div className="flex flex-row mx-5 bg-buttercream-frosting h-7 my-1 px-3 rounded-md items-center justify-center">
          <p className="text-xs ">Beta</p>
        </div> : null}
      </div>
      {campaign && campaign.uiType === 'tooltip' ? (
        <CampaignTooltip campaign={campaign} handleAcknowledged={handleAcknowledged} handleDismissed={handleDismissed} />
      ) : null}
      {campaign && campaign.uiType === 'modal' ? (
        <CampaignModal campaign={campaign} handleAcknowledged={handleAcknowledged} handleDismissed={handleDismissed} />
      ) : null}
      <>{children}</>
    </div>
  );
};
