import { PlusIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Action, Integration_Type, Resource, useCreateOauthStateSecretMutation } from '../../generated/graphql';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { useContext } from 'react';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import toast from 'react-hot-toast';

export const ExternalTicketsIntegrationCardBody = ({
  integration,
  redirect,
  setModalOpen,
}: {
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  setModalOpen: (bool: boolean) => void;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { hasPermission } = useContext(PermissionsContext);

  const [createOauthSecretMutation, createOauthData] = useCreateOauthStateSecretMutation();

  const openModalOrAuthRedirect = async (integration: Integration_Type, redirect?: IIntegrationRedirect) => {
    const authRedirectUrl = integration.authRedirect;
    if (authRedirectUrl && redirect?.scraperKey !== integration.scraperKey && integration.orgExternalTicketsIntegration?.length === 0) {
      await createOauthSecretMutation({
        variables: {
          orgId,
        },
        onCompleted(data) {
          const authUrl = new URL(integration.authRedirect ?? '');
          //The URL from the DB might contain important pre-existing state (eg for redirect uris)
          const existingState = authUrl.searchParams.get('state');
          let existingStateObj = {};
          if (existingState) {
            try {
              existingStateObj = JSON.parse(existingState);
              authUrl.searchParams.delete('state');
            } catch (e) {
              console.error('Error parsing existing state:', e);
            }
          }

          const newState = {
            ...existingStateObj,
            secret: data.createOauthStateSecret?.secret_state,
            destinationURL: window.location.origin + window.location.pathname,
            orgId,
          };

          //The redirect_uri is needed when local testing. Otherwise it uses the first on the list, which is the prod Connections api.
          //const url = `${authUrl}&state=${state}&redirect_uri=https://qx7fv2t125.execute-api.us-east-2.amazonaws.com/slack/oauthd`;
          const url = `${authUrl.toString()}&state=${encodeURIComponent(JSON.stringify(newState))}`;
          window.location.href = url;
        },
        onError(error) {
          toast.error('Failed to authenticate - please try again.');
        },
      });
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div key={integration.id} className="divide-y divide-buttercream-frosting-100">
      <div className="block">
        <div className="flex items-center py-4">
          <div className="flex flex-row items-center gap-x-2 w-full">
            <div className="flex flex-row items-center gap-x-4 w-full">
              {integration.logoUrl && <img className="h-10 w-10" src={integration.logoUrl} alt="" />}
              <div className="flex flex-col">
                <h1 className="text-md font-recoleta text-licorice-noir">{integration.title}</h1>
                <p className="flex items-center text-sm text-gray-tertiary">{integration.description}</p>
              </div>
            </div>
          </div>
          <div>
            {integration.orgExternalTicketsIntegration.length > 0 ? (
              <Button
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                disabled={false}
                disabledTooltip="Only Admins can unlink connections."
                icon={<CheckCircleIcon className="h-5 w-5 stroke-2 text-pistachio-gelato" />}
                iconPosition="left"
                text="Connected"
                onClick={() => openModalOrAuthRedirect(integration, redirect)}
              >
                <XCircleIcon className="h-5 w-5" />
              </Button>
            ) : (
              <Button
                text={'Connect'}
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                disabled={!hasPermission(Resource.TicketIntegrations, Action.Create)}
                icon={<PlusIcon className="h-3 w-3 stroke-2" />}
                iconPosition="left"
                onClick={() => openModalOrAuthRedirect(integration, redirect)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
