import { useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useGetSlackChannelIdLazyQuery } from '../../generated/graphql';
import TooltipIcon from './Modals/TooltipIcon';
import slackLogo from '../../assets/slack-logo.png';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../v2/util';
import { WhiteSpinner } from '../baseComponents/WhiteSpinner';

export const SlackChannelSelector = ({
  initialChannelName,
  updateChannelId,
  inline,
}: {
  initialChannelName?: string | null;
  updateChannelId: (channelId: string | null, channelName: string | null) => void;
  inline?: boolean;
}) => {
  const { currentOrg, curOrgId: orgId } = useValidTeamAppContext();
  const [channelNameInput, setChannelNameInput] = useState<string>(initialChannelName ?? '');
  const [getChannelId, { loading: loadingChannel }] = useGetSlackChannelIdLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [editable, setEditable] = useState<boolean>(!!!initialChannelName);
  const [displayedError, setDisplayedError] = useState<string | null>(null);

  const handleSearchChannel = async () => {
    setDisplayedError(null);
    //Strip the beginning # if exists
    const inputChannelName = channelNameInput.startsWith('#') ? channelNameInput.slice(1) : channelNameInput;
    getChannelId({
      fetchPolicy: 'no-cache',
      variables: {
        orgId,
        channelName: inputChannelName,
      },
      onCompleted(data) {
        setEditable(false);
        if (data.getChannelId) updateChannelId(data.getChannelId, inputChannelName);
      },
      onError(error) {
        if (error.graphQLErrors[0].message === 'Channel not found') setDisplayedError('Channel not found'); //asdd
        else setDisplayedError('Unknown Error');
      },
    });
  };

  const handleRemoveChannel = () => {
    updateChannelId(null, null);
    setChannelNameInput('');
    setEditable(true);
  };
  return (
    <div className={classNames(inline ? 'flex-row items-center gap-x-9' : 'flex-col gap-y-1', 'flex')}>
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-x-2">
          <img src={slackLogo} className={'h-4 w-4'} alt="Slack Logo" />
          <h1 className={'text-sm font-medium'}>Slack</h1>
          {!inline ? <TooltipIcon tooltipContent={`Alerts can currently only be delivered to public Slack channels.`} /> : null}
        </div>
        {!inline ? <p className="text-xs">Enter the exact channel's name (eg: #alerts)</p> : null}
      </div>
      {currentOrg.connections.some((connection) => connection.type.id === 1) ? (
        <div className="flex flex-col gap-y-2 relative">
          <form
            id="slack-picker-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex flex-row flex-wrap gap-x-2 relative">
              {editable ? (
                <input
                  disabled={loadingChannel}
                  type={'text'}
                  onKeyDown={(e) => {
                    //To manage the case where this form is inside the alert modal form, as HTML doesn't allow nested form, the "submit" function gets confused.
                    if (e.key === 'Enter') handleSearchChannel();
                  }}
                  placeholder={'#your-channel-name'}
                  onChange={(e) => setChannelNameInput(e.target.value)}
                  value={channelNameInput}
                  className={classNames(
                    'block rounded-md border-buttercream-frosting-300 shadow-sm focus:border-licorice-noir focus:ring-licorice-noir sm:text-sm',
                    loadingChannel && 'disabled cursor-default opacity-70 select-none'
                  )}
                />
              ) : (
                <div className="group relative flex select-none items-center rounded-md bg-licorice-noir px-2 py-2 text-white">
                  <p className="text-sm">{channelNameInput.startsWith('#') ? channelNameInput : `#${channelNameInput}`}</p>
                  <XCircleIcon
                    className="absolute -top-1.5 -right-1.5 hidden h-5 w-5 cursor-pointer text-red-500 duration-300 group-hover:block"
                    onClick={() => {
                      handleRemoveChannel();
                    }}
                  />
                </div>
              )}
              {!editable ? (
                <button
                  type="button"
                  className="bg-licorice-noir hover:bg-licorice-noir-lighter duration-150 rounded-xl text-white cursor-pointer flex justify-center w-10 items-center"
                  onClick={() => setEditable(true)}
                >
                  {<PencilSquareIcon className="w-5 h-5" />}
                </button>
              ) : null}
              {editable ? (
                <button
                  type={'submit'}
                  form="slack-picker-form"
                  className="bg-licorice-noir hover:bg-licorice-noir-lighter duration-150 rounded-xl text-white cursor-pointer flex justify-center w-10 items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSearchChannel();
                  }}
                >
                  {loadingChannel ? <WhiteSpinner /> : <CheckCircleIcon className="w-5 h-5" />}
                </button>
              ) : null}
            </div>
          </form>
          {displayedError ? <p className="text-candy-apple text-sm absolute -bottom-6">{displayedError}</p> : null}
        </div>
      ) : (
        <a
          className="flex w-48 cursor-pointer select-none flex-row items-center justify-center gap-x-1 rounded-md border border-buttercream-frosting-100 bg-white py-1 px-4 text-sm font-medium text-gray-tertiary shadow-sm duration-200 hover:bg-gray-50"
          href={`/dashboard/organizations/${orgId}`}
        >
          <PlusIcon className="h-5 w-5" />
          <h1>Connect to Slack</h1>
        </a>
      )}
    </div>
  );
};
