import { Context, Dispatch, createContext } from 'react';
import { GroupAction, GroupDispatch, GroupState } from '../reducers/group/GroupDispatch';

export const defaultGroupState: GroupState = {
  groupData: null,
  totalEntries: null,
  filteredEntries: null,
  auxillaryGroupDataErrorMessage: null,
  auxillaryGroupDataLoading: false,
};

/**
 * NOTE FOR OUR AMAZING CURRENT & FUTURE ENGINEERS
 *
 * Currently, GroupDataContext duplicates much of the data that already exists in TaxonomyContext.
 *
 * This creates several problems:
 * - Data redundancy increases the risk of state inconsistencies
 * - Additional memory overhead from storing the same data twice
 * - Complex sync logic needed to keep both contexts up to date
 *
 * Instead of duplicating data, we should:
 * - Reference data directly from TaxonomyContext when needed
 * - Keep only unique GroupPage-specific data in GroupContext (or only use local GroupPage state) that isn't already in the taxonomy
 * - Use composition to access both contexts only when necessary
 * - Consider creating selector functions to easily access taxonomy data for a specific group
 *
 * This approach will:
 * - Maintain a single source of truth for shared data
 * - Reduce complexity and potential bugs from data synchronization
 * - Improve performance by eliminating redundant state updates
 */

export const GroupDataContext = createContext<GroupState>(defaultGroupState);

const defaultProvider: Dispatch<GroupAction> = ({}) => {
  return defaultGroupState;
};

export const GroupDispatchContext: Context<Dispatch<GroupAction>> = createContext<GroupDispatch>(defaultProvider);
