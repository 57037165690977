import { Events, Properties, logEvent } from '../v2/AnalyticsUtil';
import { classNames } from '../v2/util';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import TooltipIcon from '../v2/components/Modals/TooltipIcon';
export interface IBadge {
  text: string;
  id: string;
}

export enum ButtonType {
  Primary,
  Primary2,
  Secondary,
  Cancel,
  Theme,
  SVG,
  Back,
  PopupConfirm,
  PopupCancel,
}

interface ButtonProps {
  onClick?: () => void;
  //Button Styles
  children?: JSX.Element;
  buttonType: ButtonType;
  text?: string;
  id?: string;
  loading?: boolean;
  enabled?: boolean;
  locked?: boolean;
  icon?: any;
  iconPosition?: 'left' | 'right';
  invertColors?: boolean;
  extraText?: string;
  tooltipContent?: string;
  disabled?: boolean;
  loadingConfirm?: boolean;
  additionalStyles?: string;
  submit?: boolean;
  // for logging events
  eventName?: Events;
  properties?: Properties;
  logClickEvent?: (eventName: Events, properties: Properties) => void;
}

export const getButtonClassName = (
  buttonType: ButtonType,
  enabled: boolean,
  invertColors: boolean,
  additionalStyles?: string,
  disabled?: boolean,
  loadingConfirm?: boolean
): string => {
  // this is an attempt at making buttons more reusable... Need love + thoughts here
  // not really sure how to make the css more composable here. It's honestly a mess...
  // Just trying to not have to have the same css in 100 different spots

  //todo: rework css logic
  let className;

  if (buttonType === ButtonType.Cancel) {
    className =
      'font-circular font-medium inline-block  py-2 items-center gap-x-2 bg-licorice-noir bg-opacity-90 hover:bg-opacity-100 text-white border border-white rounded-md \
      transform w-full duration-200 hover:bg-licorice-noir hover:text-white cursor-pointer select-none';
  } else if (buttonType === ButtonType.Secondary) {
    className =
      'font-circular font-medium bg-white py-2 flex px-3 border border-licorice-noir rounded-md shadow-sm text-sm leading-4 font-medium text-licorice-noir hover:bg-buttercream-frosting focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-licorice-noir ';
    if (!enabled) className += 'opacity-50 cursor-not-allowed';
  } else if (buttonType === ButtonType.Primary2) {
    className =
      'font-circular font-medium flex flex-row gap-x-1 rounded-md text-sm px-3 py-2 border border-licorice-noir shadow-unwrap-1-2 \
      duration-200  select-none ';
    className += invertColors ? 'bg-licorice-noir text-white' : 'bg-white hover:bg-licorice-noir hover:text-white text-licorice-noir';
  } else if (buttonType === ButtonType.Theme) {
    className =
      'group inline-block px-2 py-2 bg-white text-licorice-noir border border-licorice-noir rounded-md shadow-unwrap-1-2 transform duration-200  cursor-pointer select-none ';
    if (disabled || loadingConfirm) {
      className += ' opacity-30';
    } else {
      className += ' hover:bg-licorice-noir hover:text-white';
    }
  } else if (buttonType === ButtonType.SVG) {
    className = 'border-none p-0 m-0';
  } else if (buttonType === ButtonType.Back) {
    className =
      'font-circular font-medium inline-block py-2 items-center gap-x-2 bg-opacity-90 hover:bg-opacity-100 text-licorice-noir border border-licorice-noir rounded-md transform w-full  cursor-pointer select-none';
  } else if (buttonType === ButtonType.PopupConfirm) {
    className = 'border border-licorice-noir rounded p-2 m-0 cursor-pointer';
  } else if (buttonType === ButtonType.PopupCancel) {
    className = 'cursor-pointer';
  } else {
    className =
      'font-circular font-medium col-span-2 inline-flex justify-center py-2 text-lg items-center gap-x-2 bg-white text-licorice-noir border border-licorice-noir rounded-md shadow-unwrap-1-2 transform w-full duration-200 select-none ';
    if (enabled) {
      className += 'hover:bg-licorice-noir hover:text-white  cursor-pointer';
    } else {
      className += 'opacity-50 cursor-not-allowed';
    }
  }
  if (additionalStyles) className += ` ${additionalStyles}`;
  return className;
};

const Button = ({
  id,
  onClick,
  loading,
  buttonType,
  text,
  enabled,
  icon,
  locked,
  iconPosition,
  invertColors = false,
  tooltipContent,
  extraText,
  eventName,
  properties,
  additionalStyles,
  disabled,
  loadingConfirm,
  submit,
  logClickEvent,
}: ButtonProps): JSX.Element => {
  const handleClick = () => {
    onClick && onClick();
    if (eventName && properties) {
      logEvent(eventName, properties);
    }
  };
  if (enabled === undefined) {
    enabled = true;
  }
  const className = getButtonClassName(buttonType, enabled, invertColors, additionalStyles, disabled, loadingConfirm);
  return (
    <button
      id={id}
      name={id}
      type={submit ? 'submit' : 'button'}
      className={className}
      onClick={handleClick}
      disabled={loading || enabled !== undefined ? !enabled : false}
    >
      {!tooltipContent && loading && (
        <svg id={id} className="mr-2 h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {buttonType === ButtonType.Theme ? (
        <div id={id} className="flex flex-col items-start justify-start">
          <div className="flex flex-row  items-center  justify-between p-0.5">
            <div className="break-none flex flex-none flex-row items-center gap-x-1">
              {icon && iconPosition === 'left' && icon}
              {locked && <LockClosedIcon className="h-4 w-4 opacity-60" />}
              <h1 className={classNames('text-xs font-medium')}>{text}</h1>
              {tooltipContent && <TooltipIcon tooltipContent={tooltipContent} />}
              {icon && iconPosition === 'right' && icon}
            </div>
            {loadingConfirm ? (
              <svg id={id} className="mr-2 h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null}
          </div>
          <p id={id} className="text-xs font-medium opacity-70">
            {extraText}
          </p>
        </div>
      ) : (
        <div id={id}>
          {icon && iconPosition === 'left' && icon}

          {text && (
            <h1 className={buttonType === ButtonType.PopupCancel || buttonType === ButtonType.PopupConfirm ? 'font-light' : `font-medium`}>{text}</h1>
          )}
          {icon && iconPosition !== 'left' && icon}
        </div>
      )}
    </button>
  );
};

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export default Button;
