import { CheckCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Action, Integration_Type, Resource } from "../../../generated/graphql";
import { useContext } from "react";
import { PermissionsContext } from "../../../v2/contexts/PermissionsContext";
import Tippy from "@tippyjs/react";

export const IntegrationAction = ({integration}: {integration: Integration_Type}) => {
    const {hasPermission} = useContext(PermissionsContext);
    const canCreateIntegration = hasPermission(Resource.Integrations, Action.Create);

    if (!canCreateIntegration) {
        return <Tippy content="You do not have permission to create an integration.">
            <div className="text-base mb-6 relative -mr-px inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-md border border-transparent bg-buttercream-frosting-300 py-3 text-sm font-medium text-white">
            <h1 className="mr-1 font-medium">Connect +</h1>
        </div>
        </Tippy>
    }
        
    if ((integration.teamIntegration ?? integration.orgExternalTicketsIntegration ?? []).length > 0) {
        return <div className="text-base mb-6 relative -mr-px inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-md border border-pistachio-gelato bg-transparent py-3 text-sm font-medium text-pistachio-gelato duration-300 group-hover:bg-pistachio-gelato hover:text-white ">
        <h1 className="mr-1 font-medium">Connected</h1>
        <CheckCircleIcon className="h-4 w-4 " aria-hidden="true" />
      </div>
    }

    return <div className="text-base mb-6 relative -mr-px inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-md border border-transparent bg-licorice-noir  py-3 text-sm font-medium  text-white duration-300 group-hover:bg-blood-orange-sorbet ">
        <h1 className="mr-1 font-medium">Connect</h1>
        <PlusIcon className="h-4 w-4 " aria-hidden="true" />
      </div>
}