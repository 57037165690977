import { TrashIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { Resource, Action } from '../../../generated/graphql';

interface EntryDeleteTrashCanProps {
  deleteEntryFromParentComponentContext?: (entryId: string) => Promise<void>;
  entryId: string;
}

/**
 * This specifically supports deleting an entry from a group on the Group Page
 *
 *
 * This is the show delete icon and allow delete path
 * The assumption / expectation is that whoever called needs to:
 * 1. handle completing the delete request
 * 2. handle removing this FeedbackEntryCard from whatever list it resides in.
 * @param param0
 * @returns
 */
export const EntryDeleteTrashCan = ({ deleteEntryFromParentComponentContext, entryId }: EntryDeleteTrashCanProps) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);
  const canDeleteEntry = hasPermission(Resource.Entries, Action.Delete);

  if (!canDeleteEntry) {
    return null;
  }

  if (!deleteEntryFromParentComponentContext) return null;

  if (loadingDelete) {
    return (
      <div
        data-testid="sentence-remove-loading"
        className="z-10 absolute -right-2 -top-2 duration-100 h-5 w-5 p-0.5 bg-candy-apple rounded-full cursor-pointer text-white opacity-80"
      >
        <AdjustableLoadingIcon color={'text-white'} width={4} height={4} />
      </div>
    );
  }

  return (
    <div className="z-10 absolute -right-2 -top-2 opacity-0 transition-opacity duration-100 h-5 w-5 p-0.5 bg-candy-apple rounded-full cursor-pointer text-white hover:bg-red-700 group-hover:opacity-80">
      <Tippy theme="dark" delay={200} placement="left" content={<p className="text-center">Remove this feedback entry from the group</p>}>
        <div>
          <TrashIcon
            id="delete-group-sentence"
            onClick={async (e) => {
              setLoadingDelete(true);
              e.stopPropagation();
              await deleteEntryFromParentComponentContext(entryId);
              setLoadingDelete(false);
            }}
          />
        </div>
      </Tippy>
    </div>
  );
};
