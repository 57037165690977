export interface ILowKeyTagProps {
  text: string;
}

export const LowKeyTag = ({ text }: ILowKeyTagProps) => {
  return (
    <div className="flex flex-row justify-center items-center text-gray-secondary font-light text-xxs px-1 pt-0 pb-0 rounded-[4px] border-buttercream-frosting-100 border-[1px] bg-buttercream-frosting whitespace-nowrap">
      {text}
    </div>
  );
};
