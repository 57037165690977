import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { logError } from '../../../applicationTelemetry';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

/**
 * This handles when a user
 * @param param0
 * @returns
 */
const SSOResponse = () => {
  const navigate = useNavigate();
  const handleSignInOrRedirect = async () => {
    // this would need to fork based on the issuer in the urlQueryParams
    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        navigate('/');
        // redirect to main page
      }
    } catch (err) {
      toast.error(`Invalid sign in credentials. Please contact your system administrator. ${err}`);
      logError(err);
    }
  };

  useEffect(() => {
    handleSignInOrRedirect();
  }, []);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 20000,
          className: '',
          style: {
            border: '1px solid white',
            fontFamily: 'sofiapro',
            backgroundColor: '#1D1E30',

            color: 'white',
            borderRadius: '1rem',
            minWidth: '8%',
          },
        }}
      />
    </>
  );
};

export default SSOResponse;
