import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { HandThumbDownIcon as SolidThumbDown, HandThumbUpIcon as SolidThumbUp } from '@heroicons/react/24/solid';

import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { AssistantDataContext } from '../../../context/assistantContext';
import UserContext from '../../../v2/contexts/UserContext';
import { Unwrap_Feature, useUpsertReviewMutation } from '../../../generated/graphql';
import { FeedbackBox } from './FeedbackBox';


export const UserRating: React.FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [id, setId] = useState<number>(-1);
  const {curTeamId: teamId} = useValidTeamAppContext();
  const {question} = useContext(AssistantDataContext);
  const { user } = useContext(UserContext);
  const [showFeedbackBox, setShowFeedbackBox] = useState<boolean>(false);


  const [submitReview] = useUpsertReviewMutation({
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
      setRating(0);
      setShowFeedbackBox(false);
    },
    onCompleted: (data) => {
      if (!data.upsertReview.success) {
        toast.error(`Error: ${data.upsertReview.message}`);
        setRating(0);
        setShowFeedbackBox(false);
      } else {
        setId(data.upsertReview.id);
        setShowFeedbackBox(true);
      }
    },
  });

  const toggle = (rating: number) => {
    return () => {
      setRating(rating);
      const liked = rating > 0;
      const disliked = rating < 0;
      if(rating != 0) setShowFeedbackBox(true);
      if(liked || disliked) {
        submitReview({
          variables: {
            feature: Unwrap_Feature.Assistant,
            id: id,
            rating: rating,
            feedback: `${user?.email} ${liked ? 'liked' : 'disliked'} the answer to "${question}"`,
            teamId: teamId
          }
        });
      }
    }
  }

  return (
    <div className="flex flex-col w-full ease-in pb-10">
      <div className="flex flex-row space-x-4 pb-2">

          {rating > 0 ? (
            <SolidThumbUp
              onClick={toggle(0)}
              className="h-8 w-8 text-licorice-noir-light font-medium p-1 cursor-pointer rounded-2xl"
              data-testid="solid-thumb-up-icon"
            />
          ) : (
            <HandThumbUpIcon
              onClick={toggle(1)}
              className="h-8 w-8 text-gray-tertiary font-medium p-1 cursor-pointer hover:text-licorice-noir-light duration-200 hover:bg-gray-50 rounded-2xl"
              data-testid="thumb-up-icon"
            />
          )}
          <div className="w-[1px] h-[1rem] my-[0.5rem] bg-gray-tertiary" />
          {rating < 0 ? (
            <SolidThumbDown
              onClick={toggle(0)}
              className="h-8 w-8 text-blood-orange-sorbet-darker font-medium p-1 cursor-pointer rounded-2xl"
              data-testid="solid-thumb-down-icon"
            />
          ) : (
            <HandThumbDownIcon
              onClick={toggle(-1)}
              className="h-8 w-8 text-gray-tertiary font-medium p-1 cursor-pointer hover:text-blood-orange-sorbet-darker duration-200 hover:bg-gray-50 rounded-2xl"
              data-testid="thumb-down-icon"
            />
          )}

      </div>
      <div className={'flex flex-row w-1/2 items-center'}>
        {showFeedbackBox && <FeedbackBox id={id} rating={rating} setShowFeedbackBox={setShowFeedbackBox} />}
      </div>
    </div>
  );
};