import React, { useEffect, useState } from 'react';

export const NameEditor = React.memo(({ name, onNameInput, isLoading }: { name: string; onNameInput: (newName: string) => void; isLoading?: boolean }) => {
  const [localValue, setLocalValue] = useState(name);

  useEffect(() => {
    setLocalValue(name);
  }, [name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onNameInput(localValue);
    }
  };

  const handleBlur = () => {
    onNameInput(localValue);
  };

  return (
    <input
      type="text"
      value={localValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      placeholder="Enter name..."
      className={`border text-sm border-buttercream-frosting-100 font-light rounded-md px-2 py-1 w-full h-[36px] focus:ring-0 focus:border-licorice-noir`}
      style={{ opacity: isLoading ? 0.5 : 1 }}
      disabled={isLoading}
    />
  );
});
