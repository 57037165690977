import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from 'react-router-dom';
import { FeedbackIntegrationFragment, Integration_Type } from "../../../generated/graphql";
import { AppRoutes } from "../../../Routes";

interface IntegrationRowProps {
    integration: FeedbackIntegrationFragment;
    integrationType: Integration_Type;
    id: string;
}
  
export const IntegrationRow = (props: IntegrationRowProps) => {
    const navigate = useNavigate();
    return (
        <div className="border-t border-buttercream-frosting-100 py-1.5 flex flex-row items-start gap-3" id={props.id}>
            <span className="w-2 h-2 flex flex-shrink-0 flex-grow-0 rounded-full bg-success mt-2"></span>
            <div className="w-full flex flex-col xl:flex-row">
                <span className="w-full text-licorice-noir">{props.integration.integrationName ? props.integration.integrationName : `${props.integration.type?.title} Integration`}</span>
            </div>
            <button className="w-10 mt-0.5 flex pr-0 justify-end" onClick={() => {
                navigate(`${AppRoutes.v3FullPath.integrations}/${props.integration.id}`);   
            }}>
                <ArrowRightIcon className="w-6 h-6 object-contain text-licorice-noir" id={`${props.id}-button`}/>
            </button>
        </div>
    );
};