import { ChartColor, multipleChartColors, primaryChartColor } from '../../../constants';
import { ChartType } from './chartElements/ChartTypeOptions';

export interface IGenerateChartColors {
  chartType: ChartType;
  numberOfColors?: number;
}

type ChartColorType = 'primary' | 'multiple';

export interface IGenerateChartColorsResponse {
  chartColorType: ChartColorType;
  chartColors: (index: number) => ChartColor;
  backgroundColors: (index: number) => string;
  borderColors: (index: number) => string;
}

export const generateChartColors = ({ chartType, numberOfColors = 1 }: IGenerateChartColors): IGenerateChartColorsResponse => {
  if (chartType === 'horizontal' || numberOfColors <= 1)
    return {
      chartColorType: 'primary',
      chartColors: (_index: number) => primaryChartColor,
      backgroundColors: (_index: number) => primaryChartColor.backgroundColor,
      borderColors: (_index: number) => primaryChartColor.borderColor,
    };

  const chartColors: ChartColor[] = multipleChartColors.slice(0, numberOfColors);

  const colorsLength = chartColors.length;

  return {
    chartColorType: 'multiple',
    chartColors: (index: number) => chartColors[index % colorsLength],
    backgroundColors: (index: number) => chartColors[index % colorsLength].backgroundColor,
    borderColors: (index: number) => chartColors[index % colorsLength].borderColor,
  };
};
