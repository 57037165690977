//https://github.com/fareez-ahamed/react-datepicker/blob/master/src/Datepicker.tsx

import { ChevronLeftIcon, ChevronRightIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import React, { useRef, useContext, createContext, useEffect, useState, ReactNode } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import { classNames } from '../../v2/util';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

const daysOfWeekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const inputStyle = {
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',
  paddingLeft: '0rem',
  paddingRight: '0rem',
};

interface DatePickerProps {
  date: Date | undefined;
  onChange: (date: Date) => void;
  onClickOverride?: () => void;
  locked?: boolean;
  pickerClassName?: string;
  placeholder?: string;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => (
  <RawDatePicker
    date={props.date}
    onChange={props.onChange}
    onClickOverride={props.onClickOverride}
    locked={props.locked}
    pickerClassName={props.pickerClassName}
    placeholder={props.placeholder}
  ></RawDatePicker>
);

export const RawDatePicker: React.FC<{
  date: Date | undefined;
  placeholder?: string;
  onChange: (date: Date) => void;
  onClickOverride?: () => void;
  locked?: boolean;
  pickerClassName?: string;
  icon?: ReactNode;
}> = ({ date, onChange, onClickOverride, locked, pickerClassName, icon, placeholder }) => {
  const popupNode = useRef<HTMLElement>();
  const ctxValue = useDatepickerCtx(date ?? new Date(), onChange, popupNode);

  return (
    <DatepickerCtx.Provider value={ctxValue}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className={classNames(
                'flex flex-row group relative bg-buttercream-frosting hover:text-blood-orange-sorbet cursor-pointer items-center font-medium outline-none focus:outline-none focus:ring-0 border-0 duration-150 rounded-md'
              )}
              ref={ref}
              onClick={(e) => (onClickOverride ? onClickOverride() : ctxValue.toggleCalendar())}
            >
              <input
                className={classNames(
                  'flex cursor-pointer text-sm text-licorice-noir hover:text-blood-orange-sorbet max-w-[6rem] bg-buttercream-frosting bg-opacity-0 border-0 rounded-md text-center font-medium outline-none duration-150 focus:outline-none focus:ring-0'
                )}
                type="text"
                id="date-picker-open"
                style={inputStyle}
                value={formattedDate(date, placeholder)}
                readOnly
              />
              {locked ? icon ?? <LockClosedIcon className="absolute right-2 h-3 w-3 self-center text-licorice-noir text-opacity-30 lg:h-4 lg:w-4" /> : undefined}
              {/*  {!locked ? <CalendarDaysIcon className="stroke-2 absolute right-1 w-4 h-4 text-licorice-noir" /> : null} */}
            </div>
          )}
        </Reference>
        <Popper placement="bottom-start" innerRef={(node) => (popupNode.current = node)}>
          {({ ref, style, placement, arrowProps }) =>
            ctxValue.isVisible ? <Calendar placement={placement} style={style} ref={ref as React.Ref<HTMLDivElement>} /> : null
          }
        </Popper>
      </Manager>
    </DatepickerCtx.Provider>
  );
};

interface CalendarProps {
  style: React.CSSProperties;
  placement: string;
  ref: React.Ref<HTMLDivElement>;
}

const Calendar: React.FC<CalendarProps> = React.forwardRef<HTMLDivElement, CalendarProps>((props, ref) => {
  const { view } = useContext(DatepickerCtx);

  let selectionComponent = null;
  switch (view) {
    case 'date':
      selectionComponent = <DateSelection />;
      break;
    case 'month':
      selectionComponent = <MonthSelection />;
      break;
    case 'year':
      selectionComponent = <YearSelection />;
      break;
  }

  return (
    <div
      className="relative z-50 mt-1 w-64 max-w-xs rounded-lg border border-buttercream-frosting-300 border-opacity-30 bg-white p-2 font-circular shadow-lg"
      ref={ref}
      data-placement={props.placement}
      style={props.style}
    >
      {selectionComponent}
    </div>
  );
});

/**
 * Date Selection Component
 * @param props
 */
const DateSelection: React.FC<{}> = (props) => {
  const {
    nextMonth,
    prevMonth,
    viewMonths,
    viewYears,
    selectDate,
    visible: { month, year },
    isSelectedDate,
  } = useContext(DatepickerCtx);

  const dates = [];

  for (let i = 0; i < beginningDayOfWeek(month, year); i++) {
    dates.push(<div key={`emptybefore${i}`}></div>);
  }

  for (let i = 1; i <= daysInMonth(month, year); i++) {
    dates.push(
      <button
        key={`day${i}`}
        id="date-picker-date-chosen"
        className={`rounded p-1 text-sm hover:bg-buttercream-frosting-100 ${isSelectedDate(i) ? 'bg-buttercream-frosting-100 font-medium' : ''}`}
        onClick={() => selectDate(i)}
        style={{ textAlign: 'center' }}
      >
        {i}
      </button>
    );
  }

  return (
    <div
      className="text-licorice-noir"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <button className={buttonClassName} onClick={(e) => prevMonth()}>
        <ChevronLeftIcon className="h-5 w-5 stroke-current" />
      </button>

      <button className={`${buttonClassName} font-medium`} style={{ gridColumn: '2/5' }} onClick={(e) => viewMonths()}>
        {monthNames[month]}
      </button>

      <button className={`${buttonClassName} font-medium`} style={{ gridColumn: '5/7' }} onClick={(e) => viewYears()}>
        {year}
      </button>

      <button className={buttonClassName} onClick={(e) => nextMonth()}>
        <ChevronRightIcon className="h-5 w-5 stroke-current" />
      </button>

      {daysOfWeekNames.map((day) => (
        <div key={(200 + day).toString()} className="p-1 text-xs font-medium" style={{ textAlign: 'center' }}>
          {day.slice(0, 2)}
        </div>
      ))}

      {dates}
    </div>
  );
};

/**
 * Month Selection Component
 * @param props
 */
const MonthSelection: React.FC<{}> = (props) => {
  const { viewYears, selectMonth, nextYear, prevYear, visible } = useContext(DatepickerCtx);

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalButton chevron="left" onClick={(e) => prevYear()} />
        <CalButton className="flex-grow" onClick={(e) => viewYears()}>
          {visible.year}
        </CalButton>
        <CalButton chevron="right" onClick={(e) => nextYear()} />
      </div>

      {monthNames.map((month, index) => (
        <CalButton onClick={(e) => selectMonth(index)}>{month.substring(0, 3)}</CalButton>
      ))}
    </div>
  );
};

/**
 * Year Selection Component
 * @param props
 */
const YearSelection: React.FC<{}> = (props) => {
  const {
    selectYear,
    prevDecade,
    nextDecade,
    visible: { year },
  } = useContext(DatepickerCtx);

  let years = [];
  let [minYear, maxYear] = [year - 6, year + 6];

  for (let i = minYear; i < maxYear; i++) {
    years.push(<CalButton onClick={(e) => selectYear(i)}>{i}</CalButton>);
  }

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalButton chevron="left" onClick={(e) => prevDecade()} />
        <CalButton className="flex-grow">{`${minYear} - ${maxYear - 1}`}</CalButton>
        <CalButton chevron="right" onClick={(e) => nextDecade()} />
      </div>

      {years}
    </div>
  );
};

const buttonClassName = 'hover:bg-buttercrean-frosting-100 rounded p-1 text-sm flex align-center justify-center focus:outline-none items-center';

const CalButton: React.FC<{
  chevron?: 'right' | 'left';
  className?: string;
  style?: React.StyleHTMLAttributes<HTMLButtonElement>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
}> = (props) => {
  let children = null;

  if (props.chevron && props.chevron === 'left') children = <ChevronLeftIcon className="h-5 w-5 stroke-current" />;
  else if (props.chevron && props.chevron === 'right') children = <ChevronRightIcon className="h-5 w-5 stroke-current" />;
  else children = props.children;

  return (
    <button className={`${buttonClassName} ${props.className}`} style={props.style} onClick={props.onClick}>
      {children}
    </button>
  );
};

/**
 * Util functions
 */
/**
 * For formatting date
 * @param date
 */
export function formattedDate(date: Date | undefined, placeholder?: string): string {
  return date ? `${monthNames[date.getMonth()]?.slice(0, 3)} ${date.getDate()}, ${date.getFullYear()}` : placeholder ?? 'Not Selected';
}

/**
 * Beginning of Day of Week of a Month
 * @param date
 */
function beginningDayOfWeek(m: number, y: number): number {
  return new Date(y, m, 1).getDay();
}

/**
 * Days in month
 */
function daysInMonth(month: number, year: number) {
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      return 31;
    case 1:
      return isLeapYear(year) ? 29 : 28;
    default:
      return 30;
  }
}

/**
 * Is Leap Year
 * @param year
 */
function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

type ViewState = 'date' | 'month' | 'year';

interface MonthYear {
  month: number;
  year: number;
}

interface DatepickerContextType {
  date: Date | null;
  visible: MonthYear;
  view: ViewState;
  nextMonth: () => void;
  prevMonth: () => void;
  nextYear: () => void;
  prevYear: () => void;
  nextDecade: () => void;
  prevDecade: () => void;
  selectMonth: (m: number) => void;
  selectYear: (y: number) => void;
  selectDate: (d: number) => void;
  viewMonths: () => void;
  viewYears: () => void;
  isVisible: boolean;
  showCalendar: () => void;
  toggleCalendar: () => void;
  isSelectedDate: (d: number) => boolean;
}

const DatepickerCtx = createContext<DatepickerContextType>({
  date: new Date(),
  visible: {
    month: 0,
    year: 1970,
  },
  view: 'date',
  nextMonth: () => {},
  prevMonth: () => {},
  nextYear: () => {},
  prevYear: () => {},
  nextDecade: () => {},
  prevDecade: () => {},
  selectMonth: (m) => {},
  selectYear: (y) => {},
  selectDate: (d) => {},
  viewMonths: () => {},
  viewYears: () => {},
  isVisible: false,
  showCalendar: () => {},
  toggleCalendar: () => {},
  isSelectedDate: (d) => false,
});

function useDatepickerCtx(date: Date, onChange: (d: Date) => void, ref: React.MutableRefObject<HTMLElement | undefined>): DatepickerContextType {
  const [monthYear, setMonthYear] = useState<MonthYear>({
    month: date?.getMonth() ?? new Date().getMonth(),
    year: date?.getFullYear() ?? new Date().getFullYear(),
  });

  const [view, setView] = useState<ViewState>('date');

  const [isVisible, setVisible] = useState<boolean>(false);

  const selectDate = (d: number) => {
    onChange(new Date(monthYear.year, monthYear.month, d));
    setVisible(false);
  };

  const isSelectedDate = (d: number): boolean => {
    if (d === date.getDate() && monthYear.month === date.getMonth() && monthYear.year === date.getFullYear()) {
      return true;
    }
    return false;
  };

  const selectMonth = (m: number) => {
    setMonthYear((state) => ({ ...state, month: m }));
    setView('date');
  };

  const selectYear = (y: number) => {
    setMonthYear((state) => ({ ...state, year: y }));
    setView('month');
  };

  useEffect(() => {
    function mouseDownListener(e: MouseEvent) {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setVisible(false);
      }
    }

    if (isVisible) {
      setMonthYear({ month: date.getMonth(), year: date.getFullYear() });
      document.addEventListener('mousedown', mouseDownListener);
    }

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return {
    date,
    visible: monthYear,
    view,
    nextMonth: () => setMonthYear((state) => (state.month >= 11 ? { month: 0, year: state.year + 1 } : { month: state.month + 1, year: state.year })),
    prevMonth: () => setMonthYear((state) => (state.month <= 0 ? { month: 11, year: state.year - 1 } : { month: state.month - 1, year: state.year })),
    nextYear: () => setMonthYear((state) => ({ ...state, year: state.year + 1 })),
    prevYear: () => setMonthYear((state) => ({ ...state, year: state.year - 1 })),
    nextDecade: () => setMonthYear((state) => ({ ...state, year: state.year + 12 })),
    prevDecade: () => setMonthYear((state) => ({ ...state, year: state.year - 12 })),
    selectMonth,
    selectYear,
    selectDate,
    viewMonths: () => setView('month'),
    viewYears: () => setView('year'),
    isVisible,
    showCalendar: () => setVisible(true),
    toggleCalendar: () => setVisible((state) => !state),
    isSelectedDate,
  };
}
