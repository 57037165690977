import { FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState, FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { useState, useContext } from 'react';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { TrashIcon, PlusIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { FilterType } from '../../../../generated/graphql';

export const AppliedFilterActions: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);
  const [isOpen, setIsOpen] = useState(false);

  const onEditNodeComplete = (node: FilterStatementSchema) => {
    /**
     * This is handling when someone clicks the '+' icon on an existing statement or collection
     * we want to add this new node to the current node
     */
    const payload =
      filter.type === 'collection'
        ? {
            id: filter.id,
            filterNode: {
              ...filter,
              items: [...filter.items, node],
            },
          }
        : {
            id: filter.id,
            filterNode: {
              type: 'collection',
              operator: FilterType.Or,
              items: [filter, node],
            },
          };

    dispatch({
      type: FilterGroupActionType.UpdateFilterNode,
      payload,
    });
  };

  return (
    <div className="flex flex-row items-center ml-1 -mr-1 justify-center">
      <FilterConfigurationPopup onComplete={onEditNodeComplete}>
        <div className="flex flex-row items-center justify-center ">
          <button name="plus-button" className="hover:text-blood-orange-sorbet hover:bg-buttercream-frosting-100 duration-150 rounded-xl">
            <PlusCircleIcon className="w-5 h-5" />
          </button>
        </div>
      </FilterConfigurationPopup>
    </div>
  );
};
