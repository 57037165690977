import toast from 'react-hot-toast';
import { Group_Trending, TaxonomyTreeNode, TeamGroupsTaxonomyFlatQuery, TeamGroupsTaxonomyTreeQuery } from '../../generated/graphql';
import {
  AddGroupPayload,
  AddTagToGroupPayload,
  AssignChildrenPayload,
  DiscardGroupPayload,
  ExpandState,
  RemoveChildFromParentPayload,
  RemoveTagFromGroupPayload,
  SetTaxonomyPayload,
  TaxonomyAction,
  ToggleExpandAllPayload,
  ToggleExpandPayload,
  TogglePinGroupPayload,
  UpdateGroupTitlePayload,
  UpdateProgressPayload,
} from '../actions/taxonomy';
import { getTaxonomyMap, saveGroupIdsToLocalStorage, toTaxonomyMap } from '../lib/taxonomy';
import { adaptTaxonomyElements, convertToTaxonomyGroup, createDummyGroup } from '../lib/taxonomyAdapter';

export interface Ancestry {
  id: string;
  title: string;
}

export interface ITag {
  id: number;
  name: string;
}

export type Creator = {
  isUnwrapGenerated: boolean;
  creatorEmail?: string; //
};

export interface TaxonomyGroup {
  id: string;
  title: string;
  totalEntries: number;
  creator: Creator;
  tags: ITag[];

  isNew?: boolean;
  /** This represents the percentage of filtered feedback, with the filter applied to the denominator */
  relativeShare?: number;
  /** This represent the percentage or unfiltered feedback, all feedback is in the denominator */
  relativeShareFull?: number;
  date: number;

  isPinnedByUser: boolean;
  showChildren: boolean;
  parentId: string | null;
  totalDescendents: number;
  children: TaxonomyGroup[]; // null means we haven't loaded the children yet
  trending: Group_Trending | null | undefined;
  canAddChildren: boolean;
  ancestors: Ancestry[]; // used to render the actual breadcrumbs

  processing: boolean;
  progress: number;
}

export const taxonomyReducer = (taxonomy: Map<string, TaxonomyGroup>, action: TaxonomyAction): Map<string, TaxonomyGroup> => {
  switch (action.type) {
    case 'loadTaxonomy': {
      const payload = action.payload as TeamGroupsTaxonomyTreeQuery;
      return toTaxonomyMap(payload.teamGroups?.taxonomyTrees as TaxonomyTreeNode[]);
    }

    case 'loadFlatTaxonomy': {
      const payload = action.payload as TeamGroupsTaxonomyFlatQuery;
      const adaptedGroups = adaptTaxonomyElements(payload.teamGroups?.taxonomyFlat as TaxonomyTreeNode[]);
      return new Map(adaptedGroups.map((group) => [group.id, group]));
    }

    case 'updateGroupTitle': {
      return updateGroupTitle(action, taxonomy);
    }

    case 'togglePinGroup': {
      return togglePinGroup(action, taxonomy);
    }

    case 'setTaxonomy': {
      const payload = action.payload as SetTaxonomyPayload;
      return payload.taxonomy;
    }

    case 'updateTaxonomy': {
      return updateTaxonomy(action, taxonomy);
    }

    case 'updateFlatTaxonomy': {
      return updateFlatTaxonomy(action, taxonomy);
    }

    case 'toggleExpand': {
      return toggleExpand(action, taxonomy);
    }

    case 'toggleExpandAll': {
      return toggleExpandAll(action, taxonomy);
    }

    case 'addGroup': {
      return addGroupToTaxonomy(action, taxonomy);
    }

    case 'discardGroup': {
      return discardGroup(action, taxonomy);
    }

    case 'updateProgress': {
      return updateProgress(action, taxonomy);
    }

    case 'removeChildFromParent': {
      return removeChildFromParent(action, taxonomy);
    }

    case 'assignChildren': {
      return assignChildren(action, taxonomy);
    }

    case 'addTagToGroup': {
      return addTagToGroup(action, taxonomy);
    }

    case 'removeTagFromGroup': {
      return removeTagFromGroup(action, taxonomy);
    }

    case 'error': {
      toast.error('Failed to fetch taxonomy from the server. Please try again later.');
      return taxonomy;
    }
    default:
      return taxonomy;
  }
};

const assignChildren = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as AssignChildrenPayload;
  const parentGroupId = payload.parentGroup.id;
  const childGroups = payload.childGroups;
  const updatedTaxonomy = new Map(taxonomy);
  let parentGroup = updatedTaxonomy.get(parentGroupId);
  if (!parentGroup) {
    // this is an edge case where if you open a group from an email and that group doesn't have any mentions, it will not be loaded in the taxonomy.
    // so we need to load it in and set the parentId to null
    parentGroup = createDummyGroup(payload.parentGroup, childGroups);
  }

  const taxonomyChildren = [];

  for (let childGroup of childGroups) {
    let taxonomyGroup = taxonomy.get(childGroup.id);
    if (!taxonomyGroup) {
      // why do we prefer to use the existing taxonomyGroup instead of the childGroup?
      // the childGroup we loaded in does not have any information about it's children because we did not load in that info. We could load in this info but it's quite expensive (slow) with how the backend will load in the entry counts. We have a refactor to do on the backend to support this and I'm not doing that now.
      // so we use the child if it's already loaded in the taxonomy otherwise we use the childGroup we got from the backend and live with the fact that we don't show that child's children.

      taxonomyGroup = convertToTaxonomyGroup(
        {
          // @ts-ignore -- so we use TaxonomyTreeNode as the type which isn't correct because it assumes you loaded in the full Group object
          // I'm not sure there's a good work around here because we only loaded in a GroupTaxonomyFragment not the full Group object from the API
          node: { ...childGroup, statistics: { ...childGroup.statistics, denominator: { ...childGroup.statistics.denominator, denominatorFiltered: 0 } } },
          groupId: childGroup.id,
          children: [],
        },
        null // don't pass in the parent here because it's the incorrect type and not needed
      );
    }
    taxonomyGroup.parentId = parentGroupId;

    updatedTaxonomy.set(childGroup.id, taxonomyGroup);
    taxonomyChildren.push(taxonomyGroup);
  }

  const newChildren = [...(parentGroup.children ?? []), ...taxonomyChildren];
  parentGroup.children = newChildren;
  // sum up all the child groups
  parentGroup.totalDescendents = parentGroup.totalDescendents + childGroups.map((child) => child.totalDescendents).reduce((a, b) => a + b, 0);
  parentGroup.showChildren = parentGroup.totalDescendents > 0;

  updatedTaxonomy.set(parentGroupId, parentGroup);

  return updatedTaxonomy;
};

const removeChildFromParent = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as RemoveChildFromParentPayload;
  const parentGroupId = payload.parentGroupId;
  const childGroupId = payload.childGroupId;
  const updatedTaxonomy = new Map(taxonomy);
  const parentGroup = updatedTaxonomy.get(parentGroupId);
  if (!parentGroup) {
    throw new Error('Parent group not found');
  }
  const childGroup = parentGroup.children?.find((child) => child.id === childGroupId);
  if (!childGroup) {
    // We want to allow deleting children groups that aren't loaded in the taxonomy.
    // in this case we need to just continue
    return updatedTaxonomy;
  }
  const totalDescendents = parentGroup.totalDescendents - childGroup.totalDescendents - 1;
  const updatedParentGroup = {
    ...parentGroup,
    children: parentGroup.children?.filter((child) => child.id !== childGroupId) ?? [],
    totalDescendents: totalDescendents,
    showChildren: totalDescendents > 0,
  };

  updatedTaxonomy.set(parentGroupId, updatedParentGroup);
  updatedTaxonomy.set(childGroupId, { ...childGroup, parentId: null });

  return updatedTaxonomy;
};

const updateProgress = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as UpdateProgressPayload;
  const groupId = payload.groupId;
  const progress = payload.progress;
  const updatedTaxonomy = new Map(taxonomy);
  updatedTaxonomy.set(groupId, { ...updatedTaxonomy.get(groupId)!, progress });
  return updatedTaxonomy;
};

const discardGroup = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as DiscardGroupPayload;
  const groupId = payload.groupId;
  const updatedTaxonomy = new Map(taxonomy);
  updatedTaxonomy.delete(groupId);
  return updatedTaxonomy;
};

const togglePinGroup = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as TogglePinGroupPayload;
  const groupId = payload.groupId;
  const pinnedByUser = payload.pinnedByUser;
  const currentTaxonomy = new Map(taxonomy);
  const group = currentTaxonomy.get(groupId);
  if (group) {
    group.isPinnedByUser = pinnedByUser;
  }
  return currentTaxonomy;
};

const updateGroupTitle = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as UpdateGroupTitlePayload;
  const groupId = payload.groupId;
  const currentTaxonomy = new Map(taxonomy);
  const group = currentTaxonomy.get(groupId);
  if (group) {
    group.title = payload.title;
  }
  return currentTaxonomy;
};

const toggleExpandAll = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as ToggleExpandAllPayload;
  const showChildren = payload.state === ExpandState.Expanded;
  const updatedTaxonomy = new Map(taxonomy);
  // Setting showChildren to true to all groups
  for (let group of Array.from(updatedTaxonomy.values())) {
    group.showChildren = showChildren;
  }

  if (showChildren) {
    // Save all Ids to localstorage
    const allIds = Array.from(updatedTaxonomy.values()).map((group) => group.id);
    saveGroupIdsToLocalStorage(allIds);
    return updatedTaxonomy;
  } else {
    // Remove all Ids from localstorage
    saveGroupIdsToLocalStorage([]);
    return updatedTaxonomy;
  }
};

const toggleExpand = (action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) => {
  const payload = action.payload as ToggleExpandPayload;
  const groupId = payload.groupId;
  const showChildren = payload.state === ExpandState.Expanded;

  if (taxonomy.get(groupId)) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(groupId, {
      ...taxonomy.get(groupId)!,
      showChildren,
    });
    const openIds = [...Array.from(updatedTaxonomy.values())].filter((group) => group.showChildren).map((group) => group.id);
    saveGroupIdsToLocalStorage(openIds);

    return updatedTaxonomy;
  }

  return taxonomy;
};

function updateTaxonomy(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as TeamGroupsTaxonomyTreeQuery;
  return new Map([...Array.from(taxonomy), ...Array.from(toTaxonomyMap(payload.teamGroups?.taxonomyTrees as TaxonomyTreeNode[]))]);
}

function updateFlatTaxonomy(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as TeamGroupsTaxonomyFlatQuery;
  return new Map([...Array.from(taxonomy), ...Array.from(toTaxonomyMap(payload.teamGroups?.taxonomyFlat as TaxonomyTreeNode[]))]);
}

function addGroupToTaxonomy(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as AddGroupPayload;
  const updatedTaxonomy = getTaxonomyMap([payload.group], taxonomy);
  return updatedTaxonomy;
}

function addTagToGroup(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as AddTagToGroupPayload;
  const groupId = payload.groupId;
  const tag = payload.tag;
  const updatedTaxonomy = new Map(taxonomy);
  const group = updatedTaxonomy.get(groupId);
  if (group) {
    group.tags.push(tag);
  }
  return updatedTaxonomy;
}

function removeTagFromGroup(action: TaxonomyAction, taxonomy: Map<string, TaxonomyGroup>) {
  const payload = action.payload as RemoveTagFromGroupPayload;
  const groupId = payload.groupId;
  const tag = payload.tag;
  const updatedTaxonomy = new Map(taxonomy);
  const group = updatedTaxonomy.get(groupId);
  if (group) {
    group.tags = group.tags.filter((t) => t.id !== tag.id);
  }
  return updatedTaxonomy;
}
