import { classNames } from '../../v2/util';

export const BaseCardWithHeader = ({
  headerChildren,
  bodyChildren,
  height = 'tall',
}: {
  headerChildren?: React.ReactNode;
  bodyChildren?: React.ReactNode;
  height?: 'short' | 'tall';
}) => {
  const bodyHeightMapping = {
    short: 'h-[105px]',
    tall: 'h-[164px]',
  };
  return (
    <div className="flex flex-col rounded-lg bg-buttercream-frosting border border-buttercream-frosting-100 cursor-pointer hover:bg-buttercream-frosting-100 hover:bg-opacity-40 duration-150">
      <div className={classNames('flex flex-row justify-between items-center px-4 rounded-t-lg h-[44px]')}>{headerChildren}</div>
      <div className={classNames('flex flex-col gap-y-3 justify-end pb-6 px-6 rounded-b-lg', bodyHeightMapping[height])}>{bodyChildren}</div>
    </div>
  );
};
