import { useState } from 'react';
import { CSVRow, HeaderCSVRow, HeaderEntry, HeaderSegmentsRow, ParsedCSV, SegmentValue } from '../../../handlers/csvUtils/csvTypes';
import Tippy from '@tippyjs/react';
import _ from 'underscore';
import { formatDate } from '../../../handlers/stringUtils/stringFormatters';
import { truncateAndEllipsis } from '../../../v2/util';

interface PreviewTableProps {
  /**
   * @description the CSV to render
   */
  renderData: ParsedCSV;
}

/**
 * @description This file creates a PreviewTable to be rendered for CSV components
 */
export default function PreviewTable({ renderData }: PreviewTableProps) {
  const [numRowsToRender, setNumRowsToRender] = useState<number>(5);

  const fieldTruncations: number[] = Object.entries(renderData.header).map(([headerName, headerVal]) => {
    return headerVal.charsTillEllipsis;
  });

  // ensure that the index of a segments render and a default value render never collide
  const segmentsIndexOffset: number = fieldTruncations.length;

  return (
    <>
      {/* Preview Table */}
      <div className="flow-root">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-buttercream-frosting-300">
                <thead className="bg-licorice-noir">
                  <tr>
                    {/* Default Headers */}
                    {Object.entries(renderData.header)
                      // filter only to column headers the user provides
                      .filter(([headerName, headerValue]) => headerValue.csvIndex != -1)
                      .map(([headerName, headerValue], idx) => {
                        return HeaderTd(headerValue, idx);
                      })}
                    {/* Segment Headers */}
                    {renderData.segmentHeader?.segHeader.map((segmentField: HeaderEntry, index: number) => {
                      return HeaderTd(segmentField, index + segmentsIndexOffset);
                    })}
                  </tr>
                </thead>

                {/* Each column rendered in order of `createRows` in `csvParser` */}
                <tbody className="divide-y divide-buttercream-frosting-100">
                  {renderData.rows
                    .slice(0, numRowsToRender)
                    .map((row: CSVRow, key: number) => previewTableRow(row, renderData.header, key, renderData.segmentHeader))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  /**
   * @description creates a row whose content body is rendered in the order of `createRows` in `CSVParser`, note that means
   *                if the order of prop creation in `createRows` and `createHeaderAndIdx`/`createSegmentsHeaderAndIdx` the header and body columns will be out of sync
   * @param csvRow a row of CSV Date whose properties were instantiated in the order specified in csvTypes
   * @returns a Row representing the data entries in order of the CSVRow properties object
   */
  function previewTableRow(csvRow: CSVRow, headerRow: HeaderCSVRow, uniqueKey: number, headerSegmentRow?: HeaderSegmentsRow): JSX.Element {
    // an array containing only non empty columns

    const colsToRender: string[] = Object.entries(headerRow)
      // filter to only columns that are provided
      .filter(([fieldName, fieldVal]) => fieldVal.csvIndex != -1)
      // return those column names
      .map(([fieldName, fieldVal]) => {
        return fieldName;
      });

    let rowCopyDateChange: CSVRow = { ...csvRow };
    rowCopyDateChange.date = formatDate(rowCopyDateChange.date);

    return (
      <tr key={uniqueKey} className="border-b border-l border-r">
        {Object.entries(rowCopyDateChange)
          // filter to columns that exist in the colsToRender array
          .filter(([fieldName, fieldValue]) => colsToRender.indexOf(fieldName) != -1)
          // display as a table cell
          .map(([columnName, columnData]: [string, keyof CSVRow], index: number) => (
            <BodyTd key={index}>{truncateAndEllipsis(columnData, fieldTruncations[index])}</BodyTd>
          ))}

        {/* Add segments to the table */}
        {csvRow.segments?.map((segmentValue: SegmentValue, index: number) => {
          return (
            <BodyTd key={index + segmentsIndexOffset}>
              {truncateAndEllipsis(segmentValue.value.toString(), headerSegmentRow ? headerSegmentRow.segHeader[index].charsTillEllipsis : 5)}
            </BodyTd>
          );
        })}
      </tr>
    );
  }
}

/**
 * @description a singular Header Table Cell
 * @param headerEntry A single header of the CSV
 * @param idx the index of the Header
 */
function HeaderTd(headerEntry: HeaderEntry, idx: number): JSX.Element {
  return (
    <th key={idx} scope="col" className="py-3 pl-3 text-left tracking-wide font-medium text-xs text-white">
      <Tippy content={headerEntry.toolTipDescription}>
        <p>{headerEntry.fieldTitle + (headerEntry.required ? '*' : '')}</p>
      </Tippy>
    </th>
  );
}

/**
 * @description a singular Body Table Cell
 */
const BodyTd = ({ children }: { children: any }): JSX.Element => {
  return <td className="pl-3 py-3 whitespace-nowrap text-sm text-licorice-noir font-light">{children == null ? '-' : children}</td>;
};
