import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export interface ICloseButton {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  styles?: React.CSSProperties;
}

export const CloseButton: FC<ICloseButton> = ({ onClick, styles }) => {
  return (
    <div
      className="p-[4px] text-sm font-medium opacity-80 hover:bg-buttercream-frosting rounded-[4px] cursor-pointer transition-all duration-200"
      onClick={onClick}
      style={styles}
    >
      <XMarkIcon className="h-5 w-5 text-gray-secondary" />
    </div>
  );
};
