import React, { useState } from 'react';
import { FeedbackHook } from '../hooks/FeedbackHook';
export const Pagination = ({ feedbackHook }: { feedbackHook: FeedbackHook }) => {
  const totalPageSize = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const pages = [];
  for (let i = 1; i <= Math.ceil(feedbackHook.entriesCount / totalPageSize); i++) {
    pages.push(i);
  }
  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const page = Number((event.target as HTMLLIElement).innerText);
    setCurrentPage(page);
    feedbackHook.paginateEntries(totalPageSize, page);
  };

  let start = currentPage - 2;
  let end = currentPage + 2;
  if (start < 1) {
    start = 1;
    end = start + 4;
  }
  if (end > pages.length) {
    end = pages.length;
    start = end - 4;
    if (start < 1) {
      start = 1;
    }
  }
  const renderPageNumbers = pages.slice(start - 1, end).map((number) => {
    return (
      <li
        key={number}
        onClick={handleClick}
        className={`px-3 py-1 border rounded cursor-pointer ${
          currentPage === number ? 'bg-licorice-noir text-white border-licorice-noir' : 'bg-white text-licorice-noir border-buttercream-frosting-300 hover:bg-buttercream-frosting'
        }`}
      >
        {number}
      </li>
    );
  });

  if (pages.length === 1) return null;

  return (
    <ul id="page-numbers" className="flex flex-row gap-x-2 justify-center items-center">
      {currentPage > 3 && (
        <li
          className={`px-3 py-1 border rounded cursor-pointer ${
            currentPage === 1 ? 'bg-licorice-noir text-white border-licorice-noir' : 'bg-white text-licorice-noir border-buttercream-frosting-300 hover:bg-buttercream-frosting '
          }`}
          onClick={handleClick}
        >
          1
        </li>
      )}
      {currentPage > 3 && <p className="pointer-events-none">...</p>}
      {renderPageNumbers}
      {currentPage < pages.length - 2 && <p className="pointer-events-none">...</p>}
      {currentPage < pages.length - 2 && (
        <li
          className={`px-3 py-1 border rounded cursor-pointer ${
            currentPage === pages.length ? 'bg-licorice-noir text-white border-licorice-noir' : 'bg-white text-licorice-noir border-buttercream-frosting-300 hover:bg-buttercream-frosting'
          }`}
          onClick={handleClick}
        >
          {pages.length}
        </li>
      )}
    </ul>
  );
};
