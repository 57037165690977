import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PlainText from './PlainText';

const stripTags = (text: string): string => {
  if (!text) return '';

  const div = document.createElement('div');
  div.textContent = text;
  return div.textContent || div.innerText || '';
};

const markdownComponents = {
  h1: ({ node, ...props }: any) => <h1 className={'text-xl mb-4 text-licorice-noir-lighter'} {...props} />,
  h2: ({ node, ...props }: any) => <h2 className={'text-xl mb-3'} {...props} />,
  h3: ({ node, ...props }: any) => <h3 className={'text-lg mb-2'} {...props} />,
  a: ({ node, children, ...props }: any) => (
    <a className={'text-blood-orange-sorbet underline hover:text-blood-orange-sorbet-darker'} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ),
  h4: ({ node, ...props }: any) => <h4 className={'text-medium accent-blood-orange-sorbet-darker'} {...props} />,
  ul: ({ node, ...props }: any) => <ul className={'list-disc ml-6'} {...props} />,
  li: ({ node, ...props }: any) => <li className={'mb-2'} {...props} />,
  code: ({ children }: any) => <PlainText text={children} />,
  pre: ({ children }: any) => <PlainText text={children} />,
};

export interface IMarkdownProps {
  text: string;
  id: string;
}

export const Markdown = ({ text, id }: IMarkdownProps) => {
  useEffect(() => {
    if (document.getElementById(id) === null) return;

    const cleanedText = stripTags(text);

    ReactDOM.render(<ReactMarkdown components={markdownComponents} children={cleanedText} />, document.getElementById(id));
  }, [text, id]);

  return <div id={id} className={'flex flex-col flex-wrap overflow-wrap-anywhere break-words text-licorice-noir-light w-full'} />;
};
