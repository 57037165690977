import { useContext, useEffect, useState } from 'react';
import Badge, { BadgeSize } from '../../../baseComponents/Badge';
import { DigestSeriesDataType, DigestDataActions, DigestDataDispatch } from '../../../reducers/digests/digestDataReducer';
import { PlusIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Modal from '../../baseComponents/Modal';
import { Dialog } from '@headlessui/react';
import Form from '../../../baseComponents/Form';
import { RowLabel } from '../../baseComponents/RowWrapper';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { getDropDownItemFromEnumSelection, getDropDownSelectionsFromEnum, getEnumValue } from '../../../reducers/utilities/enumHandling';
import {
  Channel_Type,
  DigestSendFrequency,
  GetDigestSeriesChannelsDocument,
  useCreateDigestSeriesChannelMutation,
  useDeleteDigestSeriesChannelMutation,
  useGetDigestSeriesChannelsQuery,
  useUpdateAutoPopulateSgListMutation,
  useEditDigestSeriesTeamMutation,
  useDeleteDigestSeriesTeamMutation,
  useGetOrganizationUserTeamsQuery,
  useUpdateDigestSeriesSendFrequencyMutation,
} from '../../../generated/graphql';
import toast from 'react-hot-toast';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import Toggle from '../Toggle';
import { DigestDataDispatchContext } from '../../../context/digestDataContext';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { EMPTY_DROPDOWN_ITEM } from '../../../reducers/utilities/consts';

const DigestSeriesMainSection = (props: { digestSeriesObject: DigestSeriesDataType }) => {
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const { digestSeriesObject } = props;
  const dispatchDigestData = useContext(DigestDataDispatchContext) as DigestDataDispatch;
  const [updateAutoPopulateSGList] = useUpdateAutoPopulateSgListMutation();
  const [autoPopulateSGList, setAutoPopulateSGList] = useState(digestSeriesObject.autoPopulateSGList);

  useEffect(() => {
    setAutoPopulateSGList(digestSeriesObject.autoPopulateSGList);
  }, [digestSeriesObject]);

  const handleToggleSwitch = (state: boolean) => {
    setAutoPopulateSGList(!state);
    dispatchDigestData({
      type: DigestDataActions.UpdateAutoPopulateSGList,
      payload: {
        digestSeriesId: digestSeriesObject.id,
        autoPopulateSGList: !state,
      },
    });
    updateAutoPopulateSGList({ variables: { autoPopulateSgList: !state, updateAutoPopulateSgListId: digestSeriesObject.id } });
  };

  return (
    <div className="flex flex-col gap-y-2 bg-white text-licorice-noir ">
      <CreateDigestSeriesChannelModal isChannelModalOpen={isChannelModalOpen} setOpen={setIsChannelModalOpen} seriesId={digestSeriesObject.id} />
      <EditDigestSeriesTeamModal orgId={digestSeriesObject.organization.id} isTeamModalOpen={isTeamModalOpen} setIsTeamModalOpen={setIsTeamModalOpen} seriesId={digestSeriesObject.id} currentTeams={digestSeriesObject.teams.map(team => ({
          id: team.id.toString(),
          name: `${team.name} (${team.id})`
        }))}/>
      {/* Title section */}
      <h1 className="text-3xl font-recoleta">{digestSeriesObject.title}</h1>
      <div className="text-md font-light text-gray-tertiary">
        {digestSeriesObject.organization.name}: ({digestSeriesObject.organization.id})
      </div>
      {/* Teams section */}
      <DigestSeriesTeams digestSeriesObject={digestSeriesObject} setIsTeamModalOpen={setIsTeamModalOpen}/>
      {/* Digest Series Type */}
      <h1 className="text-2xl font-recoleta mt-10">Digest Series Type</h1>
      <div className="flex flex-row gap-x-4 gap-y-4">
        {digestSeriesObject.digestSeriesType}
      </div>
      {/* Digest Series Send Freq */}
      <DigestSeriesSendFrequency digestSeriesObject={digestSeriesObject} />
      {/* Channels */}
      <DigestSeriesChannels digestSeriesObject={digestSeriesObject} setIsChannelModalOpen={setIsChannelModalOpen} />
      <h2 className="text-xl font-recoleta mt-5">Auto Populate Email List</h2>
      <Toggle
        initialState={digestSeriesObject.autoPopulateSGList}
        value={autoPopulateSGList}
        onSwitch={handleToggleSwitch}
      />
    </div>
  );
};

const DigestSeriesTeams = (props: { digestSeriesObject: DigestSeriesDataType; setIsTeamModalOpen: (open: boolean) => void }) => {
  const { digestSeriesObject, setIsTeamModalOpen } = props;
  const [deleteTeams, deleteTeamsLoading] = useDeleteDigestSeriesTeamMutation();
  const dispatchDigestData = useContext(DigestDataDispatchContext) as DigestDataDispatch;

  const handleRemoveTeam = async (team: { id: number; name: string }) => {
    try {
      const result = await deleteTeams({
        variables: { seriesId: digestSeriesObject.id, teamId: team.id },
      });
      
      if (result.data?.deleteDigestSeriesTeam) {
        dispatchDigestData({
          type: DigestDataActions.RemoveDigestSeriesTeam,
          payload: {
            digestSeriesId: digestSeriesObject.id,
            teamId: team.id,
          },
        });
        toast.success(`Removed team ${team.name}`);
      }
    } catch (error) {
      toast.error(`Failed to remove team: ${error}`);
    }
  };
  return (
    <>
    <h1 className="text-2xl font-recoleta mt-10">Teams</h1>
    <div className="flex flex-row gap-x-4 gap-y-4">
      {digestSeriesObject.teams.map((team) => {
        return <Badge 
          key={team.id} 
          badge={{ text: `${team.name} (${team.id})`, id: team.id.toString() }} 
          loading={deleteTeamsLoading.loading}
          onRemove={() => handleRemoveTeam(team)}/>;
      })}
      <Tippy theme="light" delay={200} content={<p className="text-center">Add Team</p>}>
      <div
        className="text-sm items-center p-0.5 bg-licorice-noir-lighter rounded-full cursor-pointer text-white hover:bg-licorice-noir "
        onClick={() => setIsTeamModalOpen(true)}
      >
        <PlusIcon className="h-5 w-5" />
      </div>
    </Tippy>
    </div>

      </>
  )
}

const EditDigestSeriesTeamModal = (props: { orgId: number, seriesId: number; isTeamModalOpen: boolean; setIsTeamModalOpen: (open: boolean) => void, currentTeams: IDropDownItem[]; }) => {
  const { orgId, seriesId, isTeamModalOpen, setIsTeamModalOpen, currentTeams } = props;
  const [ editTeam, editTeamLoading ] = useEditDigestSeriesTeamMutation();
  const { data: teamsData, loading: teamsLoading } = useGetOrganizationUserTeamsQuery({variables: { orgId }, skip: !isTeamModalOpen});
  const [ selectedTeams, setSelectedTeams ] = useState<IDropDownItem[]>([]);
  const [ availableTeams, setAvailableTeams ] = useState<IDropDownItem[]>([]);
  const [ selectedTeam, setSelectedTeam ] = useState<IDropDownItem>(EMPTY_DROPDOWN_ITEM);
  const dispatchDigestData = useContext(DigestDataDispatchContext) as DigestDataDispatch;

  useEffect(() => {
    if (!isTeamModalOpen) {
      setSelectedTeams([]);
      setSelectedTeam(EMPTY_DROPDOWN_ITEM);
      setAvailableTeams([]);
    } else {
      setSelectedTeams(currentTeams);
      setSelectedTeam(EMPTY_DROPDOWN_ITEM);
      
      if (teamsData?.currentOrgUser?.teams) {
        const selectedTeamIds = new Set(currentTeams.map(team => team.id));
        const teamOptions = teamsData.currentOrgUser.teams
          .filter(team => !selectedTeamIds.has(team.id.toString()))
          .map((team): IDropDownItem => ({
            id: team.id.toString(),
            name: `${team.name} (${team.id})`
          }));
        setAvailableTeams(teamOptions);
      }
    }
  }, [isTeamModalOpen, currentTeams, teamsData]);

  const handleAddTeam = (team: IDropDownItem) => {
    if (team && !selectedTeams.some(t => t.id === team.id)) {
      setSelectedTeams([...selectedTeams, team]);
      setAvailableTeams(availableTeams.filter(t => t.id !== team.id));
      setSelectedTeam(EMPTY_DROPDOWN_ITEM);
    }
  };

  const handleRemoveTeam = (team: IDropDownItem) => {
    setSelectedTeams(selectedTeams.filter(t => t.id !== team.id));
    setAvailableTeams([...availableTeams, team]);
  };

  const editDigestSeriesTeam = async() => {
    const result = await editTeam({
      variables: {
        editDigestSeriesTeamId: seriesId,
        teamIds: selectedTeams.map(team => parseInt(String(team.id))),
      },
    });
    if (result.data?.editDigestSeriesTeam) {
      dispatchDigestData({
        type: DigestDataActions.SetDigestSeriesTeams,
        payload: {
          digestSeriesId: seriesId,
          teams: selectedTeams.map(team => ({
            id: parseInt(String(team.id)),
            name: team.name.split(' (')[0], // Remove the ID from the name
          })),
        },
      });
      toast.success(`Added team`);
      setIsTeamModalOpen(false);
    }
    if (result.errors) {
      toast.error(`Error creating channel: ${result.errors}`);
    }
  }

  // we have the org in our state, so simply use the org to display the teams
  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setIsTeamModalOpen(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={editTeamLoading.loading}
            onClick={() => {
              editDigestSeriesTeam();
            }}
            loadingConfirm={editTeamLoading.loading}
          ></Button>
        </div>
      </div>
    );
  };
  return (
    <Modal open={isTeamModalOpen} setOpen={setIsTeamModalOpen}>
      <div className="mt-3 text-center sm:mt-5 text-licorice-noir">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Add New Team
        </Dialog.Title>
        <div className="flex flex-col gap-y-8 pt-5 h-full px-6">
          <div className="flex flex-row gap-x-2 items-center justify-between w-full">
            <RowLabel label="Channel Type" />
            <VirtualizedComboBox
              placeholder="Choose Teams..."
              comboBoxData={availableTeams}
              setSelectedItem={(item) => {
                if (item) {
                  handleAddTeam(item);
                }
              }}
              selectedItem={selectedTeam}
            />
            <div className="col-span-3 flex justify-start items-top px-6 gap-x-2">
              {selectedTeams.map((team) => {
                return (
                  <Badge
                    badge={{ text: team.name, id: team.id.toString() }}
                    key={team.id}
                    onRemove={() => handleRemoveTeam(team)}
                    size = {BadgeSize.Small}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
}

const DigestSeriesSendFrequency = (props: { digestSeriesObject: DigestSeriesDataType }) => {
  const { digestSeriesObject } = props;
  const [updateSendFrequency, updateSendFrequencyLoading] = useUpdateDigestSeriesSendFrequencyMutation();
  const dispatchDigestData = useContext(DigestDataDispatchContext) as DigestDataDispatch;
  const [selectedFrequency, setSelectedFrequency] = useState<IDropDownItem>(
    digestSeriesObject.sendFrequency 
      ? { id: digestSeriesObject.sendFrequency, name: formatSendFrequency(digestSeriesObject.sendFrequency) }
      : EMPTY_DROPDOWN_ITEM
  );
  useEffect(() => {
    setSelectedFrequency(digestSeriesObject.sendFrequency
      ? { id: digestSeriesObject.sendFrequency, name: formatSendFrequency(digestSeriesObject.sendFrequency) }
      : EMPTY_DROPDOWN_ITEM
    );
  }, [digestSeriesObject]);


  function formatSendFrequency(frequency: string): string {
    switch (frequency) {
      case 'weekly':
        return 'Weekly';
      case 'every_two_weeks':
        return 'Every Two Weeks';
      case 'monthly':
        return 'Monthly';
      default:
        return frequency;
    }
  }

  const handleFrequencyChange = async (item: IDropDownItem | undefined) => {
    if (!item) return;
    
    setSelectedFrequency(item);
    
    try {
      const result = await updateSendFrequency({
        variables: {
          digestSeriesId: digestSeriesObject.id,
          //@ts-ignore
          sendFrequency: getEnumValue(DigestSendFrequency, item)
        }
      });
      
      if (result.data?.updateDigestSeriesSendFrequency) {
        dispatchDigestData({
          type: DigestDataActions.UpdateDigestSeriesSendFrequency,
          payload: {
            digestSeriesId: digestSeriesObject.id,
            //@ts-ignore
            sendFrequency: getEnumValue(DigestSendFrequency, item),
          },
        });
        //@ts-ignore
        toast.success(`Send frequency updated to ${formatSendFrequency(item.name)}`);
      }
    } catch (error) {
      toast.error(`Failed to update send frequency: ${error}`);
      setSelectedFrequency(
        digestSeriesObject.sendFrequency 
          ? { id: digestSeriesObject.sendFrequency, name: formatSendFrequency(digestSeriesObject.sendFrequency) }
          : EMPTY_DROPDOWN_ITEM
      );
    }
  };

  return (
    <>
      <h1 className="text-2xl font-recoleta mt-10">Send Frequency</h1>
      <div className="flex flex-row gap-x-4 items-center">
        <div className="w-64">
          <VirtualizedComboBox
            placeholder="Choose send frequency..."
            comboBoxData={getDropDownSelectionsFromEnum(DigestSendFrequency)}
            setSelectedItem={handleFrequencyChange}
            selectedItem={selectedFrequency}
          />
        </div>
        {updateSendFrequencyLoading.loading && (
          <AdjustableLoadingIcon color={'text-milk'} width={4} height={4} />
        )}
      </div>
    </>
  );
};

const DigestSeriesChannels = (props: { digestSeriesObject: DigestSeriesDataType; setIsChannelModalOpen: (open: boolean) => void }) => {
  const { digestSeriesObject, setIsChannelModalOpen } = props;
  const channels = useGetDigestSeriesChannelsQuery({ variables: { seriesId: digestSeriesObject.id } });
  const [deleteChannel, deleteData] = useDeleteDigestSeriesChannelMutation();
  return (
    <>
      <h1 className="text-2xl font-recoleta mt-10">Digest Delivery</h1>
      <div className="flex flex-row gap-x-4 gap-y-4">
        Channels:
        {/* show list of channels for the series  */}
        {channels.loading && <AdjustableLoadingIcon color={'text-white'} width={4} height={4} />}
        {channels.data?.digestSeriesChannels.map((channel) => {
          return (
            <Badge
              key={channel.id}
              badge={{ text: `${channel.subChannelName} (${channel.subChannelType})`, id: channel.id.toString() }}
              loading={deleteData.loading}
              onRemove={() => {
                deleteChannel({
                  variables: { channelId: channel.id, seriesId: digestSeriesObject.id },
                  refetchQueries: [{ query: GetDigestSeriesChannelsDocument, variables: { seriesId: digestSeriesObject.id } }],
                  awaitRefetchQueries: true,
                });
              }}
            />
          );
        })}
        <Tippy theme="light" delay={200} content={<p className="text-center">Add Channel</p>}>
          <div
            className="text-sm items-center p-0.5 bg-licorice-noir-lighter rounded-full cursor-pointer text-white hover:bg-licorice-noir"
            onClick={() => setIsChannelModalOpen(true)}
          >
            {/* <p>Add Sentence to Group</p> */}

            <PlusIcon id="add-group-sentence" className="h-5 w-5" />
          </div>
        </Tippy>
      </div>
    </>
  );
};

const CreateDigestSeriesChannelModal = (props: { seriesId: number; isChannelModalOpen: boolean; setOpen: (open: boolean) => void }) => {
  const { seriesId, isChannelModalOpen, setOpen } = props;
  const [channelType, setChannelType] = useState<Channel_Type>(Channel_Type.Sendgrid);
  const [channelValue, setChannelValue] = useState<string>('');
  const [createChannel, createResult] = useCreateDigestSeriesChannelMutation();

  const canCreateChannel = () => {
    return channelValue.length > 0;
  };

  const createDigestSeriesChannel = async () => {
    const result = await createChannel({
      variables: {
        channelType: channelType,
        seriesId,
        slack: channelType === Channel_Type.Slack ? { channelName: channelValue } : undefined,
        sendGrid: channelType === Channel_Type.Sendgrid ? { listId: channelValue } : undefined,
      },
      // refetch - channels
      refetchQueries: [{ query: GetDigestSeriesChannelsDocument, variables: { seriesId } }],
      awaitRefetchQueries: true,
    });
    if (result.data?.createDigestSeriesChannel) {
      toast.success(`Created channel ${result.data.createDigestSeriesChannel.subChannelName}`);
      setOpen(false);
    }
    if (result.errors) {
      toast.error(`Error creating channel: ${result.errors}`);
    }
    setChannelValue('');
  };

  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setOpen(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={createResult.loading || !canCreateChannel()}
            onClick={() => {
              createDigestSeriesChannel();
            }}
            loadingConfirm={createResult.loading}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={isChannelModalOpen} setOpen={setOpen}>
      <div className="mt-3 text-center sm:mt-5 text-licorice-noir">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Create New Channel
        </Dialog.Title>
        <div className="flex flex-col gap-y-8 pt-5 h-full px-6">
          <div className="flex flex-row gap-x-2 items-center justify-between w-full">
            <RowLabel label="Channel Type" />
            <VirtualizedComboBox
              comboBoxData={getDropDownSelectionsFromEnum(Channel_Type)}
              setSelectedItem={(item) => {
                if (item) {
                  //@ts-ignore
                  setChannelType(getEnumValue(Channel_Type, item));
                }
              }}
              selectedItem={getDropDownItemFromEnumSelection(Channel_Type, channelType)}
              placeholder="Choose Channel"
            />
          </div>
          {channelType === Channel_Type.Sendgrid ? (
            <GetSendgridChannelValue channelValue={channelValue} setChannelValue={setChannelValue} />
          ) : (
            <GetSlackChannelValue channelValue={channelValue} setChannelValue={setChannelValue} />
          )}
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            // we don't want the modal to immediately close when the submit button is hit. this just prevents the modal closing prematurely.
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
};

const GetSendgridChannelValue = (props: { channelValue: string; setChannelValue: (value: string) => void }) => {
  const { channelValue, setChannelValue } = props;
  return (
    <div className="flex flex-row gap-x-2 items-center justify-between w-full">
      <RowLabel label="Sendgrid List Id" />
      <input
        type="text"
        className="rounded-md border border-buttercream-frosting-300 p-2 w-1/2"
        placeholder="Enter the sendgrid list id"
        value={channelValue}
        onChange={(e) => setChannelValue(e.target.value)}
      />
    </div>
  );
};

const GetSlackChannelValue = (props: { channelValue: string; setChannelValue: (value: string) => void }) => {
  const { channelValue, setChannelValue } = props;
  return (
    <div className="flex flex-row gap-x-2 items-center justify-between w-full">
      <RowLabel label="Slack Channel Name" />
      <input
        type="text"
        className="rounded-md border border-buttercream-frosting-300 p-2 w-1/2"
        placeholder="#channel-name"
        value={channelValue}
        onChange={(e) => setChannelValue(e.target.value)}
      />
    </div>
  );
};

export default DigestSeriesMainSection;
