import { useRef, useState } from 'react';
import { BreakdownSelector } from './BreakdownSelector';
import { SelectorModal } from './SelectorModal';
import { Option } from './SelectorDropdown';
import { CustomIcon } from '../../icons/CustomIcon';
import { Breakdown } from '../../../../generated/graphql';

export type BreakdownID = Breakdown | number | undefined;
export interface IBreakdownSelectorAndModalProps {
  selectedBreakdown: Option<BreakdownID>;
  setSelectedBreakdown: (breakdown: Option<BreakdownID>) => void;
  breakdowns: Option<BreakdownID>[];
  disabled?: boolean;
  isBreakdownSelectorModalOpen: boolean;
  toggleBreakdownSelectorModal: (isBreakdownSelectorModalOpen: boolean) => void;
}

export const BreakdownSelectorAndModal = ({
  selectedBreakdown,
  setSelectedBreakdown,
  breakdowns,
  disabled = false,
  isBreakdownSelectorModalOpen,
  toggleBreakdownSelectorModal,
}: IBreakdownSelectorAndModalProps) => {
  const breakdownParentRef = useRef<HTMLDivElement>(null);

  const openBreakdownSelectorModal = () => {
    toggleBreakdownSelectorModal(true);
  };

  const closeBreakdownSelectorModal = () => {
    toggleBreakdownSelectorModal(false);
  };

  return (
    <div className="flex relative" ref={breakdownParentRef}>
      <BreakdownSelector<BreakdownID>
        selectedBreakdown={selectedBreakdown}
        isBreakdownSelectorModalOpen={isBreakdownSelectorModalOpen}
        openBreakdownSelectorModal={openBreakdownSelectorModal}
        closeBreakdownSelectorModal={closeBreakdownSelectorModal}
        disabled={disabled}
      />
      {!disabled ? (
        <SelectorModal<BreakdownID>
          icon={<CustomIcon name="chart-breakdown" className="w-4 h-4" />} // TODO: Add gray-secondary
          title="Breakdown"
          selectedOption={selectedBreakdown}
          setSelectedOption={setSelectedBreakdown}
          options={breakdowns}
          isModalOpen={isBreakdownSelectorModalOpen}
          closeModal={closeBreakdownSelectorModal}
          parentRef={breakdownParentRef}
          hasSearch={true}
          deleteOption={
            selectedBreakdown.value !== -1
              ? {
                  text: 'Delete breakdown',
                  onClick: () => {
                    setSelectedBreakdown({ label: 'All Feedback', value: -1 });
                    closeBreakdownSelectorModal();
                  },
                }
              : undefined
          }
          position="bottom-right"
        />
      ) : null}
    </div>
  );
};

export const defaultBreakdownOptions: Option<BreakdownID>[] = [
  { label: 'All Feedback', value: -1, sources: [] },
  { label: 'Source', value: Breakdown.Source, sources: [] },
  { label: 'All Groups', value: Breakdown.Group, sources: [] },
  { label: 'Non-Parent Groups', value: Breakdown.LeafGroup, sources: [] },
];
