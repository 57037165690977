export type ChartColor = {
  backgroundColor: string;
  borderColor: string;
  name?: string; // REFERENCE ONLY
};

export const primaryChartColor: ChartColor = {
  backgroundColor: '#FCCBC9',
  borderColor: '#F6524A',
  name: 'Primary',
};

export const multipleChartColors: ChartColor[] = [
  {
    backgroundColor: '#F7D7CC',
    borderColor: '#E47A54',
    name: 'First',
  },
  {
    backgroundColor: '#DFE9D3',
    borderColor: '#94B76B',
    name: 'Second',
  },
  {
    backgroundColor: '#C7B9ED',
    borderColor: '#744FD1',
    name: 'Third',
  },
  {
    backgroundColor: '#EFC8CA',
    borderColor: '#CB484F',
    name: 'Fourth',
  },
  {
    backgroundColor: '#F8E4D6',
    borderColor: '#DDAD8B',
    name: 'Fifth',
  },
  {
    backgroundColor: '#BBBBC1',
    borderColor: '#1D1E30',
    name: 'Sixth',
  },
  {
    backgroundColor: '#F9E9CB',
    borderColor: '#ECB753',
    name: 'Seventh',
  },
  {
    backgroundColor: '#BFD2F1',
    borderColor: '#2B6BD0',
    name: 'Eighth',
  },
  {
    backgroundColor: '#EAEAE6',
    borderColor: '#BABAAB',
    name: 'Ninth',
  },
  {
    backgroundColor: '#FCD6D1',
    borderColor: '#F57865',
    name: 'Tenth',
  },
  {
    backgroundColor: '#F2F6D8',
    borderColor: '#C1D06E',
    name: 'Eleventh',
  },
  {
    backgroundColor: '#BCD6DC',
    borderColor: '#20788A',
    name: 'Twelfth',
  },
  {
    backgroundColor: '#FBE8D7',
    borderColor: '#DFA46E',
    name: 'Thirteenth',
  },
  {
    backgroundColor: '#EAE5F8',
    borderColor: '#B9A7E8',
    name: 'Fourteenth',
  },
  {
    backgroundColor: '#EFF4E9',
    borderColor: '#B7CCA1',
    name: 'Fifteenth',
  },
  {
    backgroundColor: '#F9DFC8',
    borderColor: '#E67E22',
    name: 'Sixteenth',
  },
  {
    backgroundColor: '#CED4DA',
    borderColor: '#5B6F84',
    name: 'Seventeenth',
  },
  {
    backgroundColor: '#DDDEC8',
    borderColor: '#8E9049',
    name: 'Eighteenth',
  },
];

export const sentimentChartColors: { [key: string]: ChartColor } = {
  positive: {
    backgroundColor: '#DFE9D3',
    borderColor: '#94B76B',
    name: 'Positive',
  },
  negative: {
    backgroundColor: '#EFC8CA',
    borderColor: '#CB484F',
    name: 'Negative',
  },
  neutral: {
    backgroundColor: '#F9E9CB',
    borderColor: '#ECB753',
    name: 'Neutral',
  },

  // Capitalized versions of the same properties above for silly devs who forgot to use .toLowerCase() somewhere [AKA me]

  Positive: {
    backgroundColor: '#DFE9D3',
    borderColor: '#94B76B',
    name: 'Positive',
  },
  Negative: {
    backgroundColor: '#F1D0D2',
    borderColor: '#CB484F',
    name: 'Negative',
  },
  Neutral: {
    backgroundColor: '#F9E9CB',
    borderColor: '#ECB753',
    name: 'Neutral',
  },
};

export const npsChartColors: { [key: string]: ChartColor } = {
  promoter: {
    backgroundColor: '#DFE9D3',
    borderColor: '#94B76B',
    name: 'Promoter',
  },
  detractor: {
    backgroundColor: '#EFC8CA',
    borderColor: '#CB484F',
    name: 'Detractor',
  },
  passive: {
    backgroundColor: '#F9E9CB',
    borderColor: '#ECB753',
    name: 'Passive',
  },

  // Capitalized versions of the same properties above for silly devs who forgot to use .toLowerCase() somewhere [AKA me]

  Promoter: {
    backgroundColor: '#DFE9D3',
    borderColor: '#94B76B',
    name: 'Promoter',
  },
  Detractor: {
    backgroundColor: '#EFC8CA',
    borderColor: '#CB484F',
    name: 'Detractor',
  },
  Passive: {
    backgroundColor: '#F9E9CB',
    borderColor: '#ECB753',
    name: 'Passive',
  },
};
