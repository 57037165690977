import { useContext } from 'react';
import { DigestDataActions, DigestObjectType, DigestSeriesDataType } from '../../../reducers/digests/digestDataReducer';
import { DigestDataDispatchContext } from '../../../context/digestDataContext';

export interface DigestSeriesListItemProps {
  item: DigestSeriesDataType;
}
const DigestSeriesListItem = ({ item }: DigestSeriesListItemProps) => {
  const digestDataDispatch = useContext(DigestDataDispatchContext);
  return (
    <div
      className="w-full border py-2 px-2 flex flex-col text-licorice-noir hover:cursor-pointer rounded-md mb-2"
      onClick={() =>
        digestDataDispatch?.({ type: DigestDataActions.SetSelectedDigestObject, payload: { selectedObject: item, digestObjectType: DigestObjectType.Series } })
      }
    >
      <div className="grid grid-cols-2">
        <div className="font-medium flex justify-start self-end">
          <div className='text-md font-recoleta'>{item.title}</div>
        </div>
        <div className="text-xs font-light text-gray-secondary flex justify-end items-end">
          <div>{new Date(item.dateCreated).toDateString()}</div>
        </div>
      </div>
      <div className="text-xs font-light">
        {item.creator.firstName} {item.creator.lastName}
      </div>
      <div className="text-xs font-light text-gray-secondary">
        {item.organization.name} ({item.organization.id})
      </div>
    </div>
  );
};

export default DigestSeriesListItem;
