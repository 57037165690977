import { ProgressState, Status } from "../../exports/ProgressMonitor";

export const ProgressBar = (params: {
    exportProgress: ProgressState
}) => {

    switch(params.exportProgress.status) {
        case Status.initializing:
            return (
                <div className="w-full rounded-full animate-pulse">
                    <p className="text-center text-xs text-blood-orange-sorbet">Exporting CSV</p>
                </div>
            )
        case Status.processing:
            return <div className="w-full rounded-full bg-vanilla-cream">
                    <div className="bg-licorice-noir text-white text-xs text-center p-0.5 leading-none rounded-full" style={{width: params.exportProgress.percent + "%"}}>{params.exportProgress.percent}%</div>
                </div>
        default:
            return <div role="empty"></div>
    }
}