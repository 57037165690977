import { SkeletonBar } from "./SkeletonBar";

export const MultiRowSkeletonTextArea = ({ rows = 4 }: { rows?: number }) => {
    return (
      <div 
        className="w-full rounded-md border border-buttercream-frosting-100 p-2 flex flex-col gap-2"
        style={{ height: `${(rows * 1.5) + 1}rem` }}
      >
        {[...Array(rows)].map((_, i) => (
        <SkeletonBar key={i} height="h-4" />
      ))}
    </div>
  );
};