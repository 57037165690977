import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../v2/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';
import { handleUserRedirect } from '../../../v2/util';
import Button, { ButtonVariant } from '../../baseComponents/Button';

export const VerificationStep = ({ email, password, fromLogin }: { email: string; password: string; fromLogin?: boolean }) => {
  const emailLower = email.toLowerCase();

  const [verificationCode, setVerificationCode] = useState('');
  const [validationLoading, setValidationLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
  const { setCurrentUser } = useContext(UserContext);

  let navigate = useNavigate();

  useEffect(() => {
    if (fromLogin) resendVerificationCode();
  }, []);

  const resendVerificationCode = async () => {
    setResendLoading(true);
    await Auth.resendSignUp(emailLower);
    setResendLoading(false);
    toast.success('Verification code sent!');
  };

  const validateCode = async () => {
    setValidationLoading(true);
    try {
      await Auth.confirmSignUp(emailLower, verificationCode);
      await Auth.signIn(emailLower, password);
      await setCurrentUser();
      handleUserRedirect(navigate);
    } catch (err: any) {
      setErrorMsg(err.message);
    }
    setValidationLoading(false);
  };

  return (
    <div>
      <div className="mb-6 flex flex-col">
        <div>
          <h1 className="text-xl font-medium">Verify your email</h1>

          <div className="pt-4 flex flex-col gap-y-2">
            {fromLogin ? <p>Your account has not yet been verified.</p> : null}
            <p>
              We've sent a {fromLogin ? 'new' : ''} verification code to <b>{emailLower}</b>. Please enter the code below.
            </p>
          </div>
        </div>
      </div>
      <form
        className="space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          validateCode();
        }}
      >
        <div>
          <div>
            <label htmlFor="code" className="block text-sm font-medium text-gray-secondary">
              Verification Code
            </label>
            <div className="mt-1">
              <input
                id="code"
                name="code"
                required
                className="block w-full appearance-none rounded-md border border-buttercream-frosting-300 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
          </div>
        </div>
        {errorMsg && (
          <div className="mt-4">
            <h1 id="error-message" className="text-sm font-medium text-red-600">
              {errorMsg}
            </h1>
          </div>
        )}

        <div className="flex flex-col gap-y-4">
          <Button id="verify-account-button" variant={ButtonVariant.Primary} text={'Submit'} submit loadingConfirm={validationLoading} expandWidth />
          <Button
            id="resent-verification-button"
            variant={ButtonVariant.Secondary}
            text={'Resend Verification Code'}
            loadingConfirm={resendLoading}
            expandWidth
            onClick={() => resendVerificationCode()}
          />
        </div>
      </form>
    </div>
  );
};
