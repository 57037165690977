import { forwardRef, useContext, useState } from "react";
import {FilterValueSelectionModal } from "./integrationFilters/FilterValueSelectionModal";
import { FilterFieldSelectionModal } from "./integrationFilters/FilterFieldSelectionModal";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { ComparisonType, FeedbackIntegrationFragment } from "../../../generated/graphql";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import { toast } from "react-hot-toast";

interface IntegrationFiltersModalProps {
    onClose: () => void;
    editingFilterIndex: number | null;
}

export const IntegrationFiltersModal = forwardRef<HTMLDivElement, IntegrationFiltersModalProps>((props, ref) => {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    
    // Initialize state from existing filter if editing
    const existingFilter = props.editingFilterIndex !== null 
        ? data.feedbackIntegration!.filters[props.editingFilterIndex]
        : null;

    const [selectedSegmentGroup, setSelectedSegmentGroup] = useState<FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"] | null>(
        existingFilter ? existingFilter.segmentGroup : null
    );
    const [comparisonValue, setComparisonValue] = useState(existingFilter ? existingFilter.value : '');
    
    // Update the dispatch action in your save/update logic
    const handleUpdateFilter = (comparisonOperator: ComparisonType, comparisonValue: string, segmentGroup: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"]) => {
        const newFilter: FeedbackIntegrationFragment["filters"][number] = {
            comparison: comparisonOperator,
            id: -1,
            segmentGroup: segmentGroup,
            value: comparisonValue
        };
        if (props.editingFilterIndex !== null) {
            newFilter.id = data.feedbackIntegration.filters[props.editingFilterIndex].id ?? -1;
            dispatch({
                type: FeedbackIntegrationActionType.UpdateExistingFilter,
                payload: {
                    filterIndex: props.editingFilterIndex,
                    filter: newFilter,
                }
            });
        } else {
            dispatch({
                type: FeedbackIntegrationActionType.AddFilter,
                payload: {
                    filter: newFilter,
                }
            });
        }
        props.onClose();
    };

    return (
        <div ref={ref} className="z-[101]" id="integration-filters-modal">
            <div className="bg-white border border-buttercream-frosting-100 shadow-md rounded-lg overflow-visible">
                <div className="flex bg-white rounded-lg h-auto min-h-[250px] md:min-h-[400px] max-h-[calc(100vh-200px)] overflow-auto">
                    <div className="md:hidden w-80 relative flex flex-col">
                        {!selectedSegmentGroup ? (
                            <FilterFieldSelectionModal onSelect={(field) => setSelectedSegmentGroup(field)} id="filter-field-selection-small" />
                        ) : (
                            <div className="w-80 bg-buttercream-frosting rounded-lg relative flex flex-col overflow-hidden">
                                <div className="p-4 border-b border-buttercream-frosting-100 flex items-center">
                                    <button 
                                        onClick={() => setSelectedSegmentGroup(null)}
                                        className="text-licorice-noir hover:text-blood-orange-sorbet flex items-center gap-2"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                        Back
                                    </button>
                                </div>
                                <FilterValueSelectionModal 
                                    handleUpdateFilter={handleUpdateFilter}
                                    segmentGroup={selectedSegmentGroup} 
                                    onClose={props.editingFilterIndex !== null 
                                        ? props.onClose 
                                        : () => setSelectedSegmentGroup(null)}
                                    comparisonValue={comparisonValue}
                                    setComparisonValue={setComparisonValue}
                                    initialComparisonOperator={existingFilter?.comparison}
                                    id="filter-value-selection-small"
                                />
                            </div>
                        )}
                    </div>
                    <div className="hidden md:flex">
                        <FilterFieldSelectionModal onSelect={(field) => setSelectedSegmentGroup(field)} id="filter-field-selection-large" />
                        {selectedSegmentGroup && (
                            <FilterValueSelectionModal 
                                handleUpdateFilter={handleUpdateFilter}
                                segmentGroup={selectedSegmentGroup} 
                                onClose={props.editingFilterIndex !== null 
                                    ? props.onClose 
                                    : () => setSelectedSegmentGroup(null)} 
                                comparisonValue={comparisonValue}
                                setComparisonValue={setComparisonValue}
                                initialComparisonOperator={existingFilter?.comparison}
                                id="filter-value-selection-large"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});