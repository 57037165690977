import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { FilterState } from '../../../../../reducers/filterStatement/filterStatementReducer';
import { useState } from 'react';
import { exportFeedbackWithProgressUpdate } from '../../../../../eventHandlers/exports/feedbackExport';
import { Status } from '../../../../../exports/ProgressMonitor';

interface ProgressState {
  status: Status;
  percent: number;
}

export const ExportToCSV = ({ teamId, filterState }: { teamId: number; filterState: FilterState }) => {
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });
  return (
    <>
      {exportProgress.status !== Status.idle && exportProgress.status !== Status.finished ? (
        <RadialProgress percent={exportProgress.percent} />
      ) : (
        <ExportButton teamId={teamId} filterState={filterState} exportProgress={exportProgress} updateExportProgress={updateExportProgress} />
      )}
    </>
  );
};

const ExportButton = ({
  teamId,
  filterState,
  exportProgress,
  updateExportProgress,
}: {
  teamId: number;
  filterState: FilterState;
  exportProgress: ProgressState;
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>;
}) => {
  return (
    <Tippy theme="dark" content="Export to CSV" delay={200}>
      <div
        className="rounded-md h-full gap-x-2 bg-white border border-licorice-noir text-licorice-noir flex flex-row justify-center items-center cursor-pointer p-2 duration-200 hover:bg-licorice-noir hover:text-white"
        onClick={() =>
          exportFeedbackWithProgressUpdate({ teamId: teamId, filterStateConsumable: filterState.filterConsumable }, exportProgress, updateExportProgress)
        }
      >
        <div>
          <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
        </div>
      </div>
    </Tippy>
  );
};

const RadialProgress = ({ percent }: { percent: number }) => {
  return (
    <>
      <style>
        {`
        .progress-ring__circle {
          transition: stroke-dashoffset 0.35s;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.8;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }

        .pulse-animation {
          animation: pulse 1.5s ease-in-out infinite;
        }
      `}
      </style>
      <div className="relative w-10 h-10 pulse-animation">
        <svg className="w-full h-full" viewBox="0 0 100 100">
          {/* Background circle */}
          <circle className="text-gray-tertiary stroke-current" strokeWidth="10" cx="50" cy="50" r="40" fill="transparent"></circle>
          {/* Progress circle */}
          <circle
            className="text-licorice-noir  progress-ring__circle stroke-current"
            strokeWidth="10"
            strokeLinecap="round"
            cx="50"
            cy="50"
            r="40"
            fill="transparent"
            strokeDasharray="251.2"
            strokeDashoffset={`calc(251.2 - (251.2 * ${percent}) / 100)`}
          ></circle>

          {/* Center text */}
          <text x="50" y="52" fill="currentColor" className="fill-licorice-noir font-medium" textAnchor="middle" alignmentBaseline="middle" fontSize="28">
            {percent}
          </text>
        </svg>
      </div>
    </>
  );
};
