export const BoardPreviewCardSkeleton = () => {
  return (
    <div
      data-testid="custom-chart-card-skeleton"
      className={`custom-chart-card-skeleton rounded-lg border border-buttercream-frosting-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-gray-tertiary  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70`}
    >
      <div className="h-[44px] rounded-t-lg px-4 bg-buttercream-frosting flex flex-row items-center w-full">
        <div className="flex flex-row gap-x-3 items-center w-full">
          <div className="w-[107px] h-3 flex rounded-lg bg-buttercream-frosting-100"></div>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 bg-buttercream-frosting justify-end pb-6 px-6 h-[164px] rounded-b-lg w-full">
        <div className="w-2/3 h-5 flex rounded-lg bg-buttercream-frosting"></div>
        <div className="w-1/3 h-5 flex rounded-lg bg-buttercream-frosting"></div>
      </div>
    </div>
  );
};
