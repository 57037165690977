import { Dispatch, SetStateAction } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Form from '../../../baseComponents/Form';
import Button, { ButtonVariant } from '../../baseComponents/Button';

export default function InformationModal({
  text,
  subtext,
  modalOpen,
  callbackModal,
}: {
  text?: string;
  subtext?: string;
  modalOpen: boolean;
  callbackModal: Dispatch<SetStateAction<boolean>>;
}) {
  const getFormButtons = () => {
    return (
      <div className="mt-4 flex flex-row justify-center text-center">
        <Button
          variant={ButtonVariant.Primary}
          text={'Close'}
          onClick={() => {
            callbackModal(false);
          }}
        ></Button>
      </div>
    );
  };
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
          <ExclamationCircleIcon className="h-8 w-8 text-blood-orange-sorbet" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-licorice-noir-lighter">
            {text}
          </Dialog.Title>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h4" className="text-md font-light leading-6 text-licorice-noir-lighter">
            {subtext}
          </Dialog.Title>
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
            callbackModal(false);
          }}
        ></Form>
      </div>
    </Modal>
  );
}
