import { useEffect, useState, useRef } from 'react';
import { FilterFieldValue, Operator } from '../../../../generated/graphql';
import { normalizeOperator } from '../operatorUtils';
import { Combobox, Listbox } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FixedSizeList } from 'react-window';
import Tippy from '@tippyjs/react';
import Badge from '../../Badge';
import { VirtualizedComboBox } from '../../VirtualizedComboBox';

interface FilterOperatorValueSelectorProps {
  operators: { id: string; label: string }[];
  valueOptions: FilterFieldValue[];
  selectedOperator: Operator;
  selectedValues: FilterFieldValue[];
  onOperatorChange: (value: Operator) => void;
  onValueChange: (value: FilterFieldValue[]) => void;
}

export function FilterOperatorValueSelector({
  operators,
  valueOptions,
  selectedOperator,
  selectedValues,
  onOperatorChange,
  onValueChange,
}: FilterOperatorValueSelectorProps) {
  const [configuringMultipleValues, setConfiguringMultipleValues] = useState(false);

  return (
    <div className="flex flex-col rounded-md px-4 py-4">
      <div className="flex flex-row gap-x-2">
        <FilterOperatorDropdown
          operators={operators}
          selectedOperator={selectedOperator}
          onOperatorChange={onOperatorChange}
          setConfiguringMultipleValues={setConfiguringMultipleValues}
        />
        {/* 
        We want to show a text box if there's no values to choose from
        then if we have values to choose from we want to show a selection box. 
        The difference is when we're configuring multiple values we want to clear the selection box and show a badge underneath
        */}
        {valueOptions.length === 0 ? (
          <FilterTextInput
            selectedValue={selectedValues[0]?.filterValue || ''}
            onSelectedValueChange={(value) => onValueChange([{ filterValue: value, displayName: value }])}
          />
        ) : configuringMultipleValues ? (
          <FilterSelectionBox
            selectedValue={undefined}
            onSelectedValueChange={(value) => onValueChange([...selectedValues, value])}
            options={valueOptions.filter((v) => !selectedValues.some((sv) => sv.filterValue === v.filterValue))}
          />
        ) : (
          <FilterSelectionBox
            selectedValue={selectedValues.length > 0 ? selectedValues[0] : undefined}
            onSelectedValueChange={(value) => onValueChange([value])}
            options={valueOptions}
          />
        )}
      </div>
      {/* Show the configured values */}
      {configuringMultipleValues && (
        <div className="flex flex-row gap-x-1 flex-wrap pt-2 gap-y-1">
          {selectedValues.map((value) => (
            <Badge
              key={value.filterValue}
              color="bg-buttercream-frosting"
              textColor="text-gray-secondary"
              hoverTextColor="text-licorice-noir"
              smaller
              badge={{ id: value.filterValue, text: value.displayName }}
              onRemove={() => {
                onValueChange(selectedValues.filter((v) => v.filterValue !== value.filterValue));
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

const FilterOperatorDropdown = ({
  operators,
  selectedOperator,
  onOperatorChange,
  setConfiguringMultipleValues,
}: {
  operators: { id: string; label: string }[];
  selectedOperator: Operator;
  onOperatorChange: (value: Operator) => void;
  setConfiguringMultipleValues: (value: boolean) => void;
}) => {
  const operatorSupportsMultipleValues = (operator: Operator) => {
    return [Operator.In, Operator.NotIn].includes(operator);
  };

  useEffect(() => {
    setConfiguringMultipleValues(operatorSupportsMultipleValues(selectedOperator));
  }, []);

  return (
    <div className="w-2/5 [&>div>button]:rounded-l-md [&>div>button]:border-r-0">
      <VirtualizedComboBox
        disableClear
        comboBoxData={operators.map((operator) => ({
          id: operator.id,
          name: operator.label,
          displayName: normalizeOperator(operator.id as Operator),
        }))}
        selectedItem={
          selectedOperator
            ? {
                id: selectedOperator,
                name: normalizeOperator(selectedOperator),
                displayName: normalizeOperator(selectedOperator),
              }
            : undefined
        }
        setSelectedItem={(item) => {
          if (!item) return;
          const operator = item.id as Operator;
          onOperatorChange(operator);
          setConfiguringMultipleValues(operatorSupportsMultipleValues(operator));
        }}
        placeholder="Select operator"
        height={256}
      />
    </div>
  );
};

const FilterTextInput = ({ selectedValue, onSelectedValueChange }: { selectedValue: string; onSelectedValueChange: (value: string) => void }) => {
  return (
    <input
      type="text"
      className="w-3/5 rounded-r-md border border-buttercream-frosting-100 h-9 pl-3 pr-10 focus:border-licorice-noir focus:outline-none focus:ring-1 focus:ring-licorice-noir"
      placeholder="Enter value"
      value={selectedValue}
      onChange={(e) => onSelectedValueChange(e.target.value)}
    />
  );
};

const FilterSelectionBox = ({
  selectedValue,
  onSelectedValueChange,
  options,
}: {
  selectedValue: FilterFieldValue | undefined;
  onSelectedValueChange: (value: FilterFieldValue) => void;
  options: FilterFieldValue[];
}) => {
  return (
    <div className="w-2/3" id="filter-selection-box">
      <VirtualizedComboBox
        disableClear
        comboBoxData={options.map((option) => ({
          id: String(option.filterValue),
          name: option.displayName,
          displayName: option.displayName,
        }))}
        selectedItem={
          selectedValue
            ? {
                id: String(selectedValue.filterValue),
                name: selectedValue.displayName,
                displayName: selectedValue.displayName,
              }
            : undefined
        }
        setSelectedItem={(item) => {
          if (!item) return;
          onSelectedValueChange({
            filterValue: String(item.id),
            displayName: item.displayName || item.name || '',
          });
        }}
        placeholder="Search value"
        height={256}
      />
    </div>
  );
};
