import { useEffect, useState } from 'react';
import { LowKeyTag } from '../../labels/LowKeyTag';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';

export type Option<T> = {
  value: T;
  label: string;
  sources?: string[];
};

export interface ISelectorDropdownProps<T> {
  options: Option<T>[];
  selectedOption: Option<T>;
  setSelectedOption: (value: Option<T>) => void;
  // parentRef: React.RefObject<HTMLDivElement>; // TODO: Add this once we use this component as a button dropdown
  hasSearch?: boolean;
  deleteOption?: {
    text: string;
    onClick: () => void;
  };
}

export const SelectorDropdown = <T,>({ options, selectedOption, setSelectedOption, hasSearch = false, deleteOption }: ISelectorDropdownProps<T>) => {
  const [filteredOptions, setFilteredOptions] = useState<Option<T>[]>(options);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase())));
  }, [searchQuery]);

  return (
    <div className="flex flex-col bg-white border-buttercream-frosting-100 rounded-md shadow-lg rounded-tl-none rounded-tr-none w-full max-h-[306px]">
      {hasSearch ? (
        <input
          key="search"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          className="cursor-pointer font-light xl:text-sm 2xl:text-md text-base px-4 py-2 border-md border rounded-md border-buttercream-frosting-100 focus:outline-none focus:border-licorice-noir m-4 mt-0 mb-0"
        />
      ) : null}
      {deleteOption ? (
        <div className="flex flex-row justify-start items-center px-2 py-3">
          <button className="flex flex-row gap-x-1 items-center text-raspberry text-sm" onClick={deleteOption.onClick}>
            <XMarkIcon className="w-4 h-4" />
            {deleteOption.text}
          </button>
        </div>
      ) : null}
      {filteredOptions.length ? (
        <div className="flex flex-col overflow-y-auto border-t-[1px] border-buttercream-frosting-100">
          {filteredOptions.map((option) => {
            const tags = option?.sources;
            const isTagsAvailable = tags !== undefined;
            const firstTag = tags?.[0];
            const isAI = firstTag == 'AI Generated';

            return (
              <div
                key={`${String(option.value)}${String(option.label)}${String(option.sources?.join(','))}`}
                onClick={() => setSelectedOption(option)}
                className={`cursor-pointer hover:bg-buttercream-frosting-100 hover:bg-opacity-50 px-4 py-2 font-medium xl:text-sm 2xl:text-md text-md ${
                  selectedOption.label === option.label ? 'bg-buttercream-frosting-100 bg-opacity-50' : ''
                } text-licorice-noir border-buttercream-frosting-100 border-b-[1px]`}
              >
                {option.label}
                {isTagsAvailable && tags?.length ? (
                  <div className={`flex flex-row gap-1 items-center flex-wrap`}>
                    {isAI ? (
                      <SparklesIcon className="h-3 w-3 stroke-gray-secondary pb-0.5" />
                    ) : (
                      <div className="text-gray-secondary font-light text-xs">Available from</div>
                    )}
                    {isAI ? <div className="text-gray-secondary font-light text-xs">AI Generated</div> : tags.map((tag) => <LowKeyTag text={tag} />)}
                  </div>
                ) : null}
                {!isAI && isTagsAvailable && !tags?.length ? <div className="text-gray-secondary font-light text-xs">Available by Default</div> : null}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col px-2 py-3 text-gray-secondary">No results found.</div>
      )}
    </div>
  );
};
