import { Dispatch, SetStateAction, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Button, { ButtonVariant } from '../../baseComponents/Button';
// make this generic for orgs and teams and also typescript bc i don't need all this repetition.

export default function NewGroupModal({
  modalOpen,
  callbackModal,
  confirmButton,
  type,
}: {
  modalOpen: boolean;
  callbackModal: Dispatch<SetStateAction<boolean>>;
  confirmButton?: (teamName: string, logoUrl: string, demoMode: string) => void;
  type: string;
}) {
  //const [open, setOpen] = useState(modalOpen);
  const [teamName, setTeamName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [demoMode, setDemoMode] = useState<string>('');
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div className="px-4">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
            <UserGroupIcon className="h-8 w-8 text-licorice-noir" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg font-recoleta leading-6 text-licorice-noir-lighter">
              Creating a New {type}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-tertiary">
                Eg: <b>Google, Amazon, AllenAI</b>
              </p>
            </div>
          </div>
          <div className="mt-10 flex flex-col gap-y-0">
            <h1 className="text-sm font-medium">{type} Name</h1>
            <input
              className="block w-full appearance-none rounded-md border border-buttercream-frosting-300 px-3 py-2 placeholder-gray-tertiary focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-8 flex  flex-row justify-between gap-x-4 text-center">
          <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal(false)} text="Cancel"></Button>
          <Button
            variant={ButtonVariant.Primary}
            text="Confirm"
            onClick={() => {
              confirmButton && confirmButton(teamName, logoUrl, demoMode);
            }}
          ></Button>
        </div>
      </div>
    </Modal>
  );
}
