import { useRef } from 'react';
import { PlotUnitSelector } from './PlotUnitSelector';
import { SelectorModal } from './SelectorModal';
import { Option } from './SelectorDropdown';
import { CustomIcon } from '../../icons/CustomIcon';
import { Y_Axis_Data } from '../../../../generated/graphql';

export type PlotUnit = 'absolute-volume' | 'percent-of-feedback' | 'positive-sentiment-rate';

export const defaultPlotUnitOptions: Option<PlotUnit>[] = [
  { label: 'Absolute Volume', value: 'absolute-volume' },
  { label: 'Percent of Feedback (%)', value: 'percent-of-feedback' },
  { label: 'Postive Sentiment Rate (%)', value: 'positive-sentiment-rate' },
];

export interface IPlotUnitSelectorAndModalProps {
  selectedPlotUnit: Option<PlotUnit>;
  setSelectedPlotUnit: (plotUnit: Option<PlotUnit>) => void;
  isPlotUnitSelectorModalOpen: boolean;
  togglePlotUnitSelectorModal: (isPlotUnitSelectorModalOpen: boolean) => void;
  styles?: React.CSSProperties;
}

export const PlotUnitSelectorAndModal = ({
  selectedPlotUnit,
  setSelectedPlotUnit,
  isPlotUnitSelectorModalOpen,
  togglePlotUnitSelectorModal,
  styles,
}: IPlotUnitSelectorAndModalProps) => {
  const plotUnitParentRef = useRef<HTMLDivElement>(null);

  const openPlotUnitSelectorModal = () => {
    togglePlotUnitSelectorModal(true);
  };

  const closePlotUnitSelectorModal = () => {
    togglePlotUnitSelectorModal(false);
  };

  return (
    <div className="relative" style={styles} ref={plotUnitParentRef}>
      <PlotUnitSelector
        selectedPlotUnit={selectedPlotUnit}
        isPlotUnitSelectorModalOpen={isPlotUnitSelectorModalOpen}
        openPlotUnitSelectorModal={openPlotUnitSelectorModal}
        closePlotUnitSelectorModal={closePlotUnitSelectorModal}
        parentRef={plotUnitParentRef}
      />
      <SelectorModal<PlotUnit>
        icon={<CustomIcon name="chart-plot-unit" className="w-4 h-4" />} // TODO: Add gray-secondary
        title="Plot Unit"
        selectedOption={selectedPlotUnit}
        setSelectedOption={setSelectedPlotUnit}
        options={defaultPlotUnitOptions}
        isModalOpen={isPlotUnitSelectorModalOpen}
        closeModal={closePlotUnitSelectorModal}
        parentRef={plotUnitParentRef}
        position="top-right"
      />
    </div>
  );
};

export const plotUnitToY_Axis_Data: { [key in PlotUnit]: Y_Axis_Data } = {
  'absolute-volume': Y_Axis_Data.AbsoluteVolume,
  'percent-of-feedback': Y_Axis_Data.RelativeShare,
  'positive-sentiment-rate': Y_Axis_Data.Favorability,
};

export const Y_Axis_DataToPlotUnit: { [key in Y_Axis_Data]: PlotUnit } = {
  [Y_Axis_Data.AbsoluteVolume]: 'absolute-volume',
  [Y_Axis_Data.RelativeShare]: 'percent-of-feedback',
  [Y_Axis_Data.Favorability]: 'positive-sentiment-rate',
};
