import { assign, reduce } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { AccessControlListQuery, Action, Domain, DomainInput, Resource, useAccessControlListLazyQuery } from "../../generated/graphql";
import { useValidTeamAppContext } from './AppContext';

type ACL = Partial<Record<Resource, Partial<Record<Action, boolean>>>>;
interface PermissionsContextProps {
  hasPermission: (resource: Resource, action: Action) => boolean;
}

export const PermissionsContext = createContext<PermissionsContextProps>({
  hasPermission: () => false,
});

// This code transforms the accessControlList data into a structured ACL object
// The ACL object is organized by resource and action, with boolean values indicating permissions
// It uses reduce to iterate over the accessControlList array
// For each permission, it creates or updates an entry in the ACL object
// The resulting structure allows for easy access to permission information

// Example input format of accessControlList:
// [
//   { resource: "TEAM", actions: ["READ", "WRITE"] },
//   { resource: "ORG", actions: ["READ"] }
// ]

// Example output format after reducing:
// {
//   TEAM: { READ: true, WRITE: true },
//   ORG: { READ: true }
// }
const computeACL = (data: AccessControlListQuery) => {
  const aclData: ACL = data.accessControlList.reduce((acc: ACL, permission) => {
      const actionMap = reduce(permission.actions, (memo, action) => assign(memo, { [action as Action]: true }), {} as ACL);
      return assign(acc, {[permission.resource]: actionMap })
  }, {} as ACL);
  return aclData;
}

/**
 * Permissions context provider depends on the AppContext to get the current team and org id.
 * It then fetches the ACL for the current team and org and stores it in the context.
 * This allows us to use the ACL anywhere in the app, which is what we want (for now).
 * @returns  PermissionsContextProvider  HOC
 */
export const PermissionsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { curTeamId, curOrgId } = useValidTeamAppContext();
  const [acl, setACL] = useState<ACL | null>({});

  const [getACL] = useAccessControlListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const domains: DomainInput[] = [];
    if (curTeamId) {
      domains.push({ id: curTeamId, type: Domain.Team });
    }
    if (curOrgId) {
      domains.push({ id: curOrgId, type: Domain.Org });
    }
    
    getACL({ variables: { domains } }).then(({ data, error }) => {
      if (data) {
        setACL(computeACL(data));
      }
      if (error) {
        console.error("Error fetching ACL", error);
      }
    });
  }, [curTeamId, curOrgId]);

  const hasPermission = (resource: Resource, action: Action) : boolean => {
    if (acl) {
      return acl[resource]?.[action] || false;
    }
    console.error("ACL not found");

    return false;
  };

  return (
    <PermissionsContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};



