import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgInfoProvider, useOrgInfoState } from '../../../context/orgInfoContext';
import { useGetOrganizationQuery } from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import LoadingSpinner from '../../../v2/components/LoadingSpinner';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import OrgConnectionsList from '../../components/OrgConnectionsList';
import OrgDomainsSection from '../../components/OrgDomainsSection';
import { OrgIntegrationsList } from '../../components/orgInfo/OrgIntegrationsList';
import { PageWrapper } from '../PageWrapper';
import { OrgMembersSection } from './sections/OrgMembersSection';
import { OrgTitleSection } from './sections/OrgTitleSection';
import { OrgViewsSection } from './sections/OrgViewsSection';

export const OrganizationPage = () => {
  const { curOrgId, setCurOrgId } = useValidTeamAppContext();
  let { orgId } = useParams<{ orgId: string }>();

  const { data: orgRes, loading: loadingOrg } = useGetOrganizationQuery({
    variables: { orgId: Number(orgId) },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  let navigate = useNavigate();

  useEffect(() => {
    if (orgId && curOrgId !== Number(orgId)) {
      try {
        setCurOrgId(Number(orgId));
      } catch (err) {
        navigate(AppRoutes.v3FullPath.organizations);
      }
    }
  }, []);

  if (loadingOrg) return <LoadingSpinner />; //Replace with a nice skeleton
  if (!orgRes?.getOrganization) {
    toast.error('Organization not found');
    navigate(AppRoutes.v3FullPath.organizations);
    return <></>;
  }

  return (
    <OrgInfoProvider initialState={{ organization: orgRes.getOrganization }}>
      <OrganizationPageBody />
    </OrgInfoProvider>
  );
};

const OrganizationPageBody = () => {
  let navigate = useNavigate();

  const { organization } = useOrgInfoState();

  const checkSuccessPropsFromQueryString = async () => {
    const connectionParams = new URLSearchParams(window.location.search).get('connectionParams');
    if (!connectionParams) return;

    window.history.replaceState({}, '', window.location.pathname);

    const parsedParams = JSON.parse(connectionParams);
    const { success, connectionTypeId, error_description } = parsedParams;

    if (!success) {
      toast.error(error_description);
      return;
    }

    toast.success('Connection successful!');
  };

  useEffect(() => {
    checkSuccessPropsFromQueryString();
  }, []);

  return (
    <PageWrapper
      title=""
      backButton={
        <Button
          variant={ButtonVariant.Tertiary}
          shape={ButtonShape.Square}
          size={ButtonSize.Small}
          text="Back"
          onClick={() => navigate(AppRoutes.v3FullPath.organizations)}
          icon={<p>{'<'}</p>}
          iconPosition="left"
        />
      }
    >
      <div className="flex flex-col gap-y-4 font-circular mb-32">
        <div className="flex flex-col gap-y-2">
          <OrgTitleSection />
          <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-14 lg:gap-y-0">
            <div className="flex flex-col gap-y-10 lg:w-3/5">
              <OrgMembersSection />
              <OrgViewsSection />
            </div>
            <div className="flex flex-col gap-y-10 lg:w-2/5">
              {!organization.isManagedByIdentityPool ? <OrgDomainsSection /> : null}
              <div className="flex flex-col py-4">
                <div className="flex flex-row items-center justify-between">
                  <h1 className="text-2xl font-recoleta text-licorice-noir">Connections</h1>
                  <div className="flex-auto border-t border-buttercream-frosting-100 ml-4"></div>
                </div>
                <div className="flex flex-col divide-y divide-buttercream-frosting-100">
                  <OrgConnectionsList />
                  <OrgIntegrationsList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
