import { Icon } from '@iconify/react';
import { FC } from 'react';
import { Chart_Type } from '../../../../generated/graphql';
import { Option } from './SelectorDropdown';
import Tippy from '@tippyjs/react';

export type ChartType = 'line' | 'area' | 'horizontal' | 'vertical' | 'pie';

type ChartTypeElement = { ariaLabel: string; icon: React.ReactNode };

const chartTypeOptions: Partial<Record<ChartType, ChartTypeElement>> = {
  line: { ariaLabel: 'Line', icon: <Icon icon="ph:chart-line" className="h-6 w-6" /> },
  area: { ariaLabel: 'Stacked Area', icon: <Icon icon="carbon:chart-area" className="h-6 w-6" /> },
  vertical: { ariaLabel: 'Stacked Column', icon: <Icon icon="carbon:chart-stacked" className="h-6 w-6" /> },
  horizontal: { ariaLabel: 'Horizontal Bar', icon: <Icon icon="carbon:chart-bar" className="h-6 w-6" /> },
  pie: { ariaLabel: 'Pie', icon: <Icon icon="ph:chart-donut" className="h-6 w-6" /> },
};
export interface IChartTypeOptionsProps {
  selectedChartType: Option<ChartType>;
  setSelectedChartType: (chartType: Option<ChartType>) => void;
}

export const ChartTypeOptions: FC<IChartTypeOptionsProps> = ({ selectedChartType, setSelectedChartType }) => {
  return (
    <div className="flex flex-row lg:gap-x-2 xl:gap-x-0.5 2xl:gap-x-2">
      {Object.entries(chartTypeOptions).map(([chartType, { ariaLabel, icon }]) => {
        return (
          <Tippy key={chartType} content={ariaLabel}>
            <div
              key={chartType}
              onClick={() => setSelectedChartType({ value: chartType as ChartType, label: ariaLabel.toUpperCase() })}
              className={`${
                selectedChartType.value == chartType ? 'bg-buttercream-frosting-100 bg-opacity-70' : 'bg-white'
              } flex justify-center items-center border-[1px] border-buttercream-frosting-100 rounded h-[36px] w-[36px] hover:cursor-pointer hover:bg-buttercream-frosting-100 hover:bg-opacity-70 transition-all duration-200`}
              aria-label={ariaLabel}
            >
              {icon}
            </div>
          </Tippy>
        );
      })}
    </div>
  );
};

export const chartTypeToChart_Type: Partial<Record<ChartType, Chart_Type>> = {
  line: Chart_Type.Line,
  area: Chart_Type.StackedArea,
  horizontal: Chart_Type.HorizontalBar,
  vertical: Chart_Type.StackedBar,
  pie: Chart_Type.Pie,
};

export const Chart_TypeToChartType: Partial<Record<Chart_Type, ChartType>> = {
  [Chart_Type.Line]: 'line',
  [Chart_Type.StackedArea]: 'area',
  [Chart_Type.StackedBar]: 'vertical',
  [Chart_Type.HorizontalBar]: 'horizontal',
  [Chart_Type.Pie]: 'pie',
  [Chart_Type.VerticalBar]: 'vertical',
};
