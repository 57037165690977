import CustomFieldRow from "./CustomFieldRow";
import { useContext } from "react";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";

interface CustomFieldsSectionProps {
}

export default function CustomFieldsSection(props: CustomFieldsSectionProps) {
    const feedbackIntegration = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const customFields = feedbackIntegration.feedbackIntegration!.segmentConfig.filter(segment => segment.path !== "OVERRIDE");
    
    return (
        <div>
            {customFields.length > 0 ? 
            <table className="w-full text-left border-b-2 border-buttercream-frosting-100">
                <thead>
                    <tr>
                        <th className="font-light italic text-gray-tertiary text-sm border-b-2 border-buttercream-frosting-100 py-1 pr-4">Display name</th>
                        <th className="font-light italic text-gray-tertiary text-sm border-b-2 border-buttercream-frosting-100 py-1 pr-4">Custom fields</th>
                        <th className="font-light italic text-gray-tertiary text-sm border-b-2 border-buttercream-frosting-100 py-1"></th>
                        <th className=" border-b-2 border-buttercream-frosting-100 py-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {customFields.map((field, index) => 
                        <CustomFieldRow customField={field}/>
                    )}
                </tbody>
            </table>
            : <div className="italic text-gray-tertiary">No custom fields yet ... Add some.</div>}
        </div>
    );
}