import { classNames } from '../../v2/util';

interface TableProps<T> {
  data?: T[];
  columns: { title: string; render: (item: T) => JSX.Element | string; extraTdClassName?: string }[];
}

export default function AlertsTable<T>({ data, columns }: TableProps<T>) {
  return (
    <div className="overflow-visible mb-4 border-b">
      <table className="min-w-full divide-y divide-buttercream-frosting-100 p-5 overflow-y-visible">
        <thead className="bg-buttercream-frosting">
          <tr>
            {columns.map((column, idx) => (
              <th key={idx} scope="col" className="py-1 pl-5 text-left text-sm font-light tracking-wide text-gray-secondary">
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-buttercream-frosting-100">
          {data?.map((item, idx) => (
            <tr key={idx}>
              {columns.map((column, idx) => (
                <td className={classNames('whitespace-nowrap py-4 px-3 pl-5 text-sm text-gray-tertiary', column.extraTdClassName)} key={idx}>
                  {column.render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
