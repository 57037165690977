import { ExternalTicketData } from '../../../generated/graphql';
import { AsanaBrowsing } from './browsing/AsanaBrowsing';
import { JiraBrowsing } from './browsing/JiraBrowsing';
import { LinearBrowsing } from './browsing/LinearBrowsing';

export const ExternalTicketsScraperKeys = {
  jira: 'JiraExternalTickets',
  asana: 'AsanaExternalTickets',
  linear: 'LinearExternalTickets',
};

export const TicketBrowsing = ({
  integrationSystemKey,
  linkTicket,
  linkingTicket,
}: {
  integrationSystemKey: string;
  linkTicket: ({ ticketData }: { ticketData: ExternalTicketData }) => Promise<void>;
  linkingTicket: boolean;
}) => {
  //integrationSystemKey is the scraperKey. JiraExternalTickets, AsanaExternalTickets, etc.
  switch (integrationSystemKey) {
    case ExternalTicketsScraperKeys.jira:
      return <JiraBrowsing linkTicket={linkTicket} linkingTicket={linkingTicket} />;
    case ExternalTicketsScraperKeys.asana:
      return <AsanaBrowsing linkTicket={linkTicket} linkingTicket={linkingTicket} />;
    case ExternalTicketsScraperKeys.linear:
      return <LinearBrowsing linkTicket={linkTicket} linkingTicket={linkingTicket} />;
    default:
      return (
        <div className="flex flex-col gap-y-1 items-center py-3">
          <h1 className="font-medium text-lg">Error - please refresh and try again</h1>
        </div>
      );
  }
};
