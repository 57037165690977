import { npsChartColors, sentimentChartColors } from '../../../constants';

export const sentimentNames: string[] = Object.keys(sentimentChartColors);
export const npsNames: string[] = Object.keys(npsChartColors);

export type ChartLabelType = 'sentiment' | 'nps' | 'custom';

export const getChartLabelType = (label?: string): ChartLabelType => {
  if (!label) return 'custom';

  const lowerCaseLabel = label.toLowerCase();

  if (sentimentNames.includes(lowerCaseLabel)) return 'sentiment';

  if (npsNames.includes(lowerCaseLabel)) return 'nps';

  return 'custom';
};
