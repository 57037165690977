import { IntegrationFilters } from "./IntegrationFilters";
import { useContext, useState, useRef, useEffect } from "react";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { IntegrationFiltersModal } from "./IntegrationFiltersModal";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { Float } from '@headlessui-float/react';

export default function IntegrationFiltersSection() {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingFilterIndex, setEditingFilterIndex] = useState<number | null>(null);
    const filterRefs = useRef<(HTMLDivElement | null)[]>([]);
    
    const filters = data.feedbackIntegration!.filters;

    useEffect(() => {
        if (!isModalOpen) return;
        
        const handleClickOutside = (event: MouseEvent) => {
            console.log("Click detected");
            
            // Check if the click was on the filter that opened the modal
            if (editingFilterIndex !== null && 
                filterRefs.current[editingFilterIndex] && 
                filterRefs.current[editingFilterIndex]?.contains(event.target as Node)) {
                console.log("Click on filter, not closing");
                return;
            }
            
            // Find the modal element by ID
            const modalId = `integration-filters-modal-${editingFilterIndex}`;
            const modalElement = document.getElementById(modalId);
            
            // Check if the click was outside the modal
            if (modalElement && !modalElement.contains(event.target as Node)) {
                console.log(`Click outside modal ${modalId}, closing`);
                setIsModalOpen(false);
                setEditingFilterIndex(null);
            } else if (!modalElement) {
                console.log(`Modal element ${modalId} not found`);
            }
        };
        
        // Use a slight delay to ensure the DOM is fully updated
        const timeoutId = setTimeout(() => {
            document.addEventListener('mousedown', handleClickOutside);
        }, 200);
        
        return () => {
            clearTimeout(timeoutId);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen, editingFilterIndex]);

    return (
        <>
            <div className="flex flex-row flex-wrap items-center gap-2">
                {filters.length > 0 ? filters.map((filter, index) => (
                    <Float
                        key={index}
                        show={isModalOpen && editingFilterIndex === index}
                        placement="bottom"
                        offset={4}
                        shift={true}
                        flip={true}
                        portal={true}
                    >
                        <div 
                            ref={el => filterRefs.current[index] = el}
                            className="flex flex-row items-center gap-2"
                        >
                            <IntegrationFilters 
                                onClose={() => {}} 
                                onEdit={() => {
                                    setEditingFilterIndex(index);
                                    setIsModalOpen(true);
                                }} 
                                filter={filter} 
                                deleteFilter={() => {
                                    dispatch({ 
                                        type: FeedbackIntegrationActionType.DeleteFilter, 
                                        payload: { filterIndex: index } 
                                    });
                                }}
                            />
                            {index < filters.length - 1 && <p className="text-licorice-noir font-medium">and</p>}
                        </div>

                        <div id={`integration-filters-modal-${index}`}>
                            <IntegrationFiltersModal 
                                onClose={() => {
                                    setIsModalOpen(false);
                                    setEditingFilterIndex(null);
                                }}
                                editingFilterIndex={editingFilterIndex}
                            />
                        </div>
                    </Float>
                )) : <div className="italic text-gray-tertiary">No filters yet ... Set up some custom fields to add some.</div>}
            </div>
        </>
    );
}