import { RefObject, CSSProperties, Dispatch, SetStateAction } from 'react';

import { XCircleIcon } from '@heroicons/react/24/outline';
/* This is a small white popup menu, intended to be used over charts. */
interface PopupProps {
  children: JSX.Element;
  title?: string;
  width?: string;
  bottomRow?: JSX.Element;
  popupRef?: RefObject<any>;
  inlineStyles?: CSSProperties;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const Popup = ({ title, children, width, bottomRow, popupRef, inlineStyles, setOpen }: PopupProps): JSX.Element => {
  return (
    <div
      style={inlineStyles}
      ref={popupRef}
      className={`absolute z-50 mt-2 bg-white ${
        width ? width : 'w-72'
      } origin-top-right divide-y divide-buttercream-frosting-100 rounded-md bg-white text-licorice-noir shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none`}
    >
      {setOpen && (
        <XCircleIcon
          className="absolute top-1 right-1 h-6 w-6 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        />
      )}
      <div className="flex flex-col items-start justify-start p-3">
        {title ? (
          <div className="my-1  font-medium">
            <h2>{title}</h2>
          </div>
        ) : (
          ''
        )}
        <div className="flex w-full flex-col items-start justify-center gap-y-2">
          {children}
          {bottomRow && bottomRow}
        </div>
      </div>
    </div>
  );
};

export default Popup;
