import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { Role, useAllTeamsLazyQuery, useGetAllOrganizationsQuery, useInternalPermissionsMutation, useInternalUsersQuery } from '../../../generated/graphql';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import AdminCard from './AdminCard';

export const PermissionsCard = () => {
  const [view, setView] = useState<IDropDownItem | undefined>();
  const [org, setOrg] = useState<IDropDownItem | undefined>();
  const [role, setRole] = useState<IDropDownItem | undefined>();
  const [user, setUser] = useState<IDropDownItem | undefined>();

  const { data: organizations, loading: organizationsLoading, error: organizationsError } = useGetAllOrganizationsQuery();
  const { data: internalUsers, loading: internalUsersLoading, error: internalUsersError } = useInternalUsersQuery();
  const [allTeams, { data: allTeamsData, loading: allTeamsLoading, error: allTeamsError }] = useAllTeamsLazyQuery();
  const [internalPermissions, { data: internalPermissionsData, loading: internalPermissionsLoading, error: internalPermissionsError }] =
    useInternalPermissionsMutation();

  const roles: Role[] = [Role.Admin, Role.Contributor, Role.Viewer, Role.Replier];

  useEffect(() => {
    if (org?.id) {
      allTeams({
        variables: {
          orgId: org.id as number,
        },
      });
      setView(undefined);
      setUser(undefined);
      setRole(undefined);
    }
  }, [org?.id]);

  useEffect(() => {
    if (view?.id) {
      setUser(undefined);
      setRole(undefined);
    }
  }, [view?.id]);

  useEffect(() => {
    if (organizationsError) {
      toast.error(`Failed to load organizations: ${organizationsError.message}`);
    }
    if (internalUsersError) {
      toast.error(`Failed to load users: ${internalUsersError.message}`);
    }
    if (allTeamsError) {
      toast.error(`Failed to load teams: ${allTeamsError.message}`);
    }
  }, [organizationsError, internalUsersError, allTeamsError]);

  const handleUpdatePermissions = () => {
    if (!org?.id || !view?.id || !user?.id || !role?.name) return;
    internalPermissions({
      variables: {
        orgId: org.id as number,
        teamId: view.id as number,
        userId: user.id as number,
        role: role.name as Role,
      },
      onCompleted: () => {
        toast.success('Permissions updated successfully');
        setOrg(undefined);
        setView(undefined);
        setUser(undefined);
        setRole(undefined);
      },
      onError: (error) => {
        toast.error(`Error updating permissions: ${error.message}`);
      },
    });
  };

  return (
    <AdminCard title="Permissions" subTitle="Internal Role Administration" actionButtonText="Update Permissions" onActionButtonClick={handleUpdatePermissions}>
      <div className="flex flex-col w-full gap-y-3">
        {organizationsLoading ? (
          <AdjustableLoadingIcon width={5} height={5} />
        ) : (
          <VirtualizedComboBox
            comboBoxData={
              organizations?.allOrganizations?.map((org) => {
                return { name: org.name, id: org.id } as IDropDownItem;
              }) ?? []
            }
            setSelectedItem={setOrg}
            id="team-combobox"
            selectedItem={org}
            placeholder="Select an organization"
          />
        )}

        {org?.id ? (
          allTeamsLoading ? (
            <AdjustableLoadingIcon width={5} height={5} />
          ) : (
            <VirtualizedComboBox
              comboBoxData={
                allTeamsData?.allTeams?.map((team) => {
                  return { name: team.name, id: team.id } as IDropDownItem;
                }) ?? []
              }
              setSelectedItem={setView}
              id="team-combobox"
              selectedItem={view}
              placeholder="Select a team"
            />
          )
        ) : null}

        {view?.id && org?.id ? (
          <>
            <VirtualizedComboBox
              comboBoxData={
                internalUsers?.internalUsers?.flatMap((user) => {
                  return { name: user.email, id: user.id } as IDropDownItem;
                }) ?? []
              }
              setSelectedItem={setUser}
              id="team-combobox"
              selectedItem={user}
              placeholder="Select a user"
            />
            <VirtualizedComboBox
              comboBoxData={roles.map((role, index) => {
                return { name: role, id: index };
              })}
              setSelectedItem={setRole}
              id="role-combobox"
              selectedItem={role}
              placeholder="Select a role for the user"
            />
          </>
        ) : null}
      </div>
    </AdminCard>
  );
};
