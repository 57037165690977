import { useContext, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Breakdown,
  Chart_Type,
  useGetFilterFieldsLazyQuery,
  useGetPlotLazyQuery,
  useGetPlotPreviewLazyQuery,
  useSegmentGroupLazyQuery,
} from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { FilterContext } from '../../../context/filterStatementContext';
import { useChartState, useChartDispatch } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { Plot } from './Plot';
import InheritedBoardFiltersSection from './InheritedBoardFiltersSection';
import ChartTitle from './ChartTitle';
import ActionBar from './ActionBar';
import { ChartFeedbackSection, ChartFeedbackRef } from './ChartFeedbackSection';
import { ChartWithIntegratedSettings } from '../standardCharts/ChartWithIntegratedSettings';
import { defaultBreakdownOptions, BreakdownID } from '../standardCharts/chartElements/BreakdownSelectorAndModal';
import { Y_Axis_DataToPlotUnit, defaultPlotUnitOptions } from '../standardCharts/chartElements/PlotUnitSelectorAndModal';
import { Chart_TypeToChartType, chartTypeToChart_Type, ChartType } from '../standardCharts/chartElements/ChartTypeOptions';
import { Option } from '../standardCharts/chartElements/SelectorDropdown';
import { DataViewSection } from './DataViewSection';
import { FilterNode } from '../../lib/filterNode';
import { ChartModal } from './ChartModal';
import { DateFilterUtility } from '../../filters/utilities/DateFilterUtility';

export const EditChartPage = () => {
  const { curTeamId: teamId, curOrgId: orgId, organizations } = useValidTeamAppContext();
  const teams = organizations.find((org) => org.id === orgId)?.teams;
  const teamsList = teams?.map((team) => ({ id: team.id, name: team.name })) || [];

  /**
   * What is the initialLoadComplete for, what are we preventing from showing a loading spinner or something?
   */
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [disableBreakdown, setDisableBreakdown] = useState(false);

  /**
   * This is the filter state from the boards page.
   * This controls the date filters on the charts page.
   * If you set the date filter on the charts page and go to the boards page it'll be what you set the date to.
   *
   * If you set any filters on the boards page they automatically get consumed on the charts page through this context
   */
  const pageLevelFilterState = useContext(FilterContext);

  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    setStartDate(DateFilterUtility.getStartDate(pageLevelFilterState)?.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }));
    setEndDate(
      (DateFilterUtility.getEndDate(pageLevelFilterState) ?? new Date())?.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    );
  }, [pageLevelFilterState]);

  const chartState = useChartState();

  const chartDispatch = useChartDispatch();

  const { chartId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedSeriesId = searchParams.get('selectedSeriesId');

  const [getPlot] = useGetPlotLazyQuery();

  const [getPreview] = useGetPlotPreviewLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    setDisableBreakdown(chartState.chartConfigs?.series ? chartState.chartConfigs?.series?.length > 1 : false);
  }, [chartState.chartConfigs]);

  const fetchPlotPreview = async () => {
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    try {
      await getPreview({
        variables: {
          teamId,
          pageLevelFilterNode: pageLevelFilterState.filterConsumable,
          plotConfigurationInput: chartState.chartConfigs,
        },
        onCompleted(data) {
          chartDispatch({
            type: ChartActionType.SetCurrentChart,
            payload: { currentChart: data.getPlotPreview },
          });
          chartDispatch({
            type: ChartActionType.SetChartConfigs,
            payload: { chartConfigs: data.getPlotPreview.plotConfiguration },
          });
        },
      });
    } finally {
      chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    /**
     * I want to guarantee that this useEffect does not run on the first render of the page.
     *
     * On first render we want to use the useEffect below that handles loading the initial page data. This useEffect is only necessary for updating the plot.
     */
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!initialLoadComplete) return;
    fetchPlotPreview();
  }, [chartState.plotChanged, pageLevelFilterState.filterConsumable]);

  useEffect(() => {
    if (chartState.currentChart) {
      /**
       * When clicking in from a board the chartState.currentChart is already set.
       * If this property is already defined we can skip fetching the plot.
       * However, we still need to set the chart ID if we have one from the URL.
       */
      if (chartId) chartDispatch({ type: ChartActionType.SetChartId, payload: { id: Number(chartId) } });

      chartDispatch({
        type: ChartActionType.SetChartConfigs,
        payload: { chartConfigs: chartState.currentChart.plotConfiguration },
      });

      setInitialLoadComplete(true);
      return;
    }
    if (chartId) {
      // If we have a chartId we want to fetch the plot otherwise we're creating a new plot
      fetchPlotById().then(() => {
        setInitialLoadComplete(true);
      });
    } else {
      fetchPlotPreview().then(() => {
        setInitialLoadComplete(true);
      });
    }
  }, []);

  const fetchPlotById = async () => {
    chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: true } });
    chartDispatch({ type: ChartActionType.SetChartId, payload: { id: Number(chartId) } });
    await getPlot({
      variables: {
        teamId,
        chartId: Number(chartId),
        pageLevelFilterNode: pageLevelFilterState.filterConsumable,
      },
      onCompleted(data) {
        chartDispatch({
          type: ChartActionType.SetChartConfigs,
          payload: { chartConfigs: data.getPlot.plotConfiguration },
        });
        chartDispatch({
          type: ChartActionType.SetCurrentChart,
          payload: { currentChart: data.getPlot },
        });
        chartDispatch({ type: ChartActionType.SetLoadingChart, payload: { loadingChart: false } });
      },
    });
  };

  const chartFeedbackRef = useRef<ChartFeedbackRef>(null);

  const handleSeriesClick = useCallback(
    (seriesIndex: number) => {
      const seriesId = chartState.currentChart?.series?.[seriesIndex]?.seriesLabel?.id;
      if (seriesId) chartFeedbackRef.current?.setActiveTab(seriesId);
    },
    [chartState.currentChart]
  );

  useEffect(() => {
    if (selectedSeriesId && chartState.currentChart && chartFeedbackRef.current) {
      const seriesIndex = chartState.currentChart.series.findIndex((series) => series.seriesLabel?.id === selectedSeriesId);

      if (seriesIndex !== -1) {
        chartFeedbackRef.current.setActiveTab(selectedSeriesId);
      }
    }
  }, [selectedSeriesId, chartState.currentChart]);

  const [getFilterFields, _FilterFields] = useGetFilterFieldsLazyQuery();

  const [segments, setSegments] = useState<Option<BreakdownID>[] | undefined>();

  useEffect(() => {
    getFilterFields({
      variables: {
        teamId: teamId,
        fieldStatementFilters: pageLevelFilterState.filterConsumable,
      },
      onCompleted: (data) => {
        const segmentGroups: Option<BreakdownID>[] = data.getFilterFields
          .filter((field) => {
            return field.fieldName.startsWith('Entry.Segment');
          })
          .map((field) => {
            const segmentGroupId = parseInt(field.fieldName.split('.')[2]);
            return { value: segmentGroupId, label: field.displayName, sources: field.additionalMetaData };
          });

        setSegments(segmentGroups);
      },
    });
  }, []);

  const chartTypeAfterDataUpdate = useMemo(
    () => (chartState.chartConfigs?.chartType && Chart_TypeToChartType[chartState.chartConfigs.chartType]) ?? 'line',
    [chartState.currentChart]
  );

  const breakdownOptions = useMemo(() => (segments ? [...defaultBreakdownOptions, ...segments] : defaultBreakdownOptions), [segments]);

  const selectedBreakdown: Option<BreakdownID> = useMemo(() => {
    if (!chartState.chartConfigs) return { value: -1, label: 'All Feedback' };

    const { breakdown, series } = chartState.chartConfigs;
    const segmentGroupId = series?.[0]?.segmentGroupId;

    if (breakdown === Breakdown.Segment) {
      const matchingSegment = breakdownOptions.find((breakdownOption) => breakdownOption.value == segmentGroupId);
      return matchingSegment ?? { value: -1, label: 'All Feedback' };
    }

    return (
      breakdownOptions.find((breakdownOption) => breakdownOption.value == breakdown) ?? {
        value: -1,
        label: 'All Feedback',
      }
    );
  }, [breakdownOptions, chartState.chartConfigs]);

  const [showModal, setShowModal] = useState(false);
  const expandChart = () => setShowModal(true);
  const closeExpandedChart = () => setShowModal(false);

  return (
    <div id="edit-chart-page" className="flex flex-col relative">
      {showModal && chartState.currentChart && chartState.chartConfigs ? (
        <ChartModal
          title={chartState.currentChart.title}
          plotUnitLabel={defaultPlotUnitOptions.find((option) => option.value == Y_Axis_DataToPlotUnit[chartState.chartConfigs.yAxisMetric])!.label}
          breakdownLabel={selectedBreakdown.label}
          loadingChart={chartState.loadingChart}
          plotData={chartState.currentChart}
          closeExpandedChart={closeExpandedChart}
          chartType={chartTypeAfterDataUpdate}
          handleSeriesClick={handleSeriesClick}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      {initialLoadComplete ? (
        <div className="flex flex-col gap-y-2 mb-3">
          <ActionBar />
          <ChartTitle />
        </div>
      ) : null}
      <div className="grid grid-cols-11 gap-y-8 xl:gap-y-0 xl:gap-x-12 w-full">
        {!initialLoadComplete && chartState.loadingChart ? (
          <div className="col-span-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-y-4 col-span-11 xl:col-span-6" id="left-column">
              <ChartWithIntegratedSettings
                teamId={teamId}
                breakdowns={breakdownOptions}
                selectedChartType={{
                  value: chartTypeAfterDataUpdate,
                  label: chartTypeAfterDataUpdate,
                }}
                selectedBreakdown={selectedBreakdown}
                tempChartComponent={
                  chartState.currentChart ? (
                    <Plot
                      loading={chartState.loadingChart}
                      plotData={chartState.currentChart}
                      showLegend={chartState.chartConfigs?.chartType !== Chart_Type.HorizontalBar}
                      onSeriesClick={handleSeriesClick}
                    />
                  ) : (
                    <LoadingSpinner />
                  )
                }
                numberOfElements={chartState.currentChart?.series.length ?? 0}
                disableBreakdown={(chartState.chartConfigs?.series ?? []).length > 1}
                expandChart={expandChart}
              />
              {new FilterNode(pageLevelFilterState).getAppliedFilterFields().length > 0 ? <InheritedBoardFiltersSection /> : null}
              <DataViewSection
                dataSeriesConfiguration={chartState.chartConfigs?.series ?? []}
                addDataView={() => chartDispatch({ type: ChartActionType.AddSeries, payload: {} })}
                defaultTeamId={teamId}
                teamsList={teamsList}
                isLoading={chartState.loadingChart}
                disableBreakdown={disableBreakdown}
              />
            </div>
            <div className="col-span-11 xl:col-span-5 flex flex-col max-h-screen">
              {chartState.currentChart ? (
                <ChartFeedbackSection
                  ref={chartFeedbackRef}
                  currentChart={chartState.currentChart}
                  loading={chartState.loadingChart}
                  selectedSeriesId={selectedSeriesId ?? undefined}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
