import moment from 'moment';
import { EntryFragment } from '../../../../generated/graphql';
import { truncateAndEllipsis } from '../../../../v2/util';
import { Icon } from '@iconify/react';

/**
 * this is some dog shit. This doesn't have the appropriate logic to determine what entry text to show and I refuse to put it here.
 * This logic needs to live in the backend and vend a property on the EntryFragment that is the text to show with no leaky logic.
 */
const InsightEntryReview = ({ entry }: { entry: EntryFragment }) => {
  var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  let limit = width <= 768 ? 90 : 160; //  Tailwind's md breakpoint
  return (
    <div className="text-sm text-gray-secondary font-light">
      <Icon
        icon="gridicons:quote"
        className="py-1 text-blood-orange-sorbet -ml-1.5"
        width={24}
        height={24}
      />
      <p>
        {truncateAndEllipsis(entry.distillateText, limit)}
        {entry.date ? <i className="font-light "> - {moment(entry.date).format('MMM DD')}</i> : null}
      </p>
    </div>
  );
};

export default InsightEntryReview;
