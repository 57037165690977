import { useEffect, useState } from 'react';
import { ChartData, ChartDataset, ChartOptions, CoreScaleOptions, Scale, Tick } from 'chart.js';
import { Breakdown, Chart_Type, Label, PlotFragment, PlotSeries, Y_Axis_Data } from '../../../generated/graphql';
import { YAxisUnit } from '../../../v2/hooks/ChartHook';
import { getTooltipCallbacks } from './chartLabelStrategies';
import { truncateAndEllipsis } from '../../../v2/util';
import PlotView from './PlotView';
import { areAllChartValuesZero } from '../../lib/chart';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { ChartColor, sentimentChartColors, npsChartColors } from '../../../constants';
import { Chart_TypeToChartType } from '../standardCharts/chartElements/ChartTypeOptions';
import { ChartLabelType, getChartLabelType } from '../standardCharts/getChartLabelType';
import { generateChartColors } from '../standardCharts/generateChartColors';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
export const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;

const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

/**
 *  What are we doing here??? NPS buckets and sentiment colors are being generated for each series?
 *
 *  Tell me about it!
 */
const generateDataset = (
  data: PlotSeries[],
  breakdown: Breakdown | null | undefined,
  customNames: (string | undefined)[],
  chartType: Chart_Type
): ChartDataset[] => {
  const { backgroundColors, borderColors } = generateChartColors({
    chartType: Chart_TypeToChartType[chartType] ?? 'horizontal',
    numberOfColors: data.length,
  });

  if (chartType === Chart_Type.Pie) {
    const labelNames = data.map((series, index) => (!breakdown && customNames[index] !== undefined ? customNames[index] : series.seriesLabel.name));
    const dataSet: ChartDataset = {
      label: '',
      data: [],
      backgroundColor: [],
      borderColor: [],
    };

    data.forEach((ser, index) => {
      const labelName: string = labelNames[index]?.toLowerCase() ?? '';
      const labelType = getChartLabelType(labelName);

      dataSet.data.push(ser.dataSeries[0]);

      let chartColor: ChartColor;
      switch (labelType) {
        case 'sentiment':
          chartColor = sentimentChartColors[labelName];
          break;
        case 'nps':
          chartColor = npsChartColors[labelName];
          break;
        default:
          chartColor = {
            backgroundColor: backgroundColors(index),
            borderColor: borderColors(index),
          };
      }
      if (Array.isArray(dataSet.backgroundColor)) {
        dataSet.backgroundColor.push(chartColor?.backgroundColor);
      } else {
        dataSet.backgroundColor = [chartColor?.backgroundColor];
      }

      if (Array.isArray(dataSet.borderColor)) {
        dataSet.borderColor.push(chartColor?.borderColor);
      } else {
        dataSet.borderColor = [chartColor?.borderColor];
      }
    });

    return [dataSet];
  }

  if (chartType === Chart_Type.HorizontalBar) {
    const dataSet: ChartDataset = {
      label: '',
      data: data.map((ser) => ser.dataSeries[0]),
      backgroundColor: backgroundColors(0),
      borderColor: borderColors(0),
      borderWidth: 1,
    };
    return [dataSet];
  }

  const datasets = data.map((plotSeries, index) => {
    const labelName: string = customNames[index] || plotSeries.seriesLabel.name || '';
    const chartLabelType: ChartLabelType = getChartLabelType(labelName);

    const dataset: ChartDataset = {
      label: labelName,
      data: plotSeries.dataSeries,
      fill: chartType === Chart_Type.StackedArea,
    };

    switch (chartLabelType) {
      case 'sentiment':
        dataset.backgroundColor = sentimentChartColors[labelName].backgroundColor;
        dataset.borderColor = sentimentChartColors[labelName].borderColor;
        break;
      case 'nps':
        dataset.backgroundColor = npsChartColors[labelName].backgroundColor;
        dataset.borderColor = npsChartColors[labelName].borderColor;
        break;
      default:
        dataset.backgroundColor = isStackedChart(chartType) || chartType === Chart_Type.Line ? backgroundColors(index) : backgroundColors(0);
        dataset.borderColor = isStackedChart(chartType) || chartType === Chart_Type.Line ? borderColors(index) : borderColors(0);
    }

    return dataset;
  });

  return datasets;
};

const getChartLegendLabels = (chart: PlotFragment): string[] => {
  if (!chart) return [];
  const { series, plotConfiguration } = chart;
  const { seriesConfig, chartType } = plotConfiguration;

  // For categorical charts (pie, horizontal bar) with multiple series
  if (isCategoricalChart(chartType) && series.length > 0) {
    return series.map((seriesItem) => seriesItem.customName || seriesItem.seriesLabel.name);
  }

  // For all other cases, use xAxisLabels
  return chart.xAxisLabels;
};

export interface PlotProps {
  plotData: PlotFragment;
  showLegend?: boolean;
  loading: boolean;
  onSeriesClick?: (seriesIndex: number) => void;
}

export const Plot = ({ plotData, loading, showLegend = true, onSeriesClick }: PlotProps) => {
  const [chartData, setChartData] = useState<ChartData>();
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  /*
  We're using a randKey to force a re-render of the chart when the data changes.
  This is a workaround because Charts end up in broken states if we just pass updated data. Needs to be investigated further.
  */
  const [randKey, setRandKey] = useState<number>(Math.random());

  useEffect(() => {
    const limitedPlotData = { ...plotData };
    limitedPlotData.series = plotData.plotConfiguration.chartType === Chart_Type.HorizontalBar ? limitedPlotData.series.slice(0, 7) : limitedPlotData.series;
    if (limitedPlotData.series && limitedPlotData.series.length > 0) {
      const labels: string[] = getChartLegendLabels(limitedPlotData);
      const datasets: ChartDataset[] = [];
      datasets.push(
        ...generateDataset(
          limitedPlotData.series,
          limitedPlotData.plotConfiguration.breakdown,
          limitedPlotData.plotConfiguration.seriesConfig.map((series) => series.customName ?? undefined),
          limitedPlotData.plotConfiguration.chartType
        )
      );
      const newChartData = { labels, datasets };
      setIsChartEmpty(areAllChartValuesZero(plotData.series));
      setChartData(newChartData);
      if (JSON.stringify(newChartData) !== JSON.stringify(chartData)) {
        setRandKey(Math.random());
      }
    }
  }, [plotData]);

  return (
    <div className="h-full w-full">
      {isChartEmpty || !chartData?.datasets.length ? (
        <div className="flex flex-col items-center justify-center h-full px-2">
          <div className="text-center text-gray-secondary">No data matches the selected configurations and filters</div>
        </div>
      ) : chartData ? (
        <div className="relative h-full">
          <PlotView
            key={randKey}
            type={plotData.plotConfiguration.chartType}
            data={chartData}
            options={getChartOptions(
              plotData.plotConfiguration.yAxisMetric === Y_Axis_Data.RelativeShare
                ? YAxisUnit.CustomPercentage
                : plotData.plotConfiguration.yAxisMetric === Y_Axis_Data.Favorability
                ? YAxisUnit.Favorability
                : YAxisUnit.CustomAbsolute,
              plotData.plotConfiguration.chartType,
              plotData.tooltipLabels,
              plotData.xAxisLabels
            )}
            hideLegends={!showLegend}
            onDataPointClick={onSeriesClick}
          />
          {loading && (
            <div className="absolute inset-0 bg-gray-secondary/30 z-10 flex items-center justify-center rounded-md animate-pulse">
              <LoadingSpinner />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

/**
 * This is some SCARY code... I have no idea what these options are controlling.
 * @returns
 */
const getChartOptions = (
  yAxisUnit: YAxisUnit,
  chart_type: Chart_Type,
  fullLabels: string[],
  chartLabels: string[],
  maximumLabelLength?: number
): ChartOptions => {
  const options: ChartOptions = {
    borderWidth: chart_type === Chart_Type.Line || chart_type === Chart_Type.StackedArea ? 2 : 1, // @Design: This is the defaultborder width for all charts.
    ...(chart_type === Chart_Type.HorizontalBar
      ? {
          datasets: {
            bar: {
              barThickness: 16,
              categoryPercentage: 0.5,
              barPercentage: 0.5,
            },
          },
        }
      : {}),
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: chart_type === Chart_Type.Pie ? 10 : 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      //This prevents incorrect tooltip positioning for horizontal bar charts
      axis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'xy',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          return data == 0 || !!data; // NOTE: Show tooltip if it's a valid integer including 0
        },
        backgroundColor: '#FFF',
        bodyColor: '#1D1E30',
        titleColor: '#1D1E30',
        footerColor: '#1D1E30',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'CircularXXWeb',
          size: 12,
        },

        titleMarginBottom: 1,
        bodyFont: {
          family: 'CircularXXWeb',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 12,
          family: 'CircularXXWeb',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#1D1E30',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks: getTooltipCallbacks(chart_type, yAxisUnit, fullLabels),
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
          display: true,
          maxRotation: 0,
          color: '#6B7280',
          font: {
            size: 12,
            family: 'CircularXXWeb',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },

      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,

          grid: {
            display: false,
          },

          ticks: {
            stepSize: 1,
            mirror: true,
            labelOffset: -16,
            padding: 6,
            display: true,
            color: '#6B7280',
            font: {
              size: 12,
              family: 'CircularXXWeb',
            },
            callback: function (value: string | number, index: number, values: Tick[]) {
              const label = this.getLabelForValue(Number(value));
              return truncateAndEllipsis(label, (maximumLabelLength = Infinity));
            },
            // Add these options to force all labels to show
            autoSkip: false,
          },
        },
      }),
      ...(yAxisUnit === YAxisUnit.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 5,
              precision: 0,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'CircularXXWeb',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'CircularXXWeb',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
  return options;
};
