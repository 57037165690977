import { useContext, useState } from 'react';
import AdminCard from './AdminCard';
import AppContext from '../../../v2/contexts/AppContext';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import { useLaunchNlpMutation } from '../../../generated/graphql';
import { VirtualizedComboBox } from '../VirtualizedComboBox';

export const NLPCard = () => {
  const { organizations, orgsHaveLoaded } = useContext(AppContext);

  const [nlpKickoffTeam, setNlpKickoffTeam] = useState<IDropDownItem | undefined>();
  const [launchNLP, launchNlpData] = useLaunchNlpMutation();
  const [message, setMessage] = useState<string | undefined>();

  if (!orgsHaveLoaded || !organizations) {
    return <AdjustableLoadingIcon width={5} height={5} />;
  }

  const handleKickOffNLP = () => {
    setMessage(`Kicking off NLP for team: ${nlpKickoffTeam!.id}`);
    launchNLP({
      variables: { teamId: nlpKickoffTeam!.id },
      onCompleted: () => setMessage(`NLP Launched for team: ${nlpKickoffTeam?.id}`),
      onError: (err) => setMessage(err.message),
    });
  };

  return (
    <AdminCard
      title="Kick Off NLP for Team"
      subTitle="Launches the full NLP pipeline for the chosen team"
      actionButtonText="Kick Off NLP"
      onActionButtonClick={handleKickOffNLP}
      isLoading={launchNlpData.loading}
    >
      {!orgsHaveLoaded || !organizations ? (
        <AdjustableLoadingIcon width={5} height={5} />
      ) : (
        <div className="flex flex-col w-full gap-y-3">
          <VirtualizedComboBox
            comboBoxData={organizations.flatMap((org) =>
              org.teams.map((team) => {
                return { name: `${team.name} (${team.id})`, id: team.id };
              })
            )}
            //@ts-ignore
            setSelectedItem={setNlpKickoffTeam}
            selectedItem={nlpKickoffTeam}
            placeholder="Select a team"
          />
          {message && <div className="col-span-8 flex flex-row justify-start">{message}</div>}
        </div>
      )}
    </AdminCard>
  );
};
