import { SparklesIcon } from '@heroicons/react/24/outline';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  useAssignChildMutation,
  useAssignChildrenMutation,
  useDeleteChildMutation,
  useGetChildrenLightLazyQuery,
  useGetChildrenToAssignQuery,
} from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Button from '../baseComponents/Button';
import { IDropDownItem } from '../baseComponents/DropDown';
import SearchInput from '../baseComponents/SearchInput';
import GroupTaxonomyEntry from './GroupTaxonomyEntry';
import { TaxonomyChildToAdd } from './TaxonomyChildToAdd';
import { VirtualizedComboBox } from './VirtualizedComboBox';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyGroup } from '../reducers/taxonomy';
import { fuzzySearch } from './VirtualizedComboBox';
import { GroupDataContext, GroupDispatchContext } from '../../context/groupContext';
import { GroupActions } from '../../reducers/group/GroupDispatch';
import toast from 'react-hot-toast';

export default function GroupTaxonomyEdit({ groupId }: { groupId: string }) {
  const { curTeamId: teamId } = useValidTeamAppContext();

  const tabs = [
    {
      id: 0,
      name: 'Children',
    },
    { id: 1, name: 'Suggest Children', icon: <SparklesIcon className="h-5 w-5" /> },
    { id: 2, name: 'Add Children' },
  ];

  const [currentTabId, setCurrentTabId] = useState(0);

  return (
    <div className="flex flex-col w-96 bg-white rounded-lg border border-buttercream-frosting-100 h-96 overflow-y-auto shadow-lg ">
      <div>
        <div className="bg-buttercream-frosting text-white pb-4 mb-3">
          <div className="flex flex-col justify-center items-center text-center pt-5 ">
            <h1 className="text-xl font-recoleta text-licorice-noir">Edit Taxonomy</h1>
          </div>
          <ParentSelector groupId={groupId} teamId={teamId} />
        </div>
        <div className="group relative flex flex-col gap-x-2 pt-2 pb-1 px-3 mb-2  text-licorice-noir w-full">
          <div className="tabs flex min-h-0 h-full flex-1 flex-col">
            <div className="flex flex-row gap-x-4 min-h-0 items-center">
              {tabs.map((tab) => {
                return (
                  <div
                    key={tab.id}
                    className={`flex flex-row gap-x-1.5 cursor-pointer ${
                      currentTabId === tab.id ? 'font-medium text-licorice-noir underline decoration-2 underline-offset-4' : 'text-gray-tertiary'
                    }`}
                  >
                    {tab.icon ? tab.icon : null}
                    <p key={tab.id} onClick={() => setCurrentTabId(tab.id)}>
                      {tab.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="min-h-0 pr-2">
            {currentTabId === 0 && <CurrentChildren groupId={groupId} teamId={teamId} />}
            {currentTabId === 2 && <AddChildren groupId={groupId} teamId={teamId} />}
            {currentTabId === 1 && <SuggestChildren groupId={groupId} teamId={teamId} />}
          </div>
        </div>
      </div>
    </div>
  );
}

const ParentSelector = (props: { groupId: string; teamId: number }) => {
  const [parentLoading, setParentLoading] = useState<boolean>(false);
  const [localParentId, setLocalParentId] = useState<string | null>(null);
  const [allGroups, setAllGroups] = useState<IDropDownItem[]>([]);

  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const taxonomy = useContext(TaxonomyContext);
  const groupDispatch = useContext(GroupDispatchContext);

  const [assignChild] = useAssignChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [deleteChild] = useDeleteChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

  useEffect(() => {
    // set the parent id to the group's parent id
    const group = taxonomy.get(props.groupId);
    if (group) {
      setLocalParentId(group.parentId);
    }

    const allGroups = Array.from(taxonomy.values())
      .filter((group) => group.id !== props.groupId)
      .map((group) => {
        const additionalInfo = group.totalDescendents > 0 ? ` (${group.totalDescendents})` : '';
        return { id: group.id, name: `${group.title}${additionalInfo}` };
      });
    setAllGroups(allGroups);
  }, [props.groupId, taxonomy]);

  const assignParentToGroup = (parentGroupId: string, parentGroupTitle: string) => {
    setParentLoading(true);
    assignChild({
      variables: {
        teamId: props.teamId,
        parentGroupId: parentGroupId,
        childGroupId: props.groupId,
      },
      onCompleted(data) {
        const existingGroup = taxonomy.get(props.groupId);
        const updatedChild = {
          ...existingGroup,
          ...data.assignChild,
          totalEntries: existingGroup?.totalEntries ?? 0,
          relativeShare: existingGroup?.relativeShare,
          relativeShareFull: existingGroup?.relativeShareFull,
          parentId: parentGroupId,
          ancestors: [{ id: parentGroupId, title: parentGroupTitle }],
        };

        taxonomyDispatch({
          type: 'assignChildren',
          payload: {
            parentGroup: { id: parentGroupId, title: parentGroupTitle },
            childGroups: [updatedChild],
          },
        });

        groupDispatch({
          type: GroupActions.UPDATE_AUXILLARY_GROUP_DATA,
          payload: {
            id: props.groupId,
            title: data.assignChild.title,
            summaryText: null,
            isPinnedByUser: data.assignChild.isPinnedByUser,
            uniqueEntries: existingGroup?.totalEntries ?? 0,
            tags: data.assignChild.tags ?? null,
            dateCreated: data.assignChild.dateCreated ? new Date(data.assignChild.dateCreated).toISOString() : '',
            creator: {
              isUnwrapGenerated: data.assignChild.creator.isUnwrapGenerated,
              user: data.assignChild.creator.user,
            },
          },
        });

        setLocalParentId(parentGroupId);
        setParentLoading(false);
      },
    });
  };

  const removeParentFromGroup = (parentGroupId: string) => {
    setParentLoading(true);
    deleteChild({
      variables: {
        parentGroupId: parentGroupId,
        teamId: props.teamId,
        childGroupId: props.groupId,
      },
      onCompleted(data) {
        taxonomyDispatch({ type: 'removeChildFromParent', payload: { parentGroupId: parentGroupId, childGroupId: props.groupId } });
        setLocalParentId(null);
        setParentLoading(false);
      },
    });
  };
  return (
    <div className="flex flex-col items-center ">
      <div className="flex flex-row mt-2 gap-x-1 items-center p-1">
        <h2 className="text-licorice-noir">Parent:</h2>
        <VirtualizedComboBox
          loading={parentLoading}
          comboBoxData={allGroups}
          setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
            /**
             * Man this is weird. if there's an item selected we want to assign the item as this group's parent.
             *
             * If there's no item selected we want to remove the current parent.
             */
            if (selectedItem) {
              if (selectedItem && selectedItem.id === localParentId) return;
              assignParentToGroup(selectedItem.id.toString(), selectedItem.name);
            } else {
              if (!localParentId) return;
              removeParentFromGroup(localParentId);
            }
          }}
          selectedItem={allGroups.find((item: IDropDownItem) => item.id === localParentId)}
        />
      </div>
    </div>
  );
};

const CurrentChildren = (props: { groupId: string; teamId: number }) => {
  const [deleteChild] = useDeleteChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const [children, setChildren] = useState<{ id: string; title: string }[]>([]);

  const [getChildrenLight, { loading: childrenLightLoading }] = useGetChildrenLightLazyQuery();

  useEffect(() => {
    getChildrenLight({
      variables: { teamId: props.teamId, parentGroupId: props.groupId },
      onCompleted: (data) => {
        setChildren(data.getChildrenLight);
      },
    });
  }, [props.groupId]);

  return (
    <div className="pt-2">
      {childrenLightLoading ? (
        <p>Loading children...</p>
      ) : children.length === 0 ? (
        <p>No children belong to this group. </p>
      ) : (
        children.map((child) => {
          return (
            <Fragment key={child.id}>
              <GroupTaxonomyEntry
                id={child.id}
                title={child.title ?? ''}
                key={child.id}
                deleteChild={() => {
                  deleteChild({
                    variables: {
                      parentGroupId: props.groupId,
                      teamId: props.teamId,
                      childGroupId: child.id,
                    },
                    onCompleted() {
                      const newChildren = children.filter((c) => c.id !== child.id);
                      setChildren(newChildren);
                      taxonomyDispatch({ type: 'removeChildFromParent', payload: { parentGroupId: props.groupId, childGroupId: child.id } });
                    },
                  });
                }}
              />
            </Fragment>
          );
        })
      )}
    </div>
  );
};

const SuggestChildren = (props: { groupId: string; teamId: number }) => {
  const [childrenHaveBeenAdded, setChildrenHaveBeenAdded] = useState(false);
  const [checkedChildGroupIds, setCheckedChildGroupIds] = useState<string[]>([]);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const groupData = useContext(GroupDataContext);
  const [assignChildren, { loading: assignChildrenLoading }] = useAssignChildrenMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const { data: childrenToAssign, loading: childrenToAssignLoading } = useGetChildrenToAssignQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId: props.teamId,
      groupId: props.groupId,
    },
    onCompleted(data) {
      // by default all children are checked when this loads
      setCheckedChildGroupIds(data.getChildrenToAssign.map((child) => child.id));
    },
  });

  return (
    <div className="pt-2 w-full">
      {childrenToAssignLoading ? (
        <p>Getting children to assign...</p>
      ) : childrenToAssign?.getChildrenToAssign.length === 0 ? (
        <p>Zero children can be added to this group automatically.</p>
      ) : childrenHaveBeenAdded ? (
        <p>All children have been added to the group successfully</p>
      ) : (
        <>
          <p className="mb-4">Pick which children to add to this group:</p>
          {childrenToAssign?.getChildrenToAssign.map((child) => {
            return (
              <Fragment key={child.id}>
                <TaxonomyChildToAdd
                  checked={checkedChildGroupIds.includes(child.id)}
                  title={child.title}
                  numberOfEntries={child.uniqueEntries}
                  addCheck={() => setCheckedChildGroupIds((prev) => [...prev, child.id])}
                  removeCheck={() => setCheckedChildGroupIds((prev) => prev.filter((id) => id !== child.id))}
                />
              </Fragment>
            );
          })}{' '}
        </>
      )}
      <div className="flex flex-row justify-center items-center">
        {childrenHaveBeenAdded || childrenToAssign?.getChildrenToAssign.length === 0 || childrenToAssignLoading ? null : (
          <Button
            id="bulk-assign-children"
            loadingConfirm={assignChildrenLoading}
            text="Confirm"
            onClick={() => {
              assignChildren({
                variables: {
                  teamId: props.teamId,
                  groupId: props.groupId,
                  childGroupIds: checkedChildGroupIds,
                },
                onCompleted(data) {
                  if (data.assignChildren) {
                    setChildrenHaveBeenAdded(true);
                    taxonomyDispatch({
                      type: 'assignChildren',
                      payload: { parentGroup: { id: props.groupId, title: groupData.groupData?.title ?? '' }, childGroups: data.assignChildren },
                    });
                  }
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

/**
 * This should fetch all potential children of this group and display them in this list
 * @param props
 * @returns
 */
const AddChildren = (props: { groupId: string; teamId: number }) => {
  const [assignChild] = useAssignChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const taxonomy = useContext(TaxonomyContext);
  const groupData = useContext(GroupDataContext);

  const [allGroupsThatAreNotDirectChildren, setAllGroupsThatAreNotDirectChildren] = useState<TaxonomyGroup[]>([]);
  const [groupsToDisplay, setGroupsToDisplay] = useState<TaxonomyGroup[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (props.groupId) {
      const group: TaxonomyGroup | undefined = taxonomy.get(props.groupId);
      const allGroups = Array.from(taxonomy.values()).filter((group) => group.id !== props.groupId);
      const children = group?.children?.map((child) => child.id);
      const allGroupsWithoutChildren = allGroups.filter((g) => !children?.includes(g.id)).filter((g) => g.id !== props.groupId && g.id !== group?.parentId);

      setAllGroupsThatAreNotDirectChildren(allGroupsWithoutChildren);
      // Only set groupsToDisplay if there's no search query
      if (!searchQuery) {
        setGroupsToDisplay(allGroupsWithoutChildren);
      }
    }
  }, [props.groupId, taxonomy]);

  // Handle search filtering
  const handleSearch = (query?: string) => {
    if (query == undefined) return;
    setSearchQuery(query);
    if (!query.trim()) {
      setGroupsToDisplay(allGroupsThatAreNotDirectChildren);
    } else {
      const filtered = fuzzySearch(query.trim(), allGroupsThatAreNotDirectChildren, ['title']);
      setGroupsToDisplay(filtered);
    }
  };

  return (
    <div className="pt-2">
      <div className="px-0.5 pb-2">
        <SearchInput placeholder={'Add more children'} queryString={searchQuery} setQueryString={handleSearch} />
      </div>
      {groupsToDisplay.length === 0 ? (
        <p>No similar children were found. </p>
      ) : (
        groupsToDisplay.map((child) => {
          return (
            <Fragment key={child.id}>
              <GroupTaxonomyEntry
                id={child.id}
                title={child.title!}
                key={child.id}
                addChild={async () => {
                  try {
                    const result = await assignChild({
                      variables: {
                        teamId: props.teamId,
                        parentGroupId: props.groupId,
                        childGroupId: child.id,
                      },
                    });

                    if (result.data?.assignChild) {
                      const updatedFullList = allGroupsThatAreNotDirectChildren.filter((g) => g.id !== child.id);
                      setAllGroupsThatAreNotDirectChildren(updatedFullList);

                      if (searchQuery) {
                        setGroupsToDisplay((current) => current.filter((g) => g.id !== child.id));
                      } else {
                        setGroupsToDisplay(updatedFullList);
                      }

                      taxonomyDispatch({
                        type: 'assignChildren',
                        payload: {
                          parentGroup: { id: props.groupId, title: groupData.groupData?.title ?? '' },
                          childGroups: [result.data.assignChild],
                        },
                      });
                    }
                  } catch (error) {
                    if (error instanceof Error && error.message.includes('This will create an invalid taxonomy')) {
                      toast.error('Cannot assign relationship as this would result in more than 6 levels of nesting');
                    } else {
                      toast.error('Failed to add child');
                    }
                  }
                }}
              />
            </Fragment>
          );
        })
      )}
    </div>
  );
};
