import { Cog6ToothIcon } from '@heroicons/react/24/outline';

interface TaxonomyFolderSkeletonProps {
  flatGroup?: boolean;
  depth?: number;
}

export const TaxonomyFolderSkeleton = ({ flatGroup, depth = 0 }: TaxonomyFolderSkeletonProps) => {
  return (
    <div className="flex flex-row items-center gap-x-3 relative group w-full mt-1">
      <div
        className={`w-full bg-white text-licorice-noir duration-150 transition-colors shadow-[0_1px_2px_rgb(0,0,0,0.1)] border rounded-lg p-3 
        relative before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-buttercream-frosting-100/60 before:to-transparent
        isolate overflow-hidden`}
      >
        <div className="flex flex-row justify-between items-center gap-x-1">
          <div className="flex flex-col gap-x-2">
            {flatGroup && (
              <div className="flex flex-row items-center gap-x-2 w-full mb-1">
                <div className="h-4 w-32 bg-buttercream-frosting-100 rounded-md animate-pulse" />
              </div>
            )}

            <div className="justify-start flex flex-row items-center gap-x-3">
              <div className="flex flex-col justify-start gap-y-2">
                <div className="flex flex-row gap-x-2 justify-start items-center">
                  <div className="h-6 w-48 bg-buttercream-frosting-100 rounded-md animate-pulse" />
                  <div className="h-5 w-5 bg-buttercream-frosting-100 rounded-full animate-pulse" />
                </div>
                <div className="flex flex-row text-sm gap-x-2">
                  <div className="h-4 w-24 bg-buttercream-frosting-100 rounded-md animate-pulse" />
                  <div className="text-gray-tertiary">|</div>
                  <div className="h-4 w-32 bg-buttercream-frosting-100 rounded-md animate-pulse" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-end gap-x-1">
            <div className="h-8 w-8 bg-buttercream-frosting-100 rounded-full animate-pulse" />
            <div className="h-8 w-8 bg-buttercream-frosting-100 rounded-full animate-pulse" />
            <div className="h-8 w-8 bg-buttercream-frosting-100 rounded-full animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  );
}; 