/**
import { Chart } from 'react-chartjs-2';

<Chart
  type={...}
  options={...}
  data={...}
  {...props}
/>

Documentation: https://react-chartjs-2.js.org/components/chart

Source: https://www.chartjs.org/docs/latest/

Examples: https://react-chartjs-2.js.org/examples
 */

import { ChartType } from './chartElements/ChartTypeOptions';
import { FC } from 'react';
import ChartView from '../../baseComponents/ChartView';
import { Chart_Type } from '../../../generated/graphql';
import { ChartWrapper } from './ChartWrapper';

export const MINIMUM_CHART_HEIGHT = 336;

const chartByType: { [key in ChartType]: FC<any> } = {
  // TODO: Implement dynamic chart components
  line: ChartView,
  area: ChartView,
  horizontal: ChartView,
  vertical: ChartView,
  pie: ChartView,
};

export interface IChartConfigurationProps {
  options: any;
  data: any;
}

export interface IChartProps extends IChartConfigurationProps {
  chartType: ChartType;
  type: Chart_Type;
}

export const Chart: FC<IChartProps> = ({ chartType, options = {}, data = [], type }) => {
  const ChartComponent = ChartView; // TODO: Replace w/ chartByType[chartType]
  return ChartComponent ? (
    <ChartWrapper chartType={chartType} numberOfElements={data.labels.length}>
      <ChartComponent options={options} data={data} type={type} hideLegends={true} />
    </ChartWrapper>
  ) : null;
};
