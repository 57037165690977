import { useRef, useState } from 'react';
import { IntervalSelector, Interval, intervalOptions } from './IntervalSelector';
import { SelectorModal } from './SelectorModal';
import { CustomIcon } from '../../icons/CustomIcon';
import { Chart_Bin_Type } from '../../../../generated/graphql';
import { Option } from './SelectorDropdown';

export interface IIntervalSelectorAndModalProps {
  selectedInterval: Option<Interval>;
  setSelectedInterval: (plotUnit: Option<Interval>) => void;
  isIntervalSelectorModalOpen: boolean;
  toggleIntervalSelectorModal: (isIntervalSelectorModalOpen: boolean) => void;
}

export const IntervalSelectorAndModal = ({
  selectedInterval,
  setSelectedInterval,
  isIntervalSelectorModalOpen,
  toggleIntervalSelectorModal,
}: IIntervalSelectorAndModalProps) => {
  const breakdownParentRef = useRef<HTMLDivElement>(null);

  const openIntervalSelectorModal = () => {
    toggleIntervalSelectorModal(true);
  };

  const closeIntervalSelectorModal = () => {
    toggleIntervalSelectorModal(false);
  };

  return (
    <div className="flex relative" ref={breakdownParentRef}>
      <IntervalSelector
        selectedInterval={selectedInterval}
        isIntervalSelectorModalOpen={isIntervalSelectorModalOpen}
        openIntervalSelectorModal={openIntervalSelectorModal}
        closeIntervalSelectorModal={closeIntervalSelectorModal}
      />
      <SelectorModal<Interval>
        icon={<CustomIcon name="chart-breakdown" className="w-4 h-4" />}
        title="Interval"
        selectedOption={selectedInterval}
        setSelectedOption={setSelectedInterval}
        options={intervalOptions}
        isModalOpen={isIntervalSelectorModalOpen}
        closeModal={closeIntervalSelectorModal}
        parentRef={breakdownParentRef}
        position="bottom-left"
      />
    </div>
  );
};

export const intervalToChart_Bin_Type: { [key in Interval]: Chart_Bin_Type } = {
  auto: Chart_Bin_Type.Dynamic,
  daily: Chart_Bin_Type.Daily,
  weekly: Chart_Bin_Type.Weekly,
  monthly: Chart_Bin_Type.Monthly,
  quarterly: Chart_Bin_Type.Quarterly,
};

export const Chart_Bin_TypeToInterval: { [key in Chart_Bin_Type]: Interval } = {
  [Chart_Bin_Type.Dynamic]: 'auto',
  [Chart_Bin_Type.Daily]: 'daily',
  [Chart_Bin_Type.Weekly]: 'weekly',
  [Chart_Bin_Type.Monthly]: 'monthly',
  [Chart_Bin_Type.Quarterly]: 'quarterly',
};
