import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { useChartDispatch } from '../../../context/chartContext';
import { FilterContext, FilterDispatchContext } from '../../../context/filterStatementContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { DataSeriesConfiguration } from '../../../generated/graphql';
import Tippy from '@tippyjs/react';
import FilterBar from '../filters/filterBar/FilterBar';
import { FilterNode } from '../../lib/filterNode';
import { InitialFilterStateProvider } from '../../filters/utilities/InitialFilterStateProvider';
import { filterStatementReducer } from '../../../reducers/filterStatement/filterStatementReducer';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';
import { NameEditor } from '../standardCharts/chartElements/NameEditor';

interface IDataViewCardProps {
  index: number;
  name: string;
  teamsList: IDropDownItem[];
  teamId: number;
  dataSeries: DataSeriesConfiguration;
  onNameInput: (newName: string) => void;
  onSeriesRemove: () => void;
  breakdownDisabled?: boolean;
  canBeDeleted?: boolean;
  isLoading?: boolean;
  hasNameEditor?: boolean;
}

export const DataViewCard: React.FC<IDataViewCardProps> = ({
  index,
  name,
  teamsList,
  teamId,
  onNameInput,
  onSeriesRemove,
  breakdownDisabled,
  canBeDeleted,
  dataSeries,
  isLoading,
  hasNameEditor,
}) => {
  const chartDispatch = useChartDispatch();
  const isFirstRender = useRef(true);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const initialState = useMemo(() => InitialFilterStateProvider.getStateFromFilterNode(dataSeries.filterNode, teamId), [teamId]);

  const [filterState, dispatch] = useReducer(filterStatementReducer, initialState);

  useEffect(() => {
    setIsFiltersOpen(new FilterNode(filterState).getAppliedFilterFields().length > 0);
  }, [filterState.appliedFilter]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    chartDispatch({ type: ChartActionType.SetSeriesFilterNode, payload: { index, filterNode: JSON.stringify(filterState.appliedFilter) } });
  }, [filterState.filterConsumable]);

  return (
    <FilterContext.Provider value={{ ...filterState, teamId }}>
      <FilterDispatchContext.Provider value={dispatch}>
        <div className="flex flex-col w-full bg-buttercream-frosting border border-buttercream-frosting-100 p-5 rounded-lg relative">
          {canBeDeleted ? (
            <Tippy theme="dark" content={<p>Remove current data series from chart</p>}>
              <button
                className="absolute top-[-8px] right-[-8px] bg-blood-orange-sorbet hover:bg-blood-orange-sorbet-darker rounded-full p-1 transition-all duration-[200ms]"
                onClick={() => {
                  onSeriesRemove();
                }}
              >
                <TrashIcon className="text-white h-4 w-4" />
              </button>
            </Tippy>
          ) : null}
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row flex-1 items-center mr-5">
              <div className="font-recoleta text-lg text-licorice-noir mr-2 whitespace-nowrap">Data view:</div>
              <VirtualizedComboBox
                comboBoxData={teamsList}
                disableClear
                disableAlphabeticalSort
                setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                  if (!selectedItem) return;
                  chartDispatch({ type: ChartActionType.SetSeriesTeamId, payload: { teamId: Number(selectedItem.id), index } });
                }}
                selectedItem={teamsList.find((item: any) => item.id === teamId)}
                disabled={breakdownDisabled || isLoading}
              />
            </div>
            {hasNameEditor ? (
              <div className="flex flex-row flex-1 items-center mr-3">
                <div className="font-light text-sm text-licorice-noir mr-2 whitespace-nowrap">Legend Name:</div>
                <NameEditor name={name} onNameInput={onNameInput} isLoading={isLoading} />
              </div>
            ) : null}
            {!isFiltersOpen ? (
              <div className="flex-0">
                <FilterBar
                  onDeleteAllFilters={() => {
                    setIsFiltersOpen(false);
                  }}
                />
              </div>
            ) : null}
          </div>
          {isFiltersOpen ? (
            <FilterBar
              isOpen={true}
              onDeleteAllFilters={() => {
                setIsFiltersOpen(false);
              }}
              styles="mt-4"
            />
          ) : null}
        </div>
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};
