import { ReactNode } from 'react';
import { SkeletonBar } from '../../../components/skeletons/SkeletonBar';

export const BoardFilterSectionSkeleton = ({ backButton }: { backButton?: ReactNode }) => {
  return (
    <div className="flex flex-row gap-x-1 items-center">
      <SkeletonBar width="w-1/5" height="h-10" />
      <div className="flex-auto border-t-2 border-buttercream-frosting-300 mx-4"></div>
      <SkeletonBar height="w-2/5 h-10" />
    </div>
  );
};
