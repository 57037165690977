import React, { useContext } from 'react';
import { AssistantDataContext, AssistantDispatchContext } from '../../../context/assistantContext';
import { AssistantActions } from '../../../reducers/assistant/AssistantDispatch';
import { ErrorIcon } from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  SuggestedQuestionsActions,
  SuggestedQuestionsStatus,
} from '../../../reducers/assistant/SuggestedQuestionsDispatch';
import {
  SuggestedQuestionsDataContext,
  SuggestedQuestionsDispatchContext,
} from '../../../context/suggestedQuestionsContext';
import { Events, logEventWithContext } from '../../../v2/AnalyticsUtil';
import { unique } from 'underscore';

function SuggestedQuestion(props: { question: string }) {
  const dispatch = useContext(AssistantDispatchContext);

  return <div
    onClick={async () => {
      dispatch({ type: AssistantActions.SUBMIT_QUESTION, payload: { question: props.question } });
      dispatch({ type: AssistantActions.UPDATE_USER_INPUT, payload: { userInput: props.question } });
      logEventWithContext(Events.QueryAssistant, {});
    }}
    className={'animate-ease-in-out duration-50 py-2 px-3 mx-2 inline-flex items-center rounded-md border border-buttercram-frosting-100 text-center text-sm outline-none text-licorice-noir hover:cursor-pointer hover:bg-white bg-buttercream-frosting leading-4'}>
    {props.question}
  </div>;
}

const SuggestedQuestionLoading = () => <div
  data-testid={'suggested-questions-loading'}
  className={'py-1 px-3 mx-2 inline-flex justify-center rounded-md border text-center outline-none bg-buttercream-frosting w-44 h-8 animate-pulse'}>
</div>

export const SuggestedQuestions = () => {
  const dispatch = useContext(SuggestedQuestionsDispatchContext);
  const {questions, status, load, page } = useContext(SuggestedQuestionsDataContext);

  switch (status) {
    case SuggestedQuestionsStatus.done:
      return (
        <div className="flex flex-col justify-start items-start w-3/4 my-4">
          <div className={'py-1 px-3 text-licorice-noir font-recoleta text-base leading-none mb-2 mt-4'}>Suggested Questions:</div>
          <div className='flex'>
          {unique(questions).slice(0,3).map((question) => <SuggestedQuestion question={question} />)}
            <div
              onClick={async () => {
                dispatch({ type: SuggestedQuestionsActions.LOAD_SUGGESTED_QUESTIONS, payload: { page: page + 1}});
              }}
              className={'animate-fade-in py-1 pl-2 mx-2 inline-flex items-center rounded-md text-center text-sm outline-none text-licorice-noir hover:cursor-pointer hover:text-blood-orange-sorbet duration-50'}>
              <ArrowPathIcon className={`h-4 w-4 stroke-2 ${load && 'animate-spin'}`}/>
            </div>
          </div>
        </div>
        
      );
    case SuggestedQuestionsStatus.loading:
      return <div className="flex flex-row w-3/4 my-4">
        <div className={'py-1 px-3 text-licorice-noir text-sm'}>Suggested Questions:</div>
        <SuggestedQuestionLoading />
        <SuggestedQuestionLoading />
        <SuggestedQuestionLoading />
      </div>;
    case SuggestedQuestionsStatus.failed:
      // Intentionally not rendering the error to avoid disruptive UX
      // Customers might think the whole feature is broken and will not use it
      return <span></span>;
  }
}