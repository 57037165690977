import Tippy from '@tippyjs/react';
import { classNames } from '../../../v2/util';

type GroupCountProps = {
  loading: boolean;
  filteredGroupCount: number;
  amountOfGroups: number | undefined;
};

const GroupCountTooltip: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Tippy
    content={
      <p>
        This indicates the currently <b>filtered</b> group count compared to the total group count.
      </p>
    }
  >
    <div className={classNames('flex w-fit')}>
      <p className="text-sm font-light text-gray-secondary mb-2">{children}</p>
    </div>
  </Tippy>
);

const LoadingState = () => <span className="inline-block w-48 h-6 bg-buttercream-frosting-100 rounded animate-pulse" />;

const NoGroups = () => <span>This team has no groups</span>;

const GroupCountDisplay = ({ filteredCount, totalCount }: { filteredCount: number; totalCount: number }) => (
  <span>
    <b className="text-licorice-noir">Showing {filteredCount}</b> of {totalCount} groups
  </span>
);

export function GroupCount({ loading, filteredGroupCount, amountOfGroups }: GroupCountProps) {
  const renderContent = () => {
    if (loading || amountOfGroups === undefined) return <LoadingState />;

    if (amountOfGroups === 0) return <NoGroups />;

    return <GroupCountDisplay filteredCount={filteredGroupCount} totalCount={amountOfGroups} />;
  };

  return <GroupCountTooltip>{renderContent()}</GroupCountTooltip>;
}
