import { forwardRef, useState, useContext } from "react";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import toast from "react-hot-toast";
import { FeedbackIntegrationState } from "../../reducers/feedbackIntegration";
import { Dropdown, TwoColumnDropdown } from "./Dropdown";

interface AddCustomFieldsModalProps {
    onClose: () => void;
}

export const AddCustomFieldsModal = forwardRef<HTMLDivElement, AddCustomFieldsModalProps>((props, ref) => {
    const [isDisplayDropdownOpen, setIsDisplayDropdownOpen] = useState(false);
    const [isCustomFieldDropdownOpen, setIsCustomFieldDropdownOpen] = useState(false);
    const [customFieldDisplayName, setCustomFieldDisplayName] = useState("");
    const [customField, setCustomField] = useState("");

    const activateAddButton = customFieldDisplayName && customField;

    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);

    const selectValue = (value: string) => {
        setIsCustomFieldDropdownOpen(false);
        setCustomField(value);
    }

    const selectCustomField = () => {
        selectValue(customField);
    }

    return (
        <div ref={ref} className="z-[101]" id="add-custom-fields-modal">
            <div className="max-w-[calc(100vw-20px)] w-[400px] md:w-[600px] bg-white border border-buttercream-frosting-100 shadow-lg rounded-xl">
                {data.sourceFieldsLoading ? (
                    <div className="p-4 text-center">
                        <LoadingSpinner />
                        <div className="mt-2">Loading available fields...</div>
                    </div>
                ) : (
                    <div className="p-4">
                        <div className="mb-4">
                            <label className="text-sm text-gray-secondary mb-1">Custom field</label>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    onClick={() => setIsCustomFieldDropdownOpen(true)}
                                    onChange={(e) => {
                                        setCustomField(e.target.value);
                                        setIsCustomFieldDropdownOpen(true);
                                    }}
                                    value={customField}
                                    placeholder="Select a custom field" 
                                    className="rounded-md border border-buttercream-frosting-100 shadow-sm px-2 pt-1.5 pb-2 h-10 text-licorice-noir placeholder-gray-tertiary focus:outline-none focus:border-licorice-noir focus:ring-0 disabled:bg-buttercream-frosting-100 disabled:text-gray-secondary w-full cursor-pointer" 
                                    id="add-custom-fields-modal-custom-field"
                                    autoComplete="off"
                                /> 
                                <TwoColumnDropdown 
                                    rows={data.dataSourceFields?.map(field => ({
                                        path: field.path,
                                        sampleValues: field.values || []
                                    })) || []}
                                    filterString={customField} 
                                    onSelect={selectValue} 
                                    onSelectCustomField={selectCustomField}
                                    isOpen={isCustomFieldDropdownOpen} 
                                    onClose={() => {
                                        setIsCustomFieldDropdownOpen(false);
                                    }}
                                    id="add-custom-fields-modal-custom-field-dropdown"
                                />
                            </div>
                        </div>
                        <div className="mb-1">
                            <label htmlFor="" className="text-sm text-gray-secondary mb-1">Display name</label>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    onSelect={() => {setIsDisplayDropdownOpen(true)}} 
                                    onChange={(e) => {setCustomFieldDisplayName(e.target.value)}} 
                                    value={customFieldDisplayName} 
                                    placeholder="Type to create a new name" 
                                    className="rounded-md border  border-buttercream-frosting-100 shadow-sm px-2 pt-1.5 pb-2 h-10  text-licorice-noir  placeholder-gray-tertiary focus:outline-none focus:border-licorice-noir focus:ring-0 disabled:bg-buttercream-frosting-100 disabled:text-gray-secondary w-full"
                                    disabled={!customField}
                                    id="add-custom-fields-modal-display-name"
                                    autoComplete="off"
                                />    
                                <Dropdown 
                                    values={data.segmentGroups.segments?.map(segment => segment.displayName) || []} 
                                    filterString={customFieldDisplayName} 
                                    onSelect={(value) => {
                                        setIsDisplayDropdownOpen(false);
                                        setCustomFieldDisplayName(value);
                                    }} 
                                    isOpen={isDisplayDropdownOpen} 
                                    onClose={() => {setIsDisplayDropdownOpen(false)}}
                                    id="add-custom-fields-modal-display-name-dropdown"
                                />
                            </div>
                        </div>
                        
                        <div className="mb-2">
                            <label htmlFor="" className="text-sm text-gray-secondary mb-1">Example value</label>
                            <div className="font-mono bg-buttercream-frosting rounded-md py-2 px-2 text-sm text-licorice-noir break-words">
                                {customField ? `${customField}: "${data.dataSourceFields?.find(field => field.path === customField)?.values[0] || "No sample value available"}"` : "Select a custom field"}
                            </div>
                        </div>
                    </div>
                )}
                <div className="border-t border-buttercream-frosting-100 py-4 px-8 lg:px-14 flex flex-col lg:flex-row gap-4 items-center justify-center">
                    <Button 
                        text="Add" 
                        shape={ButtonShape.Square} 
                        icon={<CheckCircleIcon className="h-6 w-6" />} 
                        iconPosition={"left"} 
                        variant={ButtonVariant.Primary} 
                        onClick={() => {
                            const customFieldExists = checkIfCustomFieldExists(customField, customFieldDisplayName, data.feedbackIntegration!);
                            if (customFieldExists) {
                                toast.error("Custom field already exists");
                                return;
                            }
                            let newCustomField = createCustomField(
                                customFieldDisplayName, 
                                customField
                            );
                            dispatch({
                                type: FeedbackIntegrationActionType.AddSegments,
                                payload: {
                                    segment: newCustomField
                                }
                            });
                            props.onClose();
                        }} 
                        disabled={!activateAddButton}
                        id="add-custom-fields-modal-add-button"
                    />                            
                </div>
            </div>
        </div>
    );
});

const checkIfCustomFieldExists = (customField: string, customFieldDisplayName: string, feedbackIntegration: FeedbackIntegrationState) => {
    return feedbackIntegration.segmentConfig.find(config => config.path === customField && config.segmentGroup.displayName === customFieldDisplayName);
}

const createCustomField = (customFieldDisplayName: string, customField: string) => {
    // Create new segment config with negative IDs
    // Ids will be replaced in the context reducer
    const newSegmentConfig = {
        path: customField,
        segmentGroup: {
            id: -1,
            displayName: customFieldDisplayName,
        },
        id: -1,
    };

    return newSegmentConfig;
}