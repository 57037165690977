import { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../../baseComponents/Button";

interface FilterValueInOperatorModalProps {
    onClose: () => void;
    value: string;
    onChange: (value: string) => void;
    sampleValues?: string[];
}

export const FilterValueInOperatorModal = (props: FilterValueInOperatorModalProps) => {
    // Local state for input values
    const [localValues, setLocalValues] = useState<string[]>(props.value.split(',').map(v => v.trim().replace(/^"|"$/g, '')));

    const updateValues = (newValues: string[]) => {
        // Only include non-empty values when updating the parent
        const formattedValue = newValues
            .filter(v => v.trim())
            .map(v => `"${v.trim()}"`)
            .join(',');
        props.onChange(formattedValue);
    };

    return (
        <div className="flex flex-col gap-1.5">
            <div className="flex flex-col gap-2">
                {localValues.map((value, index) => (
                    <div key={index} className="text-licorice-noir flex flex-row items-center justify-between gap-2">
                        <span className="text-licorice-noir font-medium">{index + 1}.</span>
                        <input 
                            type="text" 
                            value={value}
                            placeholder={props.sampleValues?.[0] ?? 'Enter value...'}
                            onChange={(e) => {
                                const newValues = [...localValues];
                                newValues[index] = e.target.value;
                                setLocalValues(newValues);
                                updateValues(newValues);
                            }}
                            className="input-primary bg-white w-full text-left px-3 py-2 rounded-md border border-buttercream-frosting-100 flex justify-between items-center"
                        />
                        {localValues.length > 1 && (
                            <Button 
                                shape={ButtonShape.Circle}
                                variant={ButtonVariant.Icon}
                                onClick={() => {
                                    const newValues = localValues.filter((_, i) => i !== index);
                                    setLocalValues(newValues);
                                    updateValues(newValues);
                                }}
                                icon={<TrashIcon className="w-5 h-5 text-gray-secondary hover:text-blood-orange-sorbet"/>}
                            />
                        )}
                    </div>
                ))}
                <div className="text-right">
                    <button 
                        className="font-medium text-licorice-noir hover:text-licorice-noir-lighter"
                        onClick={() => setLocalValues([...localValues, ''])}
                    >
                        Add value +
                    </button>
                </div>
            </div>
        </div>
    );
}