import { Fragment, useEffect, useState, useContext } from 'react';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import UserContext from '../../v2/contexts/UserContext';
import InfoCard from '../components/InfoCard';
import { PageWrapper } from './PageWrapper';

export function ProfilePage({ ...props }) {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <PageWrapper title="Account Settings">
      <div className="flex flex-col gap-y-3">
        {isLoading || !user ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="w-full md:w-1/2 mb-10 flex flex-col gap-x-5 gap-y-4 font-light">
              {/*Change Password Component*/}
              <InfoCard title={'Full Name'} data={user?.firstName + ' ' + user?.lastName} />
              <InfoCard title={'Email'} data={user?.email} />
              {/* <ChangePassword userId={user?.id} /> */}
              <InfoCard
                title={'Terms & Privacy'}
                data={
                  <>
                    <a
                      href="https://www.unwrap.ai/legal/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blood-orange-sorbet underline font-light hover:text-licorice-noir"
                    >
                      {'Terms of Service'}
                    </a>
                    <span className="font-light"> and </span>
                    <a
                      href="https://www.unwrap.ai/legal/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blood-orange-sorbet underline font-light hover:text-licorice-noir"
                    >
                      {'Privacy Policy'}
                    </a>
                  </>
                }
              />
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
