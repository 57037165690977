import { TrashIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";   
import { FeedbackIntegrationFilter } from "../../reducers/feedbackIntegration";

interface IntegrationFiltersProps {
    onClose: () => void;
    onEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    filter: FeedbackIntegrationFilter;
    deleteFilter: () => void;
}

export const IntegrationFilters = (props: IntegrationFiltersProps) => {

    return (
        <div className="inline-flex">
            <div className="flex items-center bg-buttercream-frosting border border-buttercream-frosting-100 rounded-md overflow-hidden">
                <div className="flex flex-row items-center group pl-4 pr-1 py-1 relative">
                    <div className="pr-1 flex items-center">
                        <button className="text-left whitespace-nowrap text-gray-secondary [&>strong]:text-licorice-noir [&>*]:hover:text-blood-orange-sorbet [&>*]:duration-200 [&>*]:transition-all [&>*]:ease-in-out leading-tight flex items-center overflow-x-auto max-w-[400px] [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-buttercream-frosting-100 [&::-webkit-scrollbar-thumb]:bg-gray-tertiary [&::-webkit-scrollbar-thumb]:rounded-full" onClick={props.onEdit}>
                            <span className="mr-1">{props.filter.segmentGroup.displayName}</span>
                            <strong>
                                {props.filter.comparison} {props.filter.value}
                            </strong>
                        </button>
                    </div>      
                    
                    <div className="flex items-center transition-all duration-200">
                        <div className="w-4 bg-[linear-gradient(to_right,rgba(229,231,235,0),rgba(229,231,235,1))] h-full"></div>
                        <Button 
                            shape={ButtonShape.Circle} 
                            icon={<TrashIcon className="h-5 w-5 text-licorice-noir hover:text-blood-orange-sorbet" />} 
                            iconPosition={"right"} 
                            variant={ButtonVariant.IconRawWithHover} 
                            onClick={props.deleteFilter} 
                            className="bg-buttercream-frosting-100" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}