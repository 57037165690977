import { useState } from 'react';
import Card from '../../components/Card';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { useGenerateTaxonomySchemaMutation, useKickoffAutoTaxonomyProcessMutation } from '../../../generated/graphql';

const AutoTaxonomyBuilderCard = (): JSX.Element => {
  const { curTeamId } = useValidTeamAppContext();
  const [instructions, setInstructions] = useState<string>('');
  const [generatedSchema, setGeneratedSchema] = useState<string>('');
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' | 'info' } | undefined>();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [getAutoTaxonomySchema] = useGenerateTaxonomySchemaMutation();
  const [kickoffAutoTaxonomyProcess] = useKickoffAutoTaxonomyProcessMutation();

  // Handle schema generation
  const handleGenerateSchema = async () => {
    if (!instructions.trim()) {
      toast.error('Please provide instructions for generating the taxonomy');
      return;
    }

    setIsGenerating(true);
    setMessage({ text: 'Generating taxonomy schema...', type: 'info' });

    try {
      const response = await getAutoTaxonomySchema({
        variables: {
          instructions,
          teamId: curTeamId,
          previousSchema: generatedSchema || undefined,
        },
      });

      if (response.data?.generateTaxonomySchema) {
        const schemaRes = JSON.parse(response.data.generateTaxonomySchema);
        setGeneratedSchema(JSON.stringify(schemaRes, null, 2));
        setMessage({ text: 'Taxonomy schema generated successfully!', type: 'success' });
        toast.success('Schema generated successfully');
      } else {
        setMessage({ text: 'Failed to generate taxonomy schema', type: 'error' });
        toast.error('Failed to generate schema');
      }
    } catch (error) {
      setMessage({ text: `Error generating schema: ${error instanceof Error ? error.message : 'Unknown error'}`, type: 'error' });
      toast.error('Failed to generate schema');
    } finally {
      setIsGenerating(false);
    }
  };

  // Handle kickoff process
  const handleKickoffProcess = async () => {
    if (!generatedSchema.trim()) {
      toast.error('Please generate a schema first');
      return;
    }

    setIsSubmitting(true);
    setMessage({ text: 'Kicking off taxonomy process...', type: 'info' });

    await kickoffAutoTaxonomyProcess({
      variables: {
        schema: generatedSchema,
        teamId: curTeamId,
      },
      onError: (error) => {
        const errorMessage = `Failed to kick off taxonomy process: ${error.message}`;
        setMessage({ text: errorMessage, type: 'error' });
        toast.error('Failed to start taxonomy process');
        console.error(error);
        setIsSubmitting(false);
      },
      onCompleted: () => {
        setMessage({ text: 'Taxonomy process started successfully!', type: 'success' });
        toast.success('Taxonomy process started');
        setIsSubmitting(false);
      },
    });
  };

  // Clear the form
  const handleClear = () => {
    setInstructions('');
    setGeneratedSchema('');
    setMessage(undefined);
  };

  const getMessageClassName = (type: 'success' | 'error' | 'info') => {
    switch (type) {
      case 'success':
        return 'text-green-600 bg-green-50 p-2 rounded';
      case 'error':
        return 'text-red-600 bg-red-50 p-2 rounded';
      case 'info':
        return 'text-blue-600 bg-blue-50 p-2 rounded';
    }
  };

  return (
    <Card title="Taxonomy Builder" subTitle="Generate and process taxonomy schemas" width="max">
      <div className="flex flex-col gap-y-4 p-6 w-[32rem]">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Instructions for generating taxonomy</label>
          <textarea
            value={instructions}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInstructions(e.target.value)}
            placeholder="Enter instructions for generating the taxonomy schema..."
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="flex flex-row justify-end gap-x-2">
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Regular}
            text="Generate Schema"
            loadingText="Generating..."
            loadingConfirm={isGenerating}
            disabled={!instructions.trim() || isGenerating || isSubmitting}
            onClick={handleGenerateSchema}
          />
        </div>

        {generatedSchema && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Generated Schema (Editable)</label>
              <textarea
                value={generatedSchema}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setGeneratedSchema(e.target.value)}
                placeholder="Generated schema will appear here..."
                rows={10}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex flex-row justify-end gap-x-2">
              <Button 
                variant={ButtonVariant.Secondary} 
                size={ButtonSize.Regular} 
                text="Clear" 
                onClick={handleClear}
                disabled={isGenerating || isSubmitting} 
              />
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Regular}
                text="Submit Schema"
                loadingText="Submitting..."
                loadingConfirm={isSubmitting}
                disabled={!generatedSchema.trim() || isSubmitting || isGenerating}
                onClick={handleKickoffProcess}
              />
            </div>
          </>
        )}

        {message && (
          <div className={`mt-2 text-sm ${getMessageClassName(message.type)}`}>
            <p>{message.text}</p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default AutoTaxonomyBuilderCard;