import { ChevronDownIcon, ChevronUpIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { EntryFragment, FilterInput } from '../../generated/graphql';
import { classNames } from '../../v2/util';
import ClusterSparkChart from './ClusterSparkChart';
import moment from 'moment';
import { useSearchGroupPolling } from '../../v2/hooks/ExploreHook';
import { ITag, GroupFull, SentenceEntry } from '../../v2/hooks/GroupHook';
import GroupEntryPreviewPage from './GroupEntryPreviewPage';
import GroupHeader from './GroupHeader';
import { GroupUIType } from '../pages/ExplorePage';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
interface GroupCardPreviewPageProps {
  group: GroupFull;
  filterInput?: FilterInput;
  artificialStartDate?: Date;
  showPreviewOverlay?: boolean;
  page: string;
  discardSearchGroup: (groupId: string) => void;
  togglePinGroup: (groupId: string) => void;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
  getAllGroupSentences: () => Promise<void>;
  loadingAllSentences: boolean;
  updateProgress: (searchGroupId: string, newProgress: number) => void;
  deleteSentence?: (groupId: string, sentenceId: number, cb: () => void) => void;
  addSentence?: (groupId: string, sentence: any, cb: () => void) => void;
  onSeeMoreClick?: () => void;
  tags?: ITag[];
  groupExport: (groupId: string) => void;
  copyLink: (groupId: string) => void;
}

/**
 * Preview Page is the only place this thing still lives.
 *
 * This is meant to show sentences that belong in the group... I think ideally we show the distillate of the entry here.
 */
const GroupCardPreviewPage = ({
  group,
  filterInput,
  artificialStartDate,
  showPreviewOverlay,
  tags,
  discardSearchGroup,
  togglePinGroup,
  replaceOrAddToSearchGroups,
  getAllGroupSentences,
  updateProgress,
  onSeeMoreClick,
  copyLink,
}: GroupCardPreviewPageProps): JSX.Element => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  useSearchGroupPolling({
    teamId: teamId ?? -1,
    searchGroupId: group.id,
    replaceOrAddToSearchGroups,
    searchProcessing: group.processing ?? false,
    updateProgress,
    filterInput,
  });
  return (
    <>
      <div id="preview-page-group-card" className={classNames(`group-cluster relative flex w-full flex-col bg-white text-licorice-noir duration-300`)}>
        <div className={classNames(showPreviewOverlay && 'select-none blur-sm')}>
          <div className="default-shadow gap-y-3 rounded-md bg-buttercream-frosting">
            <div className={`grid grid-cols-8 items-center border-b-2 border-buttercream-frosting-100 pb-2`}>
              <GroupHeader
                group={group}
                isCondensedView={false}
                showTags={true}
                type={GroupUIType.PreviewPage}
                togglePinGroup={togglePinGroup}
                discardSearchGroup={discardSearchGroup}
                margin={{ t: 0, b: 0, x: 0 }}
                padding={{ b: 2, t: 4, x: 8 }}
                rounded={true}
                copyLink={copyLink}
              />
              <div className="col-span-8 px-2">
                {group.aggregateData && !group.processing && (
                  <ClusterSparkChart
                    artificialStartDate={artificialStartDate}
                    filterInput={filterInput ? filterInput : { startDate: moment().subtract(360, 'days').startOf('day').toDate(), endDate: moment().toDate() }}
                    disableAnnotations
                    aggregateData={group.aggregateData}
                    normalizedData={group.normalizedData}
                    tooltipLabels={group.tooltipLabels}
                    chartLabels={group.chartLabels}
                  />
                )}
              </div>
            </div>
            {group.processing && (
              <div className="my-8 flex items-center justify-center gap-x-4 pb-6 text-licorice-noir">
                <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-licorice-noir"></div>
                <h1 className="text-xl">Building group... {group.progress != null && group.progress}%</h1>
              </div>
            )}
            {!group.processing && (
              <div className="px-2 pb-4">
                <ExpandableList
                  id={Number(group.id)}
                  sentences={group.sentences ?? []}
                  entries={group.entries ?? []}
                  totalEntriesInCluster={group.totalEntries}
                  loadSeeMore={onSeeMoreClick ? undefined : () => getAllGroupSentences()}
                  onSeeMoreClick={onSeeMoreClick ? () => onSeeMoreClick() : undefined}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface ExpandableListProps {
  id: number;
  sentences: SentenceEntry[];
  entries: EntryFragment[];
  totalEntriesInCluster: number;
  loadSeeMore?: () => Promise<void>;
  deleteSentence?: (sentenceId: number, groupId: string) => void;
  onSeeMoreClick?: () => void;
}

const ExpandableList = ({ id, sentences, entries, loadSeeMore, totalEntriesInCluster, deleteSentence, onSeeMoreClick }: ExpandableListProps) => {
  const [allDataShown, setAllDataShown] = useState(loadSeeMore && totalEntriesInCluster <= 3);
  const [loading, setLoading] = useState(false);
  const onExpandButtonClick = async () => {
    if (loadSeeMore) {
      setLoading(true);
      await loadSeeMore();
      setLoading(false);
      setAllDataShown((prevVal) => !prevVal);
    }
    if (onSeeMoreClick) {
      onSeeMoreClick();
    }
  };
  return (
    <div className="flex flex-col gap-y-2 ">
      <div className={classNames('flex flex-col divide-y', allDataShown ? 'max-h-80 overflow-y-auto' : 'max-h-60 overflow-y-auto', 'overflow-x-hidden')}>
        {entries.slice(0, allDataShown ? entries.length : 3).map((entry: EntryFragment, idx: number) => {
          return <GroupEntryPreviewPage id={id} entry={entry} key={idx} />;
        })}
      </div>
      {(onSeeMoreClick != null || totalEntriesInCluster > 3) && (
        <div
          id="group-card-see-more"
          className="group relative flex h-6 cursor-pointer flex-row items-center justify-center rounded-2xl bg-white py-6 duration-100 hover:font-medium"
          onClick={() => {
            onExpandButtonClick();
          }}
        >
          {!allDataShown && (
            <div className="mr-2 flex flex-row items-center gap-x-2 text-licorice-noir">
              <h1>See More</h1>
              {!onSeeMoreClick ? <ChevronDownIcon className="h-5 w-5 stroke-2" /> : <ChevronRightIcon className="h-5 w-5 stroke-2" />}
            </div>
          )}
          {loading && (
            <div className="flex flex-row items-center justify-center rounded-md duration-300">
              <div className="h-6 w-6 animate-spin rounded-full border-t-2 border-b-2 border-licorice-noir"></div>
            </div>
          )}
          {allDataShown && !loading && (
            <div className="flex flex-row items-center gap-x-2">
              <h1>Collapse</h1>
              <ChevronUpIcon className="h-5 w-5 stroke-2 text-licorice-noir" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupCardPreviewPage;
