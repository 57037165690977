import { forwardRef } from 'react';
import { FilterInput } from '../../../generated/graphql';
import { PreviewModalOrigin } from '../../AnalyticsUtil';
import moment from 'moment';
import SearchInput from '../../../v3/baseComponents/SearchInput';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import { DatePicker } from '../../../v3/baseComponents/DatePicker';

interface PreviewFiltersForClusterization {
  teamId: number;
  orgId: number;
  orgName: string;
  teamName: string;
  clusterFilters: FilterInput;
  sentenceFilters: FilterInput;
  sentenceIdSort: number | undefined;
  clusterIdSort: number | undefined;
  setSentenceIdSort: (id: number | undefined) => void;
  setClusterIdSort: (id: number | undefined) => void;
  setClusterFilters: (filters: FilterInput) => void;
  setSentenceFilters: (filters: FilterInput) => void;
  openPreviewModal?: (originatedBy: any) => void;
  userId?: number;
  pageName: string;
  originalStartDate: Date;
  searchInput?: string;
  onConfirmSearch?: () => void;
  setSearchInput?: (search: string) => void;
}

const PreviewFiltersForClusterization = forwardRef(
  (
    { clusterFilters, openPreviewModal, originalStartDate, searchInput, setSearchInput, onConfirmSearch }: PreviewFiltersForClusterization,
    ref
  ): JSX.Element => {
    return (
      <div>
        <div className="grid grid-cols-2 gap-y-3 gap-x-5 ">
          <div className="col-span-2 xl:col-span-1"></div>
          <div className="col-span-2 flex flex-row justify-between gap-x-2 xl:col-span-1 xl:justify-end">
            <DatePicker date={clusterFilters.startDate} onChange={() => {}} onClickOverride={() => openPreviewModal?.(PreviewModalOrigin.date)} />
            <DatePicker date={clusterFilters.endDate} onChange={() => {}} onClickOverride={() => openPreviewModal?.(PreviewModalOrigin.date)} />
            <div className="buttercream-frosting-with-shadow rounded-full cursor-pointer p-4" onClick={() => openPreviewModal?.(PreviewModalOrigin.date)}>
              <AdjustmentsHorizontalIcon className="h-6 w-6 text-licorice-noir" />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PreviewFiltersForClusterization;
