import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { logError } from '../../../applicationTelemetry';
import { configureAmplifyAuth } from '../../../AuthConfigs';
import { Toaster, toast } from 'react-hot-toast';

/**
 * This handles when a user
 * @param param0
 * @returns
 */
const SSOLogin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const handleSignInOrRedirect = async () => {
    // this would need to fork based on the issuer in the urlQueryParams
    const issuer = urlParams.get('iss');
    const tenant = urlParams.get('tenant');
    try {
      if (issuer && tenant) {
        // redirect to federatedSignIn
        const config = configureAmplifyAuth(issuer, tenant);
        await Auth.federatedSignIn({ customProvider: config.identityProviderId! });
      } else {
        // toast message and error
        toast.error('Invalid issuer or tenant. Please contact your system administrator.');
        logError(new Error(`Invalid issuer or tenant. Got issuer: ${issuer} and tenant: ${tenant}`));
      }
    } catch (err) {
      toast.error(`Invalid issuer or tenant. Please contact your system administrator. ${err}`);
      logError(new AggregateError([err], `Invalid issuer or tenant. Got issuer: ${issuer} and tenant: ${tenant}`));
    }
  };

  useEffect(() => {
    handleSignInOrRedirect();
  }, []);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 20000,
          className: '',
          style: {
            border: '1px solid white',
            fontFamily: 'sofiapro',
            backgroundColor: '#1D1E30',

            color: 'white',
            borderRadius: '1rem',
            minWidth: '8%',
          },
        }}
      />
    </>
  );
};

export default SSOLogin;
