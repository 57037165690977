import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import { FilterContext, FilterDispatchContext } from '../../../context/filterStatementContext';
import { FilterGroupActionType } from '../../../reducers/filterStatement/filterStatementReducer';
import { EntryTextSearchFilter } from '../../filters/utilities/EntryTextSearchFilter';

const EMPTY_SEARCH_VALUE = '';
export const SearchBar = () => {
  const dispatch = useContext(FilterDispatchContext);
  const { staticConditions: topLevelAndConditions } = useContext(FilterContext);
  const searchField = topLevelAndConditions.find((condition) => condition.type === 'statement' && condition.fieldName === 'Entry.text');
  const [searchValue, setSearchValue] = useState<string>(searchField?.value ?? EMPTY_SEARCH_VALUE);

  const handleSearch = () => {
    if (searchValue === EMPTY_SEARCH_VALUE) return;
    dispatch?.({
      type: FilterGroupActionType.AlterStaticFilters,
      payload: EntryTextSearchFilter.composeFilterUpdatePayload(searchValue),
    });
  };

  /**
   * This wants to clear the search value and remove the filter node from the top level and conditions
   *
   * Should it have to find the appropriate filter node from the top level and conditions to be able to remove it?
   * You'd kind of want an api that says given a fieldName and operator I'll find the appropriate filter node for you and deal with it.
   */
  const handleClearSearch = () => {
    setSearchValue(EMPTY_SEARCH_VALUE);
    if (!searchField?.id) return;
    dispatch?.({
      type: FilterGroupActionType.AlterStaticFilters,
      payload: {
        upsert: { filterNodes: [] },
        remove: { fieldNames: [EntryTextSearchFilter.fieldName] },
      },
    });
  };

  return (
    <div className="relative flex-grow">
      <input
        type="text"
        id="full-text-search"
        className="w-full rounded-lg border-buttercream-frosting-100 border shadow-sm pl-10 text-sm text-licorice-noir placeholder-licorice-noir/30 outline-none duration-200 hover:bg-buttercream-frosting/50 focus:border-licorice-noir focus:ring-0"
        placeholder="Search for feedback about ..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-licorice-noir/50" />
      {searchValue && (
        <button
          onClick={() => handleClearSearch()}
          className="absolute right-3 top-1/2 -translate-y-1/2 rounded-full p-1 text-licorice-noir/50 hover:bg-licorice-noir/10 hover:text-licorice-noir"
          type="button"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};
