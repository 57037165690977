import { useContext, useState } from 'react';
import {
  Action,
  Domain,
  Organization_Users_Role,
  OrganizationUserFragment,
  Resource,
  Role,
  useUpdateOrganizationUserMutation,
} from '../../../generated/graphql';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import DualToneModal from '../../baseComponents/DualToneModal';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { ViewPermissionsEditorSection } from '../orgInfo/ViewPermissionsEditorSection';
import { IDROPDOWN_ORG_ROLES } from '../orgInfo/utils';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../context/orgInfoContext';
import { PermissionsProvider, usePermissionsState } from '../../../context/roleAssignmentContext';
import { OrgInfoActionTypes } from '../../../reducers/orgInfo/orgInfoReducer';
import { permissionMapToAssignedRolesForAUser } from '../../../reducers/orgInfo/permissions/permissionsReducer';
import toast from 'react-hot-toast';
import { logError } from '../../../applicationTelemetry';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';

interface EditMemberModalProps {
  user: OrganizationUserFragment;
  modalOpen: boolean;
  callbackModal: () => void;
}

export const EditMemberModal = ({ user, modalOpen, callbackModal }: EditMemberModalProps) => {
  return (
    <PermissionsProvider>
      <EditMemberModalBody user={user} modalOpen={modalOpen} callbackModal={callbackModal} />
    </PermissionsProvider>
  );
};

const EditMemberModalBody = ({ user, modalOpen, callbackModal }: EditMemberModalProps) => {
  const { organization } = useOrgInfoState();
  const [selectedRole, setSelectedRole] = useState<Role>(
    (user.user?.roles.find((role) => role.domainId === organization.id && role.domain === Domain.Org)?.role as Role) ?? Role.Member
  );
  const { hasPermission } = useContext(PermissionsContext);

  const orgInfoDispatch = useOrgInfoDispatch();
  const { permissionsMap } = usePermissionsState();

  const [editOrgUserMutation, editOrgUserMutationRes] = useUpdateOrganizationUserMutation();
  const handleEditMember = async () => {
    const assignedRoles = permissionMapToAssignedRolesForAUser(permissionsMap);
    assignedRoles.push({
      domainId: organization.id,
      domain: Domain.Org,
      role: selectedRole,
    });
    await editOrgUserMutation({
      variables: {
        orgId: organization.id,
        userToUpdateEmail: user.email,
        assignedRoles,
      },
      onCompleted: (data) => {
        const updatedUser = data.updateOrganizationUser?.find((orgUser) => orgUser.email === user.email);
        if (updatedUser?.user) {
          orgInfoDispatch({ type: OrgInfoActionTypes.EditMember, payload: { userId: updatedUser.user.id, updatedMember: updatedUser } });
        }
        callbackModal();
      },
      onError: (error) => {
        toast.error(`Failed to update ${user.email}: ${error.message}.`);
        console.error(error);
        logError(error);
      }
    });
  };

  return (
    <DualToneModal
      open={modalOpen}
      setOpen={callbackModal}
      headerChildren={
        <div className="flex flex-col items-center text-center gap-y-5">
          <div className="gap-x-2">
            <p className="text-3xl font-medium text-gray-tertiary">Edit user</p>
            <h1 className="text-3xl text-licorice-noir font-medium">{user.user?.firstName + ' ' + user.user?.lastName}</h1>
          </div>
          <p className="text-gray-tertiary">Change member’s role for this organization.</p>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col gap-y-5 justify-between w-full text-licorice-noir">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-1">
              <h1 className="text-sm">Role</h1>
              <VirtualizedComboBox
                comboBoxData={IDROPDOWN_ORG_ROLES}
                disableClear
                selectedItem={IDROPDOWN_ORG_ROLES.find((role) => role.value === selectedRole)}
                setSelectedItem={(item) => item && setSelectedRole(item.value as Role)}
                placeholder={'Select role'}
              />
            </div>
            {selectedRole === Role.Member ? <ViewPermissionsEditorSection user={user} /> : null}
          </div>
          <div className="mt-4 flex flex-row justify-between gap-x-4 text-center w-full">
            <div className="flex w-1/3">
              <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={callbackModal} text="Cancel" expandWidth></Button>
            </div>
            <div className="flex w-2/3">
              <Button
                variant={ButtonVariant.Primary}
                shape={ButtonShape.Pill}
                disabled={!hasPermission(Resource.Users, Action.Update) || editOrgUserMutationRes.loading}
                disabledTooltip={!hasPermission(Resource.Users, Action.Update) ? 'You don\'t have permission to update user roles' : ''}
                text={'Save Changes'}
                loadingConfirm={editOrgUserMutationRes.loading}
                onClick={handleEditMember}
                expandWidth
              ></Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

//Until we deprecate the concept of org role...
const oldRoleToNewRole = (oldRole: Organization_Users_Role): Role => {
  return oldRole === Organization_Users_Role.Admin ? Role.Admin : Role.Member;
};
