import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, InformationCircleIcon, SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import { ArrowRightOnRectangleIcon, ChevronDownIcon, QuestionMarkCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { Auth } from 'aws-amplify';
import 'tippy.js/dist/tippy.css';
import unwrapLightLogo from '../../../assets/unwrap_full_logo_2_MAIN.svg';
import { OrganizationFragment, Resource, TeamFlagsQueryResult, useGetDashboardAlertsQuery } from '../../../generated/graphql';
import { AppRoutes, V3_DASHBOARD } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import UserContext, { IUser } from '../../../v2/contexts/UserContext';
import { CustomerFacingAlert } from '../../../v2/sections/Navigation/CustomerFacingAlert';
import { classNames, filterRoutes } from '../../../v2/util';
import ChooseDropdownModal from '../../components/Modals/ChooseDropdownModal';
import NewGroupModal from '../../components/Modals/NewGroupModal';
import { useReplyHook } from '../../hooks/ReplyHook';
import { ViewDropdownRevamp } from '../../sections/Navigation/ViewDropdownRevamp';
import { INavigation, navigationItems } from './helpers';
import Tippy from '@tippyjs/react';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';

//https://tailwindui.com/components/application-ui/application-shells/sidebar

const DashboardAlertBanner = ({ message }: { message: string }) => {
  return (
    <div className="mt-4 mb-1 lg:ml-60 border border-blood-orange-sorbet bg-blood-orange-sorbet bg-opacity-20 p-2.5 flex flex-row items-center gap-4 text-white rounded-lg mr-3">
      <InformationCircleIcon className=" w-6 h-6" />
      <div className="flex flex-col mt-0.5">
        <p className="">{message}</p>
      </div>
    </div>
  );
};

export default function DashboardLayout({ children, flags, addTeam, addOrganization }: DashboardLayoutProps) {
  const { organizations, currentOrg, clearAppContext, setCurOrgId, curTeamId, currentTeam } = useValidTeamAppContext();
  const { data: dashboardAlertsData } = useGetDashboardAlertsQuery({ variables: { teamId: currentTeam?.id } });
  const { user, setCurrentUser } = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let navigate = useNavigate();

  const [queryString, setQueryString] = useState<string>('');
  const client = useApolloClient();

  const [switchOrgModalOpen, setSwitchOrgModalOpen] = useState(false);
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);

  const signOut = async () => {
    await Auth.signOut();
    await setCurrentUser();
    clearAppContext();
    // this needs to be after the current user is signed out
    await client.resetStore();
    navigate(AppRoutes.login);
  };

  return (
    <div className="min-h-screen font-circular font-300 bg-licorice-noir">
      {switchOrgModalOpen && (
        <ChooseDropdownModal
          modalOpen={switchOrgModalOpen}
          callbackModal={() => setSwitchOrgModalOpen(false)}
          title="Select Which Organization To Switch To:"
          subtitle={''}
          confirmButton={(org: OrganizationFragment | undefined) => {
            if (!org) return;
            setCurOrgId(org.id);
            setSwitchOrgModalOpen(false);
            navigate(AppRoutes.v3FullPath.home);
          }}
          loadingConfirm={false}
          data={organizations}
        />
      )}
      {newTeamModalOpen && (
        <NewGroupModal
          modalOpen={newTeamModalOpen}
          callbackModal={() => setNewTeamModalOpen(false)}
          confirmButton={(teamName: string, logoUrl: string, demoMode: string) => {
            addTeam(teamName, logoUrl, demoMode, currentOrg?.id!);
            setNewTeamModalOpen(false);
          }}
          type="View"
        />
      )}
      {newOrgModalOpen && (
        <NewGroupModal
          modalOpen={newOrgModalOpen}
          callbackModal={() => setNewOrgModalOpen(false)}
          confirmButton={(orgName: string, logoUrl: string, demoMode: string) => {
            addOrganization(orgName);
            setNewOrgModalOpen(false);
          }}
          type="Organization"
        />
      )}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-tertiary bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-licorice-noir">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-4">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col overflow-y-auto pt-5 h-full">
                  <a href="https://unwrap.ai" target="_blank" rel="noreferrer">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img className="h-8 w-auto" src={unwrapLightLogo} alt="Unwrap" />{' '}
                    </div>
                  </a>
                  <nav className="mt-5 space-y-1 px-2 font-circular">
                    <MainNavigation user={user} customersLockedOut={(currentTeam?.defaultValues?.customer_locked_out === true && !user?.isUnwrapper)} curTeamId={curTeamId} navigation={navigationItems} />
                  </nav>
                  <div className="mt-auto">
                    <div className="flex mb-2 mx-4">{user?.isUnwrapper && currentOrg?.isCustomerFacing ? <CustomerFacingAlert /> : null}</div>
                    <div className="flex min-h-[4.5rem] items-center justify-between bg-black bg-opacity-20">
                      <ViewDropdownRevamp
                        queryString={queryString}
                        setQueryString={setQueryString}
                        setSwitchOrgModalOpen={setSwitchOrgModalOpen}
                        setNewOrgModalOpen={setNewOrgModalOpen}
                        setNewTeamModalOpen={setNewTeamModalOpen}
                      />
                    </div>
                    <div className="flex flex-shrink-0 items-center justify-between bg-black bg-opacity-30 p-4">
                      <NavLink
                        id="navigation-profile"
                        to={`/${V3_DASHBOARD}${AppRoutes.profile}`}
                        className={
                          'flex flex-row items-center gap-x-1.5 rounded-full bg-licorice-noir-light p-2 px-3 text-buttercream-frosting-300 transition duration-200 hover:cursor-pointer hover:bg-licorice-noir-lighter'
                        }
                        onClick={() => {}}
                      >
                        <UserCircleIcon className="h-5 w-5" />
                        <h1 className="textEllipsis text-ellipsis text-sm">{user?.firstName}</h1>
                      </NavLink>

                      <a
                        id="navigation-docs"
                        href="https://docs.unwrap.ai"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-full bg-licorice-noir-light p-2 transition duration-200 hover:cursor-pointer hover:bg-licorice-noir-lighter"
                      >
                        <QuestionMarkCircleIcon className="h-5 w-5 text-white" />
                      </a>
                      <div
                        id="navigation-logout"
                        onClick={() => signOut()}
                        className="rounded-full bg-licorice-noir-light p-2 transition duration-200 hover:cursor-pointer hover:bg-licorice-noir-lighter"
                      >
                        <ArrowRightOnRectangleIcon className="h-5 w-5 text-white" />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      <Disclosure>
        {/* Static sidebar for desktop */}

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}

          <div className="flex min-h-0 flex-1 flex-col bg-licorice-noir">
            <div className="flex flex-1 flex-col gap-y-4 overflow-y-auto pt-5">
              <a href="https://app.unwrap.ai" target="_blank" rel="noreferrer">
                <div className="flex flex-shrink-0 items-center px-5">
                  <img className="h-6 w-auto" src={unwrapLightLogo} alt="Unwrap" />
                </div>
              </a>
              <MainNavigation customersLockedOut={(currentTeam?.defaultValues?.customer_locked_out === true && !user?.isUnwrapper)} user={user} curTeamId={curTeamId} navigation={navigationItems} />
            </div>
            {user?.isUnwrapper && currentOrg?.isCustomerFacing ? (
              <div className="flex mb-2 mx-4">
                <CustomerFacingAlert />
              </div>
            ) : null}
            <div className="flex items-center justify-between bg-black bg-opacity-20 ml-4 mr-4 mb-3 rounded-md">
              <ViewDropdownRevamp
                queryString={queryString}
                setQueryString={setQueryString}
                setSwitchOrgModalOpen={setSwitchOrgModalOpen}
                setNewOrgModalOpen={setNewOrgModalOpen}
                setNewTeamModalOpen={setNewTeamModalOpen}
              />
            </div>
            <div className="flex flex-shrink-0 items-center justify-between pt-2 px-4 pb-4">
              <NavLink
                id="navigation-profile"
                to={`/${V3_DASHBOARD}${AppRoutes.profile}`}
                className={
                  'flex flex-row items-center gap-x-1.5 rounded-md text-white transition duration-200 hover:cursor-pointer hover:text-buttercream-frosting-300'
                }
                onClick={() => {}}
              >
                <UserCircleIcon className="h-5 w-5" />
                <h1 className="textEllipsis text-ellipsis text-sm">{user?.firstName}</h1>
              </NavLink>

              <div className='flex gap-2'>
                <a
                  id="navigation-docs"
                  href="https://docs.unwrap.ai"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-md p-2 transition duration-200 hover:cursor-pointer text-white group hover:text-buttercream-frosting-300"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5 group-hover:text-buttercream-frosting-300 group-hover:duration-200" />
                </a>
                <div
                  id="navigation-logout"
                  onClick={() => signOut()}
                  className="rounded-full bg-white bg-opacity-10 p-2 transition duration-200 text-white hover:cursor-pointer hover:bg-licorice-noir-light hover:text-white"
                >
                  <ArrowRightOnRectangleIcon className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {dashboardAlertsData?.getDashboardAlerts.map((alert) => (
          <DashboardAlertBanner key={alert.id} message={alert.message} />
        ))}
        {currentTeam?.defaultValues?.customer_locked_out && (
          <DashboardAlertBanner message={currentTeam?.defaultValues?.lock_out_message || ''} />
        )}
        <div className="flex flex-1 flex-col lg:pl-60 bg-licorice-noir">
          <div className="sticky top-0 z-10 bg-buttercream-frosting pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-secondary hover:text-gray-licorice-noir focus:outline-none focus:ring-2 focus:ring-inset focus:ring-licorice-noir"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 relative min-h-screen bg-white rounded-xl my-3 mr-3">
            <div className="py-4">
              <div className="font-circular">{children}</div>
            </div>
          </main>
        </div>
      </Disclosure>
    </div>
  );
}

interface MainNavigationProps {
  navigation: INavigation[];
  customersLockedOut: boolean;
  curTeamId: number | null;
  user?: IUser;
}

export const MainNavigation = ({ navigation, customersLockedOut, user, curTeamId }: MainNavigationProps) => {
  const {hasPermission} = useContext(PermissionsContext);
  const unlockedPages = ["Home", "Organizations", "Integrations"];

  return (
    <nav className="containerScrollbar flex-1 space-y-1 ml-5 mr-3">
      {navigation
        .filter((item) => filterRoutes(item, user))
        .map((item) => {
          item.subNavigations?.forEach((subItem: INavigation) => {
            if(subItem.requiredPermissions) {
              subItem.requiredPermissions?.forEach((permission) => {
                const hasPerms = hasPermission(permission.resource, permission.action);
                subItem.disabled = !hasPerms || customersLockedOut;
              });
            }
          });

          item.disabled = customersLockedOut && !unlockedPages.includes(item.name);

          return <NavItem key={item.name} item={item} user={user} curTeamId={curTeamId} level={0} />;
        })}
    </nav>
  );
};
interface NavItemProps {
  item: INavigation;
  curTeamId: number | null;
  user?: IUser;
  level?: number;
}

const NavItem = ({ item, curTeamId, user, level }: NavItemProps) => {
  const { validIntegrations } = useReplyHook({});
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const selectedIconClass = level === 0 ? 'mr-4 h-5 w-5 flex-shrink-0 text-white' : 'mr-4 h-5 w-5 flex-shrink-0 text-white';
  const iconClass = level === 0 ? 'mr-5 h-5 w-5 flex-shrink-0 stroke-2 text-white' : 'mr-4 h-5 w-5 flex-shrink-0 stroke-2 text-white';
  const fontSize = level === 0 ? 'text-base' : 'text-sm';

  const isActiveLink = (path: string) => {
    if (level === 0) {
      // For main item, active if the current path starts with the item's path
      return window.location.pathname.startsWith(path);
    } else {
      // For sub-items, active only if the current path matches exactly
      return window.location.pathname === path;
    }
  };
  
  const isDisabled = item.disabled;
  // If the item has subNavigations, we only want to render the chevron menu if there are subNavigations that are not the same as the current path
  // otherwise, the chevron menu is redundant

  const shouldRenderSubNavigations = () => {
    if (!item.subNavigations || item.subNavigations.length === 0) {
      return false;
    }
    return item.subNavigations.filter((item) => filterRoutes(item, user, validIntegrations)).some((subItem) => subItem.path !== item.path);
  };

  const toggleSubmenu = () => setIsSubmenuOpen((prev) => !prev);

  return (
    <div>
      <Tippy  placement="right" disabled={!isDisabled} content={<p>You do not have permissions to access this page. Contact your admin and request the appropriate role.</p>}> 
        <NavLink
          onClick={() => {
          if (isDisabled) return;
          if (!isSubmenuOpen) toggleSubmenu();
        }}
        to={{ pathname: `${isDisabled ? window.location.pathname : item.path}`, search: '' }}
        id={`navigation-${item.name.replace(' ', '-').toLowerCase()}`}
        className={({ isActive }) =>
          classNames(
            isDisabled ? 'disabled opacity-60 cursor-not-allowed' : '',
            item.teamRequired && !curTeamId ? 'disabled opacity-60' : '',
            'rounded-md',
            'group flex select-none items-center px-3 py-2 my-2.5 text-base font-500 text-white',
            !(item.teamRequired && !curTeamId)
              ? isActive && isActiveLink(item.path)
                ? 'bg-blood-orange-sorbet'
                : 'transition-colors duration-100 hover:bg-licorice-noir-light hover:bg-opacity-80 '
              : ''
          )
        }
      >
        {({ isActive }) => (
          <div className="flex flex-row gap-x-3 items-center justify-between w-full">
            <div className="flex flex-row items-center">
              {isActive && isActiveLink(item.path) ? (
                <item.selectedIcon className={selectedIconClass} aria-hidden="true" />
              ) : (
                <item.icon className={iconClass} aria-hidden="true" />
              )}
              <p className={fontSize}>{item.name}</p>
            </div>
            {item.showNewBadge ? (
              <div className="flex flex-row gap-x-1 bg-white bg-opacity-10 px-3 py-1 rounded-md items-center justify-center">
                <SparklesIcon className="h-3 w-3 text-white" />
                <p className="text-xs">New</p>
              </div>
            ) : null}
            {item.showBetaBadge ? (
              <div className="flex flex-row gap-x-1 bg-white bg-opacity-10 px-3 rounded-md items-center justify-center">
                <p className="text-xs py-1">Beta</p>
              </div>
            ) : null}
            {shouldRenderSubNavigations() && (
              <ChevronDownIcon
                className={`transform transition-transform w-4 h-4 mt-0.5 ${isSubmenuOpen ? 'rotate-180' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleSubmenu();
                }}
              />
            )}
          </div>
        )}
      </NavLink>
      </Tippy>
      {isSubmenuOpen && item.subNavigations && shouldRenderSubNavigations() && (
        <div className="ml-4 mt-0.5">
          {item.subNavigations
            .filter((item) => filterRoutes(item, user, validIntegrations))
            .map((subItem) => (
              <NavItem key={subItem.name} item={subItem} user={user} curTeamId={curTeamId} level={(level ?? 0) + 1} />
            ))}
        </div>
      )}
    </div>
  );
};

interface DashboardLayoutProps {
  children?: JSX.Element;
  customerFacingAlertOpen: boolean;
  setCustomerFacingAlertOpen: (bool: boolean) => void;
  flags: TeamFlagsQueryResult | undefined;
  addTeam: (teamName: string, logoUrl: string, demoMode: string, id: number) => void;
  addOrganization: (orgName: string) => void;
}