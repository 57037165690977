import { IntegrationRow } from './IntegrationRow';
import { Integration_Type, useGetFeedbackIntegrationsQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import LoadingSpinner from '../../../v2/components/LoadingSpinner';
import { useEffect } from 'react';


interface IntegrationCardProps {
  integrationType: Integration_Type;
  filterString: string;
}

export const IntegrationTypeCard = (props: IntegrationCardProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { data, loading, error, refetch } = useGetFeedbackIntegrationsQuery({
    variables: { 
      teamId: teamId, 
      integrationTypeId: props.integrationType.id 
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, [teamId, props.integrationType.id, refetch]);

  return (
    (showIntegrationType(props.integrationType, props.filterString)) ? (
      <div className="border bg-buttercream-frosting border-buttercream-frosting-100 rounded-lg p-5" id={`${props.integrationType.title}-integration-type-card`}>
        <div className="flex items-center gap-3 content-start">
          <img src={props.integrationType.logoUrl} alt="" className="w-8 h-8 object-contain"/>
          <h4 className="text-licorice-noir text-lg font-medium">{props.integrationType.title}</h4>
        </div>
        <div className="border-b border-buttercream-frosting-100 mt-5">
          {loading ? <LoadingSpinner /> : 
            data?.feedbackIntegration?.map((integration, index) => (
              (showIntegration(integration.integrationName ? integration.integrationName : `Integration ${integration.id}`, props.filterString) || 
               showIntegration(props.integrationType.title, props.filterString)) &&
              (<IntegrationRow 
                integration={integration} 
                integrationType={props.integrationType} 
                id={`${props.integrationType.title}-integration-row-${index}`}
              />)
            ))
          }
        </div>
      </div>
    ) : null
  );
};

const showIntegrationType = (integrationType: Integration_Type, filterString: string) => {
  if (showIntegration(integrationType.title, filterString)) return true;
  for (const integration of integrationType.teamIntegration) {
    if (showIntegration(integration.integrationName ? integration.integrationName : `Integration ${integration.id}`, filterString)) return true;
  }
  return false;
} 

const showIntegration = (title: string, filterString: string) => {

  if (filterString === '') return true;

  return (title.toLowerCase().includes(filterString.toLowerCase()));
}
