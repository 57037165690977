import { useContext } from "react";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";

interface LabelsSectionProps {
}

export default function LabelsSection(props: LabelsSectionProps) {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);

    const labels = data.feedbackIntegration.segmentConfig.filter(segment => segment.override);


    return (
        <div>
            {labels.length > 0 ? labels.map(label => 
            <div className="bg-buttercream-frosting-100 rounded-md pt-1 pb-1 pl-2 pr-2 inline-flex flex-row items-center text-licorice-noir gap-2 h-6 mr-2 mb-2">
                {label.segmentGroup.displayName}: {label.override}
                {label.id < 0 && 
                <Button 
                    variant={ButtonVariant.IconRaw} 
                    shape={ButtonShape.Circle} 
                    icon={<XMarkIcon className="w-4 h-4 stroke-2 text-gray-tertiary"/>} 
                    onClick={() => {
                        dispatch({
                            type: FeedbackIntegrationActionType.DeleteSegment, 
                            payload: {segment: label}
                        });
                    }} 
                />
                }
            </div>
            )
            : <div className="italic text-gray-tertiary">No labels yet ... Add some.</div>}
        </div>
    );
}