import { Icon } from '@iconify/react';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
import { useContext } from 'react';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { ExpandState } from '../actions/taxonomy';

export const TaxonomyChildrenToggle = () => {
  const { currentTeam, currentOrg } = useValidTeamAppContext();
  const dispatch = useContext(TaxonomyDispatchContext);
  return (
    <div className="w-full flex justify-start text-licorice-noir ">
      <div className="flex flex-row justify-center items-center">
        <Tippy theme="dark" content="Expand all folders">
          <div
            className="flex cursor-pointer justify-center rounded-l-md -mr-[1px] items-center bg-buttercream-frosting border border-buttercream-frosting-100 py-1 px-1.5 text-licorice-noir duration-200 hover:bg-buttercream-frosting-100 hover:bg-opacity-50 hover:text-blood-orange-sorbet"
            onClick={() => {
              dispatch({ type: 'toggleExpandAll', payload: { state: ExpandState.Expanded } });
              logEvent(Events.TaxonomyToggled, {
                View_ID: currentTeam.id,
                View_Name: currentTeam.name,
                Org_ID: currentOrg.id,
                Org_Name: currentOrg.name,
                Toggle_Type: 'Open All',
              });
            }}
          >
            <Icon icon="mdi:expand-all" width="20" height="20" className="cursor-pointer" />
          </div>
        </Tippy>
        <Tippy theme="dark" content="Collapse all folders">
          <div
            className="flex justify-center cursor-pointer items-center gap-x-1 rounded-r-md bg-buttercream-frosting border border-buttercream-frosting-100 py-1 px-1.5 text-licorice-noir duration-200 hover:bg-buttercream-frosting-100 hover:bg-opacity-50 hover:text-blood-orange-sorbet"
            onClick={() => {
              dispatch({ type: 'toggleExpandAll', payload: { state: ExpandState.Collapsed } });
              logEvent(Events.TaxonomyToggled, {
                View_ID: currentTeam.id,
                View_Name: currentTeam.name,
                Org_ID: currentOrg.id,
                Org_Name: currentOrg.name,
                Toggle_Type: 'Close All',
              });
            }}
          >
            <Icon icon="mdi:collapse-all" width="20" height="20" className="cursor-pointer" />
          </div>
        </Tippy>
      </div>
    </div>
  );
};
