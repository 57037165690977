import { classNames } from '../../v2/util';

interface CardProps {
  title: string;
  subTitle?: string;
  children?: JSX.Element | JSX.Element[];
  width?: 'small' | 'large' | 'max';
}

const Card = ({ title, subTitle, children, width }: CardProps) => {
  return (
    <div
      className={classNames(
        'overflow-hidden rounded-lg border border-buttercream-frosting-100 bg-buttercream-frosting',
        width === 'small' || !width ? 'max-w-sm' : width === 'large' ? 'max-w-lg' : 'max-w-2xl'
      )}
    >
      <div className="px-6 py-6">
        <div className="mb-3 text-xl font-recoleta">{title}</div>
        {subTitle && <p className="text-base text-gray-secondary">{subTitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default Card;
