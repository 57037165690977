import { SyntheticEvent, useContext } from 'react';
import Tippy from '@tippyjs/react';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { Resource, Action } from '../../../generated/graphql';

export function PinGroupLink(props: {
    pinnedByUser: boolean,
    pinLoading: boolean,
    onClick: (e: SyntheticEvent) => void
}) {
    const {hasPermission} = useContext(PermissionsContext);
    const canPinGroup = hasPermission(Resource.PinnedGroups, Action.Update);

    if (!canPinGroup) {
        return <Tippy theme="light" delay={200} content={<p>You do not have permission to pin groups. Please contact your administrator and request the contributor role.</p>}>
            <BookmarkIcon className="h-5 w-5 self-center cursor-not-allowed" id="pin-group" onClick={(e) => {e.stopPropagation()}}  />
        </Tippy>
    }
    return <div className="rounded-md p-2 hover:cursor-pointer hover:bg-buttercream-frosting-100" data-cy="pin-group-link">
        <Tippy theme="light" delay={200} content={<p
            className="text-center">{props.pinnedByUser ? 'Unpin Group' : 'Pin Group'}</p>}>
            {props.pinLoading ? (
                <AdjustableLoadingIcon width={5} height={5} color="white" />
            ) : props.pinnedByUser ? (
                <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group"
                                   onClick={props.onClick} />
            ) : (
                <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={props.onClick} />
            )}
        </Tippy>
    </div>;
}