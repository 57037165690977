import { ChartBarIcon } from '@heroicons/react/24/solid';
import { FilterInput, SeriesDataFragment } from '../../../../generated/graphql';
import ClusterSparkChart from '../../ClusterSparkChart';

interface InsightChartProps {
  loading: boolean;
  filterInput: FilterInput | null | undefined;
  seriesData?: SeriesDataFragment | null;
}
const InsightChart = ({ loading, filterInput, seriesData }: InsightChartProps) => {
  if (loading) {
    return <InsightChartLoadingSkeleton />;
  }

  if (seriesData && filterInput) {
    return (
      <ClusterSparkChart
        artificialStartDate={undefined}
        autoHeight
        aggregateData={seriesData.aggregateData[0]}
        normalizedData={seriesData.normalizedData[0]}
        tooltipLabels={seriesData.tooltipLabels}
        chartLabels={seriesData.chartLabels}
        filterInput={filterInput}
      />
    );
  }

  return <InsightChartErrorSkeleton />;
};

const InsightChartErrorSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-buttercream-frosting relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-full"
    >
      <div className="space-y-3 justify-center items-center flex py-8 h-full">
        <ChartBarIcon className="h-32 w-32 text-buttercream-frosting-300" />
      </div>
    </div>
  );
};
const InsightChartLoadingSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-buttercream-frosting relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-full"
    >
      <div className="space-y-3 justify-center items-center flex py-8 h-full">
        <ChartBarIcon className="h-32 w-32 text-buttercream-frosting-300" />
      </div>
    </div>
  );
};

export default InsightChart;
