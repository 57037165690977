import { Float } from '@headlessui-float/react';
import { useEffect, useRef, useState } from 'react';

interface ConfigurationTypeHeaderProps {
    title: string;
    onIconClick: (modalOpen: boolean) => void;
    showIcon: boolean;
    children?: React.ReactNode;
    showChildren: boolean;
    id: string;
    disableButton: boolean;
}

export const ConfigurationTypeHeader = (props: ConfigurationTypeHeaderProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [floatElement, setFloatElement] = useState<HTMLElement | null>(null);
    
    useEffect(() => {
        if (!props.showChildren) return;
        
        // Find the modal element by ID
        const modalId = `${props.id}-modal`;
        const modalElement = document.getElementById(modalId);
        
        if (modalElement) {
            setFloatElement(modalElement);
        } 
        
        const handleClickOutside = (event: MouseEvent) => {
            // Check if the click was on the button (we don't want to close when clicking the button)
            if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
                console.log("Click on button, not closing");
                return;
            }
            
            // Find the modal element again in case it was added after this effect ran
            const modalElement = document.getElementById(modalId);
            
            // Check if the click was outside the modal
            if (modalElement && !modalElement.contains(event.target as Node)) {
                console.log(`Click outside modal ${modalId}, closing`);
                props.onIconClick(false);
            } else if (!modalElement) {
                console.log(`Modal element ${modalId} not found`);
            } 
        };
        
        // Use a slight delay to ensure the DOM is fully updated
        setTimeout(() => {
            document.addEventListener('mousedown', handleClickOutside);
        }, 100);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props.showChildren, props.onIconClick, props.id]);
    
    return (
        <div className="flex flex-row gap-3 items-center justify-between mb-5" id={`${props.id}-section`}>
            <h4 className="w-content text-xl font-recoleta text-licorice-noir whitespace-nowrap">{props.title}</h4>
            <div className="w-full border-t border-buttercream-frosting-100 flex-grow"></div>
            <div className="flex flex-col items-end relative">
                {props.showIcon && (
                    <Float
                        show={props.showChildren}
                        placement="bottom"
                        offset={4}
                        shift={true}
                        flip={true}
                        portal={true}
                    >
                        <button 
                            ref={buttonRef}
                            id={`${props.id}-button`}
                            onClick={() => props.onIconClick(true)}
                            className="flex flex-row items-center justify-center whitespace-nowrap font-circular font-medium transition-all disabled:cursor-not-allowed disabled:opacity-50 duration-200 px-1 py-1 text-md rounded-md bg-white border-licorice-noir border fil-licorice-noir hover:bg-licorice-noir hover:fill-white w-8 h-8"
                            disabled={props.disableButton}
                        >
                            <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 fill-licorice-noir hover:fill-white">
                                <path d="M9.37907 10.6211L3.40033 10.6213C3.23721 10.6212 3.09289 10.5603 2.96738 10.4388C2.84206 10.3172 2.77936 10.171 2.77926 10.0002C2.77936 9.82942 2.8402 9.68324 2.96178 9.56166C3.08336 9.44007 3.22955 9.37923 3.40034 9.37913L9.37907 9.37928L9.37892 3.40055C9.37902 3.23742 9.43986 3.0931 9.56145 2.96759C9.68303 2.84227 9.82921 2.77957 10 2.77947C10.1708 2.77957 10.317 2.84041 10.4386 2.96199C10.5601 3.08357 10.621 3.22976 10.6211 3.40054L10.6209 9.37928L16.5997 9.37913C16.7628 9.37923 16.9071 9.44007 17.0326 9.56166C17.1579 9.68324 17.2206 9.82942 17.2207 10.0002C17.2206 10.171 17.1598 10.3172 17.0382 10.4388C16.9166 10.5603 16.7705 10.6212 16.5997 10.6213L10.6209 10.6211L10.6211 16.5999C10.621 16.763 10.5601 16.9073 10.4386 17.0328C10.317 17.1581 10.1708 17.2209 10 17.2209C9.82921 17.2209 9.68303 17.16 9.56145 17.0384C9.43986 16.9168 9.37902 16.7707 9.37892 16.5999L9.37907 10.6211Z"/>
                            </svg> 
                        </button>
                        
                        <div id={`${props.id}-modal`}>
                            {props.children}
                        </div>
                    </Float>
                )}
            </div>
        </div>
    );
};