export interface IChartLabelProps {
  labelType?: string;
  labelValue?: string;
}

export const ChartLabel: React.FC<IChartLabelProps> = ({ labelType, labelValue }) => {
  return labelType && labelValue ? (
    <div className="flex flex-row items-center gap-x-2 px-2 h-[32px] rounded-md bg-buttercream-frosting border boder-buttercream-frosting-100">
      <div className="text-gray-secondary text-md whitespace-nowrap min-w-fit">{`${labelType}:`}</div>
      <div className="font-medium text-licorice-noir whitespace-nowrap min-w-fit">{labelValue}</div>
    </div>
  ) : null;
};
