import { TrashIcon } from "@heroicons/react/24/outline";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { FeedbackSegmentConfig } from "../../reducers/feedbackIntegration";
import { FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { useContext } from "react";


interface CustomFieldRowProps {
    customField: FeedbackSegmentConfig;
}

export default function CustomFieldRow(props: CustomFieldRowProps) {
    const dispatch = useContext(FeedbackIntegrationDispatchContext  );
    return (
        <tr>
            <td className="font-medium text-licorice-noir pb-3 pt-2 border-b border-buttercream-frosting-100 pr-4">{props.customField.segmentGroup.displayName}</td>
            <td className="text-licorice-noir pb-3 pt-2 border-b border-buttercream-frosting-100 pr-4">{props.customField.path}</td>
            <td className="pb-3 pt-2 border-b border-buttercream-frosting-100">
                {props.customField.id && props.customField.id < 0 && (
                    <Button 
                    variant={ButtonVariant.IconRawWithHover} 
                    shape={ButtonShape.Circle} 
                    icon={<TrashIcon className="w-5 h-5 stroke-2 hover:text-blood-orange-sorbet"/>} 
                    onClick={() => {
                        dispatch({
                            type: FeedbackIntegrationActionType.DeleteSegment, 
                            payload: {segment: props.customField}
                        });
                    }} />
                )}
            </td>
        </tr>
    );
}