import { Action, Resource, useGetBoardsLazyQuery } from '../../../generated/graphql';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { PageWrapper } from '../PageWrapper';
import { useBoardsListDispatch, useBoardsListState } from '../../../context/boardsListContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { BoardsListActionTypes } from '../../../reducers/boards/boardsListReducer';
import { BoardPreviewCardSkeleton } from '../../components/boards/skeletons/BoardPreviewCardSkeleton';
import { BoardPreviewCardStaticSkeleton } from '../../components/boards/skeletons/BoardPreviewCardStaticSkeleton';
import { CreateBoardModal } from '../../components/Modals/CreateBoardModal';
import { debounce } from 'lodash';
import SearchInput from '../../baseComponents/SearchInput';
import { BoardPreviewCard } from '../../components/boards/BoardPreviewCard';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';

export const BoardsPage = () => {
  const { hasPermission } = useContext(PermissionsContext);
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const state = useBoardsListState();
  const dispatch = useBoardsListDispatch();

  const [createBoardModalOpen, setCreateBoardModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [getBoards, { loading: loadingBoards }] = useGetBoardsLazyQuery({
    variables: {
      teamId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      dispatch({ type: BoardsListActionTypes.SetBoards, payload: { boards: data.getBoards } });
      setIsSearching(false);
    },
  });

  useEffect(() => {
    getBoards();
  }, []);

  const debouncedOnChange = useCallback(
    debounce((newValue: string) => {
      getBoards({
        variables: {
          teamId,
          titleSubstring: newValue,
        },
      });
    }, 300),
    []
  );

  async function handleSearchInputChange(newValue: string) {
    setIsSearching(true);
    setSearchValue(newValue);
    if (newValue === '') {
      //If the input is cleared, quickly get all boards from cache instead of debounce.
      getBoards({
        variables: {
          teamId,
        },
      });
    } else {
      debouncedOnChange(newValue);
    }
  }

  const disabledTooltip = 'You do not have permissions to create a board. Contact your admin to request the contributor role.';

  return (
    <PageWrapper title="">
      <CreateBoardModal modalOpen={createBoardModalOpen} setModalOpen={setCreateBoardModalOpen} />
      <div className="flex flex-col gap-y-3 bg-white">
        <div className="flex flex-row justify-between">
          <h1 className="text-4xl flex flex-col font-recoleta text-licorice-noir">Boards</h1>
          <Button
            id="new-board-button"
            variant={ButtonVariant.Secondary}
            disabledTooltip={disabledTooltip}
            size={ButtonSize.Small}
            disabled={!hasPermission(Resource.Boards, Action.Create)}
            shape={ButtonShape.Square}
            text="New Board +"
            onClick={() => setCreateBoardModalOpen(true)}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-5">
          <div className="col-span-2">
            <SearchInput
              noPadding
              onSearch={() => {}}
              onChange={(e) => {
                handleSearchInputChange(e.target.value);
              }}
              onClear={() => {
                setIsSearching(true);
                getBoards({
                  variables: {
                    teamId,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-5">
          {loadingBoards || isSearching ? (
            <>
              <BoardPreviewCardSkeleton />
              <BoardPreviewCardSkeleton />
              <BoardPreviewCardSkeleton />
            </>
          ) : (
            <>
              {state.boards.map((board) => (
                <BoardPreviewCard key={board.id} board={board} />
              ))}
              {searchValue !== '' ? (
                state.boards.length === 0 ? (
                  <div className="text-center col-span-3 mt-8 text-gray-secondary">No boards match your search.</div>
                ) : null
              ) : (
                <BoardPreviewCardStaticSkeleton onButtonClick={() => setCreateBoardModalOpen(true)} />
              )}
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
