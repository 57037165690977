import { MINIMUM_CHART_HEIGHT } from './Chart';
import { ChartType } from './chartElements/ChartTypeOptions';

export interface IChartWrapperProps {
  children: React.ReactNode | React.ReactElement;
  chartType?: ChartType;
  numberOfElements?: number;
  forcedHeight?: number;
}

export const ChartWrapper = ({ children, chartType, numberOfElements, forcedHeight }: IChartWrapperProps) => {
  // const adjustedHeight = chartType == 'horizontal' ? MINIMUM_CHART_HEIGHT + Math.max((numberOfElements - 5) * 36, 0) : forcedHeight || MINIMUM_CHART_HEIGHT;

  return (
    <div style={{ height: MINIMUM_CHART_HEIGHT }} className={`flex flex-1 w-full overflow-x-hidden`}>
      {children}
    </div>
  );
};
