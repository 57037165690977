import { Popover, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { alphabeticalSort, capitalizeFirstLetter } from '../../../../v2/util';
import Badge from '../../../../baseComponents/Badge';
import { ComboBox } from '../../../../baseComponents/ComboBox';
import toast from 'react-hot-toast';
import { GroupDataContext } from '../../../../context/groupContext';
import { useGetTagsQuery } from '../../../../generated/graphql';
import { useRemoveTagFromGroupMutation } from '../../../../generated/graphql';
import { useCreateTagMutation } from '../../../../generated/graphql';
import { GetTagsDocument, useTagGroupMutation } from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { IDropDownItem } from '../../../../baseComponents/DropDown';
import { TaxonomyDispatchContext } from '../../../context/TaxonomyDispatchContext';
import { TaxonomyContext } from '../../../context/TaxonomyContext';
import { ITag } from '../../../reducers/taxonomy';

export const TagsPopover = () => {
  const groupState = useContext(GroupDataContext);
  const taxonomyState = useContext(TaxonomyContext);
  const [currentTags, setCurrentTags] = useState<ITag[]>([]);

  useEffect(() => {
    if (taxonomyState && groupState.groupData) {
      setCurrentTags(taxonomyState.get(groupState.groupData.id)?.tags ?? []);
    }
  }, [taxonomyState, groupState.groupData]);

  return (
    <Popover className="relative items-center font-circular">
      <Popover.Button className="flex focus:outline-none">
        <div className="flex flex-row gap-x-2 h-8 bg-buttercream-frosting items-center rounded-md border border-buttercream-frosting-100 hover:cursor-pointer px-3 hover:bg-buttercream-frosting-100 hover:bg-opacity-50 duration-150">
          <h1 className="font-light text-licorice-noir w-full flex flex-row gap-x-1">
            Tags: <b className="font-medium">{currentTags.length}</b>
          </h1>
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute flex-col gap-y-4 right-0 z-50 text-licorice-noir bg-white rounded-md border border-buttercream-frosting-100 shadow-md p-4 flex w-[24rem]">
          {() => <TagsPopoverBody />}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

/**
 * This needs to be refactored to use the GroupDataContext state and manually fetch the tags from the backend.
 * Otherwise this will not update when you change the tags here.
 */
const TagsPopoverBody = () => {
  const { curTeamId } = useValidTeamAppContext();
  const groupState = useContext(GroupDataContext);
  const taxonomyState = useContext(TaxonomyContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const [currentTags, setCurrentTags] = useState<ITag[]>([]);
  const [selectedTag, setSelectedTag] = useState<IDropDownItem | undefined>(undefined);

  const { data, loading } = useGetTagsQuery({
    variables: { teamId: curTeamId },
  });
  const [createTagMutation] = useCreateTagMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId: curTeamId } }],
  });
  const [tagGroupMutation] = useTagGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (taxonomyState && groupState.groupData) {
      setCurrentTags(taxonomyState.get(groupState.groupData.id)?.tags ?? []);
    }
  }, [taxonomyState, groupState.groupData]);

  if (loading) return <div>Loading...</div>;

  const clearTag = () => {
    setSelectedTag(undefined);
  };

  const createTag = async (item?: IDropDownItem) => {
    setSelectedTag(item);
    if (!groupState.groupData) return;

    if (item) {
      if (!data?.getTags?.some((tag) => tag.id === item.id)) {
        const toastId = toast.loading('Creating tag...');
        const createTagResponse = await createTagMutation({
          variables: { teamId: curTeamId, name: item.name },
          onCompleted: () => {
            if (!groupState.groupData) return;
            toast.dismiss(toastId);
            clearTag();
          },
        });

        if (createTagResponse.data?.createTag && groupState.groupData) {
          await tagGroupMutation({
            variables: { teamId: curTeamId, groupId: groupState.groupData!.id, tagId: createTagResponse.data.createTag.id },
            onCompleted: () => {
              taxonomyDispatch({ type: 'addTagToGroup', payload: { groupId: groupState.groupData!.id, tag: createTagResponse.data!.createTag } });
              toast.dismiss(toastId);
              clearTag();
            },
          });
        }
      } else {
        const toastId = toast.loading('Adding tag to group...');
        tagGroupMutation({
          variables: { teamId: curTeamId, groupId: groupState.groupData!.id, tagId: item.id },
          onCompleted: () => {
            taxonomyDispatch({ type: 'addTagToGroup', payload: { groupId: groupState.groupData!.id, tag: item } });
            toast.dismiss(toastId);
            clearTag();
          },
        });
      }
    }
  };

  return (
    <div id="tags-popover-body" className="flex flex-col gap-y-3">
      <div className="flex w-full">
        <ComboBox
          placeholder="Search for tag or create one..."
          comboBoxData={
            data?.getTags
              ?.filter((tag) => groupState.groupData?.id && !currentTags.some((t) => t.id === tag.id))
              .map((tag) => {
                return { ...tag, name: capitalizeFirstLetter(tag.name) };
              })
              .sort((a, b) => alphabeticalSort(a.name, b.name)) ?? []
          }
          defaultOption={true}
          selectedItem={selectedTag}
          setSelectedItem={createTag}
        />
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {currentTags
          .map((tag) => {
            return { ...tag, name: capitalizeFirstLetter(tag.name) };
          })
          .sort((a, b) => alphabeticalSort(a.name, b.name))
          .map((tag) => {
            return <TagItem tag={tag} />;
          })}
      </div>
    </div>
  );
};

export const TagItem = (props: { tag: { id: number; name: string } }) => {
  const [removeTagLoading, setRemoveTagLoading] = useState<boolean>(false);
  const { curTeamId } = useValidTeamAppContext();
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const groupState = useContext(GroupDataContext);
  const [removeTagMutation] = useRemoveTagFromGroupMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GetTagsDocument, variables: { teamId: curTeamId } }],
  });
  return (
    <div className="flex-none">
      <Badge
        color="bg-buttercream-frosting-100"
        textColor="text-licorice-noir"
        key={props.tag.id}
        capitalize={true}
        badge={{ id: props.tag.id.toString(), text: props.tag.name }}
        onRemove={() => {
          const toastId = toast.loading('Removing tag...');
          setRemoveTagLoading(true);
          removeTagMutation({
            variables: { teamId: curTeamId, groupId: groupState.groupData!.id, tagId: props.tag.id },
            onCompleted: () => {
              taxonomyDispatch({ type: 'removeTagFromGroup', payload: { groupId: groupState.groupData!.id, tag: props.tag } });
              toast.dismiss(toastId);
              setRemoveTagLoading(false);
            },
          });
        }}
        loading={removeTagLoading}
      />
    </div>
  );
};
