import { useParams } from "react-router-dom";
import { useGetFeedbackIntegrationsQuery, useSegmentGroupQuery } from "../../generated/graphql";
import { useValidTeamAppContext } from "../../v2/contexts/AppContext";
import LoadingSpinner from "../baseComponents/LoadingSpinner";
import { FeedbackIntegrationProvider } from "../context/FeedbackIntegrationContext";
import { IntegrationSettingsPageContent } from "../components/integrationsSettings/IntegrationSettingsPageContent";
import { FeedbackIntegrationState } from "../reducers/feedbackIntegration";
import { FeedbackIntegrationFragment } from "../../generated/graphql";

interface IntegrationSettingsPageProps {
  pageName: string;
}

export default function IntegrationSettingsPage (props: IntegrationSettingsPageProps) {
    
    const { integrationId } = useParams();
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    
    const { data: feedbackIntegrationData, loading: feedbackIntegrationLoading, error: feedbackIntegrationError, refetch: refetchFeedbackIntegration } = useGetFeedbackIntegrationsQuery({
        variables: { teamId: teamId, integrationId: parseInt(integrationId!)},
    });
    const { data: segmentGroupsData, loading: segmentGroupsLoading, error: segmentGroupsError } = useSegmentGroupQuery({
        variables: { teamId: teamId, aiGenerated: false},
    });

    const refreshData = async () => {
        const [integrationResult] = await Promise.all([
            refetchFeedbackIntegration(),
        ]);

        // Get the new integration data
        const newIntegration = integrationResult.data?.feedbackIntegration?.[0];
        return { newIntegration: newIntegration ? createFeedbackIntegrationState(newIntegration) : null };
    };

    const loading = feedbackIntegrationLoading || 
                   segmentGroupsLoading;

    return (
        (loading ? 
            <div className="fixed inset-0 flex flex-col items-center justify-center">
                <LoadingSpinner/> 
                <div className="text-gray-secondary mt-4">Loading integration configuration, and pulling metadata...</div>
            </div> :
            (!feedbackIntegrationData || !feedbackIntegrationData.feedbackIntegration || !segmentGroupsData) ? <div>No data</div> :
                <FeedbackIntegrationProvider 
                    initialFeedbackIntegration={createFeedbackIntegrationState(feedbackIntegrationData.feedbackIntegration[0])} 
                    // We keep the old integration state for when we discard changes, and to keep track of true changes to the integration.
                    initialOldFeedbackIntegration={createFeedbackIntegrationState(feedbackIntegrationData.feedbackIntegration[0])}
                    initialSegmentGroups={segmentGroupsData}
                    initialDataSourceFields={[]}
                > 
                    <IntegrationSettingsPageContent 
                        onSaveSuccess={refreshData} 
                    />
                </FeedbackIntegrationProvider>
        )
    );
};

function createFeedbackIntegrationState(feedbackIntegration: FeedbackIntegrationFragment): FeedbackIntegrationState {
    return {
        id: feedbackIntegration.id,
        integrationName: feedbackIntegration.integrationName,
        filters: feedbackIntegration.filters,
        requirementValues: feedbackIntegration.requirementValue.map((requirement) => {return {
            value: requirement.value,
            requirement: {
                id: requirement.requirement.id,
                key: requirement.requirement.key
            },
            valueId: requirement.valueId,
            
        }}),
        segmentConfig: feedbackIntegration.segmentConfig,
        defaultDistillateDisplay: feedbackIntegration.defaultDistillateDisplay,
        type: feedbackIntegration.type,
        integrationTypeRequirements: feedbackIntegration.requirements.requirements
    };
}

