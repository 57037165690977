import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { SelectorButton } from './SelectorButton';
import { useRef } from 'react';
import { truncateAndEllipsis } from '../../../../v2/util';
import { Option } from './SelectorDropdown';

export type Interval = 'auto' | 'daily' | 'weekly' | 'monthly' | 'quarterly';

export const intervalOptionsAndLabels: { [K in Interval]: string } = {
  auto: 'Auto',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
};

export const intervalOptions: Option<Interval>[] = Object.entries(intervalOptionsAndLabels).map(([key, value]) => ({ value: key as Interval, label: value }));
export interface IIntervalSelectorProps {
  selectedInterval: Option<Interval>;
  isIntervalSelectorModalOpen: boolean;
  openIntervalSelectorModal: () => void;
  closeIntervalSelectorModal: () => void;
}

export const IntervalSelector = ({
  selectedInterval,
  openIntervalSelectorModal,
  isIntervalSelectorModalOpen,
  closeIntervalSelectorModal,
}: IIntervalSelectorProps) => {
  const intervalSelectorButtonRef = useRef<HTMLDivElement>(null);

  return (
    <SelectorButton onClick={isIntervalSelectorModalOpen ? closeIntervalSelectorModal : openIntervalSelectorModal} ref={intervalSelectorButtonRef}>
      <div className="flex flex-row gap-x-2 justify-start items-center w-full">
        <div className="text-gray-secondary xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">Interval:</div>
        <div className="text-licorice-noir font-medium xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">{truncateAndEllipsis(selectedInterval.label, 20)}</div>
        <Cog6ToothIcon className={`h-4 w-4 ${isIntervalSelectorModalOpen ? 'text-blood-orange-sorbet' : 'text-gray-secondary'}`} />
      </div>
    </SelectorButton>
  );
};
