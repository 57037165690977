//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { UserContextProvider } from './v2/contexts/UserContext';
import { AppContextProvider } from './v2/contexts/AppContext';
import mixpanel from 'mixpanel-browser';
import { initTelemetry } from './applicationTelemetry';
import { trackUserActions } from './latencyTracker';
import { configureAmplifyAuth, STANDARD_ISSUER } from './AuthConfigs';
// tw-elements is actually used don't remove it
import 'tw-elements';
import { isDevMode } from './v2/AnalyticsUtil';
import { client } from './client';
import { UnauthorizedModal } from './v3/components/Modals/AuthorizationModal';

//The ResizeObserver loop limit exceeded warning is a known, non-breaking warning that is thrown only on local and might depend on your browser's extensions.
//There is no clear solution and it's widely suggested to safely ignore it, as it doesn't affect production users.
const suppressedErrors = /(ResizeObserver loop completed with undelivered notifications)/;

const originalError = console.error;
console.error = (...args) => {
  if (typeof args[0] === 'string' && args[0].match(suppressedErrors)) {
    return;
  }
  originalError.apply(console, args);
};

initTelemetry();

if (!window.location.pathname.includes('/auth/session/')) {
  configureAmplifyAuth(STANDARD_ISSUER);
}

trackUserActions();

if (!isDevMode) {
  // MIXPANEL
  mixpanel.init('dd52785f537781c3f5b474cd7c0a79b1', {
    debug: false,
    api_host: 'https://tracking.api.production.unwrap.ai',
    api_payload_format: 'json',
    verbose: false,
    /** Turning off request batching because it looks like this module is sending some partial payloads to our api which is causing issues. */
    batch_requests: false,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <UserContextProvider>
          <App />
          <UnauthorizedModal />
        </UserContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
