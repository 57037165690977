import { ClockIcon, EyeIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { ExternalTicketData } from '../../../../generated/graphql';
import { capitalizeFirstLetter, localDateString, truncateAndEllipsis } from '../../../../v2/util';

//Preview of a ticket while browsing, before it's linked
export const TicketPreview = ({
  ticket,
  integrationLogo,
  integrationTitle,
}: {
  ticket: ExternalTicketData;
  integrationLogo: string;
  integrationTitle: string;
}) => (
  <a
    href={ticket.sourcePermalink}
    target="_blank"
    className="flex flex-row cursor-pointer items-center gap-x-2 bg-buttercream-frosting hover:bg-licorice-noir-lighter hover:text-white px-2 py-2 rounded-md duration-100 w-full"
    rel="noreferrer"
  >
    <div className="flex flex-col gap-y-1">
      <div className="flex flex-row gap-x-2 items-center">
        <h1 className="font-medium">{ticket.name}</h1>
      </div>
      <div className="flex flex-row gap-x-3 items-center">
        <img src={integrationLogo} alt={integrationTitle} className="w-3 h-3" />
        <div className="flex flex-row items-center gap-x-0.5">
          <EyeIcon className="h-4 w-4" />
          <p className="text-xs">{capitalizeFirstLetter(ticket.status)}</p>
        </div>
        <div className="flex flex-row items-center gap-x-0.5">
          <UserCircleIcon className="h-4 w-4" />
          <p className="text-xs">{truncateAndEllipsis(ticket.assignee, 30)}</p>
        </div>
        {ticket.dueDate && (
          <div className="flex flex-row items-center gap-x-0.5 ">
            <ClockIcon className="h-4 w-4" />
            <p className="text-xs">{localDateString(new Date(ticket.dueDate))}</p>
          </div>
        )}
      </div>
    </div>
  </a>
);
