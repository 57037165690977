import { useEffect, useState } from 'react';
import {
  Domain,
  OrganizationUserFragment,
  TeamDataFragment,
  useCreateTeamMutation,
  useEditTeamMutation,
} from '../../../generated/graphql';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import DualToneModal from '../../baseComponents/DualToneModal';
import { UsersPermissionsEditingSection } from '../orgInfo/UsersPermissionsEditorSection';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../context/orgInfoContext';
import toast from 'react-hot-toast';
import { OrgInfoActionTypes } from '../../../reducers/orgInfo/orgInfoReducer';
import { PermissionsProvider, usePermissionsState } from '../../../context/roleAssignmentContext';
import { permissionMapToAssignedRolesForAView } from '../../../reducers/orgInfo/permissions/permissionsReducer';
import _ from 'lodash';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';

interface EditViewModalProps {
  view?: TeamDataFragment;
  users: OrganizationUserFragment[];
  modalOpen: boolean;
  callbackModal: () => void;
}
/**
 * If no view is passed, it will create a new view. If a view is passed, it will edit the view.
 * @returns
 */
export const EditViewModal = ({ view, users, modalOpen, callbackModal }: EditViewModalProps) => {
  return (
    <PermissionsProvider>
      <EditViewModalBody view={view} users={users} modalOpen={modalOpen} callbackModal={callbackModal} />
    </PermissionsProvider>
  );
};

const EditViewModalBody = ({ view, users, modalOpen, callbackModal }: EditViewModalProps) => {
  const { organization } = useOrgInfoState();
  const dispatch = useOrgInfoDispatch();
  const { updateOrg, addTeamToOrg } = useValidTeamAppContext();
  const { permissionsMap } = usePermissionsState();
  const [viewNameInput, setViewNameInput] = useState<string>(view?.name ?? '');

  const [createViewMutation, createViewMutationRes] = useCreateTeamMutation();
  const [editViewMutation, editViewMutationRes] = useEditTeamMutation();

  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {

    setFormDisabled(createViewMutationRes.loading || editViewMutationRes.loading || viewNameInput.length === 0);

  }, [createViewMutationRes.loading, viewNameInput.length, permissionsMap]);

  const handleCreateView = async () => {
    const assignedRoles = permissionMapToAssignedRolesForAView(permissionsMap);
    await createViewMutation({
      variables: {
        orgId: organization.id,
        name: viewNameInput,
        usersRoles: assignedRoles,
      },
      onCompleted: (data) => {
        const newView = data.createTeam;
        if (!newView) return;

        const updates: { userId: number; updatedMember: Partial<OrganizationUserFragment> }[] = [];

        const groupedRoles = _.groupBy(newView.roles, 'userId');
        for (const [userId, roles] of Object.entries(groupedRoles)) {
          const orgUser = organization.orgUser.find((ou) => ou.user?.id === Number(userId));
          if (!orgUser?.user) continue;
          const updatedMember: OrganizationUserFragment = {
            ...orgUser,
            user: {
              ...orgUser.user,
              roles,
            },
          };
          updates.push({ userId: Number(userId), updatedMember });
        }
        addTeamToOrg(newView);
        dispatch({ type: OrgInfoActionTypes.EditMembers, payload: { updates } });
        dispatch({ type: OrgInfoActionTypes.AddView, payload: { view: newView } });
        callbackModal();
      },
      onError: (error) => {
        toast.error(`Error creating view: ${error.message}`);
        console.error(error);
      },
    });
  };

  const handleEditView = async () => {
    if (!view) return;
    const assignedRoles = permissionMapToAssignedRolesForAView(permissionsMap);
    await editViewMutation({
      variables: {
        orgId: organization.id,
        teamId: view.id,
        name: viewNameInput,
        userRoles: assignedRoles,
      },
      onCompleted: (data) => {
        const updatedView = data.editTeam;
        if (!updatedView) return;

        const updates: { userId: number; updatedMember: Partial<OrganizationUserFragment> }[] = [];

        //This is janky, but we need to keep the roles on users updated when we make changes to views...
        const groupedRoles = _.groupBy(updatedView.roles, 'userId');
        for (const [userId, newRoles] of Object.entries(groupedRoles)) {
          const orgUser = organization.orgUser.find((ou) => ou.user?.id === Number(userId));
          if (!orgUser?.user) continue;
          const existingRoles = orgUser.user.roles.filter((r) => r.domain !== Domain.Team || r.domainId !== updatedView.id);
          const updatedMember: OrganizationUserFragment = {
            ...orgUser,
            user: {
              ...orgUser.user,
              roles: [
                ...existingRoles,
                ...newRoles,
              ],
            },
          };
          updates.push({ userId: Number(userId), updatedMember });
        }

        dispatch({ type: OrgInfoActionTypes.EditMembers, payload: { updates } });
        if (updatedView) dispatch({ type: OrgInfoActionTypes.EditView, payload: { viewId: view.id, updatedView } });
        callbackModal();
      },
      onError: (error) => {
        toast.error('Error editing view');
        console.error(error);
      },
    });
  };
  const getFormButtons = () => {
    //This could be inside the Modal and params passed to it...
    return (
      <div className="mt-4 flex flex-row justify-between gap-x-4 text-center w-full">
        <div className="flex w-1/3">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={callbackModal} text="Cancel" expandWidth></Button>
        </div>
        <div className="flex w-2/3">
          <Button
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            text={view ? 'Save changes' : 'Create view'}
            loadingConfirm={createViewMutationRes.loading || editViewMutationRes.loading}
            disabled={formDisabled}
            onClick={view ? handleEditView : handleCreateView}
            expandWidth
          ></Button>
        </div>
      </div>
    );
  };
  return (
    <DualToneModal
      open={modalOpen}
      setOpen={callbackModal}
      headerChildren={
        <div className="flex flex-col items-center text-center gap-y-5">
          {view ? (
            <div className="gap-x-2">
              <p className="text-3xl font-medium text-gray-tertiary">Edit view</p>
              <h1 className="text-3xl text-licorice-noir font-medium line-clamp-1">{view.name}</h1>
            </div>
          ) : (
            <h1 className="text-3xl font-medium">Create a new View</h1>
          )}
        </div>
      }
      bodyChildren={
        <div className="flex flex-col justify-between text-licorice-noir min-h-[33rem] w-full">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-1">
              <h1 className="text-sm">View name</h1>
              <input
                autoComplete="off"
                aria-autocomplete="none"
                className="block w-full appearance-none rounded-md border border-buttercream-frosting-300 px-3 py-2 placeholder-gray-tertiary focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                onChange={(e) => setViewNameInput(e.target.value)}
                value={viewNameInput}
                data-testid="view-name-input"
              />
            </div>
            <UsersPermissionsEditingSection view={view} />
          </div>
          {getFormButtons()}
        </div>
      }
    />
  );
};
