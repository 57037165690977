/* change password component */

export default function InfoCard({ title, data }: { title: string; data: string | JSX.Element | JSX.Element[] }) {
  return (
    <div className="bg-buttercream-frosting border border-buttercream-frosting-100 rounded-lg text-licorice-noir">
      <div className="px-3 py-4 sm:p-5">
        <h3 className="text-xl font-recoleta leading-6">{title}</h3>
        <div className="text-md mt-2 max-w-xl font-light text-gray-secondary">{data}</div>
      </div>
    </div>
  );
}
