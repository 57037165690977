import { DataSeriesConfiguration } from '../../../generated/graphql';
import { DataViewCard } from './DataViewCard';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { useEffect, useMemo, useRef } from 'react';
import { useChartDispatch, useChartState } from '../../../context/chartContext';
import { ChartActionType } from '../../../reducers/charts/chartReducer';

interface IDataViewSectionProps {
  dataSeriesConfiguration: DataSeriesConfiguration[];
  defaultTeamId: number;
  teamsList: IDropDownItem[];
  addDataView: () => void;
  isLoading?: boolean;
  disableBreakdown: boolean;
}

export const DataViewSection = ({ dataSeriesConfiguration, defaultTeamId, teamsList, addDataView, isLoading, disableBreakdown }: IDataViewSectionProps) => {
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();

  const debouncedUpdateRefs = useRef<{ [key: number]: NodeJS.Timeout }>({});

  useEffect(() => {
    if (!disableBreakdown) {
      chartState.chartConfigs?.series.forEach((_, index) => {
        chartDispatch({
          type: ChartActionType.SetSeriesCustomName,
          payload: { index, customName: '' },
        });
      });
    }
  }, [disableBreakdown]);

  const handleNameInput = (index: number, newName: string) => {
    if (!disableBreakdown) return;

    chartDispatch({
      type: ChartActionType.SetSeriesCustomName,
      payload: { index, customName: newName },
    });
  };

  const handleSeriesRemove = (index: number) => {
    if (debouncedUpdateRefs.current[index]) {
      clearTimeout(debouncedUpdateRefs.current[index]);
      delete debouncedUpdateRefs.current[index];
    }

    chartDispatch({ type: ChartActionType.RemoveSeries, payload: { index } });
  };

  useEffect(() => {
    return () => {
      Object.values(debouncedUpdateRefs.current).forEach((timeout) => clearTimeout(timeout));
      debouncedUpdateRefs.current = {};
    };
  }, []);

  const inheritedDataView = useMemo(
    () => (
      <DataViewCard
        key={`data-view-section`}
        index={0}
        name={dataSeriesConfiguration[0].customName ?? ''}
        teamsList={teamsList}
        teamId={dataSeriesConfiguration[0]?.teamIdOverride ?? defaultTeamId}
        dataSeries={dataSeriesConfiguration[0]}
        onNameInput={(newName: string) => handleNameInput(0, newName)}
        onSeriesRemove={() => handleSeriesRemove(0)}
        breakdownDisabled={true}
        isLoading={isLoading}
        hasNameEditor={dataSeriesConfiguration.length > 1}
      />
    ),
    [teamsList, defaultTeamId, dataSeriesConfiguration[0]]
  );

  const dataViews = useMemo(
    () =>
      dataSeriesConfiguration.slice(1).map((dataSeries, index) => {
        const dataViewIndex = index + 1;
        const teamId = dataSeries.teamIdOverride ?? defaultTeamId;

        return (
          <DataViewCard
            key={`data-view-${dataViewIndex}`}
            index={dataViewIndex}
            teamId={teamId}
            teamsList={teamsList}
            name={dataSeries.customName ?? ''}
            dataSeries={dataSeries}
            onNameInput={(newName: string) => handleNameInput(dataViewIndex, newName)}
            onSeriesRemove={() => handleSeriesRemove(dataViewIndex)}
            canBeDeleted={true}
            isLoading={isLoading}
            hasNameEditor={dataSeriesConfiguration.length > 1}
          />
        );
      }),
    [dataSeriesConfiguration, defaultTeamId, teamsList, isLoading]
  );

  const AddDataViewButton = (
    <button
      onClick={addDataView}
      className=" text-licorice-noir font-medium text-sm bg-buttercream-frosting border border-licorice-noir rounded-md px-5 py-1 hover:bg-licorice-noir hover:text-white w-fit whitespace-nowrap h-[36px] transition-all duration-[200ms]"
    >
      + Add data view
    </button>
  );

  return (
    <div className="flex flex-col w-full gap-y-3">
      {inheritedDataView ?? null}
      {dataViews}
      {AddDataViewButton}
    </div>
  );
};
