import { useState, useEffect } from 'react';
import logoUnwrap from '../../../assets/unwrap_logo_MAIN.png';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { InviteLinkStatus, useEmailExistsLazyQuery, useGetInviteLinkStatusLazyQuery } from '../../../generated/graphql';
import { logError } from '../../../applicationTelemetry';
import { Toaster, toast } from 'react-hot-toast';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { getAuthConfigByEmailDomain } from '../../../AuthConfigs';
import { VerificationStep } from '../../components/auth/VerificationStep';
import ReCAPTCHA from 'react-google-recaptcha';

const errorMsgs = {
  emailInUse: 'Email already in use',
  unknown: 'Unknown error.',
};

export default function SignUpPage() {
  const [emailInput, setEmailInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [getInviteLinkStatus] = useGetInviteLinkStatusLazyQuery();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const inviteLink = queryParams.get('linkHash');
  const [verifying, setVerifying] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);

  let navigate = useNavigate();

  useEffect(() => {
    // check if there's an inviteLink
    if (inviteLink) {
      getInviteLinkStatus({
        variables: { inviteLinkHash: inviteLink },
        onCompleted: (data) => {
          if (data.getInviteLinkStatus !== InviteLinkStatus.Valid) {
            toast.error('Invite link is Invalid or Expired please contact your administrator.');
          }
        },
        onError: (err) => console.error(err),
      });
    }
    const signupEmail = queryParams.get('email');
    if (signupEmail) setEmailInput(signupEmail);
  }, []);

  const signupWithGoogle = async () => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: window.location.search });
  };

  const signupWithEmail = async () => {
    const externalIDP = getAuthConfigByEmailDomain(emailInput.toLowerCase())?.externalIDP;
    if (externalIDP) {
      setErrorMsg(`Error: Your organization is managed through ${externalIDP}. Please sign in through your ${externalIDP} SSO portal.`);
      return;
    }
    setLoading(true);
    const emailLower = emailInput.toLowerCase();
    try {
      /**
       * Notes for future developers:
       * validationData is the way to pass through arbitrary data to the lambda trigger (It'd be nice if they documented this :smh:)
       * In the past I had to make a custom attribute 'custom:signInLink' to pass through the invite link info which is used in the lambda trigger,
       * however this is a pain in the butt because you have to modify the actual user pool to be able to do this and this attribute will be stored with the user forever which isn't necessary.
       */
      await Auth.signUp({
        username: emailLower,
        password: passwordInput,
        validationData: {
          captchaToken: captchaToken,
        },
        attributes: {
          given_name: firstNameInput,
          family_name: lastNameInput,
          'custom:signInLink': inviteLink,
        },
      });
      setErrorMsg(undefined);
      setVerifying(true);
    } catch (err: any) {
      logError(err);
      if (err.response?.status === 422) {
        setErrorMsg(errorMsgs.emailInUse);
      } else {
        setErrorMsg(err.message);
      }
    }
    setLoading(false);
  };

  const onCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <div className="flex min-h-screen bg-white font-circular">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
          className: '',
          style: {
            border: '1px solid white',
            fontFamily: 'sofiapro',
            backgroundColor: '#1D1E30',

            color: 'white',
            borderRadius: '1rem',
            minWidth: '8%',
          },
        }}
      />
      <div className="flex flex-1 flex-col justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-10 w-auto" src={logoUnwrap} alt="Workflow" />
            {/* <h2 className="mt-6 text-2xl font-medium text-gray-secondary">Create a new account</h2> */}
          </div>
          <div className="mt-10">
            <div className="mt-6">
              {verifying ? (
                <VerificationStep email={emailInput} password={passwordInput} />
              ) : (
                <form
                  className="space-y-6"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    e.currentTarget.reset();
                    await signupWithEmail();
                  }}
                >
                  <div>
                    <label htmlFor="email" className="block text-sm font-light text-gray-secondary">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={emailInput}
                        className="block w-full appearance-none rounded-md border border-buttercream-frosting-100 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                        onChange={(e) => setEmailInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-light text-gray-secondary">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-buttercream-frosting-100 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                        onChange={(e) => setPasswordInput(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-light text-gray-secondary">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        name="first name"
                        required
                        className="block w-full appearance-none rounded-md border border-buttercream-frosting-100 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                        onChange={(e) => setFirstNameInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-light text-gray-secondary">
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        name="last name"
                        className="block w-full appearance-none rounded-md border border-buttercream-frosting-100 px-3 py-2 placeholder-gray-tertiary shadow-sm focus:border-licorice-noir focus:outline-none focus:ring-licorice-noir sm:text-sm"
                        onChange={(e) => setLastNameInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <ReCAPTCHA sitekey="6LcFuxwqAAAAAH4oSqH2BScCV8X1dPpM4bWwbuaQ" onChange={onCaptchaChange} />
                  </div>

                  {errorMsg && (
                    <div className="mt-4">
                      <h1 id="error-message" className="text-sm font-medium text-red-600">
                        {errorMsg}
                      </h1>
                    </div>
                  )}

                  <div className="flex flex-col gap-y-2">
                    <Button
                      id="sign-up-button"
                      variant={ButtonVariant.Secondary}
                      text={'Create my account'}
                      submit
                      loadingConfirm={loading}
                      expandWidth
                      disabled={!captchaToken}
                    />
                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center gap-x-5 rounded-md border border-buttercream-frosting-300 bg-white py-2 px-4 text-md font-medium text-licorice-noir shadow-sm hover:bg-gray-50"
                        onClick={() => signupWithGoogle()}
                      >
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
                          <defs>
                            <path
                              id="a"
                              d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                            />
                          </defs>
                          <clipPath id="b">
                            <use xlinkHref="#a" overflow="visible" />
                          </clipPath>
                          <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
                          <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
                          <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
                          <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
                        </svg>
                        <span>Create account with Google</span>
                      </a>
                    </div>
                    <div id="terms" className="flex justify-center py-3">
                      <p className="text-sm text-center">
                        By creating an account, you agree to <br />
                        our{' '}
                        <a href="https://www.unwrap.ai/legal/terms-of-service" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                          terms of service
                        </a>{' '}
                        and our{' '}
                        <a href="https://www.unwrap.ai/legal/privacy-policy" target="_blank" style={{ textDecoration: 'underline' }}>
                          privacy policy
                        </a>
                        .
                      </p>
                    </div>
                    <Button
                      variant={ButtonVariant.Primary}
                      text={'Existing user? Go to login page'}
                      expandWidth
                      onClick={() => {
                        navigate('/login' + window.location.search);
                      }}
                      preventDefault
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
