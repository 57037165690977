import { useContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterContext } from '../../context/filterStatementContext';
import {
  Group_Status,
  TaxonomyTreeNode,
  useAmountOfGroupsLazyQuery,
  useGroupSearchLazyQuery,
  useTeamGroupsTaxonomyFlatLazyQuery,
  useTeamGroupsTaxonomyTreeLazyQuery,
} from '../../generated/graphql';
import { getTotalPageLoadEvent } from '../../latencyTracker';
import { AppRoutes } from '../../Routes';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { GroupBase, GroupFull } from '../../v2/hooks/GroupHook';
import { TaxonomyAction } from '../actions/taxonomy';
import DateFilterComponent from '../components/filters/filterBar/DateFilterComponent';
import { GroupSemanticSearchResults } from '../components/GroupSemanticSearchResults';
import SearchPreviewModal from '../components/Search/SearchPreviewModal';
import SearchSection from '../components/Search/SearchSection';
import { GroupCount } from '../components/taxonomy/GroupCount';
import { GroupDeleter } from '../components/taxonomy/GroupDeleter';
import { TaxonomyView } from '../components/TaxonomyView';
import { SearchPreviewContext } from '../context/SearchPreviewContext';
import { SearchPreviewDispatchContext } from '../context/SearchPreviewDispatchContext';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { ExplorePageView } from '../hooks/ExploreGroupHook';
import { FilterTree } from '../lib/filterTree';
import { defaultGroup } from '../lib/searchPreview';
import { toTaxonomyMap } from '../lib/taxonomy';
import { searchPreviewReducer, SearchPreviewReducerResult } from '../reducers/searchPreview';
import { pageSize } from './explore/ExplorePageRouter';
import { PageWrapper } from './PageWrapper';
import FilterBarWrapper from '../components/filters/filterBar/FilterBarWrapper';
import { TaxonomyChildrenToggle } from '../components/TaxonomyChildrenToggle';
import { TaxonomyFolderSkeleton } from '../components/taxonomy/TaxonomyFolderSkeleton';

export enum GroupUIType {
  Card,
  Modal,
  PreviewPage,
}

interface ExplorePageProps {
  pageName: string;
}

const hasGroupLevelFilter = (filterConsumable: string): boolean => {
  if (!filterConsumable) return false;
  const filterTree = FilterTree.fromConsumable(filterConsumable);
  const groupFilters = filterTree.getAppliedFields().filter((field) => field.includes('Group'));
  return groupFilters.length > 0;
};

export const ExplorePage = ({ pageName }: ExplorePageProps) => {
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const [hasParents, setHasParents] = useState(false);

  const [searchPreview, previewDispatch] = useReducer(searchPreviewReducer, {
    group: defaultGroup,
    belongingIds: [],
    excludedIds: [],
    searchTerm: '',
    searchInput: '',
    excludeFromNewFilter: false,
  } as SearchPreviewReducerResult);

  const dispatch: React.Dispatch<TaxonomyAction> = useContext(TaxonomyDispatchContext);
  const taxonomy = useContext(TaxonomyContext);

  useEffect(() => {
    setHasParents(Array.from(taxonomy.values()).some((item) => item.parentId !== null && item.parentId !== undefined));
  }, [taxonomy]);

  const [view, setView] = useState<ExplorePageView>(ExplorePageView.Taxonomy);
  const [announcementId, setAnnouncementId] = useState<string | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<string | null>(null);
  const location = useLocation();

  // if we have an active group level filter (tags, linked actions, trendingnew, etc), we want to show the flat view

  const [getAmountOfGroups, amountOfGroups] = useAmountOfGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: teamId, status: Group_Status.Monitored },
  });

  const filterState = useContext(FilterContext);
  const groupLevelFiltersActive = hasGroupLevelFilter(filterState.filterConsumable);

  const [groupSearch, { loading: searchLoading }] = useGroupSearchLazyQuery({
    variables: { teamId, searchString: searchPreview.searchTerm, filterStatement: filterState.filterConsumable },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getAmountOfGroups();
  }, [teamId]);

  useEffect(() => {
    if (location.pathname === AppRoutes.v3FullPath.explore) {
      const savedPosition = sessionStorage.getItem('savedScrollPosition');
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('savedScrollPosition');
      }
    }
  }, [location]);

  const [loadTaxonomyTree, { loading: loadingTree }] = useTeamGroupsTaxonomyTreeLazyQuery({
    variables: { teamId, filterStatement: filterState.filterConsumable, take: 10000, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'loadTaxonomy',
        payload: data,
      };
      dispatch(action);
      // This event is used to track the total page load time (including how long it takes to fetch the taxonomy)
      const event = getTotalPageLoadEvent({ view: 'taxonomy' });
      window.dispatchEvent(event);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  const [loadTaxonomyFlat, { loading: loadingFlat }] = useTeamGroupsTaxonomyFlatLazyQuery({
    variables: { teamId, filterStatement: filterState.filterConsumable, take: 10000, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'loadFlatTaxonomy',
        payload: data,
      };
      dispatch(action);
      const event = getTotalPageLoadEvent({ view: 'taxonomyFlat' });
      window.dispatchEvent(event);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  /**
   * This useEffect handles view switching based on the search term
   */
  useEffect(() => {
    // if there is no search term, we want to load the taxonomy view
    if (searchPreview.searchTerm === '') {
      // this handles the case where the user switches to the taxonomy / flat view via the toggle with a search term entered.
      // this action will set the search term to '', but we don't want to re-init the view.
      if (view === ExplorePageView.Taxonomy) {
        return;
      }

      setView(ExplorePageView.Taxonomy);
      return;
    }

    // if there is a search term and we are not already on the search view, we want to switch to it
    if (view === ExplorePageView.Taxonomy) {
      setView(ExplorePageView.Search);
      return;
    }

    // if there is a search term and we are already on the search view, we want to re-search
    if (view === ExplorePageView.Search) {
      groupSearch({
        variables: { teamId, searchString: searchPreview.searchTerm, filterStatement: filterState.filterConsumable },
        onCompleted: (data) => {
          const taxonomy = toTaxonomyMap(data.groupSearch as TaxonomyTreeNode[]);

          dispatch({
            type: 'setTaxonomy',
            payload: { taxonomy },
          });
        },
        onError: (error: Error) => {
          toast('Error loading taxonomy', { icon: 'error' });
          console.error(error);
        },
      });

      return;
    }
  }, [searchPreview.searchTerm]);

  // this useEffect handles initial loading of each view
  useEffect(() => {
    switch (view) {
      case ExplorePageView.Taxonomy:
        previewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
        if (groupLevelFiltersActive) {
          loadTaxonomyFlat({
            variables: {
              teamId,
              filterStatement: filterState.filterConsumable,
              take: pageSize,
              skip: 0,
            },
          });
        } else {
          loadTaxonomyTree({
            variables: {
              teamId,
              filterStatement: filterState.filterConsumable,
              take: pageSize,
              skip: 0,
            },
          });
        }
        break;
      case ExplorePageView.Search: {
        groupSearch({
          variables: { teamId, searchString: searchPreview.searchTerm, filterStatement: filterState.filterConsumable },
          onCompleted: (data) => {
            const taxonomy = toTaxonomyMap(data.groupSearch as TaxonomyTreeNode[]);
            dispatch({
              type: 'setTaxonomy',
              payload: { taxonomy },
            });
          },
          onError: (error: Error) => {
            toast('Error loading taxonomy', { icon: 'error' });
            console.error(error);
          },
        });
        break;
      }
      default:
        throw new Error('View type not supported');
    }
  }, [view, teamId, filterState.filterConsumable]);

  const loading = searchLoading || loadingTree || loadingFlat;

  const navigate = useNavigate();

  return (
    <SearchPreviewContext.Provider value={searchPreview}>
      <SearchPreviewDispatchContext.Provider value={previewDispatch}>
        {curGroupIdToDelete !== null ? (
          <GroupDeleter
            groupToDelete={curGroupIdToDelete}
            closeCallback={() => {
              setCurGroupIdToDelete(null);
            }}
            deleteCallback={() => {
              navigate('/dashboard/explore', { replace: true });
            }}
          />
        ) : null}

        <PageWrapper title={'Explore'} styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
          <div>
            <div className={`flex flex-col items-center justify-center`}>
              <div className="flex w-4/5 flex-col gap-y-1 mt-4 mb-6">
                <SearchSection />
                <SearchPreviewModal />
              </div>
            </div>
          </div>

          {/*  <div className="flex cursor-default relative flex-col text-licorice-noir">
            <h1 className="text-3xl font-recoleta">Groups</h1>
          </div> */}
          <div className="flex flex-col items-center justify-center gap-y-2 mt-4">
            <div className="flex flex-col-reverse w-4/5 flex-wrap md:flex-nowrap md:flex-row md:items-center items-start gap-x-5 gap-y-2 justify-between ">
              <FilterBarWrapper />
              <div className="flex-1 h-[1px] bg-buttercream-frosting-100 rounded-md" /> {/* Added vertical divider */}
              <DateFilterComponent teamId={teamId} />
            </div>
            <div className="flex flex-row w-4/5 justify-between items-center">
              <GroupCount loading={loading} filteredGroupCount={taxonomy.size} amountOfGroups={amountOfGroups.data?.amountOfGroups.amount} />
              <div className="flex justify-end">{hasParents && view === ExplorePageView.Taxonomy ? <TaxonomyChildrenToggle /> : null}</div>
            </div>

            <div className={`w-4/5 flex-col items-center gap-y-4`}>
              {view === ExplorePageView.Taxonomy ? (
                loading ? (
                  <div className="flex flex-col gap-y-4">
                    {[...Array(20)].map((_, index) => (
                      <TaxonomyFolderSkeleton key={index} flatGroup={groupLevelFiltersActive} depth={0} />
                    ))}
                  </div>
                ) : (
                  <>
                    <TaxonomyView
                      flatView={groupLevelFiltersActive}
                      pageName={pageName}
                      discardSearchGroup={(groupId) => {
                        setCurGroupIdToDelete(groupId);
                      }}
                      //@ts-ignore
                      openAnnouncementModal={(id) => setAnnouncementId(id)}
                    />
                  </>
                )
              ) : view === ExplorePageView.Search ? (
                loading ? (
                  <div className="flex flex-col gap-y-4">
                    {[...Array(5)].map((_, index) => (
                      <TaxonomyFolderSkeleton key={index} flatGroup={true} depth={0} />
                    ))}
                  </div>
                ) : (
                  <>
                    <GroupSemanticSearchResults setCurrentGroupId={(id) => setCurGroupIdToDelete(id)} />
                  </>
                )
              ) : null}
            </div>
          </div>
        </PageWrapper>
      </SearchPreviewDispatchContext.Provider>
    </SearchPreviewContext.Provider>
  );
};
