import React, { FC } from 'react';
import { SelectorButton } from './SelectorButton';

export interface IXAxisSelectorProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const XAxisSelector: FC<IXAxisSelectorProps> = ({ children, onClick, disabled = false }) => {
  return (
    <div className="h-[32px]">
      <SelectorButton onClick={onClick} disabled={disabled}>
        {children}
      </SelectorButton>
    </div>
  );
};
