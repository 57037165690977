import { EqualityType, FilterType, GenericFilterType, ValueType } from '../../../generated/graphql';
import Button, { ButtonVariant, ButtonSize, ButtonShape, ButtonState } from '../../baseComponents/Button';
import { FilterHook } from '../../hooks/FilterHook';
const TrendingNewButton = ({ filterHook }: { filterHook: FilterHook }) => {
  const hasTrendingNewFilter = filterHook.filters.filterSet?.filterables?.some((filterable) =>
    filterable.filters.some((filter) => filter.filterType === GenericFilterType.TrendingNew)
  );
  const toggleFilters = () => {
    filterHook.setFilters({
      ...filterHook.filters,
      filterSet: hasTrendingNewFilter
        ? { filterables: [], filterCondition: FilterType.And }
        : {
            filterCondition: FilterType.And,
            filterables: [
              {
                filterCondition: FilterType.And,
                filters: [{ filterType: GenericFilterType.TrendingNew, equality: EqualityType.Equal, valueType: ValueType.Boolean, value: 'true' }],
              },
            ],
          },
    });
  };
  return (
    <Button
      onClick={() => toggleFilters()}
      variant={ButtonVariant.Bordered}
      shape={ButtonShape.Square}
      size={ButtonSize.XSmall}
      text="Trending / New"
      state={hasTrendingNewFilter ? ButtonState.Active : ButtonState.Default}
    />
  );
};

export default TrendingNewButton;
