import Badge from '../../../../components/Badge';
import { IBadgeFilter, IFilter } from '../../FiltersTypes';
import { getBadgeText } from '../../FiltersUtil';

export const FiltersShownList = ({
  filtersShown,
  onEditFilter,
  onRemoveFilter,
  teamId,
  disableFilterEditing,
}: {
  filtersShown: IFilter[];
  onEditFilter: any;
  onRemoveFilter: any;
  teamId: number;
  disableFilterEditing: boolean;
}) => {
  return (
    <div className="flex flex-row flex-wrap items-center gap-x-1 gap-y-1 font-medium text-licorice-noir">
      {filtersShown.map((filter, index) => {
        const badgeValue: IBadgeFilter = { text: getBadgeText(filter), id: index.toString(), filter: filter };
        return (
          <div id="applied-filter" className="" key={index}>
            <Badge
              badge={badgeValue}
              key={index}
              onEdit={
                disableFilterEditing
                  ? undefined
                  : (badge: any) => {
                      onEditFilter(badge as IBadgeFilter);
                    }
              }
              smaller
              onRemove={disableFilterEditing ? undefined : (badge) => onRemoveFilter(badge as IBadgeFilter, teamId)}
              color="bg-licorice-noir"
              textColor="text-white"
              /*  color="bg-buttercream-frosting-300"
              textColor="text-licorice-noir" */
              textFont="font-medium"
              capitalize={false}
            />
          </div>
        );
      })}
    </div>
  );
};
