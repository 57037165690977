import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useOrgInfoState } from '../../context/orgInfoContext';
import {
  Action,
  GetOrganizationDocument,
  Org_Domains,
  Resource,
  useAddDomainToOrgMutation,
  useRemoveDomainFromOrgMutation,
  useUpdateDomainDiscoveryMutation,
} from '../../generated/graphql';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import UserContext from '../../v2/contexts/UserContext';
import { classNames } from '../../v2/util';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../baseComponents/Button';
import SettingsMenu, { MenuAlign } from '../baseComponents/SettingsMenu';
import { isOrgUserAdmin } from '../pages/organization/util';
import NewTextModal from './Modals/NewTextModal';
import TooltipIcon from './Modals/TooltipIcon';
import Toggle from './Toggle';

export default function OrgDomainsSection() {
  const [newDomainModalOpen, setNewDomainModalOpen] = useState(false);
  const [loadingCreatingDomain, setLoadingCreatingDomain] = useState(false);
  const { user } = useContext(UserContext);
  const { organization } = useOrgInfoState();
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateDomain = hasPermission(Resource.OrganizationsDomains, Action.Create);

  //Maybe this can go inside the org hook
  const [createDomainMutation] = useAddDomainToOrgMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: organization.id } }],
  });
  const [updateDomainMutation] = useUpdateDomainDiscoveryMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: organization.id } }],
  });
  const [removeDomainMutation] = useRemoveDomainFromOrgMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: organization.id } }],
  });

  const onAddDomain = async (domainName: string) => {
    if (organization.orgDomains?.some((orgDomain) => orgDomain.domain === domainName)) return toast.error('Domain already associated.');
    setLoadingCreatingDomain(true);
    await createDomainMutation({
      variables: {
        allowDiscovery: true,
        domain: domainName,
        orgId: organization.id,
      },
      onCompleted(data) {
        setNewDomainModalOpen(false);
        toast.success('Domain successfully added.');
      },
      onError(error) {
        toast.error('Failed to add domain.');
        setNewDomainModalOpen(false);
      },
    });
    setLoadingCreatingDomain(false);
  };

  const onRemoveDomain = async (orgDomainId: number) => {
    await removeDomainMutation({
      variables: {
        orgDomainId,
        orgId: organization.id,
      },
      onCompleted(data) {
        toast.success('Domain successfully removed.');
      },
      onError(error) {
        toast.error('Failed to remove domain.');
      },
    });
  };

  const toggleDiscovery = (orgDomainId: number, newVal: boolean) => {
    updateDomainMutation({
      variables: {
        allowDiscovery: newVal,
        orgDomainId,
        orgId: organization.id,
      },
      onCompleted(data) {
        toast.success('Domain discoverability updated.');
      },
      onError(error) {
        toast.error('Failed to update domain discovery.');
      },
    });
  };

  return (
    <div className={classNames('flex flex-col', organization.orgDomains.length > 0 ? 'border-b border-buttercream-frosting-100' : '')}>
      {newDomainModalOpen && (
        <NewTextModal
          modalOpen={newDomainModalOpen}
          callbackModal={() => setNewDomainModalOpen(false)}
          loadingConfirm={loadingCreatingDomain}
          confirmButton={(domainName: string) => {
            onAddDomain(domainName);
          }}
          title="Add Domain"
          subtitle={
            <>
              Add a domain related to your organization (eg: <b>unwrap.ai</b>, <b>google.com</b>)
            </>
          }
          textLabel="Domain"
        />
      )}
      <div className="flex flex-row items-center justify-between pb-1">
        <div className="flex flex-row gap-x-2 items-center text-licorice-noir">
          <h1 className="text-2xl font-recoleta">Domains</h1>
          <div className="">
            <TooltipIcon
              tooltipContent={`Administrators can associate domains with your organization, and let users with emails with these domains find your organization to request an invite, provided they haven't yet been invited.`}
            />
          </div>
        </div>
        <div className="flex-auto border-t-2 border-buttercream-frosting-100 mx-4"></div>
        {canCreateDomain ? (
          <Button
            id="add-org-domain-button"
            variant={ButtonVariant.Bordered}
            size={ButtonSize.Small}
            shape={ButtonShape.Square}
            onClick={() => {
              setNewDomainModalOpen(true);
            }}
            icon={<PlusIcon className="h-4 w-4" />}
            iconPosition="right"
          ></Button>
        ) : null}
      </div>
      <div className="flex flex-col divide-y divide-buttercream-frosting-100 text-licorice-noir">
        {organization.orgDomains.length === 0 ? (
          <p className='font-light text-gray-secondary italic'>No domains associated.</p>
        ) : (
          organization.orgDomains?.map((orgDomain) => {
            return <OrgDomainCard key={orgDomain.id} orgDomain={orgDomain} deleteDomain={onRemoveDomain} toggleDiscovery={toggleDiscovery} />;
          })
        )}
      </div>
    </div>
  );
}

const OrgDomainCard = ({
  orgDomain,
  deleteDomain,
  toggleDiscovery,
}: {
  orgDomain: Org_Domains;
  deleteDomain?: (orgDomainId: number) => void;
  toggleDiscovery?: (orgDomainId: number, newVal: boolean) => void;
}) => {
  const [discoveryEnabled, setDiscoveryEnabled] = useState(orgDomain.allowDiscovery ?? false);
  const handleToggleDiscovery = () => {
    toggleDiscovery?.(orgDomain.id, !discoveryEnabled);
    setDiscoveryEnabled(!discoveryEnabled);
  };
  const { hasPermission } = useContext(PermissionsContext);
  const canDeleteDomain = hasPermission(Resource.OrganizationsDomains, Action.Delete);
  const canUpdateDomain = hasPermission(Resource.OrganizationsDomains, Action.Update);
  const canAdministrateDomain = canDeleteDomain && canUpdateDomain;

  const settingsItems = [{ id: 0, group: 'remove', name: 'Remove Domain', onClick: () => deleteDomain?.(orgDomain.id) }];

  if (!canAdministrateDomain) {
    return (
      <div className="flex flex-row flex-wrap items-center justify-between py-3 text-licorice-noir">
        <div className="flex flex-row gap-x-2 items-center">
          <h1 className="text-sm font-medium">{orgDomain.domain}</h1>
        </div>
        <div className="flex flex-row gap-x-4 items-center justify-center">
          <div className="flex flex-row gap-x-4 items-center">
            <div className="flex flex-row gap-x-1 items-center">
              <Tippy
                content={
                  'Lets any user with this domain in their email address find your Organization and request an invite. Any request must be approved by an organization admin before access is granted.'
                }
              >
                <p className="text-sm text-gray-tertiary italic">Discoverable</p>
              </Tippy>
            </div>
            <Toggle initialState={discoveryEnabled} onSwitch={handleToggleDiscovery} elementDisabled={true} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row flex-wrap items-center justify-between py-3 text-licorice-noir">
      <div className="flex flex-row gap-x-2 items-center">
        <h1 className="text-sm font-medium">{orgDomain.domain}</h1>
      </div>
      <div className="flex flex-row gap-x-4 items-center justify-center" onClick={(e) => e.stopPropagation()}>
        <div className="flex flex-row gap-x-4 items-center">
          <div className="flex flex-row gap-x-1 items-center">
            <Tippy
              content={
                'Lets any user with this domain in their email address find your Organization and request an invite. Any request must be approved by an organization admin before access is granted.'
              }
            >
              <p className="text-sm text-gray-tertiary italic">Discoverable</p>
            </Tippy>
          </div>
          <Toggle initialState={discoveryEnabled} onSwitch={handleToggleDiscovery} />
        </div>
        <SettingsMenu settings={settingsItems} align={MenuAlign.RIGHT} center>
          <EllipsisVerticalIcon className="h-5 w-5" />
        </SettingsMenu>
      </div>
    </div>
  );
};
