import { mapObject, values } from 'underscore';
import { Markdown } from './Markdown';
import { AssistantDataContext } from '../../../../context/assistantContext';
import { useContext } from 'react';
import { GroupBreakdownChartPreview } from '../../GroupBreakdownChartPreview';

export const AnswerComponentFactory = () => {
  const { components } = useContext(AssistantDataContext);
  const children = values(
    mapObject(components, (component, id) => {
      switch (component.type) {
        case 'markdown':
          return (
            <div className={'w-[400px]'}>
              <Markdown id={id} text={component.props.text} />
            </div>
          );
        case 'chart':
          return <GroupBreakdownChartPreview id={id} />;
        default:
          return null;
      }
    })
  );

  return <div className={'flex flex-row gap-x-8'}>{children}</div>;
};
