import React from 'react';
import { AdminActionButton } from './AdminActionButton';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';

interface IAdminCard {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  onActionButtonClick?: (...args: any) => any;
  actionButtonText?: string;
  isLoading?: boolean;
}

export const AdminCard: React.FC<IAdminCard> = ({ title, subTitle, children, onActionButtonClick, actionButtonText, isLoading }) => {
  return (
    <div className="flex flex-col border border-buttercream-frosting-100 bg-buttercream-frosting rounded-lg p-6 gap-3 relative overflow-visible min-w-[360px]">
      {title && <div className="text-xl font-recoleta">{title}</div>}
      {title && subTitle && <p className="text-base text-gray-secondary">{subTitle}</p>}
      {children}
      {!isLoading && onActionButtonClick && actionButtonText && <AdminActionButton onClick={onActionButtonClick}>{actionButtonText}</AdminActionButton>}
      {isLoading && (
        <div className="w-full flex flex-row justify-center items-center">
          <AdjustableLoadingIcon width={5} height={5} />
        </div>
      )}
    </div>
  );
};

export default AdminCard;
