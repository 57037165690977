import { Digest_Group_Status, InsightLightFragment } from '../../../generated/graphql';

import Badge, { BadgeShape, BadgeSize } from '../../../baseComponents/Badge';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { Icon } from '@iconify/react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import InsightChartSection from '../digests/insightCharts/InsightChartSection';
import { toPresentableCase } from '../../../reducers/utilities/enumHandling';
import InsightEntryReview from '../digests/insights/InsightEntryReview';
import { buildFilterInputFromSavedFilterInput } from '../../pages/ChartsPage';
import { getGroupPageUrl } from '../../lib/groups';
import { AppRoutes } from '../../../Routes';

interface InsightSliderCardProps {
  insight: InsightLightFragment;
  onClick: () => void;
}

const InsightSliderCard = (props: InsightSliderCardProps) => {
  const { insight, onClick } = props;
  return (
    <a
      href={getGroupPageUrl(insight.team.id, insight.team.orgId!, insight.collection.id, undefined, [
        `group=${JSON.stringify(buildFilterInputFromSavedFilterInput(insight.filterInput, insight.filterInput.startDate, insight.filterInput.endDate))}`,
      ])}
      id="group-preview-card"
      className="bg-buttercream-frosting text-licorice-noir rounded-2xl min-h-80 cursor-pointer " /* onClick={onClick} */
    >
      <div className="px-6 py-6 flex flex-col gap-x-6 gap-y-1 min-h-[80%] rounded-lg bg-buttercream-frosting border border-x mb-4">
        <div className="flex flex-col md:flex-row gap-x-2 gap-y-4 h-full content-stretch items-stretch lg:min-h-[28rem] xl:min-h-[24rem] 2xl:min-h-[20rem]">
          <div className="lg:w-3/5 gap-y-1 md:pr-10 flex h-full items-stretch">
            <div className="flex flex-col justify-between gap-y-2 h-full">
                <div className='flex flex-col md:flex-row gap-4 items-start md:items-center w-full mb-2'>
                  <h3 className="text-2xl font-recoleta leading-6">{insight.title}</h3>
                  <Badge
                    size={BadgeSize.Small}
                    badge={{ id: '0', text: toPresentableCase(insight.feedbackStatus) }}
                    capitalize={false}
                    color={insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? 'bg-peach-cobbler-glaze' : 'bg-pistachio-gelato-glaze'}
                    textColor={insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? 'text-peach-cobbler' : 'text-pistachio-gelato'}
                    borderColor={insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? 'border-peach-cobbler' : 'border-pistachio-gelato'}
                    shape={BadgeShape.Pill}
                    icon={
                      insight.feedbackStatus === Digest_Group_Status.NeedsImprovement ? (
                        <ExclamationCircleIcon className="h-5 w-5 stroke-2" />
                      ) : (
                        <CheckCircleIcon className="h-5 w-5 stroke-2" />
                      )
                    }
                  />
                </div>
                <p className="font-light text-base md:text-base text-licorice-noir mb-1">{insight.text}</p>
                <div className="grid mb-1 min-h-[8rem]">
                  <div className="flex flex-col md:flex-row gap-x-4 items-center gap-y-2 justify-evenly text-gray-secondary italic">
                    {insight.entries.map((entry) => {
                      return <InsightEntryReview key={entry.id} entry={entry} />;
                    })}
                  </div>
              </div>
              <div className="flex flex-row items-start justify-start w-full mt-4">
                <Button text={'See all mentions'} variant={ButtonVariant.Secondary} size={ButtonSize.Small} />
              </div>
            </div>
          </div>
          <div className="lg:w-2/5 h-full min-h-[16rem]">
            <InsightChartSection insight={insight} />
          </div>
        </div>
      </div>
    </a>
  );
};

export default InsightSliderCard;
