import zendeskLogo from '../../assets/zendesk-logo.png';
import githubLogo from '../../assets/github-logo.png';
import appstoreLogo from '../../assets/appstore-logo.png';
import shopifyLogo from '../../assets/shopify-logo.png';
import googleLogo from '../../assets/google-logo.png';
import g2Logo from '../../assets/g2-logo.png';
import slackLogo from '../../assets/slack-logo.png';
import yelpLogo from '../../assets/yelp-logo.png';
import csvLogo from '../../assets/csv-logo.png';
import resyLogo from '../../assets/resy-logo.png';
import gplayLogo from '../../assets/google-play-logo.png';
import redditLogo from '../../assets/reddit-logo.png';
import intercomLogo from '../../assets/intercom-logo.png';
import twitterLogo from '../../assets/twitter-logo.png';
import unwrapLogo from '../../assets/unwrap_logo.svg';
import stackOverflowLogo from '../../assets/stack-overflow-logo.png';
import jetbrainsLogo from '../../assets/jetbrains-logo.svg';
import vscodeLogo from '../../assets/vscode-logo.png';
import jiraLogo from '../../assets/jira-logo.png';
import zoomMeetingsLogo from '../../assets/zoom-meetings-logo.svg';
import zoomPhoneLogo from '../../assets/zoom-phone-logo.svg';
import { Fragment } from 'react';

export interface ISource {
  name: string;
  description: string | JSX.Element;
  logo: any;
  source: string;
  itemName: string;
}

const sourcesMap: Record<string, ISource> = {
  appstore: { name: 'App Store', description: 'Import issues from the Apple App store', logo: appstoreLogo, source: 'appstore', itemName: 'Review' },
  gplay: { name: 'Google Play', description: 'Import reviews from the Google Play store', logo: gplayLogo, source: 'gplay', itemName: 'Review' },
  intercom: { name: 'Intercom', description: '', logo: intercomLogo, source: 'intercom', itemName: 'Review' },
  shopify: { name: 'Shopify', description: 'Import your ecommerce reviews', logo: shopifyLogo, source: 'shopify', itemName: 'Review' },
  google: { name: 'Google Maps', description: 'Import Google Map Reviews', logo: googleLogo, source: 'google', itemName: 'Review' },
  zendesk: {
    name: 'Zendesk',
    description: 'Integrate Zendesk tickets straight into your dashboard',
    logo: zendeskLogo,
    source: 'zendesk',
    itemName: 'Review',
  },
  g2: { name: 'G2', description: 'Import reviews from G2', logo: g2Logo, source: 'g2', itemName: 'Review' },
  slack: { name: 'Slack', description: 'Import messages from your Slack conversations', logo: slackLogo, source: 'slack', itemName: 'Message' },
  yelp: { name: 'Yelp', description: 'Import reviews from Yelp', logo: yelpLogo, source: 'yelp', itemName: 'Review' },
  reddit: { name: 'Reddit', description: '', logo: redditLogo, source: 'reddit', itemName: 'Review' },
  twitter: { name: 'Twitter', description: '', logo: twitterLogo, source: 'twitter', itemName: 'Post' },
  csv: {
    name: 'CSV',
    description: (
      <Fragment>
        Upload any CSV with columns <i>title</i>, <i>details</i>, <i>date</i>, and <i>user</i>
      </Fragment>
    ),
    logo: csvLogo,
    source: 'csv',
    itemName: 'Item',
  },
  resy: { name: 'Resy', description: 'Import Resy reviews left by your customers', logo: resyLogo, source: 'resy', itemName: 'Review' },
  unwrap: {
    name: 'Unwrap',
    logo: unwrapLogo,
    source: 'Unwrap',
    description: 'Add feedback from an Unwrap chart!',
    itemName: 'Message',
  },
  'github discussion': {
    name: 'Github',
    logo: githubLogo,
    source: 'github discussion',
    description: '',
    itemName: 'Discussion',
  },
  'stack overflow': {
    name: 'Stack Overflow',
    logo: stackOverflowLogo,
    source: 'stack overflow',
    description: '',
    itemName: 'Discussion',
  },
  'jetbrains reviews': {
    name: 'Jetbrains',
    logo: jetbrainsLogo,
    source: 'jetbrains reviews',
    description: '',
    itemName: 'Review',
  },
  'vs marketplace': {
    name: 'VS Marketplace',
    logo: vscodeLogo,
    source: 'vs marketplace',
    description: '',
    itemName: 'Discussion',
  },
  jira: { name: 'Jira', description: '', logo: jiraLogo, source: 'jira', itemName: 'Issue' },
  zoom: { name: 'Zoom Meetings', description: '', logo: zoomMeetingsLogo, source: 'zoom', itemName: 'Meeting' },
  zoom_phone: { name: 'Zoom Phone', description: '', logo: zoomPhoneLogo, source: 'zoom_phone', itemName: 'Call' },
};

export default sourcesMap;
