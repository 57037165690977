import { useContext, useEffect, useState } from 'react';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import AdminCard from './AdminCard';
import AppContext from '../../../v2/contexts/AppContext';
import { useMoveTeamMutation } from '../../../generated/graphql';
import toast from 'react-hot-toast';
import { VirtualizedComboBox } from '../VirtualizedComboBox';

export const MoveTeamCard = () => {
  const { organizations, orgsHaveLoaded } = useContext(AppContext);
  const [view, setView] = useState<IDropDownItem | undefined>(undefined);
  const [sourceOrg, setSourceOrg] = useState<IDropDownItem | undefined>(undefined);
  const [destinationOrg, setDestinationOrg] = useState<IDropDownItem | undefined>(undefined);
  const [enableMoveButton, setEnableMoveButton] = useState(false);
  const [moveTeam, moveTeamData] = useMoveTeamMutation();

  useEffect(() => {
    const orgsAreDifferent = sourceOrg?.id !== destinationOrg?.id;
    const teamIdIsValid = view?.id !== -1;
    const orgIdsAreValid = sourceOrg?.id !== -1 && destinationOrg?.id !== -1;

    organizations.forEach((org) => {
      org.teams.forEach((team) => {
        if (team.id === view?.id) {
          if (sourceOrg?.id != org.id) {
            setSourceOrg({ name: org.name, id: org.id });
          }
        }
      });
    });

    setEnableMoveButton(orgsAreDifferent && teamIdIsValid && orgIdsAreValid);
  }, [sourceOrg?.id, destinationOrg?.id, view?.id]);

  const handleMoveTeam = () => {
    if (!destinationOrg?.id || !sourceOrg?.id || !view?.id) {
      toast.error('Please select a destination organization, source organization, and team to move');
      return;
    }
    moveTeam({
      variables: {
        destinationOrgId: destinationOrg.id,
        originOrgId: sourceOrg.id,
        teamId: view.id,
      },
      onCompleted: () => {
        toast.success('Team moved successfully');
      },
      onError: (error) => {
        toast.error(`Error moving team: ${error.message}`);
      },
    });
  };

  return (
    <AdminCard title="Move Team" subTitle="Moves a team to a different organization" actionButtonText="Move Team" onActionButtonClick={handleMoveTeam}>
      <div className="flex flex-col w-full gap-y-3">
        <VirtualizedComboBox
          comboBoxData={organizations.flatMap((org) =>
            org.teams.map((team) => {
              return { name: `${team.name} (${team.id})`, id: team.id };
            })
          )}
          setSelectedItem={(item) => {
            if (!item) throw new Error('No item selected');
            setView({ name: item.name, id: item.id as number });
          }}
          id="team-combobox"
          selectedItem={view}
          placeholder="Select a team"
          onClear={() => {
            setView(undefined);
          }}
        />
        <VirtualizedComboBox
          comboBoxData={organizations.map((org) => {
            return { name: org.name, id: org.id };
          })}
          id="source-org-combobox"
          setSelectedItem={(item) => {
            if (!item) throw new Error('No item selected');
            setSourceOrg({ name: item.name, id: item.id as number });
          }}
          selectedItem={sourceOrg}
          placeholder="Select a source organization"
          onClear={() => {
            setSourceOrg(undefined);
          }}
        />

        <VirtualizedComboBox
          comboBoxData={organizations.map((org) => {
            return { name: org.name, id: org.id };
          })}
          id="dest-org-combobox"
          setSelectedItem={(item) => {
            if (!item) throw new Error('No item selected');
            setDestinationOrg({ name: item.name, id: item.id as number });
          }}
          selectedItem={destinationOrg}
          placeholder="Select a destination organization"
          onClear={() => {
            setDestinationOrg(undefined);
          }}
        />
      </div>
    </AdminCard>
  );
};
