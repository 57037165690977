import { Dispatch } from 'react';

export enum GroupActions {
  ADD_TAG_TO_GROUP,
  REMOVE_TAG_FROM_GROUP,
  SET_AUXILLARY_GROUP_DATA_LOADING,
  SET_AUXILLARY_GROUP_DATA_ERROR,
  SET_PIN_GROUP,
  UPDATE_AUXILLARY_GROUP_DATA,
  UPDATE_GROUP_TITLE,
  UPDATE_DENOMINATOR,
  UPDATE_UNFILTERED_DENOMINATOR,
}

export interface GroupState {
  groupData: {
    // we can place more items in this object as needed
    summaryText: string | null | undefined;
    title: string;
    id: string;
    isPinnedByUser: boolean;
    uniqueEntries: number;
    tags: { id: number; name: string }[] | null | undefined;
    dateCreated: Date;
    creator: {
      isUnwrapGenerated: boolean;
      creatorEmail?: string;
    };
  } | null;
  totalEntries: number | null | undefined;
  filteredEntries: number | null | undefined;
  auxillaryGroupDataErrorMessage: string | null;
  auxillaryGroupDataLoading: boolean;
}

interface AuxillaryGroupDataError {
  error: string;
}

interface SetPinGroup {
  isPinnedByUser: boolean;
}

interface UpdateAuxillaryGroupData {
  id: string;
  summaryText: string | null | undefined;
  title: string;
  isPinnedByUser: boolean;
  uniqueEntries: number;
  tags: { id: number; name: string }[] | null | undefined;
  dateCreated: string; // ISO string
  creator: {
    isUnwrapGenerated: boolean;
    user?: { email: string } | null | undefined;
  };
}
interface UpdateGroupTitle {
  title: string;
}

interface TagToGroupPayload {
  tag: { id: number; name: string };
}

export type GroupAction =
  | {
      type: GroupActions.UPDATE_DENOMINATOR;
      payload: { totalEntries: number };
    }
  | {
      type: GroupActions.UPDATE_UNFILTERED_DENOMINATOR;
      payload: { totalEntries: number };
    }
  | {
      type: GroupActions.UPDATE_AUXILLARY_GROUP_DATA;
      payload: UpdateAuxillaryGroupData;
    }
  | {
      type: GroupActions.UPDATE_GROUP_TITLE;
      payload: UpdateGroupTitle;
    }
  | {
      type: GroupActions.SET_AUXILLARY_GROUP_DATA_LOADING;
      payload: {};
    }
  | {
      type: GroupActions.SET_AUXILLARY_GROUP_DATA_ERROR;
      payload: { error: string };
    }
  | {
      type: GroupActions.SET_PIN_GROUP;
      payload: SetPinGroup;
    };

export type GroupDispatch = Dispatch<GroupAction>;

export const GroupReducer = (state: GroupState, action: GroupAction): GroupState => {
  switch (action.type) {
    case GroupActions.SET_AUXILLARY_GROUP_DATA_LOADING: {
      return {
        ...state,
        auxillaryGroupDataLoading: true,
        auxillaryGroupDataErrorMessage: null,
      };
    }
    case GroupActions.UPDATE_AUXILLARY_GROUP_DATA: {
      // cast payload to UpdateAuxillaryGroupData
      const payload = action.payload as UpdateAuxillaryGroupData;
      return {
        ...state,
        groupData: {
          summaryText: payload.summaryText,
          title: payload.title,
          id: payload.id,
          isPinnedByUser: payload.isPinnedByUser,
          uniqueEntries: payload.uniqueEntries,
          tags: payload.tags,
          dateCreated: new Date(payload.dateCreated),
          creator: {
            isUnwrapGenerated: payload.creator.isUnwrapGenerated,
            creatorEmail: payload.creator.user?.email,
          },
        },
        auxillaryGroupDataLoading: false,
        auxillaryGroupDataErrorMessage: null,
      };
    }
    case GroupActions.UPDATE_DENOMINATOR: {
      return {
        ...state,
        filteredEntries: action.payload.totalEntries,
      };
    }
    case GroupActions.UPDATE_UNFILTERED_DENOMINATOR: {
      return {
        ...state,
        totalEntries: action.payload.totalEntries,
      };
    }
    case GroupActions.SET_AUXILLARY_GROUP_DATA_ERROR: {
      // cast payload to AuxillaryGroupDataError
      const payload = action.payload as AuxillaryGroupDataError;
      return {
        ...state,
        auxillaryGroupDataErrorMessage: payload.error,
        auxillaryGroupDataLoading: false,
      };
    }
    case GroupActions.SET_PIN_GROUP: {
      const payload = action.payload as SetPinGroup;
      if (!state.groupData) {
        throw new Error('Cannot set pin group when group is not loaded');
      }
      return {
        ...state,
        groupData: {
          ...state.groupData,
          isPinnedByUser: payload.isPinnedByUser,
        },
      };
    }
    case GroupActions.UPDATE_GROUP_TITLE: {
      // cast payload to UpdateGroupTitle
      const payload = action.payload as UpdateGroupTitle;
      if (!state.groupData) {
        throw new Error('Cannot update group title when group is not loaded');
      }
      return {
        ...state,
        groupData: {
          ...state.groupData,
          title: payload.title,
        },
      };
    }
    default:
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
