import { GroupDataFragment, GroupTaxonomyFragment, TeamGroupsTaxonomyFlatQuery, TeamGroupsTaxonomyTreeQuery } from '../../generated/graphql';
import { ITag, TaxonomyGroup } from '../reducers/taxonomy';

export interface RemoveChildFromParentPayload {
  parentGroupId: string;
  childGroupId: string;
}

export interface AssignChildrenPayload {
  parentGroup: {
    id: string;
    title: string;
  };
  childGroups: GroupTaxonomyFragment[];
}

export interface TogglePinGroupPayload {
  groupId: string;
  pinnedByUser: boolean;
}

export interface DiscardGroupPayload {
  groupId: string;
}

export interface UpdateProgressPayload {
  groupId: string;
  progress: number;
}

export interface AddOrphanPayload {
  getGroups: GroupTaxonomyFragment[];
}

export interface SetTaxonomyPayload {
  taxonomy: Map<string, TaxonomyGroup>;
}

export interface UpdateGroupTitlePayload {
  groupId: string;
  title: string;
}

export interface ToggleExpandPayload {
  groupId: string;
  state: ExpandState;
}

export interface ToggleExpandAllPayload {
  state: ExpandState;
}

export interface ErrorPayload {
  error: Error;
}

export interface SearchGroupsPayload {
  searchInput: string;
}

export interface CreateGroupPayload {
  searchInput: string;
}

export interface BuildGroupPayload {
  group: GroupDataFragment;
}

export interface AddGroupPayload {
  group: GroupDataFragment;
}

export interface AddTagToGroupPayload {
  groupId: string;
  tag: ITag;
}

export interface RemoveTagFromGroupPayload {
  groupId: string;
  tag: ITag;
}

export enum ExpandState {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
}

export type TaxonomyAction =
  | {
      type: 'loadTaxonomy';
      payload: TeamGroupsTaxonomyTreeQuery;
    }
  | {
      type: 'loadFlatTaxonomy';
      payload: TeamGroupsTaxonomyFlatQuery;
    }
  | {
      type: 'setTaxonomy';
      payload: SetTaxonomyPayload;
    }
  | {
      type: 'toggleExpand';
      payload: ToggleExpandPayload;
    }
  | {
      type: 'toggleExpandAll';
      payload: ToggleExpandAllPayload;
    }
  | {
      type: 'updateTaxonomy';
      payload: TeamGroupsTaxonomyTreeQuery;
    }
  | {
      type: 'updateFlatTaxonomy';
      payload: TeamGroupsTaxonomyFlatQuery;
    }
  | {
      type: 'addGroup';
      payload: AddGroupPayload;
    }
  | {
      type: 'updateGroupTitle';
      payload: UpdateGroupTitlePayload;
    }
  | {
      type: 'buildGroup';
      payload: BuildGroupPayload;
    }
  | {
      type: 'error';
      payload: ErrorPayload;
    }
  | {
      type: 'togglePinGroup';
      payload: TogglePinGroupPayload;
    }
  | {
      type: 'discardGroup';
      payload: DiscardGroupPayload;
    }
  | {
      type: 'updateProgress';
      payload: UpdateProgressPayload;
    }
  | {
      type: 'removeChildFromParent';
      payload: RemoveChildFromParentPayload;
    }
  | {
      type: 'assignChildren';
      payload: AssignChildrenPayload;
    }
  | {
      type: 'addTagToGroup';
      payload: AddTagToGroupPayload;
    }
  | {
      type: 'removeTagFromGroup';
      payload: RemoveTagFromGroupPayload;
    };
