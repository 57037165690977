import { Dispatch, SetStateAction, useState } from 'react';
import Popup from '../../../../baseComponents/Popup';
import Button, { ButtonType } from '../../../../baseComponents/Button';
import moment from 'moment';
import { DatePicker } from '../../../../v3/baseComponents/DatePicker';
export const AddAnnotationModal = ({
  mode,
  x,
  y,
  annotation,
  addAnnotation,
  editAnnotation,
  setModalOpen,
  closeAll,
  startDate,
}: {
  mode: string;
  x: number;
  y?: number;
  annotation?: any;
  addAnnotation?: (title: string, date: Date, description: string) => void;
  editAnnotation?: (title: string, date: Date, description: string) => void;
  closeAll?: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  startDate?: number;
}) => {
  // keep track of local edit changes
  const [title, setTitle] = useState<string>(annotation?.title);
  const [description, setDescription] = useState<string>(annotation?.description);
  const [date, setDate] = useState<Date>(
    annotation?.date
      ? new Date(annotation.date)
      : moment(startDate ? new Date(startDate) : new Date())
          .endOf('day')
          .toDate()
  );

  const getFormButtons = () => {
    return (
      <div className="flex w-full flex-row items-center justify-end gap-x-2">
        <Button buttonType={ButtonType.PopupCancel} onClick={() => setModalOpen(false)} text={'Cancel'} />
        <Button buttonType={ButtonType.PopupConfirm} onClick={() => handleSubmit()} text={mode === 'Add' ? 'Create' : 'Update'} />
      </div>
    );
  };
  const handleSubmit = () => {
    mode === 'Add' ? addAnnotation && addAnnotation(title, date, description) : editAnnotation && editAnnotation(title, date, description);
    setModalOpen(false);
    closeAll && closeAll();
  };
  return (
    <Popup title={`${mode} Note`} bottomRow={getFormButtons()} setOpen={setModalOpen} inlineStyles={{ top: `${y}px`, left: `${x}px` }}>
      <div className="w-full">
        <div className="flex w-full flex-row items-center justify-between">
          <input
            value={title}
            placeholder="Title"
            style={{ border: '1px solid black' }}
            className="h-8 w-24 rounded border-licorice-noir pl-2"
            onChange={(e) => setTitle(e.target.value)}
          />
          <p>at</p>
          <DatePicker date={date} onChange={(date) => setDate(date)} />
        </div>
        {/* <textarea
          value={description}
          className="w-full rounded resize-none"
          placeholder="Add a description"
          onChange={(e) => setDescription(e.target.value)}
        ></textarea> */}
      </div>
    </Popup>
  );
};
/* just do annotationModal type={"Add"} or {"Edit"}, props=x,y? title? description? date? description? */

export default AddAnnotationModal;
