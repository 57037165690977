import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { OperatorSelector } from '../filterBar/RootFilter';
import { AppliedFilterActions } from './AppliedFilterActions';
import { AppliedStatementFilter } from './AppliedStatementFilter';

export const AppliedCollectionFilter: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  if (filter.type !== 'collection') throw new Error('AppliedCollectionFilter must be a collection');

  return (
    <div className="flex flex-row flex-wrap gap-x-2 items-center">
      {filter.items.map((item, index) => (
        <div key={item.id} className="flex flex-row items-center gap-x-1.5">
          {index > 0 && (
            <div className="flex flex-row items-center relative h-4/6 rounded-md px-1.5 border border-buttercream-frosting-100 bg-white">
              <OperatorSelector filter={filter} />
            </div>
          )}

          {item.type === 'statement' && <AppliedStatementFilter filter={item} isInCollection />}
        </div>
      ))}
      <AppliedFilterActions filter={filter} />
    </div>
  );
};
