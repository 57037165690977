import InlineEditableTextArea from '../../../../baseComponents/InlineEditableTextArea';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../../context/orgInfoContext';
import { OrgInfoActionTypes } from '../../../../reducers/orgInfo/orgInfoReducer';
import { useOrganizationHook } from '../../../../v2/hooks/OrganizationHook';
import toast from 'react-hot-toast';

export const OrgTitleSection = () => {
  const { organization } = useOrgInfoState();
  const dispatch = useOrgInfoDispatch();
  const orgHook = useOrganizationHook();

  const onEditNameComplete = async (name: string) => {
    if (name === organization.name) return; // IMPORTANT: No more dispatching or requests if values have not changed; this applies to all inline editable text areas.
    dispatch({ type: OrgInfoActionTypes.SetTitle, payload: { title: name } });
    await orgHook.editOrganization(name); //Updates the AppContext used everywhere.
    toast.success('Organization name updated');
  };

  return (
    <div className="flex flex-row items-center justify-start gap-x-2 text-licorice-noir py-3">
      <h1 className="text-3xl font-recoleta">Settings:</h1>
      <div className="w-96">
        <InlineEditableTextArea
          value={organization.name}
          onEditComplete={onEditNameComplete}
          editable
          ignorePadding
          additionalClassNames={
            'pl-2 h-auto font-recoleta border-none focus:border-none ring-1 ring-transparent hover:ring-buttercream-frosting-100 focus:ring-buttercream-frosting-100 hover:bg-buttercream-frosting focus:ring-1 hover:ring-1 focus:bg-buttercream-frosting bg-transparent transition-[background-color] duration-100 rounded-md'
          }
          textClassName="text-3xl text-gray-secondary font-medium"
        />
      </div>
    </div>
  );
};
