import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { ScrollToTop } from '../baseComponents/ScrollToTop';
import { FeedbackEntriesList } from '../components/FeedbackEntriesList';
import { PageWrapper } from './PageWrapper';
import { SmallSpinner } from '../components/SmallSpinner';
import { useReplyHook } from '../hooks/ReplyHook';
import FilterBarWrapper from '../components/filters/filterBar/FilterBarWrapper';
import { useContext, useEffect } from 'react';
import { useAmountOfEntriesLazyQuery, useAmountOfEntriesQuery, useFeedbackEntriesLazyQuery } from '../../generated/graphql';
import { getTotalPageLoadEvent } from '../../latencyTracker';
import { FilterContext } from '../../context/filterStatementContext';
import { SearchBar } from './feedbackPage/SearchBar';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import { EntryTextSearchFilter } from '../filters/utilities/EntryTextSearchFilter';
import { ExportToCSV } from '../sections/Filters/ManagerV2/sections/ExportToCSV';
import DateFilterComponent from '../components/filters/filterBar/DateFilterComponent';
import FilterBar from '../components/filters/filterBar/FilterBar';

interface FeedbackPageProps {
  pageName: string;
}

export const FeedbackPage = ({ pageName }: FeedbackPageProps) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { validIntegrations } = useReplyHook({});
  const filterState = useContext(FilterContext);

  const [getFeedbackEntries, feedbackEntries] = useFeedbackEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: { teamId, take: 50, filterStatement: filterState.filterConsumable },
  });

  const { data: totalEntriesAllTime, loading: origAmountOfEntriesLoading } = useAmountOfEntriesQuery({
    variables: { teamId },
  });

  const [getAmountOfEntries, amountOfEntries] = useAmountOfEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: { teamId, filterStatement: filterState.filterConsumable },
  });

  /**
   * I don't really understand the page load here?
   */
  useEffect(() => {
    Promise.all([
      getAmountOfEntries({ variables: { teamId, filterStatement: filterState.filterConsumable } }),
      getFeedbackEntries({
        variables: { teamId, filterStatement: filterState.filterConsumable },
      }),
    ]);
    const event = getTotalPageLoadEvent({ view: 'feedback' });
    window.dispatchEvent(event);
  }, [teamId, filterState.filterConsumable]);

  const fetchMoreEntries = (amount?: number) => {
    feedbackEntries.fetchMore({ variables: { skip: feedbackEntries.data?.entries?.length, take: amount } });
  };

  return (
    <PageWrapper title={'Feedback'}>
      <ScrollToTop />
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-full max-w-[105rem] flex-col ">
          <div className="flex flex-col gap-y-5">
            <div className="flex flex-row gap-x-2 w-full justify-between">
              <div className="w-2/5">
                <SearchBar />
              </div>
              <ExportToCSV teamId={teamId} filterState={filterState} />
            </div>
            <div className="flex flex-col-reverse lg:flex-row items-start gap-x-5 pb-3 w-full justify-between">
              <FilterBar />
              <div className="flex-1 h-[1px] bg-buttercream-frosting-100 rounded-md mt-[16px]" /> {/* Added vertical divider */}
              <DateFilterComponent teamId={teamId} />
            </div>
          </div>
          {/* This length === 0 check makes sure we don't show the loading spinner when we're fetching more entries */}
          {/* For some reason the amount of entries shows up before the entries themselves, so we need to show the loading spinner until we have entries */}
          {feedbackEntries.loading && !feedbackEntries.data?.entries?.length ? (
            <div className="flex flex-col items-center justify-start mt-12">
              <LoadingSpinner height="auto" />
            </div>
          ) : (
            <div className="mt-2 flex flex-col items-center">
              {/* How do we deal with the query string? I want to know if it's in the filter state but does this component really need to bind to that? */}
              {EntryTextSearchFilter.textSearchApplied(filterState) ? (
                <p className="mb-2 text-sm lg:text-xs">
                  You're using simple search. For AI-powered smart search,{' '}
                  <NavLink data-testid="visit-explore-page" to={AppRoutes.v3FullPath.explore} className="font-medium text-blue-800">
                    {' '}
                    visit the Explore page.{' '}
                  </NavLink>
                </p>
              ) : null}
              <div className="w-full">
                <FeedbackEntriesList entries={feedbackEntries.data?.entries} validIntegrations={validIntegrations} />
              </div>
              {amountOfEntries.loading || origAmountOfEntriesLoading ? (
                <div className="mt-4 flex flex-col items-center justify-center gap-y-2">
                  <SmallSpinner />
                </div>
              ) : (
                <div className="mt-4 flex flex-col items-center justify-center gap-y-2">
                  <h1 className="text-sm text-licorice-noir">
                    {totalEntriesAllTime?.amountOfEntries.amount === 0 ? (
                      'No feedback found on this view'
                    ) : amountOfEntries.data?.amountOfEntries.amount === 0 ? (
                      `No entries match the selected filters`
                    ) : (
                      <span>
                        Showing <span id="entries-shown">{feedbackEntries.data?.entries?.length.toLocaleString('en-US')}</span> of{' '}
                        <span id="entries-matching">{amountOfEntries.data?.amountOfEntries.amount.toLocaleString('en-US')}</span> results matching filters
                        {amountOfEntries.data?.amountOfEntries.amount !== 0 ? (
                          <span id="total-entries"> ({totalEntriesAllTime?.amountOfEntries.amount.toLocaleString('en-US')} total)</span>
                        ) : null}
                      </span>
                    )}
                  </h1>
                  {feedbackEntries.data?.entries?.length !== amountOfEntries.data?.amountOfEntries.amount ? (
                    <Button
                      id="load-more"
                      variant={ButtonVariant.Primary}
                      text={'Load More'}
                      loadingText={'Loading...'}
                      onClick={() => fetchMoreEntries(50)}
                      loadingConfirm={feedbackEntries.loading}
                    />
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
