import { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Breakdown, useFeedbackEntriesQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import FeedbackEntryCard, { FeedbackEntrySkeleton } from '../FeedbackEntryCard';
import { getGroupPageUrl } from '../../lib/groups';
import { AppRoutes } from '../../../Routes';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { FilterContext } from '../../../context/filterStatementContext';
import { computeFilterConsumable, FilterState, FilterNodeState } from '../../../reducers/filterStatement/filterStatementReducer';
import uuid from 'react-uuid';
import { FilterNode } from '../../lib/filterNode';
import { ChartColor } from '../../../constants';

const ENTRIES_PER_PAGE = 15;

export interface TabItem {
  id: string;
  title: string;
  color: ChartColor;
  filterState: FilterState;
  teamId: number;
}

interface ChartTabBodyProps {
  tab: TabItem;
  currentBreakdown?: Breakdown | null;
}

export const ChartTabBody = ({ tab, currentBreakdown }: ChartTabBodyProps) => {
  const { curOrgId: orgId } = useValidTeamAppContext();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const pageLevelFilterState = useContext(FilterContext);

  const [hasNextPage, setHasNextPage] = useState(true);

  const combinedFilter = useMemo(() => {
    const allStaticConditions = [...(pageLevelFilterState?.staticConditions || []), ...tab.filterState.staticConditions];

    const node = new FilterNode({
      appliedFilter: tab.filterState.appliedFilter,
      staticConditions: allStaticConditions,
      filterConsumable: computeFilterConsumable(allStaticConditions, tab.filterState.appliedFilter),
      teamId: tab.teamId,
    });

    if (pageLevelFilterState?.appliedFilter) {
      node.addAppliedFilterAsAnd(pageLevelFilterState.appliedFilter);
    }

    const combinedAppliedFilter: FilterNodeState = {
      type: 'collection',
      operator: 'AND',
      id: uuid(),
      items: [pageLevelFilterState.appliedFilter, tab.filterState.appliedFilter],
    };

    return {
      teamId: tab.teamId,
      appliedFilter: combinedAppliedFilter,
      staticConditions: allStaticConditions,
      filterConsumable: computeFilterConsumable(allStaticConditions, combinedAppliedFilter),
    };
  }, [pageLevelFilterState, tab.filterState]);

  const { data, loading, fetchMore } = useFeedbackEntriesQuery({
    variables: {
      teamId: tab.teamId,
      take: ENTRIES_PER_PAGE,
      skip: 0,
      filterStatement: combinedFilter.filterConsumable,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !loading) {
          const result = await fetchMore({
            variables: {
              teamId: tab.teamId,
              skip: data?.entries?.length,
              take: ENTRIES_PER_PAGE,
              filterStatement: combinedFilter.filterConsumable,
            },
          });

          setHasNextPage(result.data?.entries?.length === ENTRIES_PER_PAGE);
        }
      },
      { threshold: 0.5 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [data?.entries?.length, fetchMore, hasNextPage, loading]);

  if (loading && !data?.entries) {
    return (
      <div className="flex flex-col gap-y-4 max-h-[calc(100vh-12rem)] overflow-y-auto">
        <FeedbackEntrySkeleton />
        <FeedbackEntrySkeleton />
        <FeedbackEntrySkeleton />
        <FeedbackEntrySkeleton />
        <FeedbackEntrySkeleton />
      </div>
    );
  }

  const entries = data?.entries || [];

  return (
    <div className="flex flex-col gap-y-2 h-full overflow-y-auto border-b-2 border-buttercream-frosting-100">
      {(currentBreakdown === Breakdown.Group || currentBreakdown === Breakdown.LeafGroup) && (
        <div className="flex items-center justify-between py-3 rounded-md">
          <div className="flex flex-col">
            <span className="text-lg font-recoleta text-licorice-noir">
              <b className="text-gray-secondary">Group: </b>
              {tab.title}
            </span>
          </div>
          <Button
            variant={ButtonVariant.Bordered}
            shape={ButtonShape.Square}
            text="Open group"
            size={ButtonSize.Small}
            icon={<ArrowTopRightOnSquareIcon className="w-4 h-4 stroke-2" />}
            onClick={() => {
              // const cleanFilter = JSON.parse(combinedFilter);
              const filterNode = new FilterNode(combinedFilter);
              filterNode.removeFieldFromAppliedFilter('Entry.Group.id');
              filterNode.removeEmptyCollections();
              // Create a new filter state with only static conditions
              // For now, we drop all filters when clicking on a group, except for the dates (static conditions).
              // We'll eventually get to a better solution.
              const staticFilterState = {
                ...filterNode.getFilterState(),
                appliedFilter: {
                  type: 'collection' as const,
                  operator: 'AND',
                  items: [],
                  id: uuid(),
                },
                filterConsumable: computeFilterConsumable([], {
                  type: 'collection',
                  operator: 'AND',
                  items: [],
                  id: uuid(),
                }),
              };

              window.open(getGroupPageUrl(tab.teamId, orgId, tab.id, staticFilterState), '_blank');
            }}
            className="px-3 py-1.5 text-sm font-medium text-white bg-licorice-noir rounded-md hover:bg-licorice-noir/90 transition-colors"
          />
        </div>
      )}

      <div className="flex flex-col gap-y-3">
        {entries.map((entry) => (
          <FeedbackEntryCard key={entry.id} entry={entry} />
        ))}

        {loading && (
          <div className="flex flex-col gap-y-4">
            <FeedbackEntrySkeleton />
            <FeedbackEntrySkeleton />
          </div>
        )}

        {entries.length > 0 && hasNextPage && !loading && <div ref={loadMoreRef} className="h-4" />}

        {!hasNextPage && entries.length > 0 && <div className="text-center text-gray-secondary py-4">No more entries to load</div>}

        {!hasNextPage && entries.length === 0 && <div className="text-center text-gray-secondary py-4">No entries found</div>}
      </div>
    </div>
  );
};
