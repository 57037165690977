import { useContext, useEffect } from 'react';
import { Group_Status, Group_Type, useAmountOfGroupsLazyQuery } from '../../generated/graphql';
import { GroupHookProps, useGroupHook, getGroups, sortGroups } from '../../v2/hooks/GroupHook';
import AppContext from '../../v2/contexts/AppContext';
import { getTotalPageLoadEvent } from '../../latencyTracker';

export enum ExplorePageView {
  FlatTaxonomy = 'FlatTaxonomy',
  Taxonomy = 'Taxonomy',
  Search = 'Search',
}

export type ExploreGroupHook = ReturnType<typeof useExploreGroupHook>;

export const useExploreGroupHook = (props: GroupHookProps) => {
  const { app } = useContext(AppContext);
  const groupHook = useGroupHook(props);

  const [getAmountOfGroups, amountOfGroups] = useAmountOfGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: props.teamId, status: Group_Status.Monitored },
  });

  useEffect(() => {
    if (!app?.currentUuid) {
      getAmountOfGroups();
    }
  }, []);
  const teamHasParents = () => {
    return groupHook.taxonomy && Array.from(groupHook.taxonomy?.values()).some((item) => item.parentId !== null && item.parentId !== undefined);
  };
  useEffect(() => {
    if (props.teamUuid != null) {
      if (!groupHook.previewSearchesQuery.loading && !groupHook.groupsQuery.loading) {
        const searches = groupHook.previewSearchesQuery?.data?.getPreviewPageGroups.filter((q) => q.status === Group_Status.Monitored);
        const clusters = groupHook.listQuery.data?.teamGroups?.groups.filter((g) => g.type === Group_Type.Cluster);
        groupHook.setGroups([...getGroups(searches), ...getGroups(clusters)]);
      }
    }
  }, [groupHook.previewSearchesQuery.loading, groupHook.groupsQuery.loading]);
  useEffect(() => {
    if (groupHook.listQuery.data && (groupHook.groupsQuery.called ? groupHook.groupsQuery.data : true)) {
      // if the skip variable is set we're loading more - check explicitly that skip is greater than zero so we know if we need to reset the groups
      const isGroupsQueryCalled = groupHook.groupsQuery.called;
      const isSkipGreaterThanZero = groupHook.groupsQuery.variables?.skip && groupHook.groupsQuery.variables.skip > 0;
      //If filters are different, we're not loading more but rather changing a filter. We want to let the other query handle this.
      const isFilterSame = props.filterInput === groupHook.groupsQuery.variables?.filterInput;

      if (isGroupsQueryCalled && isSkipGreaterThanZero && isFilterSame) {
        let seen = new Set();
        const newGroups = [...groupHook.groups, ...getGroups(groupHook.groupsQuery.data?.getGroups)];
        const filtered = newGroups.filter((group) => {
          return seen.has(group.id) ? false : seen.add(group.id);
        });
        groupHook.setGroups(filtered);
      } else {
        const groups = groupHook.listQuery.data.teamGroups?.groups ?? [];
        groupHook.setGroups([...getGroups(groups).sort(sortGroups)]);
      }
      const event = getTotalPageLoadEvent({ view: 'list' });
      window.dispatchEvent(event);
    }
  }, [groupHook.listQuery, groupHook.groupsQuery]);

  return {
    ...groupHook,
    totalAmountOfGroups: amountOfGroups.data?.amountOfGroups.amount ?? 0,
    hasParents: teamHasParents(),
    loadMore: async (startIndex: number, pageSize: number): Promise<number> => {

      //@ts-ignore
      const res = await groupHook.getGroupsQuery({ variables: { ...groupHook.groupsQuery.variables, take: pageSize, skip: startIndex, filterInput: props.filterInput } });
      return res.data?.getGroups.length ?? 0;
    },
    loadMoreOrphans: async (startIndex: number, pageSize: number) => {
      //@ts-ignore
      groupHook.getOrphans({ variables: { ...groupHook.orphansQuery.variables, take: pageSize, skip: startIndex, filterInput: props.filterInput } });
    },
  };
};
