import { useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { SearchPreviewContext } from '../context/SearchPreviewContext';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { BuildGroupButton } from './Search/BuildGroupButton';
import { TaxonomyFolder } from './TaxonomyFolder';
import { TaxonomyGroup } from '../reducers/taxonomy';

/**
 * Displays the results of a semantic search on the Group's centroid text.
 *
 * This component is very similar to the Taxonomy view only the taxonomy folders here will not have children
 * as they are not relevant to the semantic search results.
 *
 * We only care about the parent level groups that are returned from the search. The reasoning is that a child is an
 * instance of a parent group and if the child group is relevant to the search, then the parent group will also be relevant.
 *
 * @param props
 * @constructor
 */
export function GroupSemanticSearchResults(props: { setCurrentGroupId: (groupId: string) => void }) {
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const taxonomyGroups = useContext(TaxonomyContext);
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { ref } = useInView({ threshold: 0 });
  const searchTerm = useContext(SearchPreviewContext).searchTerm;

  const searchResults = Array.from(taxonomyGroups.values()).map((group: TaxonomyGroup) => {
    return (
      <div>
        <TaxonomyFolder
          flatGroup={true}
          trending={group.trending}
          taxonomyGroup={taxonomyGroups.get(group.id)!}
          itemRef={ref}
          key={group.id}
          children={[]}
          teamId={teamId}
          setActiveId={(id) => {
            setActiveId(id);
          }}
          setCurrentGroupId={(id) => {
            // do nothing for now
            props.setCurrentGroupId(id);
          }}
          zIndex={activeId === group.id ? 'z-20' : 'z-0'}
          depth={0}
        />
      </div>
    );
  });

  if (searchResults.length === 0)
    return (
      <div className="mt-2 flex w-full flex-col items-center gap-y-2 pt-2">
        <div className="mt-2 flex w-full flex-col items-center gap-y-4 pt-2 text-gray-secondary">
          <h1>No search groups match the given search term and selected filters.</h1>
          <BuildGroupButton />
        </div>
      </div>
    );

  return (
    <div>
      <div className="mt-2 mb-10 flex w-full flex-col items-center gap-y-2 pt-2 text-gray-secondary">
        <p>Showing the most similar Groups to "{searchTerm}" below. </p>
        <BuildGroupButton />
      </div>
      {searchResults}
    </div>
  );
}
