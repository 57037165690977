import { useRef, useState } from "react";
import { Date_Window, TeamDefaultsDocument, useTeamDefaultsQuery, useUpdateTeamDefaultsMutation } from "../../../generated/graphql";
import { useValidTeamAppContext } from "../../../v2/contexts/AppContext";
import toast from "react-hot-toast";
import { HorizontalDateSelector, HorizontalDateSelectorOption } from "../HorizontalDateSelector";
import { TrashIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import AdjustableLoadingIcon from "../../../baseComponents/AdjustableLoadingIcon";
import Card from "../Card";
import { DateOptions } from "../../sections/Filters/FiltersUtil";
import { DatePicker } from "../../baseComponents/DatePicker";
import Toggle from "../Toggle";
import Button, { ButtonVariant } from "../../baseComponents/Button";

export const TeamDefaultsCard = () => {
  const { curTeamId: teamId, updateTeamDefaultValues, currentTeam } = useValidTeamAppContext();
  const { data } = useTeamDefaultsQuery({
    variables: { teamId },
  });
  const [updateTeamDefaults] = useUpdateTeamDefaultsMutation();
  const defaults = data?.teamDefaults;
  const { exploreDefaultWindow, startDate, endDate, customer_locked_out, lock_out_message } = defaults || {};
  const dateSelectorRef = useRef<any>(null);

  const [lockoutMessage, setLockoutMessage] = useState(lock_out_message || '');
  const [lockOutTextBoxShown, setLockOutTextBoxShown] = useState(customer_locked_out || false);

  const updateDefaults = ({
    exploreDefaultWindow,
    startDate,
    endDate,
    customer_locked_out,
    lock_out_message,
  }: {
    exploreDefaultWindow?: Date_Window | null;
    startDate?: Date | null;
    endDate?: Date | null;
    customer_locked_out?: boolean;
    lock_out_message?: string;
  }) => {
    const loadingToast = toast.loading('Updating team defaults...', { duration: 0 });
    updateTeamDefaults({
      variables: {
        teamId,
        exploreDefaultWindow,
        startDate,
        endDate,
        customer_locked_out,
        lock_out_message,
      },
      refetchQueries: [TeamDefaultsDocument],
      onCompleted: (data) => {
        if (!data.updateTeamDefaults.exploreDefaultWindow) dateSelectorRef.current?.clearSelectedWindow();
        toast.success('Updated team defaults');
        toast.dismiss(loadingToast);
      },
      onError: (err) => {
        toast.error("Couldn't update team defaults");
        toast.dismiss(loadingToast);
      },
    });

    // update team default in app context when team defaults are updated
    updateTeamDefaultValues(teamId, {
      customer_locked_out: customer_locked_out || false,
      endDate: endDate, 
      exploreDefaultWindow: exploreDefaultWindow,
      oldestFeedbackDate: currentTeam?.defaultValues?.oldestFeedbackDate,
      lock_out_message: lock_out_message,
      startDate: startDate
    })
  };

  return (
    <Card
      title="Team Defaults"
      subTitle="if you specify a Start AND End date, the window will be ignored. Dates are specified if their badge is blue."
      width="max"
    >
      {defaults ? (
        <div className="flex flex-col px-6 gap-y-3 pb-2">
          <p className="underline">
            <b>Currently using:</b>{' '}
            {exploreDefaultWindow !== null && (!startDate || !endDate) ? 'Window' : !!startDate && !!endDate ? 'Start & End Dates' : 'None'}
          </p>
          <div className="flex flex-row gap-x-2 items-center">
            <p>Window:</p>
            <HorizontalDateSelector
              onChooseDate={(newStartDate: Date | undefined, newEndDate: Date | undefined, option: HorizontalDateSelectorOption | undefined) => {
                updateDefaults({ exploreDefaultWindow: option?.date_window, startDate: startDate, endDate: endDate, customer_locked_out: customer_locked_out || false, lock_out_message: lock_out_message || '' });
              }}
              options={DateOptions}
              ref={dateSelectorRef}
              defaultSelect={exploreDefaultWindow ? DateOptions.find((option) => option.date_window === exploreDefaultWindow) : undefined}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow: null, startDate: startDate, endDate: endDate, customer_locked_out: customer_locked_out || false, lock_out_message: lock_out_message || '' });
              }}
            />
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <p>Start Date:</p>
            <DatePicker
              date={startDate ? moment(startDate).toDate() : new Date()}
              onChange={(dt: Date) => {
                updateDefaults({ exploreDefaultWindow, startDate: dt, endDate: moment(endDate).endOf('day').toDate(), customer_locked_out: customer_locked_out || false, lock_out_message: lock_out_message || '' });
              }}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow, startDate: null, endDate: null, customer_locked_out: customer_locked_out || false, lock_out_message: lock_out_message || '' });
              }}
            />
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <p>End Date:</p>
            <DatePicker
              date={endDate ? moment(endDate).toDate() : new Date()}
              onChange={(dt: Date) => {
                updateDefaults({
                  exploreDefaultWindow,
                  startDate: startDate,
                  endDate: moment(dt).endOf('day').toDate(),
                  customer_locked_out: customer_locked_out || false,
                  lock_out_message: lock_out_message || ''
                });
              }}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow, startDate: null, endDate: null, customer_locked_out: customer_locked_out || false, lock_out_message: lock_out_message || '' });
              }}
            />
          </div>

          <div className="flex flex-col gap-y-3">
            <div className="flex flex-row gap-x-2 items-center">
              <p>Restrict Customer Access:</p>
              <Toggle
                initialState={customer_locked_out || false}
                onSwitch={(enabled) => {
                  setLockOutTextBoxShown(enabled);
                  if (!enabled) {
                    updateDefaults({ 
                      exploreDefaultWindow,
                      startDate,
                      endDate,
                      customer_locked_out: enabled,
                      lock_out_message: '',
                    });
                  }
                }}
              />
            </div>
            
            {(lockOutTextBoxShown) && (
              <div className="flex flex-col gap-y-4 mt-2">
                <div className="flex flex-row gap-x-2 items-start">
                  <p className="mt-2">Lock Out Message:</p>
                  <div className="flex-1 flex flex-col gap-y-2">
                    <textarea
                      value={lockoutMessage}
                      placeholder="Message to display to customers"
                      onChange={(e) => {
                        setLockoutMessage(e.target.value);
                      }}
                      className="w-full h-24 p-3 border border-buttercream-frosting-100 rounded-md focus:outline-none focus:ring-2 focus:ring-licorice-noir focus:border-transparent"
                    />
                    
                    <div className="flex justify-end">
                      <Button
                        variant={ButtonVariant.Primary}
                        onClick={() => {
                          updateDefaults({
                            exploreDefaultWindow,
                            startDate,
                            endDate,
                            customer_locked_out: true,
                            lock_out_message: lockoutMessage,
                          });
                        }}
                        text="Update customer restrictions"
                        className="px-4 py-2 bg-licorice-noir text-white rounded-md hover:bg-opacity-90"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <AdjustableLoadingIcon width={5} height={5} />
      )}
    </Card>
  );
};