import { useContext } from 'react';
import { FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterGroupActionType, FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilter } from '../appliedFilters/AppliedFilter';
import { Popover, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { Fragment } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

export const RootFilter: React.FC<{ filter: FilterNodeState | undefined }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);

  if (!filter) return <></>;
  if (filter.type !== 'collection') {
    throw new Error('Root filter must be a collection');
  }
  return (
    <div className="flex flex-wrap gap-2">
      {filter.items.map((item, index) => (
        <div className="flex flex-row items-center gap-x-2">
          {index > 0 && (
            <span key={`operator-${index}`} className="flex border border-buttercream-frosting-100 px-1.5 rounded-md items-center relative group cursor-pointer">
              <OperatorSelector filter={filter} />
            </span>
          )}
          <span className="flex bg-buttercream-frosting hover:bg-buttercream-frosting-100 hover:bg-opacity-40 border border-buttercream-frosting-100 px-3 py-1 rounded-md relative group">
            <button
              onClick={() => {
                dispatch({
                  type: FilterGroupActionType.RemoveFilterNode,
                  payload: {
                    id: item.id,
                  },
                });
              }}
              className="absolute -top-2.5 -right-2 opacity-0 group-hover:opacity-100 transition-opacity text-white duration-200 hover:bg-blood-orange-sorbet-darker p-1 rounded-full bg-blood-orange-sorbet shadow-sm z-10"
            >
              <TrashIcon className="w-3 h-3" />
            </button>
            <AppliedFilter filter={item} key={item.id} />
          </span>
        </div>
      ))}
    </div>
  );
};

export const OperatorSelector: React.FC<{ filter: FilterNodeState }> = ({ filter }) => {
  const dispatch = useContext(FilterDispatchContext);

  const options = [
    { label: 'and', value: 'and' },
    { label: 'or', value: 'or' },
  ];

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <Float
          placement="bottom-start"
          offset={6}
          shift={true}
          flip={{
            fallbackPlacements: ['top-start'],
            padding: 8,
          }}
          portal={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Button className="focus:outline-none">
            <span className="rounded-md font-medium text-licorice-noir text-sm group-hover:text-blood-orange-sorbet hover:text-blood-orange-sorbet transition-all duration-150 ease-out">
              {filter.operator.toLowerCase()}
            </span>
          </Popover.Button>

          <Popover.Panel className="w-24 z-50" data-testid="operator-selector-dropdown-options">
            <div className="flex flex-col font-circular rounded-lg border border-buttercream-frosting-100 bg-white shadow-lg">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`w-full px-4 py-2 text-left h-full font-medium flex flex-row border-b border-buttercream-frosting-100 items-center justify-between hover:bg-buttercream-frosting duration-100 first:rounded-t-lg last:border-none last:rounded-b-lg`}
                  onClick={() => {
                    dispatch({
                      type: FilterGroupActionType.UpdateCollectionOperator,
                      payload: { id: filter.id, operator: option.value },
                    });
                    close();
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  );
};
