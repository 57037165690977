import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { useContext, useEffect, useState } from 'react';
import Badge from '../../baseComponents/Badge';
import { classNames } from '../../v2/util';
import { logEvent } from '../../v2/AnalyticsUtil';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import UserContext from '../../v2/contexts/UserContext';

interface SearchInputProps {
  onSearch?: (input?: string) => void;
  id?: string;
  queryString?: string;
  placeholder?: string;
  setQueryString?: (queryString?: string) => void;
  additionalSearch?: {
    id?: string;
    visibleText: string;
    hoverText?: string;
    onRemove: () => void;
  };
  onClick?: () => void;
  locked?: boolean;
  noPadding?: boolean;
  onClear?: () => void;
  rounded?: 'md' | 'full';
  disabled?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({
  queryString,
  placeholder,
  additionalSearch,
  setQueryString,
  onSearch,
  onClick,
  locked,
  id,
  noPadding,
  onClear,
  rounded = 'md',
  disabled,
  onChange,
  className,
}: SearchInputProps): JSX.Element => {
  const [query, setQuery] = useState(queryString);
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (queryString === undefined) setQuery(undefined);
  }, [queryString]);

  const updateQuery = (query?: string) => {
    setQuery(query);
    if (setQueryString) setQueryString(query);
  };
  return (
    <div className={`flex w-full flex-row items-center gap-x-2 ${className}`} onClick={onClick}>
      <div className={'relative flex w-full items-stretch text-gray-tertiary focus-within:text-gray-secondary'}>
        <input
          id={id ?? 'full-text-search'}
          className={classNames(
            'lg:text-md border-buttercream-frosting-100 block w-full pl-12 text-licorice-noir ring-0 focus:border-licorice-noir focus:ring-0 placeholder:text-gray-tertiary font-light',
            !noPadding && 'py-4',
            `rounded-${rounded}`
          )}
          data-testid="search-input"
          placeholder={placeholder ?? 'Search'}
          type="text"
          name="search"
          disabled={locked || disabled}
          autoComplete="off"
          value={queryString ?? query ?? ''}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && teamId !== -1) {
              if (onSearch) onSearch(query);
              logEvent('full-text-search', {
                View_ID: teamId,
                View_Name: currentTeam?.name ?? '',
                Org_ID: orgId,
                Org_Name: currentOrg?.name ?? '',
                Page: window.location.pathname,
                Email: user?.email,
                Text_Searched: query,
              });
            }
          }}
          onChange={(e) => {
            onChange?.(e);
            updateQuery(e.target.value);
          }}
        />
        <div className="absolute inset-y-0 left-4 flex items-center">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-tertiary" />
        </div>
        <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2 ">
          {locked ? <LockClosedIcon className="h-4 w-4 opacity-60" /> : undefined}
          {query && query.length > 0 && (
            <div
              className=" rounded-full p-1 hover:bg-buttercream-frosting"
              onClick={() => {
                updateQuery(undefined);
                setQueryString?.(undefined);
                if (onSearch) onSearch(undefined);
                onClear?.();
              }}
            >
              <XMarkIcon id="search-box-clear" className="h-5 w-5 text-gray-tertiary" aria-hidden="true" />
            </div>
          )}
          {additionalSearch &&
            (additionalSearch.hoverText ? (
              <Tippy content={additionalSearch.hoverText}>
                <div>
                  <Badge
                    id={additionalSearch.id}
                    badge={{ text: additionalSearch.visibleText, id: '0' }}
                    color="bg-blood-orange-sorbet"
                    onRemove={() => additionalSearch.onRemove()}
                  />
                </div>
              </Tippy>
            ) : (
              <Badge
                id={additionalSearch.id}
                badge={{ text: additionalSearch.visibleText, id: '0' }}
                color="bg-blood-orange-sorbet"
                onRemove={() => additionalSearch.onRemove()}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
