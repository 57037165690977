import { CustomIcon } from '../../icons/CustomIcon';
import { FC } from 'react';

export interface IExpandButtonProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
}

export const ExpandButton: FC<IExpandButtonProps> = ({ onClick, style }) => {
  return (
    <div className="cursor-pointer flex flex-row items-center justify-center p-1 rounded-md hover:scale-110 xl:h-[38px] xl:w-[38px] 2xl:h-full 2xl:w-auto duration-200 ml-1 hover:text-blood-orange-sorbet xl:bg-buttercream-frosting border-none xl:border xl:border-buttercream-frosting-100 2xl:bg-transparent 2xl:border-none" onClick={onClick} style={style}>
      <CustomIcon name="open-in-full" className="h-3 w-3 xl:h-5 xl:w-5 text-gray-secondary hover:zoom-in group-hover:text-blood-orange-sorbet" />
    </div>
  );
};
