import { SelectorButton } from './SelectorButton';
import { useRef, useEffect, FC } from 'react';

export interface IYAxisSelectorProps {
  children: React.ReactNode;
  onClick?: () => void;
  parentRef?: React.RefObject<HTMLDivElement>;
}

export const YAxisSelector: FC<IYAxisSelectorProps> = ({ children, onClick, parentRef }) => {
  const rotatedDivRef = useRef<HTMLDivElement>(null);

  const handleSizing = () => {
    const updateHeight = () => {
      if (rotatedDivRef?.current && parentRef?.current) {
        const parentHeight = parentRef.current.clientHeight;
        const rotatedDiv = rotatedDivRef.current;

        rotatedDiv.style.width = `${parentHeight}px`;
        rotatedDiv.style.height = `${parentHeight}px`;
        rotatedDiv.style.display = 'flex';
        rotatedDiv.style.justifyContent = 'end';
        rotatedDiv.style.alignItems = 'center';
        rotatedDiv.style.left = `calc(50% - ${parentHeight / 2}px)`;
        rotatedDiv.style.top = `calc(50% - ${32 / 2}px)`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  };

  useEffect(handleSizing, [parentRef, children]);

  return (
    <div className="relative w-[32px] h-[32px]" ref={parentRef}>
      <div className="absolute origin-center -rotate-90" ref={rotatedDivRef}>
        <SelectorButton
          onClick={() => {
            handleSizing();
            if (onClick) onClick();
          }}
        >
          {children}
        </SelectorButton>
      </div>
    </div>
  );
};
