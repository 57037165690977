import { PencilSquareIcon, PresentationChartLineIcon, TrashIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrgInfoDispatch, useOrgInfoState } from '../../../../context/orgInfoContext';
import { Action, OrganizationUserFragment, Resource, TeamDataFragment, useSetDefaultOrganizationTeamMutation } from '../../../../generated/graphql';
import { OrgInfoActionTypes } from '../../../../reducers/orgInfo/orgInfoReducer';
import { AppRoutes } from '../../../../Routes';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../../v2/contexts/PermissionsContext';
import UserContext from '../../../../v2/contexts/UserContext';
import { useOrganizationHook } from '../../../../v2/hooks/OrganizationHook';
import { ifUnwrapperShowId } from '../../../../v2/util';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../../baseComponents/Button';
import SettingsMenu, { ISettingsItem } from '../../../baseComponents/SettingsMenu';
import { EditViewModal } from '../../../components/Modals/EditViewModal';
import YesCancelModal from '../../../components/Modals/YesCancelModal';
import Tippy from '@tippyjs/react';
import toast from 'react-hot-toast';

export const OrgViewsSection = () => {
  const { organization } = useOrgInfoState();
  const { user } = useContext(UserContext);
  const { hasPermission } = useContext(PermissionsContext);

  const [newViewModalOpen, setNewViewModalOpen] = useState(false);

  const filteredMembers: OrganizationUserFragment[] =
    organization.orgUser.filter((member) => {
      if (organization.id === 254) return false; //Hide hundreds of members on Playwright Org
      if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) return false; //Hide Unwrap members for non-Unwrap users
      return true;
    }) || [];

  return (
    <div className="flex flex-col gap-y-2 w-full" data-testid="org-views-section">
      {newViewModalOpen ? <EditViewModal users={filteredMembers} modalOpen={newViewModalOpen} callbackModal={() => setNewViewModalOpen(false)} /> : null}
      <div className="flex flex-row justify-between text-licorice-noir items-center mb-2">
        <h1 className="text-2xl font-recoleta">Views</h1>
        <div className="flex-auto border-t border-buttercream-frosting-100 mx-4"></div>
        <Button
          variant={ButtonVariant.Bordered}
          shape={ButtonShape.Square}
          size={ButtonSize.Small}
          disabled={!hasPermission(Resource.Teams, Action.Create)}
          disabledTooltip="You don't have permission to create views"
          text="Create view +"
          onClick={() => setNewViewModalOpen(true)}
          dataCy="create-view-button"
        ></Button>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-x-5 gap-y-2 xl:gap-y-3">
        {organization.teams.map((team, index) => (
          <ViewBadge key={index} team={team} data-testid={`view-badge-${team.name}`} />
        ))}
      </div>
    </div>
  );
};

const ViewBadge = ({ team }: { team: TeamDataFragment }) => {
  const { user } = useContext(UserContext);
  const { hasPermission } = useContext(PermissionsContext);
  const { setCurTeamId, updateOrg } = useValidTeamAppContext();
  const { organization } = useOrgInfoState();

  const dispatch = useOrgInfoDispatch();

  const { deleteTeam } = useOrganizationHook();
  const navigate = useNavigate();

  const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
  const [deletingTeamLoading, setDeletingTeamLoading] = useState<boolean>(false);

  const filteredMembers: OrganizationUserFragment[] =
    organization.orgUser.filter((member) => {
      if (organization.id === 254) return false; //Hide hundreds of members on Playwright Org
      if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) return false; //Hide Unwrap members for non-Unwrap users
      return true;
    }) || [];

  const [editViewModalOpen, setEditViewModalOpen] = useState(false);
  const [setDefaultTeam] = useSetDefaultOrganizationTeamMutation();
  const settingsItems: ISettingsItem[] = [
    { id: 0, group: 'actions', name: 'Edit', icon: <PencilSquareIcon className="w-5 h-5 text-licorice-noir" />, onClick: () => setEditViewModalOpen(true) },
    {
      id: 1,
      group: 'actions',
      name: team.id === organization.defaultTeamId ? 'Remove as Default' : 'Set as Default',
      icon: <WrenchScrewdriverIcon className="w-5 h-5 text-licorice-noir" />,
      onClick: () => {
        const toastId = toast.loading('Setting default view...');
        const newDefaultTeamId = team.id === organization.defaultTeamId ? null : team.id;
        setDefaultTeam({
          variables: {
            orgId: organization.id,
            defaultTeamId: newDefaultTeamId,
          },
          onCompleted: (data) => {
            dispatch({
              type: OrgInfoActionTypes.SetDefaultTeam,
              payload: {
                defaultTeamId: newDefaultTeamId,
              },
            });

            if (data.setDefaultOrganizationTeam) {
              updateOrg({
                ...data.setDefaultOrganizationTeam,
                defaultTeamId: newDefaultTeamId,
              });
            }

            toast.success('Default view set', { id: toastId });
          },
        });
      },
    },
    {
      id: 2,
      group: 'delete',
      name: 'Remove',
      icon: <TrashIcon className="w-5 h-5 text-candy-apple" />,
      textColor: 'failure',
      onClick: () => setDeleteTeamModalOpen(true),
    },
  ];
  const handleDeleteTeam = async () => {
    setDeletingTeamLoading(true);
    await deleteTeam(team.id);
    dispatch({ type: OrgInfoActionTypes.RemoveView, payload: { viewId: team.id } });
    setDeletingTeamLoading(false);
    setDeleteTeamModalOpen(false);
  };

  return (
    <div
      className="flex flex-row justify-between gap-x-3 items-center rounded-md text-licorice-noir border border-buttercream-frosting-100 bg-buttercream-frosting hover:bg-buttercream-frosting-100 hover:bg-opacity-40 duration-150 hover:cursor-pointer pl-4 pr-2 py-4"
      onClick={() => {
        setCurTeamId(team.id);
        navigate(AppRoutes.v3FullPath.home);
      }}
    >
      {editViewModalOpen ? (
        <EditViewModal view={team} users={filteredMembers} modalOpen={editViewModalOpen} callbackModal={() => setEditViewModalOpen(false)} />
      ) : null}
      <YesCancelModal
        modalOpen={deleteTeamModalOpen}
        callbackModal={setDeleteTeamModalOpen}
        idToPass={team.id}
        text={'Are you sure you want to delete this view?'}
        subtext={'This action cannot be undone. All data will be deleted.'}
        closeOnConfirm={false}
        understandCheck={true}
        confirmButton={handleDeleteTeam}
        confirmText={'Yes, delete'}
        loadingConfirm={deletingTeamLoading}
      />
      <div className="flex flex-row items-center gap-x-2">
        {team.logoUrl ? <img src={team.logoUrl} className="h-6 w-6" /> : <PresentationChartLineIcon className="h-5 w-5 text-gray-tertiary" />}
        <p className="font-recoleta line-clamp-1">{team.name}</p>
        <p className="text-xs">{ifUnwrapperShowId(user, team.id)}</p>
      </div>
      {user && hasPermission(Resource.Teams, Action.Update) && hasPermission(Resource.Teams, Action.Delete) ? (
        <div className="flex flex-row gap-x-2 items-center flex-shrink-0" onClick={(e) => e.stopPropagation()}>
          {team.id === organization.defaultTeamId && (
            <Tippy content="This is the default view new users will see when they first join Unwrap">
              <span className="px-2 py-1 text-xs font-medium bg-buttercream-frosting text-gray-secondary rounded-md">Default</span>
            </Tippy>
          )}
          <SettingsMenu settings={settingsItems} center>
            <Button
              variant={ButtonVariant.IconRawWithHover}
              shape={ButtonShape.Circle}
              size={ButtonSize.Small}
              icon={<EllipsisVerticalIcon className="h-5 w-5" />}
            />
          </SettingsMenu>
        </div>
      ) : null}
    </div>
  );
};
