import React from 'react';

interface IPlainTextProps {
  text: string | React.ReactNode | React.ReactNode[];
}

const PlainText: React.FC<IPlainTextProps> = ({ text }) => {
  const getText = (content: string | React.ReactNode | React.ReactNode[]): string => {
    if (typeof content === 'string') return content;

    if (content == null) return '';

    if (React.isValidElement(content)) return getText(content.props.children);

    if (Array.isArray(content)) return content.map(getText).join('');

    return String(content);
  };

  return <span className="whitespace-pre-wrap hyphens-auto break-all overflow-hidden w-full">{getText(text)}</span>;
};

export default PlainText;
