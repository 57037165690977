import { useContext, useState } from 'react';
import AdminCard from './AdminCard';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import AppContext from '../../../v2/contexts/AppContext';
import { useCopyTeamEntriesMutation } from '../../../generated/graphql';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { VirtualizedComboBox } from '../VirtualizedComboBox';

export const CopyDataCard = () => {
  const [originTeam, setOriginTeam] = useState<IDropDownItem | undefined>();
  const [message, setMessage] = useState<string | JSX.Element | undefined>();
  const { currentTeam, organizations, orgsHaveLoaded } = useContext(AppContext);

  const [copyTeamEntriesMutation, _] = useCopyTeamEntriesMutation({});

  const handleCopyEntries = () => {
    if (originTeam && currentTeam) {
      setMessage(
        <h1>
          Copying entries from team: <br />
          <b>{originTeam.name} </b> <br />
          into team: <br />
          <b>
            {currentTeam.name} ({currentTeam.id}){' '}
          </b>
        </h1>
      );
      copyTeamEntriesMutation({
        variables: {
          originTeamId: originTeam.id,
          destinationTeamId: currentTeam.id,
        },
        onCompleted: () =>
          setMessage(
            <h1>
              <p className="text-green-600">Success!</p>
              Copied entries from team: <br />
              <b>{originTeam.name} </b> <br />
              into team: <br />
              <b>
                {currentTeam.name} ({currentTeam.id}){' '}
              </b>
            </h1>
          ),
        onError: (err) => setMessage(err.message + ' (check the team entries in case it timed out)'),
      });
    }
  };

  return (
    <AdminCard
      title="Copy Data from Team"
      subTitle="Copies all entries from the selected team into the current one"
      actionButtonText="Copy Entries from Team"
      onActionButtonClick={handleCopyEntries}
    >
      {!orgsHaveLoaded || !organizations ? (
        <AdjustableLoadingIcon width={5} height={5} />
      ) : (
        <div className="flex flex-col w-full gap-y-3">
          <VirtualizedComboBox
            comboBoxData={organizations.flatMap((org) =>
              org.teams.map((team) => {
                return { name: team.name + ` (${team.id})`, id: team.id };
              })
            )}
            //@ts-ignore
            setSelectedItem={setOriginTeam}
            selectedItem={originTeam}
            placeholder="Select a team"
          />
          {message && <div className="col-span-8 flex flex-row justify-start pt-2">{message}</div>}
        </div>
      )}
    </AdminCard>
  );
};
