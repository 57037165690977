import { ChevronDownIcon, ChevronRightIcon, TicketIcon } from '@heroicons/react/24/solid';
import Button, { ButtonVariant } from '../../../baseComponents/Button';
import { External_Ticket_Status, Feedback_Integration_Type, useIntegrationsQuery, useListExternalTicketsQuery } from '../../../../generated/graphql';
import { classNames } from '../../../../v2/util';
import { useEffect, useState } from 'react';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { useExternalTicketsDispatch, useExternalTicketsState } from '../../../../context/externalTicketsContext';
import { ExternalTicketsActionTypes } from '../../../../reducers/externalTickets/externalTicketsReducer';
import { LinkedActionsList, LinkedActionStatusMap } from './LinkedActionsList';
import { LinkedActionsWidget } from '../LinkedActionsWidget';
import { preloadImages } from '../../../../utilities';

export const GroupLinkedActions = ({ groupId }: { groupId: string }) => {
  const [open, setOpen] = useState(false);
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { externalTickets } = useExternalTicketsState();
  const dispatch = useExternalTicketsDispatch();

  useIntegrationsQuery({ variables: { orgId, teamId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets } });

  //To preload integrations for external tickets
  useIntegrationsQuery({
    variables: { orgId: orgId ?? -1, teamId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets },
    onCompleted(data) {
      //Testing ways to preload images so the user doesn't see the "loading" process when opening the menu.
      preloadImages(data?.integrations?.map((integ) => integ.logoUrl) ?? []);
    },
  });

  const { data: externalTicketsRes, loading } = useListExternalTicketsQuery({
    variables: {
      groupId,
      teamId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (externalTicketsRes) {
      dispatch({ type: ExternalTicketsActionTypes.SET_EXTERNAL_TICKETS, payload: { tickets: externalTicketsRes.listExternalTickets } });
    }
  }, [externalTicketsRes]);

  return (
    <div className="flex flex-col mb-6">
      {loading && externalTickets.length === 0 ? (
        <GroupLinkedActionsSkeleton />
      ) : (
        <div className="flex lg:flex-row flex-col gap-y-1 items-center">
          <LinkedActionsPreviewSection onClick={() => setOpen((val) => !val)} isListOpen={open} />
          <div className="flex-auto border-t border-buttercream-frosting-100 mx-4"></div>
          <div className="lg:w-auto w-full">
            <LinkedActionsWidget groupId={groupId} />
          </div>
        </div>
      )}
      {open && externalTickets.length > 0 ? <LinkedActionsList groupId={groupId} dispatch={dispatch} tickets={externalTickets} /> : null}
    </div>
  );
};

export const LinkedActionsPreviewSection = ({ onClick, isListOpen }: { onClick: () => void; isListOpen: boolean }) => {
  const { externalTickets } = useExternalTicketsState();

  return externalTickets.length > 0 ? (
    <div
      data-testid="group-linked-actions-opener"
      className="flex flex-col lg:flex-row w-full lg:w-auto gap-y-1 lg:gap-x-3 bg-buttercream-frosting border border-buttercream-frosting-100 rounded-md px-3 lg:px-1.5 py-1.5 items-center hover:cursor-pointer select-none hover:bg-buttercream-frosting-100 duration-100"
      onClick={onClick}
    >
      <div className="flex flex-row justify-between w-full items-center">
        <div className="flex flex-row gap-x-2 items-center">
          <LinkedActionsSquaresIcon />

          <p className="font-medium">
            {externalTickets.length} linked action{externalTickets.length > 1 ? 's' : ''}:
          </p>
        </div>
        <div className="lg:hidden">
          <Button variant={ButtonVariant.IconRaw} icon={isListOpen ? <ChevronDownIcon className="w-5 h-5" /> : <ChevronRightIcon className="w-5 h-5" />} />
        </div>
      </div>
      <div className="flex flex-row gap-x-2 w-full lg:w-auto">
        <StatusAndCountBadge
          type={External_Ticket_Status.Open}
          count={externalTickets.filter((ticket) => ticket.status === External_Ticket_Status.Open).length}
        />
        <StatusAndCountBadge
          type={External_Ticket_Status.Closed}
          count={externalTickets.filter((ticket) => ticket.status === External_Ticket_Status.Closed).length}
        />
      </div>
      <div className="hidden lg:flex">
        <Button variant={ButtonVariant.IconRaw} icon={isListOpen ? <ChevronDownIcon className="w-5 h-5" /> : <ChevronRightIcon className="w-5 h-5" />} />
      </div>
    </div>
  ) : (
    <div className="flex flex-col lg:flex-row w-full lg:w-auto gap-y-1 lg:gap-x-3 bg-buttercream-frosting border border-buttercream-frosting-100 rounded-lg px-3 lg:px-3 py-1.5 items-center select-none italic text-gray-tertiary">
      No actions linked to this group
    </div>
  );
};

const LinkedActionsSquaresIcon = () => {
  return (
    <div className="relative w-7 h-7">
      <div className="flex absolute bg-licorice-noir bg-opacity-50 w-6 h-6 px-1.5 py-1.5 rounded-md top-0 left-0"></div>
      <div className="flex absolute bg-licorice-noir w-6 h-6 px-1.5 py-1.5 rounded-md top-1 left-1 items-center">
        <TicketIcon className="text-white w-5 h-5 " />
      </div>
    </div>
  );
};

const StatusAndCountBadge = ({ type, count }: { type: External_Ticket_Status; count: number }) => {
  const countBgColor = type === External_Ticket_Status.Closed ? 'bg-green-500' : 'bg-yellow-500';
  const textColor = type === External_Ticket_Status.Closed ? 'text-green-500' : 'text-yellow-500';
  return (
    <div className={classNames(`flex flex-row items-baseline text-sm rounded-lg gap-x-3 bg-white border border-buttercream-frosting-300`)}>
      <p className={classNames('ml-1', textColor)}>{LinkedActionStatusMap[type]}</p>
      <div className={classNames('flex rounded-md items-center justify-center w-6 h-5', countBgColor)}>
        <h1 className={'text-white'}>{count}</h1>
      </div>
    </div>
  );
};

export const GroupLinkedActionsSkeleton = () => {
  return (
    <div className="flex flex-row w-full">
      <div
        className="group-linked-actions-skeleton space-y-5 rounded-lg bg-buttercream-frosting relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-12 w-full"
      ></div>
    </div>
  );
};
