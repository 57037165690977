import { useContext } from 'react';
import { TaxonomyContext } from '../../context/TaxonomyContext';
import { getGroupPageUrl } from '../../lib/groups';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Ancestry, TaxonomyGroup } from '../../reducers/taxonomy';

export function GroupPageBreadcrumbs(props: { group: TaxonomyGroup }) {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const taxonomy = useContext(TaxonomyContext);
  let ancestors: Ancestry[] = taxonomy.get(props.group.id)?.ancestors ?? [];
  const { group } = props;
  ancestors = ancestors.concat({ id: group.id, title: group.title! });

  return (
    <p className="text-sm flex flex-row flex-wrap">
      {ancestors.map((group, index) => {
        const groupPageUrl = getGroupPageUrl(teamId, orgId, group.id);
        return (
          <span key={group.id} className="flex flex-row flex-wrap items-center">
            {index === ancestors.length - 1 ? (
              <div className="text-gray-tertiary font-light italic">{group.title}</div>
            ) : (
              <a target="_blank" href={groupPageUrl} className={'hover:cursor-pointer hover:text-gray-tertiary'}>
                {group.title}
              </a>
            )}

            {index < ancestors.length - 1 ? <ChevronRightIcon className="w-4 h-4 mx-1" /> : null}
          </span>
        );
      })}
    </p>
  );
}
