import { ChartWrapper } from '../standardCharts/ChartWrapper';
import { ChartType } from '../standardCharts/chartElements/ChartTypeOptions';
import { PlotFragment } from '../../../generated/graphql';
import { Plot } from './Plot';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { useRef } from 'react';
import { ChartLabel } from '../standardCharts/chartElements/ChartLabel';
import { CloseButton } from '../standardCharts/chartElements/CloseButton';
import { RotatedContainer } from '../standardCharts/chartElements/RotatedContainer';

export interface IChartModalProps {
  title: string;
  plotData: PlotFragment;
  closeExpandedChart: () => void;
  chartType: ChartType;
  handleSeriesClick: (seriesIndex: number) => void;
  plotUnitLabel: string;
  breakdownLabel?: string;
  loadingChart?: boolean;
  startDate?: string;
  endDate?: string;
}

export const ChartModal = ({
  title,
  plotUnitLabel,
  breakdownLabel,
  loadingChart,
  plotData,
  closeExpandedChart,
  chartType,
  handleSeriesClick,
  startDate,
  endDate,
}: IChartModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCloseExpandedChart = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    closeExpandedChart();
  };

  const TitleText = <div className="text-2xl font-bold text-licorice-noir">{title}</div>;

  const DateRangeText =
    startDate && endDate ? <div className="text-gray-secondary text-md whitespace-nowrap min-w-fit">{`from ${startDate} to ${endDate}`}</div> : null;

  const TitleSection = (
    <div className="flex flex-col gap-y-[8px] w-full justify-center items-center mb-[40px]">
      <h2 className='font-recoleta text-2xl'>{TitleText}</h2>
      {DateRangeText}
    </div>
  );

  const PlotUnitLabel = (
    <RotatedContainer containerRef={modalRef} leftOffset="30px" width="32px">
      <ChartLabel labelType="Plot Unit" labelValue={plotUnitLabel} />
    </RotatedContainer>
  );

  const BreakdownLabel = (
    <div className="absolute bottom-[30px] inset-x-0 flex flex-row items-center justify-center">
      <div className="w-fit flex flex-row items-center justify-center">
        <ChartLabel labelType="Breakdown" labelValue={breakdownLabel} />
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-licorice-noir bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto p-[32px]" onClick={closeExpandedChart}>
      <div
        className={`items-start bg-white rounded-lg p-[96px] pt-[32px] relative pb-${breakdownLabel ? '[96px]' : '[32px]'} min-h-fit my-auto w-2/3`}
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
      >
        <CloseButton onClick={handleCloseExpandedChart} styles={{ position: 'absolute', top: '12px', right: '12px' }} />
        {TitleSection}
        {PlotUnitLabel}
        {BreakdownLabel}
        {loadingChart ? (
          <LoadingSpinner />
        ) : (
          <ChartWrapper chartType={chartType ?? 'line'} numberOfElements={plotData?.series.length ?? 0}>
            <Plot loading={loadingChart ?? false} plotData={plotData} showLegend={chartType !== 'horizontal'} onSeriesClick={handleSeriesClick} />
          </ChartWrapper>
        )}
      </div>
    </div>
  );
};
