import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { GroupDataContext } from '../../../context/groupContext';
import ChartLoading from '../../../v2/sections/Charts/ChartLoading';
import ReactMarkdown from 'react-markdown';
import { FilterContext } from '../../../context/filterStatementContext';
import { Breakdown, Chart_Bin_Type, Chart_Type, useGetGroupPlotQuery, Y_Axis_Data } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import ClusterSparkChart from '../ClusterSparkChart';
import { computeFilterConsumable } from '../../../reducers/filterStatement/filterStatementReducer';
import { FilterTree } from '../../lib/filterTree';

export interface InfoBoxChartProps {
  aggregateData: number[];
  normalizedData: number[];
  tooltipLabels: string[];
  chartLabels: string[];
}

const GroupPageInfoBox = () => {
  const { groupData: group, auxillaryGroupDataLoading } = useContext(GroupDataContext);
  const [chartLoaded, setChartLoaded] = useState(false);

  return (
    <div className="flex">
      <div className="grid grid-cols-2 gap-x-4 w-full">
        <div className="col-span-1 h-fit">
          {auxillaryGroupDataLoading || !group ? <ChartLoading /> : <GroupChart groupId={group.id} onLoad={() => setChartLoaded(true)} />}
        </div>
        {group?.summaryText && chartLoaded ? (
          <div className="col-span-1 bg-buttercream-frosting border border-buttercream-frosting-100 rounded-md h-full flex flex-col">
            <div className="flex-1 min-h-0 relative">
              <div className="absolute inset-0 overflow-y-auto p-4">
                <SummaryMarkdown text={group?.summaryText} />
              </div>
              <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-buttercream-frosting pointer-events-none" />
            </div>
          </div>
        ) : (
          <SummaryLoadingSkeleton />
        )}
      </div>
    </div>
  );
};

const GroupChart = ({ groupId, onLoad }: { groupId: string; onLoad: () => void }) => {
  const filterState = useContext(FilterContext);
  const {
    curTeamId,
    currentTeam: { name: teamName },
  } = useValidTeamAppContext();
  const filterTree = FilterTree.fromConsumable(filterState.filterConsumable);
  const hasAppliedGroupFilter = filterTree.getAppliedFields().find((field) => field.includes('Entry.Group.id'));
  const { data, loading } = useGetGroupPlotQuery({
    variables: {
      pageLevelFilterNode: computeFilterConsumable(
        // put in the explicit groupId filter into the static conditions
        [...filterState.staticConditions, { type: 'statement', fieldName: 'Entry.Group.id', operator: '==', value: groupId, id: 'group-id-filter' }],
        filterState.appliedFilter
      ),

      // the group plot should show (filteredGroupEntries/filteredTotalEntries)
      // if we don't do this then it will cause a bug where the chart will not reflect the stats we show on the group page
      // see https://app.asana.com/0/1208185496695696/1209314382075348
      denominatorFilterOverride: computeFilterConsumable([...filterState.staticConditions], filterState.appliedFilter),
      plotConfigurationInput: {
        title: '',
        binSelection: Chart_Bin_Type.Dynamic,
        /**
         * Why breakdown on group instead of getting the absolute count? This has to do with an oddity of how we query data with a Group filter applied.
         * We want the following behavior - If you set a group filter on the group page, to a group different from the group of the group page
         * we should show zero mentions and the chart should show a flat line.
         *
         * If make the chart with no breakdown we'll query on the feedback entries table and our group filter(s) one for the group page group and one for the other group configured
         * will filter entries and the filter will result in the entries overlapping from the two groups. We don't want this on the group page because customers find it confusing.
         *
         * If we make the chart a group breakdown we'll query on the group_entries table and the query will return rows with the corresponding group ids. With two groups being and'ed
         * we'll effectively return zero rows. This is the behavior we want.
         *
         *
         * This is clearly a hack but I don't really have a better alternative here. Alternatives being restructure how we query the group - entry relationships, which we could do. The
         * downside of this unfortunately just makes the queries much more costly (slow)
         *
         * We only apply the breakdown when we have a group filter applied. This is because we want to show the same absolute count of mentions on the group page.
         */
        breakdown: hasAppliedGroupFilter ? Breakdown.Group : undefined,
        chartType: Chart_Type.Line,
        yAxisMetric: Y_Axis_Data.RelativeShare,
        series: [
          {
            filterNode: computeFilterConsumable([], undefined),
            teamIdOverride: curTeamId,
            customName: teamName,
          },
        ],
      },
      teamId: curTeamId,
    },
  });

  if (loading) return <ChartLoading />;
  if (data?.getPlotPreview) {
    setTimeout(() => {
      onLoad();
    }, 1000);
    return (
      <ClusterSparkChart
        artificialStartDate={undefined}
        autoHeight
        aggregateData={data.getPlotPreview.unNormalizedSeries[0].dataSeries}
        normalizedData={data.getPlotPreview.series[0].dataSeries}
        tooltipLabels={data.getPlotPreview.tooltipLabels}
        chartLabels={data.getPlotPreview.xAxisLabels}
        /**
         * Jesus's ballsack... this is how you enable annotations? If you look deeper in the code
         * it recomposes the bin range and pulls in the annotations and it uses the start and end dates from the
         * filterInput... this ...hurts...
         */
        filterInput={{
          startDate: new Date(data.getPlotPreview.rangeStart),
          endDate: new Date(data.getPlotPreview.rangeEnd),
        }}
      />
    );
  }
  return <ChartLoading />;
};

const SummaryMarkdown = ({ text }: { text: string }) => {
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const checkScrollPosition = useCallback(() => {
    const element = contentRef.current;
    if (element) {
      const isBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 4;
      const isTop = element.scrollTop < 4;
      setIsAtBottom(isBottom);
      setIsAtTop(isTop);
    }
  }, []);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      checkScrollPosition();
      element.addEventListener('scroll', checkScrollPosition);
      const resizeObserver = new ResizeObserver(checkScrollPosition);
      resizeObserver.observe(element);

      return () => {
        element.removeEventListener('scroll', checkScrollPosition);
        resizeObserver.disconnect();
      };
    }
  }, [checkScrollPosition]);

  const textToDisplay = text.replace(/^```/g, '').replace(/```$/g, '');

  return (
    <div className={'flex flex-col h-full w-full text-licorice-noir'}>
      <h1 className="font-recoleta text-xl mb-1">Summary</h1>
      <div className="relative flex-1">
        <div ref={contentRef} className="absolute inset-0 overflow-y-auto p-4 pb-12">
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => <h1 className={'text-xl mb-4 flex-wrap'} {...props} />,
              h2: ({ node, ...props }) => <h2 className={'text-xl mb-3 flex-wrap'} {...props} />,
              h3: ({ node, ...props }) => <h3 className={'text-lg mb-2 flex-wrap'} {...props} />,
              a: ({ node, ...props }) => <a className={'underline flex-wrap'} target="_blank" rel="noopener noreferrer" {...props} />,
              h4: ({ node, ...props }) => <h4 className={'text-medium flex-wrap'} {...props} />,
              ul: ({ node, ...props }) => <ul className={'list-disc ml-6 flex-wrap'} {...props} />,
              li: ({ node, ...props }) => <li className={'mb-2 flex-wrap'} {...props} />,
            }}
            children={textToDisplay}
          />
        </div>
        {!isAtTop && <div className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-t from-transparent to-buttercream-frosting pointer-events-none" />}
        {!isAtBottom && <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-buttercream-frosting pointer-events-none" />}
      </div>
    </div>
  );
};

const SummaryLoadingSkeleton = () => {
  return (
    <div
      data-testid="summary-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-buttercream-frosting-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-full"
    ></div>
  );
};

export default GroupPageInfoBox;
