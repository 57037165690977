import { XMarkIcon } from '@heroicons/react/24/outline';
import { Option, SelectorDropdown } from './SelectorDropdown';
import { useEffect, useState } from 'react';

export interface ISelectorModalProps<T> {
  icon: React.ReactNode;
  title: string;
  options: Option<T>[];
  selectedOption: Option<T>;
  setSelectedOption: (option: Option<T>) => void;
  isModalOpen: boolean;
  closeModal: () => void;
  parentRef: React.RefObject<HTMLDivElement>;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  hasSearch?: boolean;
  deleteOption?: {
    text: string;
    onClick: () => void;
  };
}

export const SelectorModal = <T,>({
  icon,
  title,
  options,
  selectedOption,
  setSelectedOption,
  isModalOpen,
  closeModal,
  parentRef,
  position = 'top-right',
  hasSearch = false,
  deleteOption,
}: ISelectorModalProps<T>) => {
  //   const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const [modalPosition, setModalPosition] = useState<{ left?: number; right?: number; top?: number; bottom?: number } | null>(null);
  const [isSelectorDropdownOpen, setIsSelectorDropdownOpen] = useState(true);

  const closeSelectorDropdown = () => {
    setIsSelectorDropdownOpen(false);
    closeModal();
  };

  const setSelectedOptionAndCloseDropdown = (option: Option<T>) => {
    setSelectedOption(option);
    closeSelectorDropdown();
  };

  useEffect(() => {
    const updatePosition = () => {
      if (parentRef?.current) {
        const parentWidth = parentRef.current.clientWidth;

        const newPosition: typeof modalPosition = {};

        if (position.endsWith('right')) {
          newPosition.left = parentWidth + 4;
        } else {
          newPosition.right = parentWidth + 4;
        }

        if (position.startsWith('bottom')) {
          newPosition.bottom = 0;
        } else {
          newPosition.top = 0;
        }

        setModalPosition(newPosition);
      }
    };

    if (isModalOpen) {
      setIsSelectorDropdownOpen(true);
      updatePosition(); // Mount
      window.addEventListener('resize', updatePosition); // Update
    }

    return () => {
      window.removeEventListener('resize', updatePosition); // Unmount
    };
  }, [isModalOpen, parentRef, position]);

  const handleCloseModal = () => {
    closeModal();
    closeSelectorDropdown();
  };

  return modalPosition && isModalOpen ? (
    <div
      style={{
        position: 'absolute',
        top: modalPosition.top,
        bottom: modalPosition.bottom,
        left: modalPosition.left,
        right: modalPosition.right,
        zIndex: 10,
      }}
      className="flex flex-col bg-white rounded-lg border-[1px] border-buttercream-frosting-100 shadow-md w-[300px] overflow-hidden"
      role="dialog"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-row justify-between items-center gap-x-2 pl-5 pr-3 py-3 border-buttercream-frosting-100">
        <div className="flex flex-row items-center gap-x-2">
          {icon}
          <div className="whitespace-nowrap text-licorice-noir font-recoleta text-lg ml-1">{title}</div>
        </div>
        <div className="cursor-pointer" onClick={handleCloseModal}>
          <XMarkIcon className="text-gray-tertiary w-5 h-5" />
        </div>
      </div>
      <SelectorDropdown<T>
        key={isSelectorDropdownOpen ? 'open' : 'closed'}
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOptionAndCloseDropdown}
        // parentRef={dropdownButtonRef}
        hasSearch={hasSearch}
        deleteOption={deleteOption}
      />
    </div>
  ) : null;
};
