import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import fullLogoMain2 from '../../../assets/unwrap_full_logo_2_MAIN_2.svg';
import logo from '../../../assets/unwrap_logo_red.svg';

import { classNames } from '../../util';

export default function GetStartedModal({ modalOpen, callbackModal }: { modalOpen: boolean; callbackModal: () => void }) {
  return (
    <Transition.Root show={modalOpen} as={Fragment} data-testid="get-started-modal">
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={callbackModal}>
        <div className="relative flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-circular sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-tertiary bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-4xl transform overflow-hidden rounded-md text-left align-bottom shadow-xl transition-all sm:align-middle">
              <div className="fade-in-up-2 absolute right-0 top-0 flex flex-row gap-x-2">
                <div className="group cursor-pointer select-none p-2 text-sm font-medium opacity-80" onClick={callbackModal}>
                  <XMarkIcon className={classNames('h-5 w-5 text-white')} />
                </div>
              </div>
              <div>
                <div className="flex flex-col items-center gap-y-3 bg-licorice-noir px-6 py-14 lg:px-20">
                  <img className="block w-1/2 lg:w-1/3 " src={fullLogoMain2} alt="Unwrap" />
                  <h1 className="text-center text-lg font-medium text-white lg:text-2xl ">
                    Thanks for your interest!
                    <br />A member from Unwrap.ai will get in touch with you within 24 hours.
                  </h1>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
