import uuid from 'react-uuid';
import { Date_Window, FilterInput, FilterType, Operator } from '../../../generated/graphql';
import {
  computeFilterConsumable,
  FilterCollectionState,
  FilterNodeSchema,
  FilterNodeState,
  FilterState,
  FilterStatementState,
} from '../../../reducers/filterStatement/filterStatementReducer';
import { DefaultDateConfig, TeamDefaultConfigs } from '../../../TeamDefaultConfigs';
import { DateFilterUtility } from './DateFilterUtility';
import { FilterTree } from '../../lib/filterTree';
import { FilterNode } from '../../lib/filterNode';

export class InitialFilterStateProvider {
  /**
   * Get the initial filter state for a given team.
   *
   * This is a waterfall method that will return the default filter state for a given team
   * 1. Get any filters set from the url params -> if exists, return filter state derived from url params
   * 2. Get any default filters set for the team -> if exists, return filter state derived from team default configs
   * 3. Return a default filter state object that contains only a 90 day date filter applied.
   *
   * @param teamId The team id to get the default filter state for.
   * @returns The default filter state for the given team.
   */
  public static getInitialFilterState(teamId: number): FilterState {
    const initialState = new InitialFilterStateProvider(teamId).getInitialState();

    /**
     * Repopulate the cache so that the next call to getTeamDefaultValues has the latest values.
     *
     * This is explicitly called non-awaited because I don't want to block the rendering thread waiting for this
     * to complete.
     */
    TeamDefaultConfigs.populateCache();

    return initialState;
  }

  constructor(private teamId: number) {}

  public static getStateFromFilterNode(filterNode: string, teamId: number): FilterState {
    const parsed = JSON.parse(filterNode) as FilterNodeSchema;
    const filterNodeState = this.populateFilterNodeState(parsed);
    const initialStateProvider = new InitialFilterStateProvider(teamId);
    const filterTree = new FilterTree(filterNodeState);
    const staticConditions = (filterTree.getStaticConditions() as FilterStatementState[]) ?? initialStateProvider.getDefaultState().staticConditions;
    const appliedFilter = filterTree.getAppliedFilters() ?? initialStateProvider.getDefaultState().appliedFilter;

    const state = {
      appliedFilter,
      staticConditions,
      filterConsumable: computeFilterConsumable(staticConditions, appliedFilter),
      teamId,
    };

    return state;
  }

  private static populateFilterNodeState(filterNode: FilterNodeSchema): FilterNodeState {
    if (filterNode.type === 'statement') {
      return { ...filterNode, id: uuid() };
    }
    return { ...filterNode, id: uuid(), items: filterNode.items.map((item) => this.populateFilterNodeState(item)) };
  }

  private getInitialState(): FilterState {
    const urlParams = new URLSearchParams(window.location.search);
    const groupParam = urlParams.get('group');
    const filterSet = urlParams.get('filterSet');

    try {
      // If the filterConsumable is present, we need to parse it and use it to set the initial state
      // this should take precedence over the old contract.
      if (filterSet) {
        const filterNode = FilterNode.getFilterNodeFromUrl(filterSet, this.teamId);
        return filterNode.getFilterState();
      }
    } catch (e) {
      console.error('Error parsing filter consumable', e);
      console.error('Invalid filter consumable', filterSet);
    }

    // No filter consumable is in the URL params. Either parse the old filter contract or use the default date filter.
    if (groupParam) {
      return InitialFilterStateProvider.parseOldFilterContractFromUrlParams(groupParam, this.teamId);
    }

    // No old filter contract in the URL params. Use the default date filter.
    const defaultConfig = TeamDefaultConfigs.getTeamDefaultValues(this.teamId);
    if (defaultConfig) {
      return this.getDefaultDateFilter(defaultConfig);
    }

    return this.getDefaultState();
  }

  /**
   * How we use the default date configs:
   *
   * - If the team has both a start and end date, set the date selector to those dates and ignore the window.
   * - If either start or end dates are missing, and the team has a default window, set the date selector to that window.
   * - If the team has no default window or dates, do nothing.
   * @param defaultConfig
   * @returns
   */
  private getDefaultDateFilter(defaultConfig: DefaultDateConfig): FilterState {
    const defaultWindow = defaultConfig.exploreDefaultWindow;
    if (defaultConfig.startDate && defaultConfig.endDate) {
      return this.getDateRangeState(new Date(defaultConfig.startDate), new Date(defaultConfig.endDate));
    } else if (defaultWindow) {
      return this.getDateWindowState(defaultWindow);
    }
    return this.getDefaultState();
  }

  private getDateWindowState(defaultWindow: Date_Window): FilterState {
    let startDate: Date | undefined;
    const staticConditions: FilterStatementState[] = [];
    switch (defaultWindow) {
      case Date_Window.Last_7d:
        startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_30d:
        startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_90d:
        startDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
        break;
      case Date_Window.Last_1y:
        startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        break;
      default:
        break;
    }
    if (startDate) {
      staticConditions.push(
        ...DateFilterUtility.getDateFilterStatement({ start: startDate }).map((node) => ({
          ...node,
          id: uuid(),
        }))
      );
    }

    return this.getStateGivenStaticConditions(staticConditions);
  }

  private getDateRangeState(startDate: Date, endDate: Date): FilterState {
    const staticConditions = DateFilterUtility.getDateFilterStatement({ start: startDate, end: endDate }).map((node) => ({
      ...node,
      id: uuid(),
    }));

    return this.getStateGivenStaticConditions(staticConditions);
  }

  private getStateGivenStaticConditions(staticConditions: FilterStatementState[]): FilterState {
    return {
      teamId: this.teamId,
      staticConditions,
      appliedFilter: this.getDefaultAppliedFilter(),
      filterConsumable: computeFilterConsumable(staticConditions, this.getDefaultAppliedFilter()),
    };
  }

  private getDefaultAppliedFilter(): FilterNodeState {
    return {
      type: 'collection',
      operator: FilterType.And,
      items: [],
      id: uuid(),
    };
  }

  private getDefaultState(): FilterState {
    const defaultStartDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
    const staticConditions = DateFilterUtility.getDateFilterStatement({ start: defaultStartDate }).map((node) => ({
      ...node,
      id: uuid(),
    }));

    return {
      teamId: this.teamId,
      appliedFilter: this.getDefaultAppliedFilter(),
      staticConditions,
      filterConsumable: computeFilterConsumable(staticConditions, this.getDefaultAppliedFilter()),
    };
  }

  /**
   * Parse the old filter contract from the url params.
   * these are the only filters that are supported in the old filter contract
   * ** Entry.date >= startDate
   * startDate?: InputMaybe<Scalars['Date']>;
   * ** Entry.date <= endDate
   * endDate?: InputMaybe<Scalars['Date']>;
   * ** Entry.id = entryId
   * entryFilter?: InputMaybe<Array<EntryFilterInput>>;
   * ** Entry.Group.id = groupId
   * groupFilter?: InputMaybe<Array<GroupFilterInput>>;
   * ** Entry.Segment.<segmentGroupId>.value = segmentId
   * segmentFilters?: InputMaybe<Array<FeedbackSegmentFilterInput>>;
   * @param groupParam The url params to parse.
   *
   * @returns The parsed filter state.
   */
  public static parseOldFilterContractFromUrlParams(groupParam: string, teamId: number): FilterState {
    const parsed = JSON.parse(decodeURIComponent(groupParam) ?? '{}') as FilterInput;
    const initialStateProvider = new InitialFilterStateProvider(teamId);
    if (!parsed) {
      return {
        teamId,
        staticConditions: [],
        appliedFilter: initialStateProvider.getDefaultAppliedFilter(),
        filterConsumable: computeFilterConsumable([], initialStateProvider.getDefaultAppliedFilter()),
      };
    }
    const staticConditions: FilterStatementState[] = [];
    staticConditions.push(
      ...DateFilterUtility.getDateFilterStatement({
        start: parsed.startDate ? new Date(parsed.startDate) : undefined,
        end: parsed.endDate ? new Date(parsed.endDate) : undefined,
      }).map((node) => ({
        ...node,
        id: uuid(),
      }))
    );

    let appliedFilter: FilterNodeState = {
      id: uuid(),
      type: 'collection',
      operator: 'AND',
      items: [],
    };

    if (parsed.entryFilter && parsed.entryFilter.length > 0 && parsed.entryFilter[0].entry.length > 0) {
      appliedFilter.items.push(
        ...(parsed.entryFilter!.map((entryFilter) => ({
          type: 'collection',
          operator: entryFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: entryFilter.entry.map(
            (entry): FilterStatementState => ({
              type: 'statement',
              fieldName: 'Entry.id',
              operator: '==' as Operator,
              value: entry.id,
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }

    if (parsed.groupFilter && parsed.groupFilter.length > 0 && parsed.groupFilter[0].group.length > 0 && parsed.groupFilter[0].group[0].id) {
      appliedFilter.items.push(
        ...(parsed.groupFilter!.map((groupFilter) => ({
          type: 'collection',
          operator: groupFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: groupFilter.group.map(
            (group): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.Group.id`,
              operator: '==' as Operator,
              value: group.id!,
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }

    if (parsed.segmentFilters && parsed.segmentFilters.length > 0 && parsed.segmentFilters[0].segments.length > 0) {
      appliedFilter.items.push(
        ...(parsed.segmentFilters!.map((segmentFilter) => ({
          type: 'collection',
          operator: segmentFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: segmentFilter.segments.map(
            (segment): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.Segment.${segmentFilter.groupId}.value`,
              operator: '==' as Operator,
              value: segment,
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }

    // max stars
    if (parsed.maxStarsFilter && parsed.maxStarsFilter.length > 0 && parsed.maxStarsFilter[0].amounts.length > 0) {
      appliedFilter.items.push(
        ...(parsed.maxStarsFilter!.map((maxStarsFilter) => ({
          type: 'collection',
          operator: maxStarsFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: maxStarsFilter.amounts.map(
            (amount): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.stars`,
              operator: '<=' as Operator,
              value: amount.toString(),
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }
    // min stars
    if (parsed.minStarsFilter && parsed.minStarsFilter.length > 0 && parsed.minStarsFilter[0].amounts.length > 0) {
      appliedFilter.items.push(
        ...(parsed.minStarsFilter!.map((minStarsFilter) => ({
          type: 'collection',
          operator: minStarsFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: minStarsFilter.amounts.map(
            (amount): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.stars`,
              operator: '>=' as Operator,
              value: amount.toString(),
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }
    // sentiment
    if (parsed.sentimentFilter && parsed.sentimentFilter.length > 0 && parsed.sentimentFilter[0].sentiments.length > 0) {
      appliedFilter.items.push(
        ...(parsed.sentimentFilter!.map((sentimentFilter) => ({
          type: 'collection',
          operator: sentimentFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: sentimentFilter.sentiments.map(
            (sentiment): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.sentiment`,
              operator: '==' as Operator,
              value: sentiment,
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }

    // source
    if (parsed.sourceFitler && parsed.sourceFitler.length > 0 && parsed.sourceFitler[0].sources.length > 0) {
      appliedFilter.items.push(
        ...(parsed.sourceFitler!.map((sourceFilter) => ({
          type: 'collection',
          operator: sourceFilter.filterCondition === FilterType.And ? 'AND' : 'OR',
          id: uuid(),
          items: sourceFilter.sources.map(
            (source): FilterStatementState => ({
              type: 'statement',
              fieldName: `Entry.source`,
              operator: '==' as Operator,
              value: source,
              id: uuid(),
            })
          ),
        })) as FilterCollectionState[])
      );
    }

    if (appliedFilter.items.length === 0) {
      appliedFilter = initialStateProvider.getDefaultAppliedFilter();
    }

    return {
      teamId,
      staticConditions,
      appliedFilter,
      filterConsumable: computeFilterConsumable(staticConditions, appliedFilter),
    };
  }
}
