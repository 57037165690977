import { classNames, truncateAndEllipsis } from '../../v2/util';
import { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EntryFragment } from '../../generated/graphql';
import Tippy from '@tippyjs/react';
import { SparklesIcon } from '@heroicons/react/24/outline';

export const SegmentLabel = ({ segment, remainingSegments }: { segment?: EntryFragment['segments'][0]; remainingSegments?: EntryFragment['segments'] }) => {
  const showRemainingSegments = !segment;
  const [isShowing, setIsShowing] = useState(false);

  const getTooltipContent = (segment: EntryFragment['segments'][0]) => {
    if (segment.group.aiGenerated) return 'Unwrap Generated';
    const source = segment.config?.feedbackIntegration?.type?.title;
    return source ? `From ${source}` : '';
  };

  return (
    <div className="">
      <Popover>
        <Popover.Group>
          <Tippy content={segment ? getTooltipContent(segment) : ''} disabled={!segment}>
            <Popover.Button
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              className={classNames(
                'items-center  group flex flex-row gap-x-1 duration-300 pt-0.5 pb-1 px-2 bg-buttercream-frosting-100 text-licorice-noir rounded-md text-xs cursor-default flex-wrap whitespace-nowrap'
              )}
            >
              {segment?.group.aiGenerated ? <SparklesIcon className="w-4 h-4 text-licorice-noir" /> : null}
              {segment != null ? (
                <h1>
                  {segment.groupName}: {truncateAndEllipsis(segment.value, 60)}
                </h1>
              ) : (
                <h1> +{remainingSegments!.length}</h1>
              )}
            </Popover.Button>
          </Tippy>

          {showRemainingSegments ? (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={isShowing}
            >
              <Popover.Panel
                className={`absolute z-10 w-[28rem] px-4 py-1 transform left-[96%] -translate-x-[95%] sm:px-0`}
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
              >
                <div className="overflow-x-auto max-h-32 bg-white rounded-lg shadow-md border-buttercream-frosting-100 border ring-1 ring-black ring-opacity-5 p-2 flex flex-row gap-y-1 overflow-y-auto flex-wrap">
                  {remainingSegments!.map((segment, index) => (
                    <Tippy key={index} content={getTooltipContent(segment)}>
                      <div
                        className={classNames(
                          'flex-none group relative  items-start justify-start flex-row duration-300 py-1 px-2 bg-buttercream-frosting-100 text-licorice-noir rounded-lg text-xs cursor-default mx-0.5'
                        )}
                      >
                        {segment.group.aiGenerated ? <SparklesIcon className="inline w-4 h-4 text-licorice-noir mr-1" /> : null}
                        <h1 className="inline">
                          {segment.groupName}: {segment.value}
                        </h1>
                      </div>
                    </Tippy>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          ) : null}
        </Popover.Group>
      </Popover>
    </div>
  );
};
