import uuid from 'react-uuid';
import {
  computeFilterConsumable,
  FilterNodeSchema,
  FilterNodeState,
  FilterState,
  FilterStatementSchema,
  FilterStatementState,
} from '../../reducers/filterStatement/filterStatementReducer';
import { FilterType } from '../../generated/graphql';

/**
 * This class and FilterTree serve a similar role and likely should be merged under a single class.
 */
export class FilterNode {
  constructor(private filterState: FilterState) {}

  setAppliedFilter(appliedFilter: FilterStatementSchema) {
    if (appliedFilter.type === 'statement') {
      this.filterState.appliedFilter = {
        type: 'collection',
        operator: FilterType.And,
        items: [{ ...appliedFilter, id: uuid() }],
        id: uuid(),
      };
    } else {
      throw new Error('Invalid filter type!');
    }
  }

  addAppliedFilterAsAnd(appliedFilter: FilterNodeState) {
    // applied filter is a collection
    if (this.filterState.appliedFilter.type === 'collection' && this.filterState.appliedFilter.operator === 'and') {
      this.filterState.appliedFilter.items.push(appliedFilter);
    } else {
      this.filterState.appliedFilter = {
        type: 'collection',
        operator: 'and',
        id: uuid(),
        items: [this.filterState.appliedFilter, appliedFilter],
      };
    }
  }

  getFilterState() {
    return this.filterState;
  }

  removeFieldFromAppliedFilter(fieldName: string) {
    const newFilterNode = this.removeField(fieldName, this.filterState.appliedFilter);
    if (newFilterNode) {
      this.filterState.appliedFilter = newFilterNode;
    } else {
      this.filterState.appliedFilter = {
        type: 'collection',
        operator: 'and',
        id: uuid(),
        items: [],
      };
    }
  }

  private removeField(fieldName: string, filterNode: FilterNodeState): FilterNodeState | null {
    if (filterNode.type === 'collection') {
      const newFilterNode = {
        ...filterNode,
        items: filterNode.items.map((item) => this.removeField(fieldName, item)).filter((item) => item !== null) as FilterNodeState[],
      };
      if (newFilterNode.items.length === 0) {
        return null;
      }
      return newFilterNode;
    }

    if (filterNode.type === 'statement' && filterNode.fieldName === fieldName) {
      return null;
    }

    return filterNode;
  }

  removeEmptyCollections() {
    const newFilterNode = this.removeEmptyCollectionsFromFilterNode(this.filterState.appliedFilter);
    if (newFilterNode) {
      this.filterState.appliedFilter = newFilterNode;
    } else {
      this.filterState.appliedFilter = {
        type: 'collection',
        operator: 'and',
        id: uuid(),
        items: [],
      };
    }
  }

  private removeEmptyCollectionsFromFilterNode(filterNode: FilterNodeState): FilterNodeState | null {
    if (filterNode.type === 'collection' && filterNode.items.length === 0) {
      return null;
    }

    if (filterNode.type === 'collection') {
      return {
        ...filterNode,
        items: filterNode.items.map((item) => this.removeEmptyCollectionsFromFilterNode(item)).filter((item) => item !== null) as FilterNodeState[],
      };
    }

    return filterNode;
  }

  getAppliedFilterFields(): string[] {
    const appliedFilters = this.filterState.appliedFilter;
    return this.getFieldsFromFilterNode(appliedFilters);
  }

  private getFieldsFromFilterNode(filterNode: FilterNodeState): string[] {
    if (filterNode.type === 'collection') {
      return filterNode.items.flatMap((item) => this.getFieldsFromFilterNode(item));
    }

    if (filterNode.type === 'statement') {
      return [filterNode.fieldName];
    }

    throw new Error('Invalid filter node type!');
  }

  /**
   * This url string should be a base64 encoded string that contains the appliedFilter and staticConditions.
   *
   * This string should be generated by the getUrlEncodedFilterNode
   * @param url
   * @returns
   */
  static getFilterNodeFromUrl(url: string, teamId: number): FilterNode {
    const parsed = JSON.parse(decodeURIComponent(atob(url))) as { appliedFilter: FilterNodeSchema; staticConditions: FilterStatementSchema[] };

    const appliedFilter = this.convertToNodeState(parsed.appliedFilter);
    const staticConditions = parsed.staticConditions.map((condition) => this.convertToNodeState(condition)) as FilterStatementState[];

    return new FilterNode({
      teamId,
      appliedFilter: appliedFilter,
      staticConditions: staticConditions,
      filterConsumable: computeFilterConsumable(staticConditions, appliedFilter),
    });
  }

  private static convertToNodeState(filterNode: FilterNodeSchema): FilterNodeState {
    if (filterNode.type === 'collection') {
      return { ...filterNode, id: uuid(), items: filterNode.items.map((item) => this.convertToNodeState(item)) };
    }
    return { ...filterNode, id: uuid() };
  }

  getUrlEncodedFilterNode(): string {
    // strip out all of the fields from applied filter that are not in FilterNodeSchema
    const appliedFilter = this.stripFields(this.filterState.appliedFilter);
    const staticConditions = this.filterState.staticConditions.map((condition) => this.stripFields(condition));
    
    return btoa(
      encodeURIComponent(
        JSON.stringify({
          appliedFilter: appliedFilter,
          staticConditions: staticConditions,
        })
      )
    );
  }

  private stripFields(filterNode: FilterNodeState): FilterNodeSchema {
    if (filterNode.type === 'collection') {
      return {
        type: filterNode.type,
        operator: filterNode.operator,
        items: filterNode.items.map((item) => this.stripFields(item)),
      };
    }

    if (filterNode.type === 'statement') {
      return {
        type: filterNode.type,
        fieldName: filterNode.fieldName,
        operator: filterNode.operator,
        value: filterNode.value,
      };
    }

    throw new Error('Invalid filter node type!');
  }
}
