import { useContext, useEffect, useRef, useState } from 'react';
import { RootFilter } from './RootFilter';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import { FilterConfigurationPopup } from '../filterConfiguration/FilterConfiguration';
import { FilterGroupActionType, FilterStatementSchema } from '../../../../reducers/filterStatement/filterStatementReducer';
import { TrashIcon } from '@heroicons/react/24/outline';

export interface IFilterBarProps {
  isOpen?: boolean;
  onDeleteAllFilters?: () => void;
  styles?: string;
}
export const FilterBar = ({ isOpen, onDeleteAllFilters, styles }: IFilterBarProps) => {
  const { appliedFilter } = useContext(FilterContext);
  const dispatch = useContext(FilterDispatchContext);

  // We use this to determine whether to show the save and delete buttons
  // the applied filter is always a collection, but it may be empty (no items)
  // If the filter has items, it means the user has added at least one filter, so we show the save and delete buttons
  const isFilterPopulated = appliedFilter && appliedFilter.type === 'collection' && appliedFilter.items.length > 0;

  const onFilterCreateComplete = (node: FilterStatementSchema) => {
    dispatch({
      type: FilterGroupActionType.AddFilterNode,
      payload: {
        filterNode: node,
      },
    });
  };

  const AddAnotherFilterButtonContent = isOpen ? (
    <div className="flex flex-row flex-0 items-center text-licorice-noir font-medium text-sm rounded-md px-2 py-1 hover:text-blood-orange-sorbet min-w-fit whitespace-nowrap duration-200">
      <div className="mr-1">+</div>
      <div className="mt-0.5">Add filter</div>
    </div>
  ) : (
    <div className="flex flex-row flex-0 items-center text-licorice-noir border border-licorice-noir font-medium text-sm rounded-md px-5 py-1 hover:text-white hover:bg-licorice-noir min-w-fit whitespace-nowrap duration-200">
      <div className="mr-1">+</div>
      <div className="mt-0.5">Add filter</div>
    </div>
  );

  const DeleteAllFiltersButton = (
    <button
      onClick={() => {
        dispatch({
          type: FilterGroupActionType.RemoveFilterNode,
          payload: {
            id: appliedFilter.id,
          },
        });
        if (onDeleteAllFilters) onDeleteAllFilters();
      }}
      className="flex flex-row flex-0 items-center text-licorice-noir font-medium text-sm rounded-md px-2 py-1 hover:text-blood-orange-sorbet min-w-fit whitespace-nowrap duration-200"
    >
      <TrashIcon className="h-4 w-4 mr-1" />
      <div className="mt-0.5">Delete all</div>
    </button>
  );

  return (
    <div className={`relative ${styles}`}>
      <div className="flex flex-row gap-x-2 items-center relative">
        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2 items-center" id="add-filter-button">
          {isFilterPopulated ? <RootFilter filter={appliedFilter} /> : null}
          <div className="flex flex-row gap-x-2 relative">
            <FilterConfigurationPopup onComplete={onFilterCreateComplete}>{AddAnotherFilterButtonContent}</FilterConfigurationPopup>
          </div>
          {isFilterPopulated
            ? // show save button here when you ready for SAVED FILTERS!!!
              // Ok, I'm almost ready for SAVED FILTERS!!! - JAX
              DeleteAllFiltersButton
            : null}
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
