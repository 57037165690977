import { useContext } from 'react';
import { TaxonomyContext } from '../../context/TaxonomyContext';
import { getGroupPageUrl } from '../../lib/groups';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { Ancestry, TaxonomyGroup } from '../../reducers/taxonomy';

export function GroupBreadcrumbs(props: { group: TaxonomyGroup }) {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const taxonomy = useContext(TaxonomyContext);
  let ancestors: Ancestry[] = taxonomy.get(props.group.id)?.ancestors ?? [];
  const { group } = props;

  ancestors = ancestors.concat({ id: group.id, title: group.title! });

  return (
    <p className="text-gray-tertiary text-sm">
      {ancestors.map((group, index) => {
        const groupPageUrl = getGroupPageUrl(teamId, orgId, group.id);
        return (
          <span key={group.id}>
            <a href={groupPageUrl} className={'hover:text-white hover:pointer'}>
              {group.title}
            </a>
            {index < ancestors.length - 1 && ' > '}
          </span>
        );
      })}
    </p>
  );
}
