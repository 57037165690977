import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useContext, useState } from 'react';
import { ProgressState, Status } from '../../../../exports/ProgressMonitor';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../../../baseComponents/SettingsMenu';
import { createExportSettingsItem } from '../../../baseComponents/SettingsMenuExportItem';
import { CopyGroupUrlButton } from './buttons/CopyGroupUrlButton';
import { EditDigestButton } from './buttons/EditDigestButton';
import { EditTaxonomyButton } from './buttons/EditTaxonomyButton';
import { GroupSubscriptionsButton } from './buttons/GroupSubscriptionsButton';
import { PinGroupButton } from './buttons/PinGroupButton';
import { ReplyToUsersButton } from './buttons/ReplyToUsersButton';
import { ToolbarBaseButton } from './buttons/ToolbarBaseButton';
import { TagsPopover } from './TagsPopover';
import { ToolbarLoadingSkeleton } from './ToolbarLoadingSkeleton';
import { PermissionsContext } from '../../../../v2/contexts/PermissionsContext';
import { Resource, Action } from '../../../../generated/graphql';
import { GroupDataContext } from '../../../../context/groupContext';
import { FilterContext } from '../../../../context/filterStatementContext';
import { TaxonomyGroup } from '../../../reducers/taxonomy';

interface GroupToolbarProps {
  taxonomy: Map<string, TaxonomyGroup>;
  showDigestButton: boolean;
  toggleDigestModal: () => void;
  toggleDeleteGroupModal: () => void;
}

export const GroupToolbar = ({ taxonomy, showDigestButton, toggleDigestModal, toggleDeleteGroupModal }: GroupToolbarProps) => {
  const groupState = useContext(GroupDataContext);
  return !groupState.groupData ? (
    <div className="w-full" data-cy="group-toolbar">
      <ToolbarLoadingSkeleton />
    </div>
  ) : (
    <div className="flex flex-row flex-wrap justify-start items-center gap-x-4" data-cy="group-toolbar">
      <div className="flex flex-row flex-wrap justify-start items-center gap-x-4 mt-2 mb-2">
        <TagsPopover />
        <ButtonGroup>
          <PinGroupButton buttonRounding="left" />
          <GroupSubscriptionsButton buttonRounding="right" />
        </ButtonGroup>
      </div>
      <div className="flex flex-row flex-wrap justify-start items-center gap-x-4 mt-2 mb-2">
        <ButtonGroup>
          <ReplyToUsersButton buttonRounding="left" />
          <EditTaxonomyButton taxonomy={taxonomy} />
          <CopyGroupUrlButton buttonRounding="right" groupId={groupState.groupData.id} />
        </ButtonGroup>
        {showDigestButton ? <EditDigestButton onClick={toggleDigestModal} buttonRounding="full" /> : null}
        <MoreSettingsButton toggleDeleteGroupModal={toggleDeleteGroupModal} />
      </div>
    </div>
  );
};

interface MoreSettingsProps {
  toggleDeleteGroupModal: () => void;
}

const MoreSettingsButton = ({ toggleDeleteGroupModal }: MoreSettingsProps) => {
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });
  const { hasPermission } = useContext(PermissionsContext);
  const { curTeamId } = useValidTeamAppContext();
  const groupState = useContext(GroupDataContext);
  const filterState = useContext(FilterContext);

  const settings: ISettingsItem[] = [
    {
      ...createExportSettingsItem({ groupState, id: 0, curTeamId, exportProgress, updateExportProgress, filterConsumable: filterState.filterConsumable }),
    },
    {
      name: 'Delete Group',
      textColor: 'failure',
      id: 1,
      disabled: !hasPermission(Resource.Groups, Action.Delete),
      group: 'delete',
      htmlId: 'delete-group',
      icon: <TrashIcon className="h-5 w-5" />,
      onClick: () => toggleDeleteGroupModal(),
    },
  ];

  return (
    <SettingsMenu settings={settings} center={true} align={MenuAlign.RIGHT}>
      <ToolbarBaseButton
        id="toolbar-group-settings-icon"
        icon={<EllipsisVerticalIcon className="h-5 w-5 stroke-2" />}
        buttonRounding="full"
        label="More Options"
      />
    </SettingsMenu>
  );
};

export const ButtonGroup = ({ children }: { children: JSX.Element[] }) => {
  return <div className="flex flex-row divide-x divide-buttercream-frosting-100">{children.map((child) => React.cloneElement(child, { key: child.props.id }))}</div>;
};
