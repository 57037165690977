import React from 'react';
import { classNames } from '../v2/util';

interface FormInputProps {
  label: string;
  description?: string;
  placeholder?: string;
  value: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  borderColor?: string;
  id?: string;
}

const FormInput = ({ label, placeholder, value, onChange, type, disabled, description, borderColor = 'border-buttercream-frosting-100', id = 'cat' }: FormInputProps): JSX.Element => {
  return (
    <div className="sm:col-span-6">
      <label
        // should refactor to allow seting the type 'postal-code' , 'email' etc.
        htmlFor={id}
        className="block text-sm font-medium text-gray-secondary"
      >
        {label}
      </label>
      <div className={classNames('mt-1', disabled && 'opacity-60')}>
        <input
          disabled={disabled}
          type={type ?? 'text'}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          checked={type === 'checkbox' ? value === '1' : undefined}
          //   name="postal-code"
          //   id="postal-code"
          //   autoComplete="postal-code"
          className={classNames(
            'block rounded-md border-buttercream-frosting-300 shadow-sm sm:text-sm',
            type === 'checkbox' ? '' : 'w-full',
            borderColor,
            'focus:border-licorice-noir'
          )}
        />
      </div>
      {/* I'm using dangerouslySetInnerHTML because I want to treat the data we pull in from the db as valid html if needed i.e. putting an <a> tag or something
        this is done currently on the Intercom Integration
       */}
      {description && <p className="mt-2 text-sm text-gray-tertiary" dangerouslySetInnerHTML={{ __html: description }}></p>}

      {/* Can use below for validation errors
      <p className="mt-2 text-sm text-red-600" id="email-error">
        Your password must be less than 4 characters.
      </p> */}
    </div>
  );
};

export default FormInput;
