import { Layout } from 'react-grid-layout';
import { useBoardState, useBoardDispatch } from '../../../../context/boardContext';
import {
  useDeleteWidgetMutation,
  useUpdateBoardLayoutMutation,
  ExistingWidgetInput,
  Widget,
  Action,
  Resource,
  useDuplicateWidgetsMutation,
} from '../../../../generated/graphql';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { BOARD_WIDGET_HEIGHT, BoardLayout } from '../BoardLayout';
import toast from 'react-hot-toast';
import { WidgetComponentFactory } from '../WidgetComponentFactory';
import { useContext, useState } from 'react';
import { PermissionsContext } from '../../../../v2/contexts/PermissionsContext';
import { CustomChartCardSkeleton } from '../../charts/CustomChartCardSkeleton';
import { ChartModal } from '../../charts/ChartModal';

export const WidgetsSection = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();
  const dispatch = useBoardDispatch();
  const { hasPermission } = useContext(PermissionsContext);
  const [deleteChartWidget, removeWidgetMutationRes] = useDeleteWidgetMutation();

  async function handleRemoveWidget(widget: Widget) {
    const toastId = toast.loading('Removing chart...');
    await deleteChartWidget({
      variables: {
        teamId,
        boardId: state.board.id,
        widgetId: widget.id,
      },
      onCompleted() {
        dispatch({ type: BoardActionTypes.RemoveWidget, payload: { widgetId: widget.id } });
        toast.dismiss(toastId);
        toast.success('Widget removed');
      },
      onError(err) {
        toast.dismiss(toastId);
        toast.error('Failed to remove chart');
        console.error(err);
      },
    });
  }

  const [duplicateWidgets, duplicateWidgetsMutationRes] = useDuplicateWidgetsMutation();

  async function handleDuplicateWidget(widget: Widget) {
    const toastId = toast.loading('Duplicating chart...');
    await duplicateWidgets({
      variables: {
        teamId,
        boardId: state.board.id,
        inputWidgets: [
          {
            widgetType: widget.widgetType,
            widgetTypeId: widget.widgetTypeId,
          },
        ],
      },
      refetchQueries: ['GetTeamWidgets'],
      onCompleted: (data) => {
        dispatch({ type: BoardActionTypes.AddWidgets, payload: { widgets: data.duplicateWidgets } });
        toast.dismiss(toastId);
        toast.success('Widget cloned successfully');
      },
      onError: (error) => {
        toast.dismiss(toastId);
        toast.error('Failed to clone chart');
      },
    });
  }

  const [updateBoardLayout, _updateBoardLayoutMutationRes] = useUpdateBoardLayoutMutation();

  async function handleLayoutChange(layout: Layout[]) {
    const existingWidgets: ExistingWidgetInput[] = layout.map((widget) => {
      const widgetId = parseInt(widget.i);
      return {
        id: widgetId,
        x: widget.x,
        y: widget.y,
        width: widget.w,
        height: BOARD_WIDGET_HEIGHT,
      };
    });
    if (hasPermission(Resource.Boards, Action.Update)) {
      await updateBoardLayout({
        variables: {
          teamId,
          boardId: state.board.id,
          widgets: existingWidgets,
        },
        onCompleted(data, clientOptions) {
          dispatch({ type: BoardActionTypes.SetWidgets, payload: { widgets: data.updateBoardLayout.widgets } });
        },
      });
    }
  }

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="relative">
      <BoardLayout
        widgets={state.board.widgets}
        onLayoutChange={handleLayoutChange}
        renderWidget={(widget) => {
          if (!widget) return <></>;
          return (
            <WidgetComponentFactory
              widget={widget}
              boardId={state.board.id}
              duplicateWidget={handleDuplicateWidget}
              removeWidget={handleRemoveWidget}
              key={widget.id}
            />
          );
        }}
      />
    </div>
  );
};

export const WidgetsSectionSkeleton = () => {
  return (
    <div className="grid grid-cols-3 gap-x-3">
      <CustomChartCardSkeleton />
      <CustomChartCardSkeleton />
      <CustomChartCardSkeleton />
    </div>
  );
};
