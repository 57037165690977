import { useEffect, useState } from 'react';
import { ArrayElement } from '../../../utilities';
import { Breakdown, Chart, Chart2DFragment, ChartFragment, Chart_Type, GetCustomChartsQuery, SeriesData, Y_Axis_Data } from '../../../generated/graphql';
import ChartView from '../../baseComponents/ChartView';
import { YAxisUnit } from '../../../v2/hooks/ChartHook';
import { ChartOptions, CoreScaleOptions, Scale, Tick } from 'chart.js';
import { getTooltipCallbacks } from './chartLabelStrategies';
import { truncateAndEllipsis } from '../../../v2/util';
import { npsChartColors, primaryChartColor, sentimentChartColors } from '../../../constants';
import { generateChartColors } from '../standardCharts/generateChartColors';
import { ChartLabelType, getChartLabelType } from '../standardCharts/getChartLabelType';
import { Chart_TypeToChartType } from '../standardCharts/chartElements/ChartTypeOptions';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;
const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

interface IGenerateDataset {
  label: string;
  data: number[];
  backgroundColor?: string;
  borderColor?: string;
}

const generateDataset = (data: SeriesData, type: 'normalized' | 'aggregate', chartType: Chart_Type, index: number): IGenerateDataset[] => {
  const dataToMap = type === 'normalized' ? data.normalizedData : data.aggregateData;
  const firstOneToEightDataSeries = dataToMap.slice(0, 8); // Arbitrary <= 8 data points limit set by Ryan Barnes on March 18, 2025
  return firstOneToEightDataSeries.map((dataSeries, idx) => {
    let labelName: string | undefined = data.breakdownLabels?.[idx]?.name;

    if (!labelName) return { label: '', data: dataSeries, backgroundColor: primaryChartColor.backgroundColor, borderColor: primaryChartColor.borderColor };

    const chartLabelType: ChartLabelType = getChartLabelType(labelName);

    const dataset: IGenerateDataset = {
      label: labelName,
      data: dataSeries,
    };

    switch (chartLabelType) {
      case 'sentiment':
        dataset.backgroundColor = sentimentChartColors[labelName].backgroundColor;
        dataset.borderColor = sentimentChartColors[labelName].borderColor;
        break;
      case 'nps':
        dataset.backgroundColor = npsChartColors[labelName].backgroundColor;
        dataset.borderColor = npsChartColors[labelName].borderColor;
        break;
      default:
        const { backgroundColors, borderColors } = generateChartColors({
          chartType: Chart_TypeToChartType[chartType] ?? 'horizontal',
          numberOfColors: firstOneToEightDataSeries.length,
        });
        dataset.backgroundColor = backgroundColors(index);
        dataset.borderColor = borderColors(index);
    }

    return dataset;
  });
};

export const CustomChart = ({ customChartData, showLegend = true }: { customChartData: ChartFragment; showLegend?: boolean }) => {
  const [chartData, setChartData] = useState<any>();
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  /*
  We're using a randKey to force a re-render of the chart when the data changes.
  This is a workaround because Charts end up in broken states if we just pass updated data. Needs to be investigated further.
  */
  const [randKey, setRandKey] = useState<number>(Date.now()); // Using Date.now() to force a re-render of the chart when the data changes, because there is technically a small chance that the random number will be the same with only 52 bits of randomness, making the odds that it's the same as the previous key is 1 in 2^52. Why risk it? Millisecond precision will never be the same at the rate of chart updates.

  useEffect(() => {
    if (customChartData.seriesData && customChartData.seriesData.length > 0) {
      let labels: string[] = customChartData.seriesData[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let datasets: any[] = [];
      customChartData.seriesData?.map((data, i) => {
        //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
        if (isPercentageChart(customChartData.y_axis_data!)) datasets.push(...generateDataset(data as SeriesData, 'normalized', customChartData.type, i));
        else datasets.push(...generateDataset(data as SeriesData, 'aggregate', customChartData.type, i));
      });
      const newChartData = { labels, datasets };
      const areAllChartValuesZero = customChartData.seriesData?.every((serData) => serData.aggregateData.every((aggData) => aggData.every((val) => val === 0)));
      setIsChartEmpty(areAllChartValuesZero);
      setChartData(newChartData);
      if (JSON.stringify(newChartData) !== JSON.stringify(chartData)) {
        setRandKey(Math.random());
      }
    }
  }, [customChartData]);

  return (
    <div className="h-full">
      {isChartEmpty || !chartData ? (
        <div className="flex flex-col items-center justify-center h-full px-2">
          <div className="text-center text-gray-tertiary">No data matches the selected configurations and filters</div>
        </div>
      ) : (
        <ChartView
          key={randKey}
          type={customChartData.type}
          data={chartData}
          options={getChartOptions(
            customChartData.y_axis_data === Y_Axis_Data.RelativeShare
              ? YAxisUnit.CustomPercentage
              : customChartData.y_axis_data === Y_Axis_Data.Favorability
              ? YAxisUnit.Favorability
              : YAxisUnit.CustomAbsolute,
            customChartData.type,
            customChartData.seriesData?.[0]?.tooltipLabels ?? [],
            customChartData.seriesData?.[0]?.chartLabels ?? [],
            false,
            showLegend
          )}
          hideLegends={!showLegend}
        />
      )}
    </div>
  );
};

export const getChartOptions = (
  yAxisUnit: YAxisUnit,
  chart_type: Chart_Type,
  fullLabels: string[],
  chartLabels: string[],
  disableAnnotations?: boolean,
  showLegend: boolean = true,
  maximumLabelLength: number = Infinity
): ChartOptions => {
  return {
    ...(chart_type === Chart_Type.HorizontalBar
      ? {
          datasets: {
            bar: {
              barThickness: chartLabels?.length ? Math.max(16, 44 - chartLabels.length * 4) : 16,
              categoryPercentage: 0.5,
              barPercentage: 0.5,
            },
          },
        }
      : {}),
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: chart_type === Chart_Type.Pie ? 10 : 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      //This prevents incorrect tooltip positioning for horizontal bar charts
      axis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'xy',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          if (!data) {
            return false;
          }
          // If all values are zero, return false to hide tooltip.
          for (let i = 0; i < Number(data.valueOf()); i++) {
            if (data !== 0) {
              return true;
            }
          }
          return false;
        },
        backgroundColor: '#FFF',
        bodyColor: '#1D1E30',
        titleColor: '#1D1E30',
        footerColor: '#1D1E30',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'CircularXXWeb', // TODO: Circular
          size: 10,
        },

        titleMarginBottom: 1,
        bodyFont: {
          family: 'CircularXXWeb',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'CircularXXWeb',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#1D1E30',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks: getTooltipCallbacks(chart_type, yAxisUnit, fullLabels),
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
        borderColor: primaryChartColor.borderColor, // Why are there so many ways to set chart border and background color?
        borderWidth: 1,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
          display: true,
          maxRotation: 0,
          color: '#6B7280',
          font: {
            size: 12,
            family: 'CircularXXWeb',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },

      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,

          grid: {
            display: false,
          },

          ticks: {
            stepSize: 1,
            mirror: true,
            labelOffset: chartLabels?.length ? Math.max(16, 44 - chartLabels.length * 4) * -1 : -16,
            padding: 6,
            display: true,
            color: '#6B7280',
            font: {
              size: 11,
              family: 'CircularXXWeb',
            },
            callback: function (value: string | number, index: number, values: Tick[]) {
              const label = this.getLabelForValue(Number(value));
              return truncateAndEllipsis(label, maximumLabelLength);
            },
            // Add these options to force all labels to show
            autoSkip: false,
          },
        },
      }),
      ...(yAxisUnit === YAxisUnit.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 5,
              precision: 0,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'CircularXXWeb',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((yAxisUnit === YAxisUnit.CustomPercentage || yAxisUnit === YAxisUnit.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#6B7280',
              font: {
                size: 12,
                family: 'CircularXXWeb',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
};

// Wait... this isn't even being used anywhere?

export const getCustomChartData = (customChartData: Chart2DFragment) => {
  if (customChartData.series && customChartData.series.length > 0) {
    let labels: string[] = customChartData.xAxisLabels;
    let datasets: any[] = [];
    customChartData.series?.map((data, i) => {
      datasets.push(...generateChartDataset(customChartData, i, customChartData.config.type));
    });
    return { labels, datasets };
  }
};

// Why are there so many duplicate chart data functions everywhere?

const generateChartDataset = (data: Chart2DFragment, index: number, chartType: Chart_Type): IGenerateDataset[] => {
  const firstOneToEightDataSeries = data.series?.slice(0, 8); // Arbitrary <= 8 data points limit set by Ryan Barnes on March 18, 2025
  return firstOneToEightDataSeries.map((dataSeries, idx) => {
    // Arbitrary <= 8 data points limit set by Ryan Barnes on March 18, 2025
    const labelName: string | undefined = data.xAxisLabels?.[idx];

    if (!labelName) return { label: '', data: dataSeries, backgroundColor: primaryChartColor.backgroundColor, borderColor: primaryChartColor.borderColor };

    const chartLabelType: ChartLabelType = getChartLabelType(labelName);

    const dataset: IGenerateDataset = {
      label: labelName,
      data: dataSeries,
    };

    switch (chartLabelType) {
      case 'sentiment':
        dataset.backgroundColor = sentimentChartColors[labelName].backgroundColor;
        dataset.borderColor = sentimentChartColors[labelName].borderColor;
        break;
      case 'nps':
        dataset.backgroundColor = npsChartColors[labelName].backgroundColor;
        dataset.borderColor = npsChartColors[labelName].borderColor;
        break;
      default:
        const { backgroundColors, borderColors } = generateChartColors({
          chartType: Chart_TypeToChartType[chartType] ?? 'horizontal',
          numberOfColors: firstOneToEightDataSeries.length,
        });
        dataset.backgroundColor = backgroundColors(index);
        dataset.borderColor = borderColors(index);
    }

    return dataset;
  });
};
