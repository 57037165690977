import { useContext, useEffect, useState } from 'react';
import { DigestDataFragment } from '../../../generated/graphql';
import { DigestDataContext, DigestDataDispatchContext } from '../../../context/digestDataContext';
import { DigestDataActions, DigestObjectType } from '../../../reducers/digests/digestDataReducer';
import { DigestStatusActions, DigestStatusBadge, getDigestStatus } from './DigestStatus';
import { classNames } from '../../../v2/util';

interface DigestListItemProps {
  item: DigestDataFragment;
}
const DigestListItem = ({ item }: DigestListItemProps) => {

  const digestData = useContext(DigestDataContext);
  const digestDataDispatch = useContext(DigestDataDispatchContext);
  const [digestStatusAction, setDigestStatusAction] = useState<DigestStatusActions>(getDigestStatus(item));

  useEffect(() => {
    setDigestStatusAction(getDigestStatus(item));
  }, [item.id, item.status]);
  return (
    <div
      className={classNames('w-full border py-2 px-2 flex flex-col text-licorice-noir hover:cursor-pointer rounded-md mb-2',
      digestData.selectedDigestObject?.id === item.id ? 'bg-peach-cobbler-glaze border-peach-cobbler text-peach-cobbler hover:bg-peach-cobbler hover:text-white duration-200' : 'bg-buttercream-frosting text-licorice-noir hover:bg-buttercream-frosting')}
      onClick={() =>
        digestDataDispatch?.({ type: DigestDataActions.SetSelectedDigestObject, payload: { selectedObject: item, digestObjectType: DigestObjectType.Digest } })
      }
    >
      <div className="grid grid-cols-2">
        <div className="flex justify-start self-end">
          <h1 className="text-md font-recoleta">{item.digestSeries.title}</h1>
        </div>
        <div className="flex justify-end items-end">
          <p className="text-xxs font-light text-gray-secondary">{new Date(item.dateCreated).toDateString()}</p>
        </div>
      </div>
      <div>
        <p className="text-xs font-light">
          {item.creator.firstName} {item.creator.lastName}
        </p>
      </div>
      <div className="grid grid-cols-3">
        <div className="col-span-2 ">
          <p className="text-xs font-light text-gray-secondary">
            {item.digestSeries.organization.name} ({item.digestSeries.organization.id})
          </p>
        </div>
        <div className="flex justify-end">
          <DigestStatusBadge actions={digestStatusAction} />
        </div>
      </div>
    </div>
  );
};

export default DigestListItem;
