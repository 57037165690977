import { useContext } from 'react';
import { Resource, Action } from '../../../../generated/graphql';
import { PermissionsContext } from '../../../../v2/contexts/PermissionsContext';
import Button, { ButtonVariant, ButtonShape } from '../../../baseComponents/Button';

export const BoardPreviewCardStaticSkeleton = ({ onButtonClick }: { onButtonClick: () => void }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateBoards = hasPermission(Resource.Boards, Action.Create);

  return (
    <div data-testid="custom-chart-card-skeleton" className="custom-chart-card-skeleton rounded-lg bg-buttercream-frosting border border-buttercream-frosting-100 hover:bg-buttercream-frosting-300 hover:bg-opacity-20 relative duration-200">
      <div className="h-[44px] rounded-t-lg px-4 flex flex-row items-center w-full">
        <div className="flex flex-row gap-x-3 items-center w-full">
          <div className="w-[107px] h-3 flex rounded-lg bg-buttercream-frosting-100"></div>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 justify-end pb-6 px-6 h-[164px] rounded-b-lg w-full">
        <div className="w-2/3 h-5 flex rounded-lg bg-buttercream-frosting-100"></div>
        <div className="w-1/3 h-5 flex rounded-lg bg-buttercream-frosting-100"></div>
        <div className="w-1/4 h-10 flex rounded-lg bg-buttercream-frosting min-w-[125px]">
          <Button expandWidth={true} 
            variant={ButtonVariant.Primary} 
            disabledTooltip={'You do not have permissions to create a board. Contact your admin to request the contributor role.'} 
            disabled={!canCreateBoards} shape={ButtonShape.Square} 
            text="New Board +" 
            onClick={onButtonClick}/>
        </div>
      </div>
    </div>
  );
};
