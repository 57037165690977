import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { BoardsPage } from './BoardsPage';
import { BoardPage } from './BoardPage';
import { BoardsListProvider } from '../../../context/boardsListContext';
import { ChartsPage } from '../ChartsPage';
import { FilterablePage } from '../FilterablePage';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { BoardProvider } from '../../../context/boardContext';
import { useGetBoardQuery } from '../../../generated/graphql';
import { PageWrapper } from '../PageWrapper';
import { BoardHeaderSectionSkeleton } from './sections/BoardHeaderSectionSkeleton';
import { BoardFilterSectionSkeleton } from './sections/BoardFilterSectionSkeleton';
import { useContext } from 'react';
import { FilterDispatchContext } from '../../../context/filterStatementContext';
import { FilterGroupActionType } from '../../../reducers/filterStatement/filterStatementReducer';
import { AppRoutes } from '../../../Routes';
import { toast } from 'react-hot-toast';
import { ChartProvider } from '../../../context/chartContext';
import { getInitialPlotState } from '../../../reducers/charts/chartReducer';
import { ChartState } from '../../../reducers/charts/chartReducer';

export const BoardsRouterPage = () => {
  const { curTeamId } = useValidTeamAppContext();
  return (
    <Routes>
      <Route
        path={`/:boardId/*`}
        element={
          <FilterablePage teamId={curTeamId}>
            <SubRouter />
          </FilterablePage>
        }
      />
      <Route
        path={`/`}
        element={
          <BoardsListProvider>
            <BoardsPage />
          </BoardsListProvider>
        }
      />
    </Routes>
  );
};

const SubRouter = () => {
  const boardId = useParams().boardId;
  const { curTeamId } = useValidTeamAppContext();
  const dispatch = useContext(FilterDispatchContext);
  const navigate = useNavigate();
  const defaultChartState: ChartState = getInitialPlotState(curTeamId);

  const { loading: loadingBoard, data: boardRes } = useGetBoardQuery({
    variables: {
      teamId: curTeamId,
      boardId: Number(boardId),
    },
    onCompleted: (data) => {
      if (boardRes?.getBoard) {
        /**
         * This sets the applied filter state to the board filters.
         */
        dispatch({ type: FilterGroupActionType.SetAppliedFilter, payload: { filterNode: JSON.parse(boardRes.getBoard.filterNode) } });
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loadingBoard)
    return (
      <PageWrapper title="">
        <SkeletonPage />
      </PageWrapper>
    );

  if (!boardRes?.getBoard) {
    toast.error('Board not found');
    navigate(AppRoutes.v3FullPath.boards);
    return null;
  }

  return (
    <BoardProvider
      initialState={{
        board: boardRes.getBoard,
        selectedChart: null,
        openChartModal: () => false,
        closeChartModal: () => false,
      }}
    >
      <ChartProvider initialState={defaultChartState}>
        <Routes>
          <Route path={`/charts/:chartId`} element={<ChartsPage />} />
          <Route path={`/charts/creator`} element={<ChartsPage />} />
          <Route path={`/`} element={<BoardPage />} />
        </Routes>
      </ChartProvider>
    </BoardProvider>
  );
};

const SkeletonPage = () => {
  return (
    <div className="flex flex-col gap-y-3">
      <BoardHeaderSectionSkeleton backButton={undefined} />
      <BoardFilterSectionSkeleton />
    </div>
  );
};
