import { useState } from 'react';
import { useOrgManagedByOktaQuery, useRequestAccessMutation } from '../../generated/graphql';
import Button, { ButtonVariant } from '../../v3/baseComponents/Button';
import { AppRoutes } from '../../Routes';
import LoadingSpinner from '../../v3/baseComponents/LoadingSpinner';
export default function DeniedAccessPage() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const teamId = urlSearchParams.get('teamId');
  // get the team id from url params

  // onclick request access
  // if page refresh, check page permissions.
  // if permitted, then redirect to page.

  const [accessRequested, setAccessRequested] = useState<boolean>(false);
  const { data, loading } = useOrgManagedByOktaQuery({ variables: { teamId: teamId ? Number(teamId) : -1 } });
  const [requestAccessMutation, _] = useRequestAccessMutation({ variables: { teamId: teamId ? Number(teamId) : -1 } });
  const requestAccess = async () => {
    await requestAccessMutation();
    setAccessRequested(true);
  };
  return (
    <div className="flex flex-col gap-y-3 text-licorice-noir">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="mt-[100px] flex h-full w-full flex-col items-center justify-center">
          <Lock />
          <h2 className="my-6 text-center text-2xl font-medium">
            {data?.isOrganizationManagedByOkta
              ? "This organization is managed by an external SSO provider. Please sign in through your company's SSO portal."
              : 'You need permission to access this organization.'}
          </h2>
          {data?.isOrganizationManagedByOkta ? (
            <div></div>
          ) : (
            <div className="flex flex-col gap-y-2 items-center">
              {accessRequested ? (
                <div>Your request to join the organization has been sent.</div>
              ) : (
                <Button variant={ButtonVariant.Primary} text={'Request Access'} onClick={requestAccess} />
              )}
              <a href={AppRoutes.v3FullPath.home}>
                <Button variant={ButtonVariant.Cancel} text="Go to Home Page" />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const Lock = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="250px" height="220px" x="0px" y="0px" viewBox="0 0 512 512">
      <path
        style={{ fill: '#C7CFE2' }}
        d="M365.463,283.767c-18.849,0-34.13-15.281-34.13-34.13V143.593c0-41.539-33.795-75.332-75.332-75.332
        c-41.539,0-75.333,33.795-75.333,75.332v106.044c0,18.849-15.281,34.13-34.13,34.13s-34.13-15.281-34.13-34.13V143.593
        C112.408,64.416,176.823,0,256,0s143.592,64.416,143.592,143.592v106.044C399.594,268.486,384.312,283.767,365.463,283.767z"
      />
      <g style={{ opacity: 0.1 }}>
        <path
          d="M146.538,283.767c18.849,0,34.13-15.281,34.13-34.13v-76.023h-68.26v76.023
            C112.408,268.486,127.688,283.767,146.538,283.767z"
        />
        <path d="M331.333,173.614v76.023c0,18.849,15.281,34.13,34.13,34.13s34.13-15.281,34.13-34.13v-76.023H331.333z" />
      </g>
      <path
        style={{ fill: '#FFD782' }}
        d="M404.055,207.745H107.945c-17.577,0-31.825,14.249-31.825,31.825v240.605
        c0,17.577,14.248,31.825,31.825,31.825h296.111c17.577,0,31.825-14.248,31.825-31.825V239.57
        C435.882,221.993,421.632,207.745,404.055,207.745z"
      />
      <path
        style={{ opacity: 0.1 }}
        d="M295.213,337.119c0-21.657-17.557-39.213-39.213-39.213
        s-39.213,17.557-39.213,39.213c0,12.519,5.874,23.66,15.009,30.839v74.598h48.408v-74.598
        C289.338,360.778,295.213,349.638,295.213,337.119z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
