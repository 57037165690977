/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { logEvent, Events, Properties } from '../../v2/AnalyticsUtil';
import { classNames } from '../../v2/util';

interface ToggleProps {
  initialState: boolean;
  id?: string;
  onSwitch: (newValue: boolean) => void;
  value?: boolean;
  eventName?: Events;
  properties?: Properties;
  logClickEvent?: (eventName: Events, properties: Properties) => void;
  elementDisabled?: boolean; //Not named disabled to avoid confusion with the Toggle state.
}

export default function Toggle({ initialState, onSwitch, value, eventName, properties, logClickEvent, id, elementDisabled, ...props }: ToggleProps) {
  const [enabled, setEnabled] = useState(initialState);

  return (
    <div id={id} className="flex flex-row items-center">
      <Switch
        checked={value ?? enabled}
        disabled={elementDisabled}
        onChange={() => {
          if (elementDisabled) return;
          if (!value) setEnabled((s) => !s);
          if (eventName && properties) {
            logEvent(eventName, properties);
          }
          onSwitch(value ?? !enabled);
        }}
        className={classNames(
          value ?? enabled ? 'bg-pistachio-gelato' : 'bg-candy-apple',
          'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border border-transparent transition-colors duration-200 ease-in-out',
          elementDisabled && 'cursor-default opacity-60'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ?? enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out mt-[1px] ml-[1px]'
          )}
        />
      </Switch>
    </div>
  );
}
