import { useState } from 'react';
import { OrganizationUserFragment, Role, useSetDefaultRoleMutation } from '../../../generated/graphql';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import DualToneModal from '../../baseComponents/DualToneModal';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { OrgTeamWithInfo } from '../../../v2/contexts/AppContext';
import { IDROPDOWN_DEFAULT_ROLES } from '../orgInfo/utils';
import { useOrgInfoState } from '../../../context/orgInfoContext';
import { logError } from '../../../applicationTelemetry';
import toast from 'react-hot-toast';

export const DefaultRoleModal = ({
  modalOpen,
  callbackModal,
}: {
  views: OrgTeamWithInfo[];
  user: OrganizationUserFragment;
  modalOpen: boolean;
  callbackModal: () => void;
}) => {
  const { organization } = useOrgInfoState();
  const [selectedRole, setSelectedRole] = useState<Role>(organization.defaultRole);
  const [setDefaultRoleMutation, setDefaultRoleMutationRes] = useSetDefaultRoleMutation();
  const handleConfirm = async () => {
    await setDefaultRoleMutation({
      variables: {
        defaultRole: selectedRole,
        orgId: organization.id,
      },
      onCompleted: () => {
        callbackModal();
      },
      onError: (error) => {
        toast.error('Could not set the default role at this time.')
        console.error(`failed to set the default role: ${error}`);
        logError(`failed to set the default role: ${error}`);
      }
    });
  };
  const getFormButtons = () => {
    return (
      <div className="mt-4 flex flex-row justify-between gap-x-4 text-center w-full">
        <div className="flex w-1/3">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={callbackModal} text="Cancel" expandWidth></Button>
        </div>
        <div className="flex w-2/3">
          <Button
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            text={'Confirm'}
            loadingConfirm={setDefaultRoleMutationRes.loading}
            disabled={setDefaultRoleMutationRes.loading}
            onClick={handleConfirm}
            expandWidth
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <DualToneModal
      open={modalOpen}
      setOpen={callbackModal}
      headerChildren={
        <div className="flex flex-col items-center text-center gap-y-5">
          <div className="gap-x-2">
            <p className="text-3xl font-medium text-licorice-noir">Default role</p>
          </div>
          <p className="text-gray-tertiary">Set a default role for new members</p>
        </div>
      }
      bodyChildren={
        <div className="flex flex-col gap-y-5 justify-between w-full text-licorice-noir">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-1">
              <h1 className="text-sm">Default Role</h1>
              <VirtualizedComboBox
                comboBoxData={IDROPDOWN_DEFAULT_ROLES}
                disableClear
                selectedItem={IDROPDOWN_DEFAULT_ROLES.find((role) => role.value === selectedRole)}
                setSelectedItem={(item) => item && setSelectedRole(item.value as Role)}
                placeholder={'Select role'}
              />
            </div>
          </div>
          {getFormButtons()}
        </div>
      }
    />
  );
};
