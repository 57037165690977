import { useContext, useEffect, useState } from 'react';
import { GroupDataContext, GroupDispatchContext } from '../../../context/groupContext';
import Tippy from '@tippyjs/react';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';
import { Action, GetGroupByIdDocument, Resource, useEditGroupMutation } from '../../../generated/graphql';
import { GroupActions } from '../../../reducers/group/GroupDispatch';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import toast from 'react-hot-toast';
import { FilterContext } from '../../../context/filterStatementContext';

export const GroupTitleDisplay = () => {
  const { curTeamId } = useValidTeamAppContext();
  const [editGroup] = useEditGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const { groupData, auxillaryGroupDataLoading } = useContext(GroupDataContext);
  const groupDataDispatch = useContext(GroupDispatchContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const { hasPermission } = useContext(PermissionsContext);
  const canEditGroups = hasPermission(Resource.Groups, Action.Update);

  if (auxillaryGroupDataLoading || !groupData) {
    return <TitleLoadingSkeleton />;
  }

  return (
    <InlineEditableTextArea
      ignorePadding
      textAreaId="group-title-text-area"
      value={groupData.title ?? ''}
      editable={canEditGroups}
      preventEmpty
      onEditComplete={(value) => {
        if (!groupData.id || value === groupData.title) return;
        editGroup({
          variables: { groupId: groupData.id, input: { title: value }, teamId: curTeamId, filterInput: {} },
          onCompleted: (data) => {
            if (!data.editGroup.group) return;
            // dispatch the new title to the app. There's two places the title needs to update (1) the GroupPage and (2) the backing Taxonomy Page
            groupDataDispatch({ type: GroupActions.UPDATE_GROUP_TITLE, payload: { title: data.editGroup.group.title } });
            taxonomyDispatch({ type: 'updateGroupTitle', payload: { groupId: groupData.id, title: data.editGroup.group.title } });
            toast.success('Group title updated');
          },
          onError: () => {
            toast.error('Failed to update group title');
          },
          // this refetches the group data and silently repopulates the apollo cache
          // this makes it so next time you open the group page for this group we pull in correct data from the cache.
          // the other option is to set the fetch policy to no-cache and refetch the data each time
          refetchQueries: [{ query: GetGroupByIdDocument, variables: { groupId: groupData.id, teamId: curTeamId } }],
        });
      }}
      additionalClassNames={
        'text-2xl xl:text-3xl  h-auto font-medium focus:border-none hover:bg-buttercream-frosting focus:bg-buttercream-frosting bg-transparent transition-[background-color] duration-100 rounded-md '
      }
    />
  );
};

export const GroupMentionsDisplay = () => {
  const groupState = useContext(GroupDataContext);

  if (!groupState.groupData || groupState.filteredEntries == null || groupState.totalEntries == null) {
    return <MentionsLoadingSkeleton />;
  }

  /**
   * If the two counts are different it means we've applied filters to our numerator and we want to show the filtered stats
   */
  const showFilteredStats = groupState.filteredEntries !== groupState.totalEntries;

  const filteredPercentage = groupState.filteredEntries === 0 ? '0.0' : (100 * (groupState.groupData.uniqueEntries / groupState.filteredEntries)).toFixed(1);

  const totalPercentage = groupState.totalEntries === 0 ? '0.0' : (100 * (groupState.groupData.uniqueEntries / groupState.totalEntries)).toFixed(1);

  return (
    <div className="flex flex-row font-light text-sm gap-x-1 items-end" data-testid="mentions-title-bar">
      <p className="font-medium text-licorice-noir">{`${groupState.groupData.uniqueEntries.toLocaleString()} mention${
        groupState.groupData.uniqueEntries === 1 ? '' : 's'
      }`}</p>
      -
      <Tippy
        theme="dark"
        delay={200}
        content={
          <p className="text-center">
            {showFilteredStats ? `${filteredPercentage}% of filtered feedback, ` : ''}
            {totalPercentage}% of all feedback.
          </p>
        }
      >
        <p className="text-gray-tertiary ">{showFilteredStats ? `${filteredPercentage}% of filtered feedback` : `${totalPercentage}% of all feedback`}</p>
      </Tippy>
    </div>
  );
};

const TitleLoadingSkeleton = () => {
  return (
    <div
      data-testid="title-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-buttercream-frosting relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-10 w-full my-1"
    ></div>
  );
};
const MentionsLoadingSkeleton = () => {
  return (
    <div
      data-testid="mentions-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-buttercream-frosting relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-licorice-noir  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-buttercream-frosting-100 opacity-70 h-6 w-[8rem] my-1"
    ></div>
  );
};
