import { formattedDate } from '../../../baseComponents/DatePicker';

type Creator = {
  isUnwrapGenerated: boolean;
  creatorEmail?: string; // because groups are created by unwrap without an explicit person
};

interface CreatedByProps {
  creator: Creator;
  dateCreated: Date;
}

/*
creator: string 
dateCreated: number (Unix timestamp in ms)
*/
const CreatedBy = ({ creator, dateCreated }: CreatedByProps) => {
  // If we don't have a creator email, we assume it's unwrap generated
  const isUnwrapGenerated = creator.isUnwrapGenerated || !creator.creatorEmail;

  // convert unix timestamp to local date in format MMM DD, YYYY (format from DatePicker)
  const localDateCreated = formattedDate(dateCreated);

  return (
    <div className="flex flex-row items-center gap-x-1 flex-wrap">
      <div className="text-gray-tertiary">Created by</div>
      <div className="break-all">{isUnwrapGenerated ? 'Unwrap.ai' : creator.creatorEmail}</div>
      <div className="text-gray-tertiary">on</div>
      <div>{localDateCreated}</div>
    </div>
  );
};

export default CreatedBy;
