import { useState } from 'react';
import { DigestObjectType } from '../../../reducers/digests/digestDataReducer';

interface DigestDigestSeriesListChooserProps {
  chosenState: DigestObjectType;
  setChoseState: React.Dispatch<React.SetStateAction<DigestObjectType>>;
}
const DigestDigestSeriesListChooser = ({ chosenState, setChoseState }: DigestDigestSeriesListChooserProps) => {
  const [currentTabId, setCurrentTabId] = useState<number>(chosenState === DigestObjectType.Digest ? 0 : 1);
  const tabs = [
    {
      id: 0,
      name: 'Digests',
    },
    { id: 1, name: 'Digest Series' },
  ];
  return (
    <div className="flex flex-row gap-x-4 mt-2 items-baseline">
      {tabs.map((tab) => {
        return (
          <p
            key={tab.id}
            id={tab.name.replace(' ', '')}
            className={`${
              currentTabId === tab.id ? 'font-medium text-licorice-noir decoration-2 underline underline-offset-4' : ' text-gray-tertiary'
            } cursor-pointer text-lg`}
            onClick={() => {
              setCurrentTabId(tab.id);
              setChoseState(tab.id === 0 ? DigestObjectType.Digest : DigestObjectType.Series);
            }}
          >
            {tab.name}
          </p>
        );
      })}
    </div>
  );
};

export default DigestDigestSeriesListChooser;
