import { ToolbarBaseButton } from './ToolbarBaseButton';
import { Fragment, useContext } from 'react';
import { Icon } from '@iconify/react';
import { Popover, Transition } from '@headlessui/react';
import GroupTaxonomyEdit from '../../../GroupTaxonomyEdit';
import { Action, Resource } from '../../../../../generated/graphql';
import { PermissionsContext } from '../../../../../v2/contexts/PermissionsContext';
import { GroupDataContext } from '../../../../../context/groupContext';
import { TaxonomyGroup } from '../../../../reducers/taxonomy';

export function EditTaxonomyButton(props: { taxonomy: Map<string, TaxonomyGroup>; buttonRounding?: 'left' | 'right' }) {
  const groupState = useContext(GroupDataContext);
  const { hasPermission } = useContext(PermissionsContext);
  const canUpdateTaxonomy = hasPermission(Resource.Taxonomy, Action.Update);
  const disabledTooltip = 'You do not have permission to edit taxonomies. Please contact your administrator and request the contributor role.';

  const { taxonomy } = props;
  if (!canUpdateTaxonomy) {
    return (
      <ToolbarBaseButton
        disabled={true}
        disabledTooltip={disabledTooltip}
        id="toolbar-edit-taxonomy-button"
        icon={<Icon icon="mdi:file-tree" color={'#1D1E30'} width="20" height="20" />}
        label={'Configure Taxonomy'}
        buttonRounding={props.buttonRounding}
      />
    );
  }

  return (
    <Popover className="relative">
      <>
        <Popover.Button
          className={`rounded-md bg-buttercream-frosting text-licorice-noir flex flex-row justify-center items-center h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none cursor-pointer`}
        >
          <ToolbarBaseButton
            dataCy="toolbar-edit-taxonomy-button"
            id="toolbar-edit-taxonomy-button"
            icon={<Icon icon="mdi:file-tree" color={'#1D1E30'} width="20" height="20" />}
            label={'Configure Taxonomy'}
            buttonRounding={props.buttonRounding}
          />{' '}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 px-8 z-50 flex">
            {!!groupState.groupData && <GroupTaxonomyEdit groupId={groupState.groupData.id} />}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}
