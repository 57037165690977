import { Chart_TypeToChartType, ChartType, chartTypeToChart_Type } from './chartElements/ChartTypeOptions';
import { ChartTypeOptions } from './chartElements/ChartTypeOptions';
import { Interval, intervalOptionsAndLabels } from './chartElements/IntervalSelector';
import { Option } from './chartElements/SelectorDropdown';
import DateFilterComponent from '../filters/filterBar/DateFilterComponent';
import { BreakdownSelectorAndModal, BreakdownID } from './chartElements/BreakdownSelectorAndModal';
import {
  PlotUnitSelectorAndModal,
  PlotUnit,
  Y_Axis_DataToPlotUnit,
  defaultPlotUnitOptions,
  plotUnitToY_Axis_Data,
} from './chartElements/PlotUnitSelectorAndModal';
import { Chart_Bin_TypeToInterval, IntervalSelectorAndModal, intervalToChart_Bin_Type } from './chartElements/IntervalSelectorAndModal';
import { ChartWrapper } from './ChartWrapper';
import { useEffect, useState } from 'react';
import { useChartDispatch, useChartState } from '../../../context/chartContext';
import { Breakdown } from '../../../generated/graphql';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import { ExpandButton } from './chartElements/ExpandButton';

export interface IChartWithIntegratedSettingsProps {
  teamId: number;
  breakdowns: Option<BreakdownID>[];
  selectedBreakdown: Option<BreakdownID>;
  selectedChartType: Option<ChartType>;
  tempChartComponent: React.ReactNode; // TODO: Remove this once we have a standardized chart component
  numberOfElements?: number;
  disableBreakdown?: boolean;
  expandChart?: () => void;
}

export const ChartWithIntegratedSettings = ({
  teamId,
  breakdowns,
  selectedBreakdown,
  selectedChartType,
  tempChartComponent,
  numberOfElements = 0,
  disableBreakdown = false,
  expandChart,
}: IChartWithIntegratedSettingsProps) => {
  const chartDispatch = useChartDispatch();
  const chartState = useChartState();

  const handleSetSelectedChartType = (chartType: Option<ChartType>) => {
    chartDispatch({
      type: ChartActionType.UpdateChartType,
      payload: { type: chartTypeToChart_Type[chartType.value] },
    });
  };

  const handleSetSelectedInterval = (interval: Option<Interval>) => {
    chartDispatch({ type: ChartActionType.SetBinType, payload: { binType: intervalToChart_Bin_Type[interval.value] } });
  };

  const handleSetSelectedPlotUnit = (plotUnit: Option<PlotUnit>) => {
    chartDispatch({ type: ChartActionType.UpdateYAxisMetric, payload: { yAxisMetric: plotUnitToY_Axis_Data[plotUnit.value] } });
  };

  const handleSetSelectedBreakdown = (breakdown: Option<BreakdownID>) => {
    const newBreakdown = breakdown.value === -1 ? undefined : breakdown.value && typeof breakdown.value !== 'number' ? breakdown.value : Breakdown.Segment;

    chartDispatch({
      type: ChartActionType.UpdateBreakdownAndSegmentGroupId,
      payload: {
        breakdown: newBreakdown,
        segmentGroupId: newBreakdown === Breakdown.Segment ? breakdown.value : undefined,
      },
    });
  };

  const [isPlotUnitSelectorModalOpen, setIsPlotUnitSelectorModalOpen] = useState(false);
  const [isBreakdownSelectorModalOpen, setIsBreakdownSelectorModalOpen] = useState(false);
  const [isIntervalSelectorModalOpen, setIsIntervalSelectorModalOpen] = useState(false);

  const togglePlotUnitSelectorModal = (newPlotUnitSelectorModalOpen: boolean) => {
    setIsBreakdownSelectorModalOpen(false);
    setIsIntervalSelectorModalOpen(false);
    setIsPlotUnitSelectorModalOpen(newPlotUnitSelectorModalOpen);
  };

  const toggleBreakdownSelectorModal = (newBreakdownSelectorModalOpen: boolean) => {
    setIsPlotUnitSelectorModalOpen(false);
    setIsIntervalSelectorModalOpen(false);
    setIsBreakdownSelectorModalOpen(newBreakdownSelectorModalOpen);
  };

  const toggleIntervalSelectorModal = (newIntervalSelectorModalOpen: boolean) => {
    setIsPlotUnitSelectorModalOpen(false);
    setIsBreakdownSelectorModalOpen(false);
    setIsIntervalSelectorModalOpen(newIntervalSelectorModalOpen);
  };

  const closeAllModals = () => {
    setIsPlotUnitSelectorModalOpen(false);
    setIsBreakdownSelectorModalOpen(false);
    setIsIntervalSelectorModalOpen(false);
  };

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      const isOutsideClick = !(event.target as Element).closest('[role="dialog"]');

      if (isOutsideClick) {
        closeAllModals();
      }
    };

    document.addEventListener('mousedown', handleGlobalClick);

    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  }, []);
  return (
    <div className="flex flex-col bg-white p-5 border-[1px] rounded-lg border-buttercream-frosting-100 gap-y-5">
      <div className="flex flex-row justify-between flex-nowrap gap-x-[8px] gap-y-[8px]">
        <ChartTypeOptions
          selectedChartType={{
            value: selectedChartType.value ?? 'line',
            label: `${selectedChartType.label ?? 'Line'} Chart`,
          }}
          setSelectedChartType={handleSetSelectedChartType}
        />
        <div className="flex flex-row justify-end xl:gap-y-2 flex-wrap gap-x-1 items-center">
          <DateFilterComponent teamId={teamId} />
          {expandChart && <ExpandButton onClick={expandChart} />}
        </div>
      </div>
      <div className="flex flex-1 flex-row h-[336px] w-full gap-x-5">
        <PlotUnitSelectorAndModal
          selectedPlotUnit={
            chartState.chartConfigs?.yAxisMetric
              ? {
                  value: Y_Axis_DataToPlotUnit[chartState.chartConfigs.yAxisMetric],
                  label:
                    defaultPlotUnitOptions.find((option) => option.value == Y_Axis_DataToPlotUnit[chartState.chartConfigs?.yAxisMetric])?.label ??
                    'Unknown Plot Unit',
                }
              : defaultPlotUnitOptions[2]
          }
          setSelectedPlotUnit={handleSetSelectedPlotUnit}
          isPlotUnitSelectorModalOpen={isPlotUnitSelectorModalOpen}
          togglePlotUnitSelectorModal={togglePlotUnitSelectorModal}
        />
        <div className="h-[336px] w-full overflow-y-auto">
          <ChartWrapper chartType={selectedChartType.value} numberOfElements={numberOfElements}>
            {tempChartComponent}
          </ChartWrapper>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2 justify-between items-center ml-[52px]">
        <BreakdownSelectorAndModal
          selectedBreakdown={selectedBreakdown}
          setSelectedBreakdown={handleSetSelectedBreakdown}
          breakdowns={breakdowns}
          disabled={disableBreakdown}
          isBreakdownSelectorModalOpen={isBreakdownSelectorModalOpen}
          toggleBreakdownSelectorModal={toggleBreakdownSelectorModal}
        />
        <IntervalSelectorAndModal
          selectedInterval={
            chartState.chartConfigs?.binSelection
              ? {
                  value: Chart_Bin_TypeToInterval[chartState.chartConfigs.binSelection],
                  label: intervalOptionsAndLabels[Chart_Bin_TypeToInterval[chartState.chartConfigs.binSelection]],
                }
              : { value: 'auto', label: 'Auto' }
          }
          setSelectedInterval={handleSetSelectedInterval}
          isIntervalSelectorModalOpen={isIntervalSelectorModalOpen}
          toggleIntervalSelectorModal={toggleIntervalSelectorModal}
        />
      </div>
    </div>
  );
};
