import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Unwrap_Feature, useUpsertReviewMutation } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import UserContext from '../../../v2/contexts/UserContext';
import { AssistantDataContext } from '../../../context/assistantContext';

interface FeedbackBoxProps {
  id: number;
  rating: number;
  setShowFeedbackBox: (show: boolean) => void;
}

export const FeedbackBox: React.FC<FeedbackBoxProps> = ({ id, rating, setShowFeedbackBox }) => {
  const {curTeamId: teamId} = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const { question } = useContext(AssistantDataContext);

  const [submitReview, {loading}] = useUpsertReviewMutation({
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    onCompleted: (data) => {
      if(data.upsertReview.success) {
        toast.success('Feedback submitted successfully!');
        setShowFeedbackBox(false);
      } else {
        toast.error(`Error: ${data.upsertReview.message}`);
      }
    },
  });
  const [feedback, setFeedback] = useState('');

  const submit = () => {
    const fullReviewText = `${user?.email ?? 'Undefined'} ${rating > 0 ? 'liked' : 'disliked'} the answer to "${question}": ${feedback}`;
    submitReview({ variables: { id, feedback: fullReviewText, rating, teamId, feature: Unwrap_Feature.Assistant } });
  };

  return (
    <div className={"flex flex-col justify-center pl-4 fade-out"}>
      <div className="mt-4 h-full rounded-lg shadow-sm border border-buttercream-frosting-300 text-licorice-noir"
           data-testid="feedback-box">
        <textarea
          style={{minWidth: '20rem'}}
          className="block w-full pt-2 resize-none border-0 bg-transparent py-1.5 placeholder:text-gray-tertiary focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Tell us more..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />


      </div>
      <button
        type="button"
        onClick={submit}
        className="flex justify-center items-center text-center font-circular bg-white py-2 px-3 my-2 border border-buttercream-frosting-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-secondary hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-licorice-noir"
      >
        {loading ? (
          <svg
            className="mr-2 h-4 w-4 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          "Submit"
        )}
      </button>
    </div>

  );
};