import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ModalProps {
  headerChildren: JSX.Element;
  bodyChildren: JSX.Element;
  setOpen: (open: boolean) => void;
  open: boolean;
  width?: string;
}

const DualToneModal = ({ open, setOpen, headerChildren, bodyChildren, width = 'w-[42rem]',  }: ModalProps): JSX.Element => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog onClick={(e) => e.stopPropagation()} as="div" className="fixed inset-0 z-50 overflow-y-auto font-circular" onClose={() => setOpen(false)}>
        <div className="flex min-h-screen items-end justify-center px-8 pt-4 pb-20 text-center font-circular sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-tertiary bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block ${width} transform text-left align-bottom shadow-xl transition-all sm:align-middle text-licorice-noir`}>
              <div className="flex flex-col w-full h-full">
                <div className="flex flex-col justify-center items-center bg-[#ECECF2] rounded-t-xl px-12 py-8">{headerChildren}</div>
                <div className="flex flex-col justify-center items-center bg-white rounded-b-xl px-12 py-6">{bodyChildren}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DualToneModal;
