import { GroupFull, writeToastMessage } from '../../../v2/hooks/GroupHook';
import { FilterHook } from '../../hooks/FilterHook';
import { useContext, useState, useEffect } from 'react';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import GroupPreview from '../../components/GroupPreview';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../v2/components/LoadingSpinner';
import { AppRoutes } from '../../../Routes';
import { useInView } from 'react-intersection-observer';
import AppContext, { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { GroupDeleter } from '../../components/GroupDeleter';
import { classNames } from '../../../v2/util';
import { PageWrapper } from '../PageWrapper';
import InsightSliderController from '../../components/insights/InsightSliderController';
import { getGroupPageUrl } from '../../lib/groups';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { RectangleStackIcon } from '@heroicons/react/24/outline';

interface HomePageProps {
  pageName: string;
  groupHook: HomeGroupHook;
  filterHook: FilterHook;
}

const HomePage = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const { orgsHaveLoaded } = useContext(AppContext);
  // user switched teams, no orgs are in url or local storage, show spinner
  if (!orgsHaveLoaded)
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  return <HomePageWithTeam pageName={pageName} groupHook={groupHook} filterHook={filterHook} />;
};

const HomePageWithTeam = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const [hasInsightGroups, setHasInsightGroups] = useState(false);

  const { curTeamId: teamId, curOrgId: orgId, currentTeam, currentOrg } = useValidTeamAppContext();
  const navigate = useNavigate();
  const pageSize = 9;

  const { ref, inView } = useInView({ rootMargin: '100px', threshold: 0 });
  const [paginating, setPaginating] = useState(false);
  const [announcementId, setAnnouncementId] = useState<string | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<string | null>(null);

  /**
   * wut is this for? If there's a tone of pinned groups?
   */
  const loadMoreGroups = async () => {
    setPaginating(true);
    await groupHook.loadMore(groupHook.groups.length, pageSize);
    setPaginating(false);
  };

  useEffect(() => {
    if (inView && !paginating) {
      // load more groups
      loadMoreGroups();
    }
  }, [inView]);
  if (!paginating && groupHook.isLoading && groupHook.groups.length === 0 && !window.location.pathname.includes('/group/')) {
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <PageWrapper title="" styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
      <>
        {/* {announcementId != null && (
          <AnnouncementModal
            groupId={announcementId}
            modalOpen={true}
            setModalOpen={() => setAnnouncementId(undefined)}
            teamId={teamId}
            orgId={orgId}
            orgName={currentOrg?.name}
            filterInput={filterHook.filters}
          />
        )} */}

        {curGroupIdToDelete !== null ? (
          <GroupDeleter
            groupToDelete={curGroupIdToDelete}
            closeCallback={() => setCurGroupIdToDelete(null)}
            deleteGroup={groupHook.discardGroup}
            deleteCallback={() => {
              groupHook.setCurrentGroup(undefined);
              navigate('/dashboard/home', { replace: true });
            }}
            loadingDelete={groupHook.loadingStatuses.discardingGroup}
          />
        ) : null}
        <InsightSliderController pageName={pageName} />

        {groupHook.groups.length > 0 ? (
          <>
            <div className="pt-2">
              <h1 className="text-xl font-recoleta text-licorice-noir">Pinned By Me</h1>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {groupHook.groups.map((group) => (
                <GroupPreview
                  key={group.id}
                  isCondensedView={true}
                  group={group as GroupFull}
                  filterInput={filterHook.filters}
                  page={pageName}
                  discardSearchGroup={() => {
                    setCurGroupIdToDelete(group.id);
                  }}
                  togglePinGroup={groupHook.togglePinGroup}
                  replaceOrAddToSearchGroups={groupHook.replaceOrAddToSearchGroups}
                  getAllGroupSentences={() => groupHook.loadAllSentences(group.id)}
                  loadingAllSentences={groupHook.loadingStatuses.loadingAllSentences}
                  updateProgress={groupHook.updateProgress}
                  copyLink={(groupId) => {
                    groupHook.copyGroupLink(groupId, filterHook.filters);
                    writeToastMessage('Copied group link to clipboard');
                  }}
                  onSeeMoreClick={() => {
                    // does currentGroup need set anymore same with refetching similar sentences?
                    if (groupHook.currentGroup?.id !== group.id) {
                      groupHook.setCurrentGroup(group);
                      groupHook.refetchSimilarSentences('', 0, 10);
                    }
                    navigate(getGroupPageUrl(teamId, orgId, group.id));
                  }}
                  editTitle={groupHook.editTitle}
                  openAnnouncementModal={() => setAnnouncementId(group.id)}
                />
              ))}
            </ul>

            <div className="flex justify-center items-center" ref={ref}>
              {groupHook.loadingPins && <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-licorice-noir"></div>}
            </div>
          </>
        ) : !groupHook.initialLoadComplete || groupHook.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="grid grid-cols-1">
            <div className={classNames('col-span-1 flex flex-col justify-center gap-y-5', hasInsightGroups ? 'items-start' : 'items-center')}>
              <h1 className="text-4xl font-recoleta font-medium text-licorice-noir">Welcome to Unwrap!</h1>
              <p className="font-light text-center text-gray-secondary">
                You currently have no pinned Feedback Groups.
                <br />
                Visit the Explore page to browse all insights and pin relevant Groups to your Home page.
              </p>
            </div>
            <div className="flex justify-center mt-10">
              <div className="flex flex-col md:flex-row w-full lg:w-4/5 xl:w-2/3 gap-4">
                <a
                  className="flex  items-end w-full md:w-1/3 bg-peach-cobbler-glaze hover:bg-peach-cobbler hover:text-bg-peach-cobbler-glaze group rounded-md p-5 min-h-[10rem] grow-1 duration-200 cursor-pointer"
                  onClick={() => navigate(AppRoutes.v3FullPath.explore)}
                >
                  <div className="flex gap-4 justify-between items-center w-full cursor-pointer">
                    <h2 className="font-recoleta text-2xl text-peach-cobbler w-full group-hover:text-peach-cobbler-glaze white">
                      Explore <br></br>Groups
                    </h2>
                    <MagnifyingGlassIcon className="h-8 w-8 stroke-3 text-peach-cobbler group-hover:text-peach-cobbler-glaze shrink-0" />
                  </div>
                </a>
                <a
                  className="flex items-end w-full md:w-1/3 bg-pistachio-gelato-glaze hover:bg-pistachio-gelato hover:text-bg-pistachio-gelato-glaze group rounded-md p-5 min-h-[10rem] grow-1 duration-200 cursor-pointer"
                  onClick={() => navigate(AppRoutes.v3FullPath.assistant)}
                >
                  <div className="flex gap-4 justify-between items-center w-full cursor-pointer">
                    <h2 className="font-recoleta text-2xl text-pistachio-gelato w-full group-hover:text-pistachio-gelato-glaze white">
                      Ask <br></br>Assistant
                    </h2>
                    <ChatBubbleBottomCenterTextIcon className="h-8 w-8 stroke-3 text-pistachio-gelato group-hover:text-pistachio-gelato-glaze shrink-0" />
                  </div>
                </a>
                <a
                  className="flex items-end w-full md:w-1/3 bg-blueberry-pie-glaze hover:bg-blueberry-pie hover:text-bg-blueberry-pie-glaze group rounded-md p-5 min-h-[10rem] grow-1 duration-200 cursor-pointer"
                  onClick={() => navigate(AppRoutes.v3FullPath.boards)}
                >
                  <div className="flex gap-4 justify-between items-center w-full cursor-pointer">
                    <h2 className="font-recoleta text-2xl text-blueberry-pie w-full group-hover:text-blueberry-pie-glaze white">
                      Review <br></br>Boards
                    </h2>
                    <RectangleStackIcon className="h-8 w-8 stroke-3 text-blueberry-pie group-hover:text-blueberry-pie-glaze shrink-0" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    </PageWrapper>
  );
};

export default HomePage;
