import { FC } from 'react';

export interface ISelectorButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  ref?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
}

export const SelectorButton: FC<ISelectorButtonProps> = ({ children, onClick, ref, disabled = false }) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={`flex h-[32px] w-fit justify-center items-center px-2 py-2 rounded-md bg-buttercream-frosting border border-buttercream-frosting-100 ${
        disabled ? '' : 'hover:cursor-pointer hover:bg-buttercream-frosting-100 hover:bg-opacity-50'
      } transition-all duration-200`}
      ref={ref}
    >
      {children}
    </div>
  );
};
