import { memo, useRef, useState, useCallback } from 'react';
import { Chart as ChartJS, ChartOptions, ChartData, ChartTypeRegistry, Filler } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { isEqual } from 'underscore';
import { Chart_Type } from '../../generated/graphql';
import { LegendItem } from 'chart.js';
import ChartLegend from '../components/charts/ChartLegend';
import { classNames } from '../../v2/util';
import { Chart_TypeToChartType } from '../components/standardCharts/chartElements/ChartTypeOptions';
import { generateChartColors } from '../components/standardCharts/generateChartColors';
function getChartLegendItems(data: ChartData, chartType: Chart_Type): LegendItem[] {
  if (!data?.datasets?.[0]) {
    return [];
  }

  const { backgroundColors, borderColors } = generateChartColors({
    chartType: Chart_TypeToChartType[chartType] ?? 'horizontal',
    numberOfColors: data.labels?.length ?? 0,
  });

  if (chartType === Chart_Type.Pie) {
    return (
      data.labels?.map((label, index) => ({
        text: label as string,
        fillStyle: backgroundColors(index),
        strokeStyle: borderColors(index),
        hidden: false,
        lineWidth: 1,
        index: index,
      })) ?? []
    );
  } else if (chartType === Chart_Type.HorizontalBar) {
    return (
      data.labels?.map((label, index) => ({
        text: label as string,
        fillStyle: backgroundColors(index),
        strokeStyle: borderColors(index),
        hidden: false,
        lineWidth: 1,
        index: index,
      })) ?? []
    );
  } else {
    return data.datasets.map((dataset, index) => ({
      text: dataset.label || '',
      fillStyle: backgroundColors(index),
      strokeStyle: borderColors(index),
      hidden: dataset.hidden ?? false,
      lineWidth: 1,
      index: index,
    }));
  }
}

const ChartView = ({ data, options, type, hideLegends }: { data: ChartData; options: ChartOptions; type: Chart_Type; hideLegends?: boolean }) => {
  const chartRef = useRef<ChartJS | null>(null);
  const [legendItems, setLegendItems] = useState<LegendItem[]>(getChartLegendItems(data, type));

  const handleLegendClick = useCallback(
    (index: number) => {
      if (chartRef.current) {
        const chart = chartRef.current;

        if (type !== Chart_Type.Pie && type !== Chart_Type.HorizontalBar) {
          const datasetMeta = chart.getDatasetMeta(index);
          datasetMeta.hidden = datasetMeta.hidden === null ? true : !datasetMeta.hidden;

          setLegendItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, hidden: datasetMeta.hidden } : item)));

          chart.update();
        }
      }
    },
    [type]
  );

  const chartOptions: ChartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className={classNames('w-full h-full', hideLegends ? '' : 'pb-9')}>
        <Chart ref={chartRef} type={getChartTypeRegistry(type)} data={data} options={chartOptions} plugins={[Filler]} />
      </div>
      {hideLegends ? null : (
        <div className="-mt-9">
          <ChartLegend items={legendItems} onLegendClick={handleLegendClick} />
        </div>
      )}
    </div>
  );
};

const getChartTypeRegistry = (type: Chart_Type) => {
  if (type === Chart_Type.StackedBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.VerticalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Line) return 'line' as keyof ChartTypeRegistry;
  if (type === Chart_Type.HorizontalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Pie) return 'doughnut' as keyof ChartTypeRegistry;
  return 'line';
};

/**
 * Returns true if:
 *  - If the number of annotations on the chart are the same
 *  - That each array of annotations have objects w/ the same annotationId, title, date, description, and value
 *  - The data has not changed
 * @param prevProps
 * @param nextProps
 * @returns bool
 */
const arePropsEqual = (
  prevProps: Readonly<{ data: ChartData; options: ChartOptions; type: Chart_Type }>,
  nextProps: Readonly<{ data: ChartData; options: ChartOptions; type: Chart_Type }>
) => {
  const areAnnotationsEqual = () => {
    const prevAnnotations = Object.values(prevProps.options.plugins?.annotation?.annotations ?? {});
    const nextAnnotations = Object.values(nextProps.options.plugins?.annotation?.annotations ?? {});
    /**
     * Returns a copy of an annotation containing only the properties to be checked for equality
     * @param annotation
     * @returns
     */
    const comparableAnnotation = (annotation: Partial<ChartAnnotationOptions>) => {
      const updatedAnnotation = {
        annotationId: annotation?.annotationId,
        date: annotation?.date,
        title: annotation?.title,
        description: annotation?.description,
        value: annotation?.value,
      };
      return updatedAnnotation;
    };
    return prevAnnotations.length
      ? prevAnnotations.every((prev, i) => {
          const prevA = comparableAnnotation(prev as ChartAnnotationOptions);
          const nextA = comparableAnnotation(nextAnnotations[i] as ChartAnnotationOptions);
          return isEqual(prevA, nextA);
        }) && prevAnnotations.length === nextAnnotations.length
      : prevAnnotations.length === nextAnnotations.length;
  };
  const areDatasetsEqual = () => {
    const prevData = prevProps.data.datasets.map((data) => data.data);
    const nextData = nextProps.data.datasets.map((data) => data.data);
    return isEqual(prevData, nextData);
  };
  const areOptionsEqual = () => {
    const prevOptions = prevProps.options;
    const nextOptions = nextProps.options;
    // const equalOld = isEqual(prevOptions, nextOptions);
    const areEqual = JSON.stringify(prevOptions) === JSON.stringify(nextOptions);

    return areEqual;
  };

  const isTypeEqual = () => {
    const typeEqual = prevProps.type === nextProps.type;
    return typeEqual;
  };

  const areAllEqual = isTypeEqual() && areOptionsEqual() && areAnnotationsEqual() && areDatasetsEqual();
  return areAllEqual;
};

// returns a memoized version of the chart to prevent excessive re-renders
export default memo(ChartView, arePropsEqual);

type ChartAnnotationOptions = {
  annotationId?: string;
  date?: string;
  title?: string;
  description?: string;
  value?: number;
};
