import { FilterNodeState } from '../../../../reducers/filterStatement/filterStatementReducer';
import { AppliedFilter } from '../appliedFilters/AppliedFilter';
import { OperatorSelector } from './RootFilter';

export const DisabledFilterDisplay: React.FC<{ filter: FilterNodeState | undefined }> = ({ filter }) => {
  if (!filter) return <></>;
  if (filter.type !== 'collection') throw new Error('Root filter must be a collection');

  return (
    <div className="flex flex-row gap-x-2 items-center relative pointer-events-none">
      <div className="flex flex-row flex-wrap gap-x-2 gap-y-2 items-center">
        <>
          {filter.items.map((item, index) => (
            <div className="h-8 flex flex-row gap-x-2">
              {index > 0 && (
                <span key={`operator-${index}`} className="flex items-center relative group">
                  <OperatorSelector filter={filter} />
                </span>
              )}
              <span key={`filter-${item.id}`} className="flex bg-buttercream-frosting-100 bg-opacity-50 border border-buttercream-frosting-100 px-3 rounded-md relative group">
                <AppliedFilter filter={item} key={item.id} />
              </span>
            </div>
          ))}
        </>
      </div>
    </div>
  );
};
