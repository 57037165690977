import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { useIsMount } from '../../v2/util';
import AppContext, { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { AppRoutes } from '../../Routes';
import UserContext from '../../v2/contexts/UserContext';
import { Insight_Collection_Type } from '../../generated/graphql';
import 'tippy.js/themes/light.css';
import { PageWrapper } from './PageWrapper';
import { ScrollToTop } from '../baseComponents/ScrollToTop';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { GroupEntries } from '../components/groups/GroupEntries';
import { GroupPageDataFetch } from '../components/groups/GroupPageDataFetch';
import { GroupMentionsDisplay, GroupTitleDisplay } from '../components/groups/GroupTitleDisplay';
import GroupPageInfoBox from '../components/groups/GroupPageInfoBox';
import CreateInsightFromCollectionModal from '../components/digests/insights/CreateInsightFromCollectionModal';
import { GroupDataContext, GroupDispatchContext, defaultGroupState } from '../../context/groupContext';
import { GroupReducer } from '../../reducers/group/GroupDispatch';
import { GroupLinkedActions, GroupLinkedActionsSkeleton } from '../components/linkedActions/group/GroupLinkedActions';
import { ExternalTicketsProvider } from '../../context/externalTicketsContext';
import { GroupToolbar } from '../components/groups/toolbar/GroupToolbar';
import CreatedBy from '../components/groups/toolbar/CreatedBy';
import { GroupPageBreadcrumbs } from '../components/taxonomy/GroupPageBreadcrumbs';
import FilterBarWrapper from '../components/filters/filterBar/FilterBarWrapper';
import DateFilterComponent from '../components/filters/filterBar/DateFilterComponent';
import { GroupDeleter } from '../components/taxonomy/GroupDeleter';
import { FilterContext } from '../../context/filterStatementContext';
import { FilterablePage } from './FilterablePage';
import { TaxonomyGroup } from '../reducers/taxonomy';
import { TagItem } from '../components/groups/toolbar/TagsPopover';

const GroupPage = () => {
  const { curTeamId } = useValidTeamAppContext();
  const [groupData, dispatch] = useReducer(GroupReducer, defaultGroupState);
  const filterState = useContext(FilterContext);

  return (
    <GroupDispatchContext.Provider value={dispatch}>
      <GroupDataContext.Provider value={groupData}>
        {/* GroupPage is wrapped in it's own FilterContext why??
        Our current behavior is if you change filters on the GroupPage and go back to the ExplorePage the ExplorePage is unchanged
        We want to keep the ExplorePage the same and independent from the GroupPage. We only want to inherit the filters from the ExplorePage to the GroupPage.

        This is why we wrap the GroupPage in it's own FilterContext.

        We don't have to do the behavior above... we could have the explore and group pages consume and modify the same filter state. 
        If we want that we just need to remove the FilterablePage wrapper.
         */}
        <FilterablePage teamId={curTeamId} initialState={filterState}>
          <GroupPageActual />
        </FilterablePage>
      </GroupDataContext.Provider>
    </GroupDispatchContext.Provider>
  );
};

/**
 * GroupHook here is only used in the TaxonomyEdit component. I cannot figure out how that component interacts with the ExplorePage
 * @returns
 */
const GroupPageActual = () => {
  const { user } = useContext(UserContext);
  const groupState = useContext(GroupDataContext);

  const { groupId: URLGroupId } = useParams();
  const { curTeamId, curOrgId } = useContext(AppContext);
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const location = useLocation();
  const teamId = params.get('teamId') != null ? Number(params.get('teamId')) : curTeamId ?? -1; // default to current teamId, but should check belonging via link for proper access denied redirect
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState<boolean>(false);
  const [showShadow, setShowShadow] = useState(false);

  GroupPageDataFetch({ teamId, groupId: URLGroupId! });

  const [digestPanelOpen, setDigestPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (isFirstRender) window.scrollTo(0, 0); //To fix Explore page positioning
  }, []);

  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.explore, { replace: true });
  }, [curTeamId]);

  const taxonomy = useContext(TaxonomyContext);

  const { showBreadcrumbs, groupTaxonomy }: { showBreadcrumbs: boolean; groupTaxonomy: TaxonomyGroup | undefined } = useMemo(() => {
    if (groupState.groupData) {
      const groupTaxonomy = taxonomy.get(groupState.groupData.id);
      if (groupTaxonomy) {
        return { showBreadcrumbs: !!groupTaxonomy.ancestors && groupTaxonomy.ancestors.length > 0, groupTaxonomy };
      }
    }
    return { showBreadcrumbs: false, groupTaxonomy: undefined };
  }, [groupState.groupData, taxonomy]);

  return (
    <PageWrapper
      title=""
      disableMx
      disableGap
      backButton={
        <div className="flex flex-row justify-between">
          <div className={'flex flex-row items-center gap-x-4 mx-8'}>
            <Button
              id="back-button"
              variant={ButtonVariant.Tertiary}
              text="Back"
              onClick={() => {
                location.pathname.includes('home') ? navigate(AppRoutes.v3FullPath.home) : navigate(AppRoutes.v3FullPath.explore);
              }}
              icon={<p>{'<'}</p>}
              iconPosition="left"
            />
            {showBreadcrumbs && !!groupTaxonomy ? (
              <div className="w-full text-licorice-noir font-medium">
                {/* Need to assert non-null because of type inference issue. If we enter this conditional currently, groupTaxonomy cannot be undefined. */}
                <GroupPageBreadcrumbs group={groupTaxonomy} />
              </div>
            ) : null}
          </div>
        </div>
      }
    >
      <ScrollToTop />
      {deleteGroupModalOpen && groupState.groupData?.id ? (
        /**
         * How does this interact with the taxonomy? That doesn't run on the group hook???
         */
        <GroupDeleter
          groupToDelete={groupState.groupData.id}
          closeCallback={() => {
            setDeleteGroupModalOpen(false);
          }}
          deleteCallback={() => {
            setDeleteGroupModalOpen(false);
            navigate('/dashboard/explore', { replace: true });
          }}
        />
      ) : null}

      <div className="z-10 top-0 text-licorice-noir flex flex-col gap-y-8">
        <div className="w-full">
          {groupState.groupData && user?.isUnwrapper ? (
            <CreateInsightFromCollectionModal
              groupTitle={groupState.groupData.title}
              groupSummary={groupState.groupData.summaryText ?? ''}
              isModalOpen={digestPanelOpen}
              setOpen={setDigestPanelOpen}
              teamId={teamId}
              orgId={curOrgId!}
              // this is a hack to get this working for now.
              filterInput={{
                startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
                endDate: new Date(),
              }}
              collection={{ id: groupState.groupData.id, type: Insight_Collection_Type.Group, title: groupState.groupData.title }}
            />
          ) : null}
          <div className={`sticky top-0 z-20 pr-0 bg-white pt-0 transition-all duration-150 ${showShadow ? 'shadow-[0_6px_12px_-12px_rgba(0,0,0,0.6)]' : ''}`}>
            <div className="mx-8 lg:flex flex-col gap-y-1 pt-4 pb-2">
              <div className="flex flex-col sm:flex-row items-center w-full gap-4">
                <div className="w-full sm:flex-1 min-w-0 font-recoleta">
                  <GroupTitleDisplay />
                </div>
                <div className="w-full sm:w-auto">
                  <GroupToolbar
                    showDigestButton={user?.isUnwrapper ?? false}
                    taxonomy={taxonomy}
                    toggleDigestModal={() => setDigestPanelOpen((curVal) => !curVal)}
                    toggleDeleteGroupModal={() => setDeleteGroupModalOpen(true)}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex flex-row gap-x-2 font-light text-sm items-baseline">
                  <GroupMentionsDisplay />
                  {/* why have we let group be undefined here? */}
                  {groupState.groupData && (
                    <div className="flex flex-row gap-x-2 max-xl:hidden">
                      <div className="text-gray-tertiary">|</div>
                      <CreatedBy creator={groupState.groupData.creator} dateCreated={groupState.groupData.dateCreated} />
                    </div>
                  )}
                </div>
                {/* <div className="flex-auto border-t-2 border-buttercream-frosting-100 mx-4"></div> */}
              </div>
              <div className="flex flex-col-reverse flex-wrap md:flex-nowrap md:flex-row items-start gap-x-5 py-4 w-full justify-between">
                <FilterBarWrapper />
                <div className="flex-1 h-[1px] bg-buttercream-frosting-100 rounded-md mt-[16px]" /> {/* Added vertical divider */}
                <DateFilterComponent teamId={teamId} />
              </div>
            </div>
          </div>

          <div className="flex flex-col mx-8 gap-y-4">
            <GroupPageInfoBox />
            {!!groupState.groupData ? (
              <ExternalTicketsProvider>
                <GroupLinkedActions groupId={groupState.groupData.id} />
              </ExternalTicketsProvider>
            ) : (
              <GroupLinkedActionsSkeleton />
            )}
          </div>
          <GroupEntries teamId={teamId} groupId={URLGroupId!} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default GroupPage;
