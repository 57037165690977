import { useContext } from 'react';
import AppContext from '../../v2/contexts/AppContext';
import { PageWrapper } from './PageWrapper';
import { PermissionsCard } from '../components/admin/PermissionsCard';
import AutoTaxonomyBuilderCard from '../components/admin/AutoTaxonomyBuilderCard';
import { NLPCard } from '../components/admin/NLPCard';
import { CopyDataCard } from '../components/admin/CopyDataCard';
import { MoveTeamCard } from '../components/admin/MoveTeamCard';
import CurrentTeamSettingsSection from '../components/admin/CurrentTeamSettingsSection';

const AdminPage = (): JSX.Element => {
  const { curTeamId } = useContext(AppContext);
  return (
    <PageWrapper title="Admin">
      <div className="flex flex-col gap-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 xxl:grid-cols-4 gap-4">
          <NLPCard />
          <CopyDataCard />
          <MoveTeamCard />
          <PermissionsCard />
        </div>
        {curTeamId ? <CurrentTeamSettingsSection /> : null}
        <div className="flex flex-row flex-wrap gap-x-3 w-full">
          <AutoTaxonomyBuilderCard />
        </div>
      </div>
    </PageWrapper>
  );
};

export default AdminPage;
