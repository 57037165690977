import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { TeamDefaultsCard } from './TeamDefaultsCard';

export const CurrentTeamSettingsSection = () => {
  const { currentTeam, curTeamId } = useValidTeamAppContext();
  return (
    <div className="text-licorice-noir mt-12">
      <h1 className="text-2xl font-recoleta mb-2">Current Team Admin Tools</h1>
      <p className="mb-4">
        Team: {currentTeam.name} ({curTeamId})
      </p>
      <TeamDefaultsCard />
    </div>
  );
};

export default CurrentTeamSettingsSection;
