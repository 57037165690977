import { Action, Integration_Type, Resource } from '../../generated/graphql';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';
import { useContext } from 'react';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import toast from 'react-hot-toast';
import { IntegrationAction } from '../pages/integrations/IntegrationAction';

export const FeedbackIntegrationCardBody = ({
  integration,
  redirect,
  openModalOrAuthRedirect,
}: {
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  openModalOrAuthRedirect: (integration: Integration_Type, redirect?: IIntegrationRedirect) => void;
}) => {
  const { hasPermission } = useContext(PermissionsContext);

  return (
    <div
      className="group col-span-1 flex h-full cursor-pointer select-none flex-col gap-y-3 border border-buttercream-frosting-100 rounded-lg bg-buttercream-frosting  text-center text-licorice-noir duration-300 "
      onClick={() => { 
        if (hasPermission(Resource.Integrations, Action.Create)) {
          openModalOrAuthRedirect(integration, redirect)
        } else {
          toast.error('You do not have permission to create a feedback integration.');
        }
      }}
    >
      <div className="integration-content flex flex-1 flex-col justify-center gap-y-3 p-8 pb-3">
        <img className="mx-auto h-auto w-10 flex-shrink-0" src={integration.logoUrl} alt="" />
        <h1 className="text-xl font-recoleta">{integration.title}</h1>
        <h1 className="text-md font-light">{integration.description}</h1>
      </div>
      <div>
        <div className="flex">
          <div className="flex flex-1 px-10">
            <IntegrationAction integration={integration}/>
          </div>
        </div>
      </div>
    </div>
  );
};
