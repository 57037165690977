import { FC, useEffect } from 'react';

export interface IRotatedContainer {
  children: React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
  rotation?: number;
  height?: string;
  width?: string;
  leftOffset?: string;
  topOffset?: string;
  rightOffset?: string;
  bottomOffset?: string;
}

export const RotatedContainer: FC<IRotatedContainer> = ({
  children,
  rotation = -90,
  height,
  width,
  leftOffset,
  topOffset,
  rightOffset,
  bottomOffset,
  containerRef,
}) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) containerRef.current.style.setProperty('--rotated-size', `${containerRef.current.offsetHeight}px`);
    });

    if (containerRef.current) resizeObserver.observe(containerRef.current);

    return () => {
      if (containerRef.current) resizeObserver.unobserve(containerRef.current);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="absolute inset-y-0 flex flex-row items-center justify-center"
      style={Object.fromEntries(
        Object.entries({
          width,
          height,
          left: leftOffset,
          top: topOffset,
          right: rightOffset,
          bottom: bottomOffset,
        }).filter(([_, value]) => value !== undefined)
      )}
    >
      <div
        className="transform origin-center flex flex-row items-center justify-center"
        style={{
          transform: `rotate(${rotation}deg)`,
          width: 'var(--rotated-size)',
          height: 'var(--rotated-size)',
        }}
      >
        {children}
      </div>
    </div>
  );
};
