import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { XAxisSelector } from './XAxisSelector';
import { Option } from './SelectorDropdown';
import { truncateAndEllipsis } from '../../../../v2/util';

export interface IBreakdownSelectorProps<T> {
  selectedBreakdown: Option<T>;
  isBreakdownSelectorModalOpen: boolean;
  openBreakdownSelectorModal: () => void;
  closeBreakdownSelectorModal: () => void;
  disabled?: boolean;
}

export const BreakdownSelector = <T,>({
  selectedBreakdown,
  isBreakdownSelectorModalOpen,
  openBreakdownSelectorModal,
  closeBreakdownSelectorModal,
  disabled = false,
}: IBreakdownSelectorProps<T>) => {
  return (
    <XAxisSelector onClick={isBreakdownSelectorModalOpen ? closeBreakdownSelectorModal : openBreakdownSelectorModal} disabled={disabled}>
      <div className="flex flex-row gap-x-2 justify-start items-center w-full">
        <div className="text-gray-secondary xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">{disabled ? 'Data from:' : 'Breakdown by:'}</div>
        <div className="text-licorice-noir font-medium xl:text-sm 2xl:text-md text-md whitespace-nowrap min-w-fit">
          {truncateAndEllipsis(selectedBreakdown.label, Math.max(Math.floor((window.innerWidth - 300) / 25), 10))}
        </div>
        {disabled ? null : <Cog6ToothIcon className={`h-4 w-4 ${isBreakdownSelectorModalOpen ? 'text-blood-orange-sorbet' : 'text-gray-secondary'}`} />}
      </div>
    </XAxisSelector>
  );
};
