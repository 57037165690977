import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import LoadingSpinner from '../../../v2/components/LoadingSpinner';
import { AppRoutes } from '../../../Routes';
import AppContext from '../../../v2/contexts/AppContext';
import TooltipIcon from '../../components/Modals/TooltipIcon';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { useDiscoverOrgsQuery } from '../../../generated/graphql';
import { OrgDiscoveryList } from '../../components/OrgDiscovery';

export const HomePageWithNoData = () => {
  const { currentOrg, curOrgId: orgId, orgsHaveLoaded } = useContext(AppContext);
  const discoverOrgsRes = useDiscoverOrgsQuery({});

  return (
    <div className="flex flex-col items-center text-licorice-noir h-[90vh]">
      {discoverOrgsRes.loading || !orgsHaveLoaded ? (
        <div className="flex h-[90vh] w-full flex-col items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-recoleta font-medium mb-10">Welcome to Unwrap!</h1>
          {(discoverOrgsRes.data?.discoverOrgs ?? []).length > 0 ? (
            <OrgDiscoveryList discoverableOrgs={discoverOrgsRes.data?.discoverOrgs ?? []} />
          ) : (
            <div className="flex flex-col h-full justify-center items-center gap-y-6 text-center">
              <div className="flex flex-row gap-x-1 items-baseline">
                {orgId ? (
                  <>
                    <h1 className="text-2xl font-medium">
                      Your organization <b>{currentOrg?.name}</b> currently has no views
                    </h1>
                    <TooltipIcon
                      tooltipContent={
                        'Views are the way you store, organize, explore and analyze your data. Start by creating one from your Organizations page.'
                      }
                    />
                  </>
                ) : (
                  <h1 className="text-2xl font-medium">
                    You are not part of any organizations. <br />
                    You can either ask for an invite from your company's organization admin, or contact Unwrap to create a new organization.
                  </h1>
                )}
              </div>
              {orgId ? (
                <NavLink to={`${AppRoutes.v3FullPath.organizations}/${currentOrg?.id}`}>
                  <Button variant={ButtonVariant.Primary} text={'Create a view from your Organization page +'} />
                </NavLink>
              ) : null}
            </div>
          )}
        </>
      )}
    </div>
  );
};
