import { useContext, useEffect, useState } from 'react';
import { useBoardDispatch, useBoardState } from '../../../../context/boardContext';
import { FilterContext, FilterDispatchContext } from '../../../../context/filterStatementContext';
import {
  FilterCollectionState,
  FilterGroupActionType,
  FilterNodeSchema,
  FilterNodeState,
  FilterStatementState,
} from '../../../../reducers/filterStatement/filterStatementReducer';
import { BoardActionTypes } from '../../../../reducers/boards/boardReducer';
import { useUpdateBoardFilterNodeMutation } from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';

const FilterSaveDiscard = () => {
  const boardState = useBoardState();
  const boardDispatch = useBoardDispatch();
  const [saveDiscardEnabled, setSaveDiscardEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { curTeamId } = useValidTeamAppContext();

  const filterState = useContext(FilterContext);
  const filterDispatch = useContext(FilterDispatchContext);
  const [updateBoardFilterNode] = useUpdateBoardFilterNodeMutation();

  const discardFilter = () => {
    /**
     * Reset the applied filter to the board filter
     */
    filterDispatch({ type: FilterGroupActionType.SetAppliedFilter, payload: { filterNode: JSON.parse(boardState.board.filterNode) } });
  };
  const saveFilterToBoard = () => {
    setIsSaving(true);
    updateBoardFilterNode({
      variables: {
        boardId: boardState.board.id,
        filterNode: JSON.stringify(stripFilter(filterState.appliedFilter)),
        teamId: curTeamId,
      },
      onCompleted: () => {
        boardDispatch({ type: BoardActionTypes.SetFilterNode, payload: { filterNode: JSON.stringify(stripFilter(filterState.appliedFilter)) } });
        setIsSaving(false);
      },
    });
  };

  /**
   * You want to know if the applied filters have diverged from the filters that are saved to the board.
   * If they have diverged, you want to show the ability to save the applied filters to the board.
   */
  useEffect(() => {
    if (areFiltersEqual(filterState.appliedFilter, boardState.board.filterNode)) {
      setSaveDiscardEnabled(false);
    } else {
      setSaveDiscardEnabled(true);
    }
  }, [filterState.appliedFilter, boardState.board.filterNode]);

  if (!saveDiscardEnabled) {
    return <div></div>;
  }

  return (
    <div className="flex flex-row gap-x-1 pt-2">
      <button
        className="text-blood-orange-sorbet hover:text-blood-orange-sorbet-darker hover:underline"
        onClick={() => {
          discardFilter();
        }}
      >
        Discard
      </button>
      <svg width="2" height="24" viewBox="0 0 2 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0V24" stroke="#D1D5DB" />
      </svg>
      <button
        className="text-licorice-noir hover:text-licorice-noir-lighter hover:underline flex items-center gap-x-1"
        onClick={() => {
          saveFilterToBoard();
        }}
      >
        <div className="pb-1">
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 5C4 4.44772 4.44772 4 5 4H7V7C7 7.55228 7.44772 8 8 8H15C15.5523 8 16 7.55228 16 7V4H16.1716C16.4368 4 16.6911 4.10536 16.8787 4.29289L19.7071 7.12132C19.8946 7.30886 20 7.56321 20 7.82843V19C20 19.5523 19.5523 20 19 20H18V13C18 12.4477 17.5523 12 17 12H7C6.44772 12 6 12.4477 6 13V20H5C4.44772 20 4 19.5523 4 19V5ZM8 20H16V14H8V20ZM14 4H9V6H14V4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V7.82843C22 7.03278 21.6839 6.26972 21.1213 5.70711L18.2929 2.87868C17.7303 2.31607 16.9672 2 16.1716 2H5Z"
              fill="#000000"
            />
          </svg>
        </div>
        Save filter to board
      </button>
      {isSaving && <div className="text-gray-secondary pl-2 animate-pulse">Saving...</div>}
    </div>
  );
};

/**
 * appliedFilter - {
 *     "type": "collection",
 *     "operator": "AND",
 *     "items": [
 *         {
 *     "type": "statement",
 *     "fieldName": "Entry.Group.id",
 *     "operator": "==",
 *     "value": "481035",
 *     "fieldDisplayName": "Group Title",
 *     "valueDisplayName": "Dark mode feature not switching automatically based on time of day.",
 *     "id": "b681663-632-45df-dd-37ba1ebd02c"
 *     }
 *     ],
 *     "id": "5fab-56c1-37-c14f-c6500bb2a5"
 * }
 * boardFilter - {"type":"collection","operator":"AND","items":[]}
 *
 * you see applied filter has a lot more 'window dressing' than the board filter.
 *
 * compare the two and see if they are equal.
 * strip out the id, fieldDisplayName, and valueDisplayName fields from the saved filter
 * then compare the two.
 */
const areFiltersEqual = (appliedFilter: FilterNodeState, boardFilter: string) => {
  const strippedAppliedFilter = stripFilter(appliedFilter);
  const strippedBoardFilter = JSON.parse(boardFilter);
  return JSON.stringify(strippedAppliedFilter) === JSON.stringify(strippedBoardFilter);
};

const stripFilter = (filter: FilterNodeState): FilterNodeSchema => {
  if (filter.type === 'statement') {
    const { id, fieldDisplayName, valueDisplayName, ...rest } = filter as FilterStatementState;
    return rest;
  } else {
    const { id, ...rest } = filter as FilterCollectionState;
    return {
      ...rest,
      items: rest.items.map((item) => stripFilter(item)),
    };
  }
};

export default FilterSaveDiscard;
