import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useIsMount } from '../../../v2/util';
import { WidgetsSection } from '../../components/boards/board/WidgetsSection';
import { PageWrapper } from '../PageWrapper';
import { BoardHeaderSection } from './sections/BoardHeaderSection';
import { useBoardState } from '../../../context/boardContext';
import toast from 'react-hot-toast';
import FilterBarWrapper from '../../components/filters/filterBar/FilterBar';
import DateFilterComponent from '../../components/filters/filterBar/DateFilterComponent';
import FilterSaveDiscard from './sections/FilterSaveDiscard';
import { ChartModal } from '../../components/charts/ChartModal';
import { defaultPlotUnitOptions, Y_Axis_DataToPlotUnit } from '../../components/standardCharts/chartElements/PlotUnitSelectorAndModal';
import { FilterContext } from '../../../context/filterStatementContext';
import { DateFilterUtility } from '../../filters/utilities/DateFilterUtility';
import { Chart_TypeToChartType } from '../../components/standardCharts/chartElements/ChartTypeOptions';

export const BoardPage = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const navigate = useNavigate();
  const boardState = useBoardState();

  useEffect(() => {
    boardState.closeChartModal();
  }, []);

  const filterState = useContext(FilterContext);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('teamId', teamId.toString());
    url.searchParams.set('orgId', orgId.toString());
    window.history.replaceState({}, '', url.toString());
  }, [orgId, teamId]);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.boards, { replace: true });
  }, [teamId]);

  if (boardState.board.id === -1) {
    /**
     * This _shouldn't_ happen. If it does, we'll just redirect to the boards page
     */
    toast.error('Board not found');
    navigate(AppRoutes.v3FullPath.boards);
    return <></>;
  }

  return (
    <PageWrapper title="">
      {boardState.selectedChart !== null && boardState.selectedChart.plotConfiguration ? (
        <ChartModal
          title={boardState.selectedChart?.title}
          plotUnitLabel={
            defaultPlotUnitOptions.find((option) => option.value == Y_Axis_DataToPlotUnit[boardState.selectedChart!.plotConfiguration.yAxisMetric])!.label
          }
          plotData={boardState.selectedChart}
          closeExpandedChart={() => boardState.closeChartModal()}
          chartType={Chart_TypeToChartType[boardState.selectedChart.plotConfiguration.chartType]!}
          handleSeriesClick={() => false}
          startDate={DateFilterUtility.getStartDate(filterState)?.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          endDate={(DateFilterUtility.getEndDate(filterState) ?? new Date())?.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
        />
      ) : null}
      <BoardPageBody />
    </PageWrapper>
  );
};

const BoardPageBody = () => {
  // Todo - need to load in default filters for the board into the filter state
  return (
    <>
      <div className="flex flex-col gap-y-3">
        <BoardTopSection />
        <WidgetsSection />
      </div>
    </>
  );
};

const BoardTopSection = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();

  return (
    <div className="flex flex-col gap-y-3">
      <BoardHeaderSection />
      <div className="pb-3">
        <div className="flex flex-col-reverse flex-wrap md:flex-nowrap md:flex-row items-start gap-x-5 w-full justify-between">
          <FilterBarWrapper />
          <div className="flex-1 flex items-start min-h-[24px]">
            <div className="h-[1px] w-full bg-buttercream-frosting-100 rounded-md mt-4" />
          </div>
          <DateFilterComponent teamId={teamId} />
        </div>
        <FilterSaveDiscard />
      </div>
    </div>
  );
};
