import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { useContext, useState, useEffect } from "react";
import EditIntegrationModal, { Page } from "../Modals/integrations/EditIntegrationModal";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { Integration_Type, Integration_Type_Requirement, Integration_Type_Requirement_Value } from "../../../generated/graphql";
import { FeedbackIntegrationState, FeedbackIntegrationRequirementValue } from "../../reducers/feedbackIntegration";


interface ConfigureIntegrationButtonProps {
    page: Page;
    buttonText: string;
    icon?: React.ReactNode;
    id: string;
}

export const ConfigureIntegrationButton = (props: ConfigureIntegrationButtonProps) => {
    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    
    // Move the initial requirements setup to a function
    const getInitialRequirements = () => (data.feedbackIntegration?.integrationTypeRequirements || []).map((requirement) => {
        let value: Integration_Type_Requirement_Value[] = [];
        const requirementValue = requirementValues.find((req) => req.requirement.id === requirement.id);
        if (requirementValue) {
            value = [{
                value: requirementValue.value,
                id: requirementValue.valueId ?? 0,
                requirement: {
                    ...requirement,
                    value: []
                }
            }]
        }
        return {
            ...requirement,
            value: value ?? []
        }
    });

    // Add useEffect to watch for context changes
    useEffect(() => {
        setName(data.feedbackIntegration?.integrationName || '');
        setRequirements(getInitialRequirements());
    }, [data.feedbackIntegration]); // Add dependency on feedbackIntegration

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [name, setName] = useState(data.feedbackIntegration?.integrationName || '');
    const requirementValues = data.feedbackIntegration?.requirementValues || [];
    const [requirements, setRequirements] = useState<Integration_Type_Requirement[]>(getInitialRequirements());

    const handleModalClose = (open: boolean) => {
        setIsModalOpen(open);
        if (!open) {
            // Reset state when modal is closed
            setName(data.feedbackIntegration?.integrationName || '');
            setRequirements(getInitialRequirements());
        }
    };

    const updateIntegration = (integrationType: Integration_Type, requirements: Integration_Type_Requirement[], name: string) => {
        const newRequirementValues = updateRequirements(requirements, data.feedbackIntegration);
        dispatch({
            type: FeedbackIntegrationActionType.UpdateRequirementsAndName,
            payload: {
                requirements: newRequirementValues,
                name: name
            }
        });
        setIsModalOpen(false);
    }

    return (
        <>
            <div id={`${props.id}`}>
                <Button 
                    onClick={() => handleModalClose(true)} 
                    shape={ButtonShape.Square} 
                    variant={ButtonVariant.Bordered} 
                    text={props.buttonText} 
                    icon={props.icon ?? undefined}
                />
            </div>
            <EditIntegrationModal
                name={name}
                setName={setName}
                modalOpen={isModalOpen}
                setModalOpen={handleModalClose}  // Use our new handler
                requirements={requirements}
                setRequirements={setRequirements}
                integrationType={
                    {
                        ...data.feedbackIntegration!.type,
                        orgExternalTicketsIntegration: [],
                        requirements: [],
                        teamIntegration: []
                    }
                }
                setSuccessModalOpen={setSuccessModalOpen}
                updateIntegration={updateIntegration}
                page={props.page}
            />
        </>
    );
}

const updateRequirements = ( requirements: Integration_Type_Requirement[], feedbackIntegration: FeedbackIntegrationState): FeedbackIntegrationRequirementValue[] => {
    let newRequirementValues: FeedbackIntegrationRequirementValue[] = requirements.filter(req => req.value.length > 0).map((req): FeedbackIntegrationRequirementValue => {
        let newReq: FeedbackIntegrationRequirementValue = {
            value: req.value[0].value,
            requirement: {
                id: req.id,
                key: req.key,
            }
        }
        if (req.value[0].id > 0) {
            newReq.valueId = req.value[0].id;
        }
        return newReq;
    })
    return newRequirementValues;
}